import {makeStyles} from "@material-ui/core";
import theme from "../../../../shared/themes";

const useStyles = makeStyles({
  animalIds: {
    minWidth: "600px",
    [theme.breakpoints.down("sm")] : {
      minWidth: "400px"
    },
    [theme.breakpoints.down("xs")] : {
      minWidth: "200px"
    }
  },
  animalIdLabelContainer: {
    paddingLeft: "8px",
  },
  animalAddButton: {
/*     position: "relative",
    left: "0px",
    marginBottom: "15px", */
  },
  dropdownContainer: {
    whiteSpace: "nowrap"
  }
});

export default useStyles;