import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "./RadioButtonGroupStyles";

interface RadioButtonGroupItem {
  name: string;
  label: string;
  disabled?: boolean;
  value: string | number;
}

interface RadioButtonGroupProps {
  label: string;
  onChange: any;
  groupItems: RadioButtonGroupItem[];
  defaultValue?: string | number;
  required?: boolean;
  errorText?: string;
  margin?: string;
  display?: string;
  horizontal?: boolean;
  disabled?: boolean;
  useControlled?: boolean;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  label,
  groupItems,
  onChange,
  defaultValue = "",
  margin = "5px 10px",
  display = "inline-block",
  required = false,
  errorText = "",
  horizontal = false,
  disabled = false,
  useControlled = false,
}) => {
  const classes = useStyles();

  const handleChange = (name, value) => {
    const item = groupItems.find((i) => i.name === name);
    const isNumber = typeof item?.value === "number";
    onChange(isNumber ? parseInt(value) : value);
  };

  return (
    <div style={{ display: display, margin: margin }}>
      {label && (
        <div className={classes.labelContainer}>
          <Typography
            display={"inline"}
            variant={"body1"}
            className={classes.label}
            style={{ color: "#535559" }}>
            {label}
          </Typography>
          {required && (
            <Typography
              display={"inline"}
              variant={"body1"}
              className={classes.label}
              style={{ color: "#cf2d3a", marginLeft: "4px" }}>
              *
            </Typography>
          )}
        </div>
      )}

      <FormControl
        component="fieldset"
        aria-labelledby="radiobutton-group-title"
        aria-describedby="radiobutton-group-description">
        <RadioGroup
          {...{
            ...(!useControlled
              ? { defaultValue: defaultValue.toString() }
              : { value: defaultValue.toString() }),
          }}
          onChange={(event) =>
            handleChange(event.target.name, event.target.value)
          }
          classes={{ root: horizontal ? classes.radioRoot : "" }}>
          {groupItems?.map((item, index) => (
            <FormControlLabel
              classes={{ label: classes.FormControlLabel }}
              key={index}
              name={item.name}
              value={item.value.toString()}
              control={
                <Radio
                  color="primary"
                  disabled={disabled || item.disabled}
                  disableFocusRipple={true}
                  disableRipple={true}
                  disableTouchRipple={true}
                />
              }
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
      {errorText && (
        <Alert
          icon={false}
          color={"error"}
          classes={{ standardError: classes.alert }}>
          {errorText}
        </Alert>
      )}
    </div>
  );
};

export default RadioButtonGroup;
