import { createSlice } from "@reduxjs/toolkit";
import {
  ICapitalInfo,
  IDebtInvoice,
  IInterestPayment,
} from "../models/Cooperative";

export interface ICooperativeState {
  capitalInfo?: ICapitalInfo;
  interestPayments?: IInterestPayment[];
  debtInvoices?: IDebtInvoice[];
  isLoading: boolean;
}

const cooperativeState: ICooperativeState = {
  capitalInfo: undefined,
  interestPayments: [],
  debtInvoices: [],
  isLoading: false,
};

export const cooperativeSlice = createSlice({
  name: "economy",
  initialState: cooperativeState,
  reducers: {
    capitalInfoReceived(state, action) {
      const data = action.payload.data;
      if (data) {
        state.capitalInfo = action.payload.data[0];
      }
    },
    interestPaymentsReceived(state, action) {
      state.interestPayments = action.payload.data;
    },
    debtInvociesReceived(state, action) {
      state.debtInvoices = action.payload.data;
    },
    fileDownloaded(state, action) {
      handleFileDownload(state, action);
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      // isCooperativeContent should not clear on state reset to avoid bugs
      return {
        ...cooperativeState,
      };
    });
  },
});

// TODO: remove these after 3.0, reference from helper.
const handleFileDownload = (state, action) => {
  const blob = new Blob([action.payload.data], {
    type: "application/pdf",
  });
  downloadPdf(blob, action.payload.filename);
};

// to instantly download without popup
const downloadPdf = (blob, filename) => {
  const fileURL = URL.createObjectURL(blob);
  //Open the URL on new Window
  let documentLink = document.createElement("a");
  documentLink.download = filename;
  documentLink.href = fileURL;
  documentLink.click();
};

//#endregion
export const {
  capitalInfoReceived,
  interestPaymentsReceived,
  debtInvociesReceived,
  fileDownloaded,
} = cooperativeSlice.actions;

export default cooperativeSlice.reducer;
