import React from "react";
import {Paper} from "@material-ui/core";
import useStyles from "./ContactsPageStyles";

const ContactsPage = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.content}>
            ContactsPage  
        </Paper>
    );
};

export default ContactsPage;