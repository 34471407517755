import { createSlice } from "@reduxjs/toolkit";
import { FrontpageInfoDTO } from "../../poultry/shared/models/FrontpageInfoDTO";

import { IBreedingBatch } from "../../poultry/shared/models/IBreedingBatch";
import { ISlaughterRejectedData } from "../../poultry/shared/models/ISlaughterRejectedData";
import {
  IHall,
  ISlaughterData,
} from "../../poultry/shared/models/ISlaughterData";
import { ISlaughter } from "../../poultry/shared/models/ISlaughter";
import { ChickNotificationDTO } from "../../poultry/shared/models/ChickNotificationDTO";
import { SlaughterNotificationDTO } from "../../poultry/shared/models/SlaughterNotificationDTO";
import { t } from "../services/langService";
import { getComparator, stableSort } from "../utils/sorting";
import Helper from "../utils/helper";

interface IPoultry {
  chickNotifications?: ChickNotificationDTO[];
  breedings: IBreedingBatch[];
  slaughterNotifications?: SlaughterNotificationDTO[];
  slaughters?: ISlaughter[];
  slaughterData?: ISlaughterData;
  diedInTransport: ISlaughterRejectedData[];
  meatInspectionRejected: ISlaughterRejectedData[];
  frontpageInfo?: FrontpageInfoDTO[];
  halls?: IHall[];
  filteredBreedings?: IBreedingBatch[];
  selectedHall: number;
}

const initialState: IPoultry = {
  chickNotifications: [],
  slaughterNotifications: [],
  breedings: [],
  slaughters: [],
  slaughterData: undefined,
  diedInTransport: [],
  meatInspectionRejected: [],
  frontpageInfo: [],
  halls: [],
  filteredBreedings: [],
  selectedHall: -1,
};

const slice = createSlice({
  name: "poultry",
  initialState: initialState,
  reducers: {
    chickNotificationsReceived: (state, action) => {
      state.chickNotifications = action.payload.data;
    },
    chickNotificationsCleared: (state) => {
      state.chickNotifications = initialState.chickNotifications;
    },
    breedingsReceived: (state, action) => {
      state.breedings = action.payload.data;
      state.filteredBreedings = state.breedings;
      state.selectedHall = initialState.selectedHall;
      // distinct halls from dataset
      let filteredHalls = Helper.arrayUnique(
        state.breedings.map((breeding) => ({
          hall: Number(breeding.hall),
          label: `${t("arrivingBatches_hall")} ${breeding.hall}`,
        }))
      );
      if (filteredHalls.length > 0) {
        // Add all selection
        filteredHalls.unshift({
          hall: -1,
          label: `${t("poultry_hall_filter_all")}`,
        });
      }

      state.halls = stableSort(filteredHalls, getComparator("asc", "hall"));
    },
    breedingsCleared: (state) => {
      state.breedings = initialState.breedings;
      state.filteredBreedings = initialState.filteredBreedings;
      state.selectedHall = initialState.selectedHall;
    },
    slaughterNotificationsReceived: (state, action) => {
      state.slaughterNotifications = action.payload.data;
    },
    slaughterNotificationsCleared: (state) => {
      state.slaughterNotifications = initialState.slaughterNotifications;
    },
    slaughterDataReceived: (state, action) => {
      if (action.payload.data.length > 0) {
        state.slaughterData = action.payload.data[0];
        state.slaughters = action.payload.data[0].slaughters;
        state.diedInTransport = action.payload.data[0].diedInTransport;
        state.meatInspectionRejected =
          action.payload.data[0].meatInspectionRejected;
      }
    },
    slaughterDataCleared: (state) => {
      state.slaughters = initialState.slaughters;
      state.slaughterData = initialState.slaughterData;
      state.diedInTransport = initialState.diedInTransport;
      state.meatInspectionRejected = initialState.meatInspectionRejected;
    },
    frontpageInfoReceived: (state, action) => {
      state.frontpageInfo = action.payload.data;
    },
    slaughterInfoSelected: (state, action) => {
      const breeding = state.breedings.find(
        (breeding) => breeding.breedingId === Number(action.payload.breedingId)
      );
      if (breeding) {
        if (breeding.slaughterData && breeding.slaughterData.length > 0) {
          state.slaughterData = breeding?.slaughterData[0];
        }

        if (breeding.slaughters) {
          state.slaughters = breeding.slaughters;
        }

        if (breeding.diedInTransport) {
          state.diedInTransport = breeding.diedInTransport;
        }

        if (breeding.meatInspectionRejected) {
          state.meatInspectionRejected = breeding.meatInspectionRejected;
        }
      }
    },
    hallFilterSelected: (state, action) => {
      if (action.payload.selectedHall < 0) {
        state.filteredBreedings = state.breedings;
      } else {
        state.filteredBreedings = state.breedings.filter(
          (breeding) =>
            Number(breeding.hall) === Number(action.payload.selectedHall)
        );
      }
      state.selectedHall = action.payload.selectedHall;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  chickNotificationsReceived,
  chickNotificationsCleared,
  breedingsReceived,
  breedingsCleared,
  slaughterNotificationsReceived,
  slaughterNotificationsCleared,
  frontpageInfoReceived,
  slaughterDataReceived,
  slaughterDataCleared,
  slaughterInfoSelected,
  hallFilterSelected,
} = slice.actions;

export default slice.reducer;
