import { t } from "../services/langService";

export interface IInvoice {
  invoiceType: InvoiceType;
  invoiceNumber: number;
  voucherNumber: number;
  dueDate: Date;
  voucherDate: Date;
  amountIncludingTaxes: number;
  openBalance: number;
  interestRate: number;
  paymentMethod: InvoicePaymentMethodType;
  quantity: number;
  amountWithoutTaxes: number;
  taxAmount: number;
}

export enum InvoiceType {
  All = 1,
  AdvancedInvoice,
  BreedingAnimalPayment,
  OtherInvoice,
}

export enum InvoicePaymentMethodType {
  Unknown,
  Cash,
  WithdrawnCash,
  Credit = 20,
  BankCredit,
  BreedingAnimalCredit,
  HKScanAnimalFunding,
}

export const getInvoiceTypeTranslation = (el: any) => {
  return t(`economy_invoiceType_${InvoiceType[el]}`);
};

export const getInvoicePaymentMethodTypeTranslation = (el: any) => {
  return t(`economy_paymentMethod_${InvoicePaymentMethodType[el]}`);
};
