import React, { useState, useEffect, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import Button from "../../shared/components/Button/Button";
import useStyles from "./ForwardingNotificationPageStyles";
import * as api from "../../shared/store/apiActions";
import { t } from "../../shared/services/langService";
import { getOrganizationByProducerNumber } from "../../shared/store/user";
import FarmSelection from "../../shared/components/FarmSelection/FarmSelection";
import AddAnimals from "../shared/components/AddAnimals/AddAnimals";
import {
  routeLeavingGuardActivated,
  routeLeavingGuardForced,
  routeLeavingGuardDeactivated,
} from "../../shared/store/ui/common";
import { beefForwardingNotificationCleared } from "../../shared/store/beef";
import InfoModal from "../../shared/components/InfoModal/InfoModal";
import { errorHandled } from "../../shared/store/api";
import scrollToLocation from "../../shared/utils/scrollToLocation";
import BeefForwardingNotificationDTO from "../shared/models/BeefForwardingNotificationDTO";
import InputField from "../../shared/components/InputField/InputField";
import moment from "moment";
import { RootState } from "../../shared/store/rootReducer";
import nautaValitysElainlajit from "../shared/enums/nautaValitysElainlajit";
import CalfMedicalQuestions from "../../shared/components/CalfMedicalQuestions/CalfMedicalQuestions";
import CalfMedicalQuestionData, {
  CalfMedicalData,
} from "../shared/models/CalfMedicalQuestionData";

import Helper from "../../shared/utils/helper";

const ForwardingNotificationPage = () => {
  const classes = useStyles();
  const childRef = useRef<any>();
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { beefForwardingNotification } = useSelector(
    (state: RootState) => state.beef
  );
  const { livestockVenues: farms } = useSelector(
    getOrganizationByProducerNumber(producerNumber, "Nauta")
  );
  const [notificationStatus, setNotificationStatus] = useState("");
  const [
    forceCompletedStateAnimalSave,
    setForceCompletedStateAnimalSave,
  ] = useState(false);
  const { error } = useSelector((state: RootState) => state.api);
  const [infoModal, setInfoModal] = useState<any>({
    open: false,
    title: "",
    text: "",
    okButtonText: "",
    onClose: () => {},
  });
  const [isDirty, setIsDirty] = useState(0);
  const [reset, setReset] = useState(new Date().getTime());
  const [selectedFarm, setSelectedFarm] = useState<any>({
    current: "",
    prev: "",
  });
  const [addedAnimals, setAddedAnimals] = useState<any[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const dispatch = useDispatch();
  const { notificationId } = useParams();
  const [medicalDataOfAnimals, setmedicalDataOfAnimals] = useState<
    CalfMedicalQuestionData
  >(new CalfMedicalQuestionData());

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const animalTypesThatHaveHealthData = [
    nautaValitysElainlajit.Rotusonnivasikka,
    nautaValitysElainlajit.Rotulehmavasikka,
  ];

  const filterAnimalsHealthData = (animals: any[]) => {
    return animals.filter((animal) =>
      animalTypesThatHaveHealthData.includes(Number(animal.animalType))
    );
  };

  useEffect(() => {
    const validAnimals = filterAnimalsHealthData(addedAnimals);

    const animalBirthIds = medicalDataOfAnimals.animalMedicalDataRows.map(
      (data) => data.birthId
    );
    const uniqueBirthIdsOnGrid = animalBirthIds.filter(onlyUnique);

    let existingGridRows = medicalDataOfAnimals.animalMedicalDataRows.filter(
      (row: any) => uniqueBirthIdsOnGrid.find((birthId) => row.id === birthId)
    );

    if (validAnimals.length > uniqueBirthIdsOnGrid.length) {
      const animalsnotInGrid = validAnimals.filter((animal) =>
        medicalDataOfAnimals.animalMedicalDataRows.find(
          (row) => row.birthId !== animal.id
        )
      );

      let newGridData = [] as CalfMedicalData[];

      if (validAnimals.length > 0 && animalsnotInGrid.length <= 0) {
        newGridData = validAnimals.map((animal) => {
          return {
            reason: "",
            productName: "",
            medicationDate: "",
            additionalInformation: "",
            birthId: animal.id,
          } as CalfMedicalData;
        });
      } else {
        newGridData = animalsnotInGrid.map((animal) => {
          return {
            reason: "",
            productName: "",
            medicationDate: "",
            additionalInformation: "",
            birthId: animal.id,
          } as CalfMedicalData;
        });
      }

      const uniqueNewAnimalRows = newGridData.filter(
        (newAnimal: CalfMedicalData) =>
          !medicalDataOfAnimals.animalMedicalDataRows.find(
            ({ birthId }) => newAnimal.birthId === birthId
          )
      );

      const updatedData = [
        ...medicalDataOfAnimals.animalMedicalDataRows,
        ...uniqueNewAnimalRows,
      ];

      const newMedicalDataOfCalvesData: CalfMedicalQuestionData = {
        ...medicalDataOfAnimals,
        animalMedicalDataRows: updatedData,
      };
      setmedicalDataOfAnimals(newMedicalDataOfCalvesData);
      return;
    } else {
      existingGridRows = medicalDataOfAnimals.animalMedicalDataRows.filter(
        (row: any) => validAnimals.find(({ id }) => row.birthId === id)
      );
      const updatedData = [...existingGridRows];
      const newMedicalDataOfCalvesData: CalfMedicalQuestionData = {
        ...medicalDataOfAnimals,
        animalMedicalDataRows: updatedData,
      };
      setmedicalDataOfAnimals(newMedicalDataOfCalvesData);
      return;
    }
  }, [addedAnimals]);

  const handleMedicalDataOfAnimalsChange = (
    newMedicalDataOfCalvesData: CalfMedicalQuestionData
  ) => {
    setIsDirty(3);
    setmedicalDataOfAnimals(newMedicalDataOfCalvesData);
  };

  useEffect(() => {
    if (notificationId) {
      dispatch(api.beef.getBeefForwardingNotification(notificationId));
      return () => dispatch(beefForwardingNotificationCleared());
    }
    return () => {
      // This is its cleanup.
    };
  }, [producerNumber]);

  useEffect(() => {
    if (notificationId) {
      const nautaValitysIlmoitusRivit =
        beefForwardingNotification?.animalData?.nautaValitysIlmoitusRivit;
      const livestockVenue = beefForwardingNotification?.animalData?.pitopaikka;
      const ilmoituksenTila =
        beefForwardingNotification?.animalData?.ilmoituksenTila;

      if (nautaValitysIlmoitusRivit && nautaValitysIlmoitusRivit?.length > 0) {
        const animals = nautaValitysIlmoitusRivit.map((r) => ({
          animalType: r.elainlaji.toString(),
          id: r.syntymatunnus,
          nupoutus: r.isNupoutettu,
          selectedWeek: r.toivottuVko.toString(),
          breed: r.rotu,
          birthday: r.syntymaPvm,
          lisatiedot: additionalInfo,
          rowId: r.rowId,
          rowDispatchState: r.rowDispatchState,
          kuormaValmis: (r as any).kuormaValmis,
          kuormausAika: (r as any).kuormausAika,
        }));

        setNotificationStatus(ilmoituksenTila ?? "");
        setSelectedFarm((prevState) => ({
          ...prevState,
          current: livestockVenue,
        }));
        setAddedAnimals(animals.sort(getAnimalOrdering));
        setAdditionalInfo(
          beefForwardingNotification?.animalData?.lisatiedot ?? ""
        );
      }

      if (beefForwardingNotification?.animalMedicalData) {
        setmedicalDataOfAnimals(beefForwardingNotification?.animalMedicalData);
      } else {
        setmedicalDataOfAnimals(new CalfMedicalQuestionData());
      }
    } else {
      setmedicalDataOfAnimals(new CalfMedicalQuestionData());
    }
  }, [beefForwardingNotification]);

  useEffect(() => {
    if (isDirty > 2) dispatch(routeLeavingGuardActivated());
  }, [isDirty]);

  useEffect(() => {
    if (error?.url?.indexOf("beef/forwarding") > -1 && error.status === 409) {
      dispatch(errorHandled());
      for (let status of ["av", "lo"]) {
        const animals = Object.keys(error.data[status] || {}).map((id) => ({
          id: id,
          notificationId:
            status === "lo"
              ? error.data[status][id].details[0].notificationId
              : error.data[status][id].notificationId[0],
        }));
        if (animals.length > 0) {
          showAnimalAlreadyExistModal(status, animals);
          break;
        }
      }
    }
  }, [error]);

  const onHandleCalfDataError = (message) => {
    //dispatch(errorHandled());
    setInfoModal({
      open: true,
      title: t("animalMedicalData_deleteWarningTitle"),
      text: t("animalMedicalData_deleteWarningContent"),
      okButtonText: t("ui_ok"),
      onClose: () =>
        setInfoModal((prevState) => ({ ...prevState, open: false })),
    });
  };

  const showAnimalAlreadyExistModal = (status, animals) => {
    const animalIdsText =
      status === "lo"
        ? animals.map((a) => a.id).join(", ")
        : animals
            .map(
              (a) =>
                `${t("animalNotification_animalIdAlreadyAdded", {
                  0: a.id,
                  1: a.notificationId,
                })}.\n`
            )
            .reduce((pre, cur) => pre + cur, "");

    setInfoModal({
      open: true,
      title:
        status === "lo"
          ? t("animalNotification_animalsAlreadyAddedLOTitle")
          : t("animalNotification_animalsAlreadyAddedTitle"),
      text:
        status === "lo"
          ? t("animalNotification_animalsAlreadyAddedLOText", {
              0: animalIdsText,
            })
          : `${animalIdsText} ${t(
              "animalNotification_animalsAlreadyAddedText"
            )}`,
      cancelButtonText:
        status === "lo" ? t("animalNotification_rejectBirthId") : undefined,
      okButtonText:
        status === "lo" ? t("animalNotification_acceptBirthId") : t("ui_ok"),
      onClose: (button) => {
        setAddedAnimals((prevState) =>
          [
            ...prevState.map((a) => ({
              ...a,
              tila:
                animals.map((animal) => animal.id).indexOf(a.id) > -1
                  ? status
                  : a.tila,
            })),
          ].sort(getAnimalOrdering)
        );
        setInfoModal((prevState) => ({ ...prevState, open: false }));
        if (status === "av") scrollToLocation("#added-animals");
        else if (status === "lo" && button === "ok")
          setForceCompletedStateAnimalSave(true);
      },
    });
  };

  const handleFarmChange = (value, prevValue) => {
    setSelectedFarm({ current: value, prev: prevValue });
    if (isDirty) {
      setInfoModal({
        open: true,
        title: t("farmSelection_farmChangeTitle"),
        text: t("farmSelection_farmChangeText"),
        cancelButtonText: t("ui_cancel"),
        okButtonText: t("ui_rejectChanges"),
        onClose: (button) => handleInfoModalClose(button),
      });
    }
  };

  const resetForm = () => {
    setIsDirty(0);
    setAddedAnimals([]);
    setReset(new Date().getTime());
  };

  const handleInfoModalClose = (button) => {
    setInfoModal({ open: false });
    if (button === "ok") {
      resetForm();
      setSelectedFarm((prevState) => ({
        ...prevState,
        current: prevState.current,
      }));
    } else {
      setSelectedFarm((prevState) => ({
        ...prevState,
        current: prevState.prev,
      }));
    }
  };

  const handleCancel = () => {
    dispatch(routeLeavingGuardForced());
  };

  const handleAnimalRemoved = (id) => {
    setAddedAnimals(
      addedAnimals.filter((a) => a.id !== id).sort(getAnimalOrdering)
    );
    setIsDirty(3);
  };

  const handleAnimalEdited = (id, propertyValues) => {
    const modifiedAnimal = { ...addedAnimals.find((a) => a.id === id) };
    for (let p of propertyValues) modifiedAnimal[p[0]] = p[1];
    setAddedAnimals(
      [...addedAnimals.filter((a) => a.id !== id), modifiedAnimal].sort(
        getAnimalOrdering
      )
    );
    setIsDirty(3);
  };

  const handleSubmit = () => {
    const animalMedicalData =
      medicalDataOfAnimals.medicated &&
      !medicalDataOfAnimals.savedInNaseva &&
      medicalDataOfAnimals.animalMedicalDataRows.length >= 1
        ? ({
            ...{
              ...medicalDataOfAnimals,
              animalMedicalDataRows: medicalDataOfAnimals.animalMedicalDataRows.map(
                (animalRow) => {
                  return {
                    ...animalRow,
                    medicationDate:
                      animalRow.medicationDate === ""
                        ? "" || undefined || null
                        : moment(
                            animalRow.medicationDate,
                            Helper.getDateFormat(animalRow.medicationDate)
                          ).format("DD.MM.YYYY"),
                  };
                }
              ),
            },
            producerNumber: Number(producerNumber),
          } as CalfMedicalQuestionData)
        : ({
            ...{
              ...medicalDataOfAnimals,
              animalMedicalDataRows: [] as CalfMedicalData[],
            },
            producerNumber: Number(producerNumber),
          } as CalfMedicalQuestionData);

    const notification: BeefForwardingNotificationDTO = {
      animalData: {
        elaimet: addedAnimals.map((a) => ({
          elainlaji: parseInt(a.animalType),
          syntymatunnus: a.id,
          nupoutus: a.nupoutus,
          toivottuVko: parseInt(a.selectedWeek),
          rowId: a.rowId,
          kuormaValmis: a.kuormaValmis,
          kuormausAika: a.kuormausAika,
        })),
        pitopaikka: selectedFarm.current,
        lisatiedot: additionalInfo,
        forceCompletedStateAnimalSave: forceCompletedStateAnimalSave,
      },
      medicalDataOfAnimals: animalMedicalData,
    };

    if (notificationId)
      dispatch(api.beef.updateBeefForwardingInfo(notification, notificationId));
    else dispatch(api.beef.createBeefForwardingNotification(notification));
    dispatch(routeLeavingGuardDeactivated());
  };

  const getAnimalOrdering = (a, b) => {
    if (notificationId) {
      if (a.rowDispatchState === "os" && b.rowDispatchState !== "os") return -1;

      if (a.rowDispatchState !== "os" && b.rowDispatchState === "os") return 1;

      if (a.rowDispatchState === "av" && b.rowDispatchState !== "av") return -1;

      if (a.rowDispatchState !== "av" && b.rowDispatchState === "av") return 1;

      if (a.rowDispatchState === "os" && b.rowDispatchState === "os") {
        if (a.kuormausAika.length === 0 && b.kuormausAika.length > 0) {
          return 1;
        } else if (a.kuormausAika.length > 0 && b.kuormausAika.length === 0) {
          return -1;
        } else if (a.kuormausAika.length === 0 && b.kuormausAika.length === 0) {
          return 0;
        }

        if (
          moment(a.kuormausAika[0].pvm).isBefore(moment(b.kuormausAika[0].pvm))
        ) {
          return -1;
        }
        if (
          moment(a.kuormausAika[0].pvm).isAfter(moment(b.kuormausAika[0].pvm))
        ) {
          return 1;
        }
      }
    }
    return a.id > b.id ? 1 : -1;
  };

  return (
    <Fragment>
      <InfoModal
        open={infoModal.open}
        title={infoModal.title}
        text={infoModal.text}
        cancelButtonText={infoModal.cancelButtonText}
        okButtonText={infoModal.okButtonText}
        onClose={infoModal.onClose}
      />

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader level="1">
            {notificationId
              ? t("animalNotification_modifyForwardingNotification", {
                  0: notificationId,
                })
              : t("animalNotification_newForwardingNotification")}
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <PageHeader level="2">
            {t("animalNotification_farmInformation")}
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <FarmSelection
            farms={farms}
            selectedFarm={selectedFarm.current}
            onChange={(value, prevValue) => handleFarmChange(value, prevValue)}
            readonly={Boolean(notificationId) || addedAnimals.length > 0}
          />
        </Grid>

        {selectedFarm.current && (
          <Fragment>
            <Grid item xs={12}>
              <PageHeader level="2" id="added-animals">
                {t("animalNotification_animalInformation")}
              </PageHeader>
            </Grid>

            <Grid item xs={12}>
              <AddAnimals
                notificationType="forwarding"
                key={reset}
                addedAnimals={addedAnimals}
                modify={Boolean(notificationId)}
                readonly={notificationStatus === "os"}
                onAnimalsAdded={(animals) => {
                  setAddedAnimals(
                    [...addedAnimals, ...animals].sort(getAnimalOrdering)
                  );
                  setIsDirty(3);
                }}
                onAnimalTypeChange={(e) => setIsDirty(2)}
                onWeekChange={(e) => setIsDirty(2)}
                onAnimalRemoved={(id) => handleAnimalRemoved(id)}
                onAnimalEdited={(id, propertyValues) =>
                  handleAnimalEdited(id, propertyValues)
                }
              />
            </Grid>
            {addedAnimals?.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <InputField
                  id="forwarding-notification-additionalinformation-input"
                  margin={""}
                  label={t("animalNotification_additionalInformation")}
                  multiline={true}
                  rows={3}
                  readonly={notificationStatus === "os"}
                  value={additionalInfo}
                  onChange={(e) => {
                    setAdditionalInfo(e.target.value);
                    setIsDirty(3);
                  }}
                  fullWidth={true}
                />
              </Grid>
            )}
            {filterAnimalsHealthData(addedAnimals).length > 0 && (
              <Grid item xs={12}>
                <CalfMedicalQuestions
                  key={reset}
                  ref={childRef}
                  handleError={onHandleCalfDataError}
                  onMedicalDataOfAnimalsChanged={
                    handleMedicalDataOfAnimalsChange
                  }
                  medicalDataOfAnimals={medicalDataOfAnimals}
                  onValidate={(isValid) => setIsDirty(isValid ? 4 : 3)}
                  readonly={notificationStatus === "os"}
                />
              </Grid>
            )}
          </Fragment>
        )}

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item xs={12}>
            <Button
              id="forwarding-notification-cancel-button"
              type="cancel"
              style={{
                marginRight: "20px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              onClick={handleCancel}>
              {t("ui_cancel")}
            </Button>

            <Button
              id="forwarding-notification-save-button"
              type="ok"
              disabled={
                addedAnimals.length === 0 ||
                addedAnimals.some((a) => a.tila === "av") ||
                (notificationId && isDirty < 3) ||
                (filterAnimalsHealthData(addedAnimals).length > 0 &&
                  medicalDataOfAnimals.animalMedicalDataRows.length > 0 &&
                  (medicalDataOfAnimals.gmoNotUsed === undefined ||
                    medicalDataOfAnimals.grazedWithMother === undefined ||
                    medicalDataOfAnimals.savedInNaseva === undefined)) ||
                (filterAnimalsHealthData(addedAnimals).length > 0 &&
                  medicalDataOfAnimals.medicated === true &&
                  medicalDataOfAnimals.savedInNaseva === false &&
                  medicalDataOfAnimals.animalMedicalDataRows.every(
                    (row) => row.medicationDate === ""
                  )) ||
                (filterAnimalsHealthData(addedAnimals).length > 0 &&
                  medicalDataOfAnimals.animalMedicalDataRows.length > 0 &&
                  medicalDataOfAnimals.medicated === undefined &&
                  medicalDataOfAnimals.savedInNaseva === false)
              }
              onClick={handleSubmit}>
              {t("animalNotification_saveNotification")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ForwardingNotificationPage;
