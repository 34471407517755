import { Grid } from "@material-ui/core";
import { FieldHookConfig, Form, Formik, useField } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import InputField, {
  InputFieldProps,
} from "../../../../shared/components/InputField/InputField";
import { t } from "../../../../shared/services/langService";
import { isRequiredInYupSchema, REQUIRED } from "../../utils/validationSchemas";

export const eInvoiceFormValidationSchema = Yup.object().shape({
  brokerIdentifier: Yup.string().required(() => t(REQUIRED)),
  eInvoiceOperator: Yup.string().required(() => t(REQUIRED)),
  eInvoiceAddress: Yup.string().required(() => t(REQUIRED)),
});

const eInvoiceInitialValues = {
  brokerIdentifier: "",
  eInvoiceOperator: "",
  eInvoiceAddress: "",
};

const EInvoiceForm = (props) => {
  const { handleSubmit } = props;
  const [initialValues, setInitialValues] = useState(eInvoiceInitialValues);

  const MyInputField = (props: InputFieldProps & FieldHookConfig<string>) => {
    const [field, meta] = useField(props);

    return (
      <InputField
        {...props}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        errorText={meta.touched && meta.error}
        required={isRequiredInYupSchema(
          eInvoiceFormValidationSchema,
          props.name
        )}
        singleLine
        disabled={props.disabled ?? false}
      />
    );
  };
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Formik
        initialValues={initialValues}
        validationSchema={eInvoiceFormValidationSchema}
        onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <Form id="einvoice-form" noValidate>
            <MyInputField
              id="brokerIdentifier"
              name="brokerIdentifier"
              label={t("eInvoice_brokerIdentifier")}
              fullWidth
            />
            <MyInputField
              id="eInvoiceOperator"
              name="eInvoiceOperator"
              label={t("eInvoice_eInvoiceOperator")}
              fullWidth
            />
            <MyInputField
              id="eInvoiceAddress"
              name="eInvoiceAddress"
              label={t("eInvoice_eInvoiceAddress")}
              fullWidth
            />
          </Form>
        </Grid>
      </Formik>
    </Grid>
  );
};
export default EInvoiceForm;
