import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: "1em",
  },
  thumbnailImage: {
    "&:hover": {
      border: "2px",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
      backgroundColor: "#efefef",
    },
  },
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 2,
    cursor: "pointer",
    overflow: "auto",
    objectFit: "fill",
  },
  overlayImage: {
    width: "calc(90vw)",
  },
  closeOverlayButton: {
    position: "fixed",
    bottom: "center",
    left: "calc(3vw)",
  },
}));

export default useStyles;
