import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { t } from "../services/langService";

export interface IWithTimeFrameProps {
  startTime?: any;
  endTime?: any;
  [x: string]: any;
}

const dateIsGreater = (startDate, endDate) => {
  if (moment(startDate) > moment(endDate)) {
    return true;
  } else {
    return false;
  }
};

export interface ITimeframePickerUserProps {
  currentStartDate?: string;
  currentEndDate?: string;
  fetchCounter?: number;
  startTimeOnChange?: Function;
  endTimeOnChange?: Function;
  onFetchClick?: Function;
  errorMsg?: string;
}

type ErrorMessageArray = {
  [key: string]: string;
};

// This only injects required parameters.
//The rendering object position can change within using component
const withTimeframe = <P extends object & IWithTimeFrameProps>(
  PageComponent: React.ComponentType<P>
): React.FC<P & IWithTimeFrameProps> => {
  return ({
    startTime = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endTime = moment().endOf("month").format("YYYY-MM-DD"),
    ...props
  }: IWithTimeFrameProps) => {
    const [currentStartDate, setCurrentStartTime] = useState(startTime); // moment date or parsable
    const [currentEndDate, setCurrentEndTime] = useState(endTime); // moment date or parsable
    const [errorMsg, setErrorMsg] = useState<string>("");

    const [fetchCounter, setFetchCounter] = useState(0);

    const errorMessages: ErrorMessageArray = {
      overlap: t("withTimeFrame_date_overlap_forbidden"),
    };

    // notify user of forbidden operation, clear after time
    useEffect(() => {
      if (errorMsg) {
        setTimeout(() => {
          setErrorMsg("");
        }, 10000);
      }
    }, [errorMsg]);

    const handleStartTimeChange = (momentDate) => {
      if (momentDate) {
        setCurrentStartTime(momentDate.startOf("month").format("YYYY-MM-DD"));
      }
    };

    const handleEndTimeChange = (momentDate) => {
      if (momentDate) {
        setCurrentEndTime(momentDate.endOf("month").format("YYYY-MM-DD"));
      }
    };

    const onButtonClick = () => {
      if (!dateIsGreater(currentStartDate, currentEndDate)) {
        setFetchCounter(fetchCounter + 1);
        setErrorMsg("");
      } else {
        setErrorMsg(errorMessages.overlap);
      }
    };

    return (
      <Fragment>
        <PageComponent
          {...(props as P)}
          currentStartDate={currentStartDate}
          currentEndDate={currentEndDate}
          fetchCounter={fetchCounter}
          startTimeOnChange={handleStartTimeChange}
          endTimeOnChange={handleEndTimeChange}
          onFetchClick={onButtonClick}
          errorMsg={errorMsg}
        />
      </Fragment>
    );
  };
};

export default withTimeframe;
