import React, { Fragment, useEffect, useState } from "react";
import TablePagination from "../components/TablePagination/TablePagination";
import { t } from "../services/langService";

export interface WithTablePaginationTableInterfaceProps {
  rows: any[] | null;
  onExpansionChanged?: Function;
}

export interface WithTablePaginationProps {
  rows: any[] | null;
  rowWrappers?: any[];
  rowFormatters?: any[];
  rowsPerPage?: number;
  labelRowsPerPage?: string;
  forceResetPagination?: number;
  isShowAllDefault?: boolean;
}

const withTablePagination = <P extends object>(
  TableComponent: React.ComponentType<P>
): React.FC<P & WithTablePaginationProps> => {
  return ({
    rows = [],
    rowsPerPage: defaultRowsPerPage = 10,
    labelRowsPerPage = t("ui_rows"),
    rowWrappers = [],
    rowFormatters = [],
    forceResetPagination = 0,
    isShowAllDefault = false,
    ...props
  }: WithTablePaginationProps) => {
    const [pagination, setPagination] = useState<any>({
      rowsPerPage: defaultRowsPerPage,
      page: 0,
    });
    const [showPagination, setShowPagination] = useState<boolean>(true);
    const [isShowAllSelection, setIsAllSelection] = useState<boolean>(
      isShowAllDefault
    );

    useEffect(() => {
      handleChangePage("", 0);
    }, [forceResetPagination]);

    const handleChangePage = (e, page) => {
      setPagination((prevState) => ({ ...prevState, page }));
    };

    const onChangeRowsPerPage = (e) => {
      // We figure out is the index last index (all selection)
      // Had to do workaround since pagination component API is a bit restricted
      let index = e.nativeEvent.target.selectedIndex;
      if (index + 1 === e.target.length) {
        setIsAllSelection(true);
      } else {
        setIsAllSelection(false);
      }

      setPagination({
        page: 0,
        rowsPerPage: Number(e.target.value),
      });
    };

    const getSlice = (data: any): any[] | null => {
      return (
        data?.slice(
          pagination.page * pagination.rowsPerPage,
          pagination.page * pagination.rowsPerPage + pagination.rowsPerPage
        ) || null
      );
    };

    return (
      <Fragment>
        <TableComponent
          {...(props as P)}
          rows={isShowAllSelection ? rows : getSlice(rows)}
          rowWrappers={isShowAllSelection ? rows : getSlice(rowWrappers)}
          rowFormatters={isShowAllSelection ? rows : getSlice(rowFormatters)}
          onExpansionChanged={(isExpanded) => setShowPagination(isExpanded)}
          page={pagination.page}
        />

        {showPagination && (
          <TablePagination
            rows={rows}
            rowsPerPage={
              isShowAllSelection ? rows?.length : pagination.rowsPerPage
            }
            page={pagination.page}
            onChangePage={(e, page) => handleChangePage(e, page)}
            onChangeRowsPerPage={onChangeRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
          />
        )}
      </Fragment>
    );
  };
};

export default withTablePagination;
