import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  formControl: {
    appearance: "none",
    backgroundClip: "padding-box",
    backgroundImage: "none",
    borderBottomColor: "rgba(0, 0, 0, 0.15)",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: 4,
    padding: "3px 10px",
    marginTop: "8px",
    marginBottom: "8px" 
  },
  formControlDisabled: {
    backgroundColor: "#f8f8f8",
  },
  labelContainer: {
    display: "block"
  },
  label: {
    fontFamily: "Avenir Next W1G Bold",
    letterSpacing: "0.28px",
    fontSize: "0.8rem",
    fontWeight: "bold"
  },
  root: {
    letterSpacing: "0.33px",
    fontSize: "0.9rem",
    color: "#4e5054",
    fontWeight: "normal",
    lineHeight: "1.5em",
  },
  inputMultiline: {
    "&::placeholder": {
      fontStyle: "italic"
    }
  },
  error: {
    color: "#650000"
  },
  success: {
    color: "#006500"
  }
});

export default useStyles;