import { Grid } from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "../../store/rootReducer";
import {
  IErrorHandler,
  ISuccessHandler,
} from "../../utils/notificationHandler";

import NotificationBanner from "../NotificationBanner/NotificationBanner";

interface INotificationsProps {
  onChange: any;
  successHandlers?: { (object: ISuccessHandler): void }[];
  errorHandlers?: { (object: IErrorHandler): void }[];
}

const Notification: React.FC<INotificationsProps> = ({
  onChange,
  successHandlers,
  errorHandlers,
}) => {
  const { error, success } = useSelector((state: RootState) => state.api);
  const [showError, setShowError] = useState<any>({});
  const [showSuccess, setShowSuccess] = useState<any>({});
  const history = useHistory();
  const { pathname } = useLocation();

  const errorCallback = useCallback(() => {
    errorHandlers?.forEach((errorHandler) => {
      errorHandler({ error, setShowError, onChange });
    });
  }, [error, setShowError, onChange]);

  const successCallback = useCallback(() => {
    successHandlers?.forEach((successHandler) => {
      successHandler({ success, setShowSuccess, onChange });
    });
  }, [success, setShowSuccess, onChange]);

  useEffect(errorCallback, [error]);

  useEffect(successCallback, [success]);

  useEffect(() => {
    if (showSuccess) {
      setShowSuccess({});
      onChange(false);
    }
    if (showError) {
      setShowError({});
      onChange(false);
    }
  }, [pathname]);

  return (
    <Fragment>
      {Object.keys(showError).length > 0 && (
        <Grid container spacing={2} alignItems="flex-start">
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center">
            <NotificationBanner
              type={"error"}
              text={showError.text}
              primaryButtonText={showError.primaryButtonText}
              secondaryButtonText={showError.secondaryButtonText}
              onClicked={(button) => {
                if (button === "primary") {
                  if (showError.primaryPath === "reset") {
                    history.go(0);
                  } else {
                    history.push(showError.primaryPath);
                  }
                } else {
                  history.push(showError.secondaryPath);
                }
              }}
            />
          </Grid>
        </Grid>
      )}
      {Object.keys(showSuccess).length > 0 && (
        <Grid container spacing={2} alignItems="flex-start">
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center">
            <NotificationBanner
              type={"ok"}
              text={showSuccess.text}
              primaryButtonText={showSuccess.primaryButtonText}
              secondaryButtonText={showSuccess.secondaryButtonText}
              onClicked={(button) => {
                if (button === "primary") {
                  if (showSuccess.primaryPath === "reset") {
                    history.go(0);
                  } else {
                    history.push(showSuccess.primaryPath);
                  }
                } else {
                  history.push(showSuccess.secondaryPath);
                }
              }}
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default Notification;
