import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

import useStyles from "./EconomyContentStyles";
import Content from "../../shared/components/Content/Content";
import PaymentsPage from "../PaymentsPage/PaymentsPage";
import InvoicesPage from "../InvoicesPage/InvoicesPage";
import DebtBreakdown from "../DebtBreakdownPage/DebtBreakdownPage";
import Notification from "../../shared/components/Notification/Notification";
import { errorHandlers } from "../../shared/utils/notificationHandler";

import withTimeframe from "../../shared/hocs/withTimeframe";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import ProductionArea from "../../shared/enums/productionLines";
import { AuthorizationContext } from "../../management/shared/models/Users";
import { RootState } from "../../shared/store/rootReducer";

const PaymentsWithTimeframe = withTimeframe(PaymentsPage);
const InvoicesWithTimeframe = withTimeframe(InvoicesPage);

const EconomyContent = () => {
  const [showRoutes, setShowRoutes] = useState(true);
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const classes = useStyles({ productionLine });

  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      {showRoutes && (
        <Switch>
          <Route
            path="/economy/payments"
            render={() => (
              <PaymentsWithTimeframe
                productionLine={productionLine}
                endTime={moment()
                  .add(1, "months")
                  .endOf("month")
                  .format("YYYY-MM-DD")}
              />
            )}
          />
          <Route
            path="/economy/invoices"
            render={() => (
              <InvoicesWithTimeframe productionLine={productionLine} />
            )}
          />
          <ProtectedRoute
            path="/economy/debtBreakdown"
            allowedProductionLines={[ProductionArea.Beef, ProductionArea.Pork]}
            authContext={AuthorizationContext.MainUser}
            render={() => <DebtBreakdown />}
          />
        </Switch>
      )}
      <Notification
        onChange={(showNotification) => setShowRoutes(!showNotification)}
        errorHandlers={[errorHandlers.economyErrorHandler]}
      />
    </Content>
  );
};

export default EconomyContent;
