import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppBar, Grid, Toolbar, useMediaQuery } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as User } from "../../../../assets/images/iconmonstr-user-2.svg";
import { ReactComponent as Cow } from "../../../../assets/images/HKScan_icons_cow_CMYK.svg";
import { ReactComponent as Pig } from "../../../../assets/images/HKScan_icons_pig_CMYK.svg";
import { ReactComponent as Chicken } from "../../../../assets/images/HKScan_icons_chicken_CMYK.svg";
import { ReactComponent as LSO } from "../../../../assets/images/LSO_LOGO.svg";
import { ReactComponent as LSOWHITE } from "../../../../assets/images/LSO_LOGO_WHITE.svg";
import ArrowMenu from "../../ArrowMenu/ArrowMenu";
import useStyles from "./OptionsStyles";
import lang, { t } from "../../../services/langService";
import { productionLineUpdated } from "../../../store/ui/settings";
import { langCodeUpdated } from "../../../store/ui/i18n";
import { persistor } from "../../../../index";
import auth from "../../../services/authService";
import ProductionArea, {
  allProductionLines,
  productionAreaConfig,
} from "../../../enums/productionLines";
import theme from "../../../themes";
import { publication } from "../../../store/apiActions";
import { topMenuItemsCleared } from "../../../store/publications";
import {
  OperationUnit,
  mainUserRoles,
  UserRoles,
  UserRole,
  ADUserRole,
} from "../../../enums/CoreEnums";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import navHelpers, { isValidUserRole } from "../../../helpers/navHelpers";
import { AuthorizationContext } from "../../../../management/shared/models/Users";
import CustomLink from "../../../components/CustomLink/CustomLink";
import { RootState } from "../../../store/rootReducer";
import { ToggleMenu } from "../../ToggleMenu/ToggleMenu";

interface IOptionSubItem {
  name?: string;
  value?: string;
  link?: string;
  productionLines?: ProductionArea[];
  operationUnits?: any;
  absolutePath?: boolean;
  hide?: boolean;
  items?: IOptionSubItem[];
  icon?: React.ReactElement;
  invertedIcon?: React.ReactElement;
  visibleToUserRoles?: UserRoles[];
  authContext?: AuthorizationContext;
}

interface IOptionItem {
  id: string;
  name: string;
  authContext: AuthorizationContext;
  link?: string;
  productionLines?: ProductionArea[];
  operationUnits?: any;
  absolutePath?: boolean;
  items: IOptionSubItem[];
  hide?: boolean;
  highlighted?: boolean;
  bordered?: boolean;
  collapsing?: boolean;
  showSelectedItem?: boolean;
  icon?: React.ReactElement;
  showSelectedItemIcon?: boolean;
  visibleToUserRoles?: UserRoles[];
}

const defaultRightMenuItems: IOptionItem[] = [
  {
    id: "management",
    name: "options_farm",
    highlighted: false,
    bordered: true,
    collapsing: false,
    showSelectedItem: false,
    items: [
      {
        name: "options_currentFarm",
        link: "/management/currentfarm",
        productionLines: allProductionLines,
        visibleToUserRoles: [
          ...mainUserRoles,
          ADUserRole.SinettiOsuuskunta,
          ADUserRole.SinettiHKScanOsuuskunta,
          UserRole.EmployeeUser,
          UserRole.EconomyUser,
          UserRole.JanToolMainUser,
        ],
      },
      {
        name: "options_userManagement",
        link: "/management/users",
        visibleToUserRoles: [
          ...mainUserRoles,
          ADUserRole.SinettiOsuuskunta,
          ADUserRole.SinettiHKScanOsuuskunta,
          UserRole.OsuuskuntaUser,
          UserRole.JanToolMainUser,
        ],
      },
      { value: "farmSelection", name: "options_farmSelection" },
    ],
    authContext: AuthorizationContext.All,
  },
  {
    id: "currentUser",
    name: "_current_user_",
    highlighted: true,
    icon: <User viewBox={"0 -10 40 40"} />,
    showSelectedItem: false,
    items: [
      {
        name: "options_currentUser",
        link: "/user/current",
      },
      {
        name: "options_user_shopCard",
        link: "/user/shopCard",
        visibleToUserRoles: [...mainUserRoles, UserRole.SecondaryMainUser],
      },
      { value: "editProfile", name: "options_editProfile" },
      { value: "changePassword", name: "options_changePassword" },
      { value: "logout", name: "options_logout" },
      {
        hide: true, //TODO: Remove this when we have swedish translations in place and want to enable language selection for users.
        value: "languages",
        name: "options_language",
        items: [],
      },
    ],
    authContext: AuthorizationContext.MainUser,
  },
  {
    id: "productionLines",
    //name: "options_prodSegments",
    name: "",
    highlighted: false,
    collapsing: false,
    showSelectedItem: true,
    showSelectedItemIcon: true,
    items: [
      {
        value: ProductionArea.Beef,
        name: "options_beef",
        link: "/beef",
        icon: <Cow height={20} width={29.33} viewBox={"0 -10 115 115"} />,
        visibleToUserRoles: productionAreaConfig.Beef.allowedUserRoles,
        authContext: productionAreaConfig.Beef.authContext,
      },
      {
        value: ProductionArea.Pork,
        name: "options_pork",
        link: "/pork",
        icon: <Pig height={20} width={28} viewBox={"-20 -25 125 125"} />,
        visibleToUserRoles: productionAreaConfig.Pork.allowedUserRoles,
        authContext: productionAreaConfig.Pork.authContext,
      },
      {
        value: ProductionArea.Poultry,
        name: "options_poultry",
        link: "/poultry",
        icon: <Chicken height={20} width={28} viewBox={"-40 -10 140 140"} />,
        visibleToUserRoles: productionAreaConfig.Poultry.allowedUserRoles,
        authContext: productionAreaConfig.Poultry.authContext,
      },
      // {
      //   value: ProductionArea.Other,
      //   name: "options_other",
      //   link: "/",
      //   icon: (
      //     <span style={{ paddingLeft: "3px" }}>
      //       <MoreHorizIcon viewBox={"-9 -2 34 32"} />
      //     </span>
      //   ),
      //   visibleToUserRoles: productionAreaConfig.Other.allowedUserRoles,
      //   authContext: productionAreaConfig.Other.authContext,
      // },
      {
        value: ProductionArea.Cooperative,
        name: "nav_cooperative",
        link: "/cooperative",
        icon: (
          <span style={{ paddingLeft: "3px" }}>
            <LSO height={20} width={28} viewBox={"-30 -75 420 300"} />
          </span>
        ),
        invertedIcon: (
          <span style={{ paddingLeft: "3px" }}>
            <LSOWHITE
              color="#FFFFFF"
              height={20}
              width={28}
              viewBox={"95 -60 180 225"}
            />
          </span>
        ),
        visibleToUserRoles: productionAreaConfig.Cooperative.allowedUserRoles,
        authContext: productionAreaConfig.Cooperative.authContext,
      },
    ],
    authContext: AuthorizationContext.All,
  },
];

interface ILeftMenuItem {
  name: string;
  link: string;
}

interface OptionsProps {
  setFarmSelectionModalOpen: any;
}

const Options: React.FC<OptionsProps> = ({ setFarmSelectionModalOpen }) => {
  const classes = useStyles();
  const { productionLine, producerNumber, operationUnit } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { langCode, supportedLanguages } = useSelector(
    (state: RootState) => state.ui.i18n
  );
  const { topMenuReferenceId, topMenuItems } = useSelector(
    (state: RootState) => state.publications
  );
  const [leftMenuItems, setLeftMenuItems] = useState<any[]>([]);
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const { organizations, roleMap } = useSelector(
    (state: RootState) => state.user
  );
  const { roles, producers } = useSelector(
    (state: RootState) => state.auth.currentUser
  );
  const dispatch = useDispatch();
  const [producer, setProducer] = useState<any>("");
  const [productionLines, setProductionLines] = useState<any[]>([]);
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  const [filteredRightMenuItems, setFilteredRightMenuItems] = useState<any[]>(
    []
  );

  const currentOrganization = organizations.find(
    (o) => Number(o.producerNumber) === Number(producerNumber)
  );

  useEffect(() => {
    if (organizations) {
      const producerTmp = organizations.find(
        (org) => org.producerNumber == producerNumber
      );
      setProducer(producerTmp);
      setProductionLines(Object.keys(producerTmp?.productionLines || {}));
    }
  }, [organizations, producerNumber]);

  useEffect(() => {
    if (productionLine) {
      dispatch(publication.getTopMenuReferenceId(productionLine));
    }
  }, [langCode, productionLine, operationUnit]);

  useEffect(() => {
    if (topMenuReferenceId) {
      dispatch(
        publication.getTopMenuItems(
          langCode,
          productionLine,
          topMenuReferenceId
        )
      );
    }
    return () => {
      dispatch(topMenuItemsCleared());
    };
  }, [langCode, topMenuReferenceId, producerNumber, productionLine]);

  useEffect(() => {
    let defaultLeftMenuItems: ILeftMenuItem[] = [];

    if (currentOrganization && producerNumber) {
      const hasValidCooperativeRole = isValidUserRole(
        [ADUserRole.SinettiHKScanOsuuskunta, UserRole.SecondaryMainUser],
        roles,
        producers,
        producerNumber,
        AuthorizationContext.OsuuskuntaUser,
        roleMap
      );

      const hasValidMainRole = isValidUserRole(
        [...mainUserRoles, UserRole.EmployeeUser],
        roles,
        producers,
        producerNumber,
        AuthorizationContext.MainUser,
        roleMap
      );

      const orgIsMember = currentOrganization.isMember;

      const hasMoreThanOneProductionArea =
        Object.keys(currentOrganization.productionLines).length > 1;

      if (
        hasValidCooperativeRole &&
        hasValidMainRole &&
        orgIsMember &&
        hasMoreThanOneProductionArea
      ) {
        defaultLeftMenuItems.push({
          name:
            productionLine === ProductionArea.Cooperative
              ? currentOrganization.operationUnit === OperationUnit.Sinetti
                ? "Sinetti"
                : "Kivinetti"
              : "LSO Osuuskunta",
          link:
            productionLine === ProductionArea.Cooperative
              ? `/landingPage/main`
              : `/landingPage/cooperative`,
        });
      }
    }

    const navigationMenuItemsFromContentProvider = navHelpers.mapCmsNavItemsToMenuItems(
      topMenuItems,
      currentOrganization?.producerNumber
    );
    setLeftMenuItems([
      ...defaultLeftMenuItems,
      ...navigationMenuItemsFromContentProvider,
    ]);
  }, [
    topMenuItems,
    currentOrganization,
    productionLine,
    roles,
    producers,
    producerNumber,
    roleMap,
  ]);

  useEffect(() => {
    if (producerNumber) {
      const frightMenuItems = defaultRightMenuItems
        .filter(
          (item: IOptionItem) =>
            !item.hide &&
            (!item.productionLines?.length ||
              item.productionLines.includes(productionLine)) &&
            (!item.operationUnits?.length ||
              item.operationUnits.includes(operationUnit)) &&
            (!item.visibleToUserRoles?.length ||
              isValidUserRole(
                item.visibleToUserRoles,
                roles,
                producers,
                producerNumber,
                item.authContext,
                roleMap
              ))
        )
        .map((item: IOptionItem) => ({
          ...item,
          items: item.items
            ?.filter(
              (subItem: IOptionSubItem) =>
                !subItem.hide &&
                (!subItem.productionLines?.length ||
                  subItem.productionLines.includes(productionLine)) &&
                (!subItem.operationUnits?.length ||
                  subItem.operationUnits.includes(operationUnit)) &&
                (!subItem.visibleToUserRoles?.length ||
                  isValidUserRole(
                    subItem.visibleToUserRoles,
                    roles,
                    producers,
                    producerNumber,
                    subItem.authContext ?? item.authContext,
                    roleMap
                  )) &&
                (subItem.value !== "farmSelection" ||
                  organizations?.length > 1 ||
                  currentUser.authenticationSource === "HKScanAzureAD") &&
                (![
                  ProductionArea.Beef,
                  ProductionArea.Pork,
                  ProductionArea.Poultry,
                  ProductionArea.Other,
                  ProductionArea.Cooperative,
                ].includes((subItem?.value || "") as ProductionArea) ||
                  productionLines.some(
                    (productionLine) => productionLine === subItem.value
                  )) &&
                ((subItem.value !== "editProfile" &&
                  subItem.value !== "changePassword") ||
                  currentUser.authenticationSource !== "HKScanAzureAD") &&
                ((subItem.name !== "options_user_shopCard" &&
                  subItem.value !== "languages") ||
                  operationUnit !== OperationUnit.Kivikyla)
            )
            .map((subItem: IOptionSubItem) => ({
              ...subItem,
              name: t(subItem.name),
              items:
                subItem.value === "languages"
                  ? Object.keys(supportedLanguages).length > 0
                    ? supportedLanguages.map((c) => ({
                        value: c,
                        name: t(`lang_${c}`),
                      }))
                    : lang.getSupported().map((code) => ({
                        value: code,
                        name: lang.nameFor(code),
                      }))
                  : undefined,
            })),
        }));
      setFilteredRightMenuItems(frightMenuItems);
    }
  }, [
    productionLines,
    producerNumber,
    langCode,
    operationUnit,
    productionLine,
    roles,
    producers,
    roleMap,
  ]);

  const getLabel = (item) => {
    if (item.name) {
      switch (item.id) {
        case "currentUser":
          return `${currentUser.firstname} ${currentUser.lastname}`;
        case "management":
          const tfarm = t(item.name) || "";
          return producer ? `${producer.name}` : tfarm;
      }
    }
    return t(item.name);
  };

  const getDefaultValues = (item) => {
    if (item.id) {
      switch (item.id) {
        case "languages":
          return langCode;
        case "productionLines":
          return productionLine;
      }
    }
  };

  const handleClose = (value, itemId) => {
    if (itemId) {
      switch (itemId) {
        case "languages":
          dispatch(langCodeUpdated({ langCode: value }));
          break;
        case "management":
          setFarmSelectionModalOpen(true);
          break;
        case "productionLines":
          dispatch(productionLineUpdated({ productionLine: value }));
          history.push("/");
          break;
        case "currentUser":
          if (value === "logout") {
            persistor.purge();
            auth.logout();
          } else if (value === "editProfile") {
            auth.editProfile();
          } else if (value === "changePassword") {
            auth.changePassword();
          }
          break;
      }
    }
  };

  return (
    <AppBar position="static" elevation={0} className={classes.optionsBar}>
      <Toolbar variant="dense">
        <Grid container direction="row" alignItems="center">
          <Grid item xs={2} className={classes.toggleMenu}>
            <ToggleMenu
              menuItems={leftMenuItems}
              menuPaperProps={{
                style: {
                  marginTop: "25px",
                },
              }}
              htmlColor="#FFFFFF"
            />
          </Grid>
          <Grid item className={classes.menuItemContainer}>
            {leftMenuItems
              .filter((i) => !i.hide)
              .map((item, index) => (
                <Fragment key={index}>
                  {index > 0 && (
                    <span className={`${classes.linkSeparator}`}>|</span>
                  )}
                  {((item.link || item.link === "") && (
                    <CustomLink
                      key={index}
                      to={item.link}
                      className={`${classes.link}`}>
                      {t(item.name)}
                    </CustomLink>
                  )) || (
                    <ArrowMenu
                      key={index}
                      menuItems={item.items}
                      label={item.name}
                      menuButtonProps={{
                        classes: {
                          root: classes.leftMenuButton,
                        },
                      }}
                      menuPaperStyle={{
                        backgroundColor: "#fff",
                        color: "#152B62",
                      }}
                    />
                  )}
                </Fragment>
              ))}
          </Grid>
          <Grid item xs={10} className={classes.options}>
            {filteredRightMenuItems
              .filter(
                (i) =>
                  i.id !== "productionLines" ||
                  (i.id === "productionLines" && i.items.length > 1)
              )
              .map((item, index) => (
                <ArrowMenu
                  key={index}
                  disabled={
                    item.id === "productionLines" &&
                    productionLines.length === 1
                  }
                  menuItems={item.items
                    .filter((item) => {
                      if (productionLine) {
                        return item.value !== productionLine;
                      }
                      return true;
                    })
                    .map((i) => ({ ...i, name: t(i.name) }))}
                  allMenuItems={item.items.map((i) => ({
                    ...i,
                    name: t(i.name),
                  }))}
                  defaultValue={getDefaultValues(item)}
                  onClose={(value, itemId) =>
                    handleClose(value, itemId || item.id)
                  }
                  label={getLabel(item)}
                  icon={item.icon}
                  arrowIcon={true}
                  showSelectedItem={
                    item.items?.some((i) => !!i.icon)
                      ? item.showSelectedItem && !isMobile
                      : item.showSelectedItem
                  }
                  showSelectedItemIcon={
                    isMobile && item.items?.some((i) => !!i.icon)
                  }
                  showLabel={
                    item.items?.some((i) => !!i.icon) ? !isMobile : true
                  }
                  menuButtonProps={{
                    classes: {
                      root: `
                ${classes.button}
                ${
                  item.id === "productionLines" && productionLines.length === 1
                    ? classes.buttonDisabled
                    : ""
                }
                ${item.highlighted ? classes.highlighted : ""}
                ${item.bordered ? classes.borderedButton : ""} 
                ${item.collapsing ? classes.collapsingLink : ""}
              `,
                    },
                  }}
                  menuPaperStyle={{
                    backgroundColor: "#fff",
                    color: "#152B62",
                    fontSize: "0.2em",
                  }}
                />
              ))}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Options;
