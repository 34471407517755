import React, {useEffect, useRef} from "react";
import {Alert} from "@material-ui/lab";
import useStyles from "./NotificationMessageStyles";

interface NotificationMessageProps {
    message: string;
    timeout?: number;
    onTimeout?: Function;
    onClickOutside?: Function;
    [x:string]: any;
}

const NotificationMessage: React.FC<NotificationMessageProps> = ({
    message,
    timeout = 0,
    onTimeout = () => {},
    onClickOutside = () => {},
    ...rest
}) => {
    const classes = useStyles();
    const containerRef = useRef<any>(null);

    useEffect(() => {
        if (timeout > 0) {
            setTimeout(() => {
                onTimeout();
            }, timeout);
        }
    }, [message]);

    // if clicked on outside of element
    useEffect(() => {
        const handleClickOutside = (event) => {
          if (containerRef.current && !containerRef.current?.contains(event.target)) 
            setTimeout(() => onClickOutside(), 100)
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [containerRef]);

    return (
        <div style={{display: "inline-block", width: "100%", paddingTop: "0"}} ref={containerRef}>
            {
                message && 
                <Alert icon={false} {...rest} classes={{standardSuccess: classes.success, standardError: classes.error}}>
                    {message}
                </Alert>
            }
        </div>
    );
};

export default NotificationMessage;