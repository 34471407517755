import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content1: { 
    backgroundColor: "#efefef",
    padding: "5px",
  },
  content2: { 
  },
  content3: { 
    padding: "5px"
  },
  cardHeader: {
    backgroundColor: "#efefef",
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    marginBottom: "10px",
  },
  heading: {
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.primary.main,
  },
  headingPanel: {
    backgroundColor: "#efefef",
  }
})); 

export default useStyles;