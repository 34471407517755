import React, { useEffect, Fragment, useState } from "react";
import { Grid, TableContainer, Hidden, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Button from "../../shared/components/Button/Button";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import {
  ExpTableCell,
  ExpTableHeaderRow,
  ExpTableRow,
} from "../../shared/components/ExpandableTable/ExpandableTable";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableHeaderRow,
  TableCell,
} from "../../shared/components/Table/Table";
import TablePagination from "../../shared/components/TablePagination/TablePagination";
import { t } from "../../shared/services/langService";
import useStyles from "./NotificationListPageStyles";
import DropdownMenu from "../../shared/components/DropdownMenu/DropdownMenu";
import * as api from "../../shared/store/apiActions";
import {
  BeefNotificationData,
  KuormausAikaData,
} from "../shared/models/BeefDispatchListing";
import nautaTeurasElainlajit from "../shared/enums/nautaTeurasElainLajit";
import nautaValitysElainlajit from "../shared/enums/nautaValitysElainlajit";
import { getComparator, stableSort } from "../../shared/utils/sorting";
import HealthQuestionsModal from "../../shared/components/HealthQuestionsModal/HealthQuestionsModal";
import { getIsLoading } from "../../shared/store/ui/common";
import ProductionArea from "../../shared/enums/productionLines";
import ColumnGroup from "../../shared/components/ColumnGroup/ColumnGroup";
import TimeframePicker from "../../shared/components/TimeframePicker/TimeframePicker";
import { RootState } from "../../shared/store/rootReducer";

const paginationInit = {
  rowsPerPage: 10,
  page: 0,
};

const NotificationListPage = ({
  currentStartDate,
  currentEndDate,
  fetchCounter,
  startTimeOnChange,
  endTimeOnChange,
  onFetchClick,
  errorMsg,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { notifications } = useSelector(
    (state: RootState) => state.beef.beefDispatchListing
  );
  const [notificationStatus, setNotificationStatus] = useState<
    "open" | "completed"
  >("open");
  const [pagination, setPagination] = useState<any>(paginationInit);
  const [healthData, setHealthData] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [secondaryOrder, setSecondaryOrder] = React.useState<any>({
    order: "desc",
    orderBy: "kuormausAikaCompare",
    secOrder: "asc",
    secOrderBy: "syntymatunnus",
  });
  const isLoading = useSelector((state) => getIsLoading(state));

  const elainlajit = Object.values(nautaTeurasElainlajit)
    .filter((el: any) => !isNaN(el))
    .map((el) => ({ [el]: t(`animalType_${nautaTeurasElainlajit[el]}`) }))
    .concat(
      Object.values(nautaValitysElainlajit)
        .filter((el: any) => !isNaN(el))
        .map((el) => ({ [el]: t(`animalType_${nautaValitysElainlajit[el]}`) }))
    )
    .reduce((acc, x) => {
      for (let key in x) acc[key] = x[key];
      return acc;
    }, {});

  const texts = {
    BeefDispatch: t("animalNotification_beefDispatch"),
    BeefSlaughter: t("animalNotification_beefSlaughter"),
  };

  const dropdownOptions: any[] = [
    {
      name: "open",
      text: t("animalNotification_untransported"),
      value: "open",
    },
    {
      name: "completed",
      text: t("animalNotification_transported"),
      value: "completed",
    },
  ];

  useEffect(() => {
    if (notificationStatus === "completed") {
      dispatch(
        api.beef.getBeefNotificationsList(
          notificationStatus,
          currentStartDate,
          currentEndDate
        )
      );
    } else {
      dispatch(api.beef.getBeefNotificationsList(notificationStatus));
    }
  }, [fetchCounter, notificationStatus]);

  const handleRequestSort = (order, setOrder, id) => {
    const isAsc = order.orderBy === id && order.order === "asc";
    setOrder((prevState) => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: id,
    }));
  };

  const formatKuormausAika = (
    ajat: KuormausAikaData[],
    rowDispatchState: string
  ): string[] => {
    if (!ajat?.length) return [];

    const newArray: string[] = [];
    for (let aika of ajat)
      newArray.push(
        rowDispatchState === "lo"
          ? t("animalNotification_transported")
          : `${moment(aika.pvm).format("DD.MM.YYYY")} ${aika.klo || ""}`
      );

    return newArray;
  };

  const renderHealtDataButton = (n: BeefNotificationData) => {
    return (
      <Button
        onClick={() => {
          setHealthData({
            notificationId: n.notificationNumber,
            healthData: n.healthData,
            animals: n.beefDetails,
          });
          setOpen(true);
        }}
        type="table"
        fn={"action"}>
        {t("notificationList_showHealthData")}
      </Button>
    );
  };

  type ScreenSize = "Mobile" | "Normal";

  const getHealthDataButtonJsx = (row: any, screenSize: ScreenSize) => {
    const hiddenProps = {
      smUp: screenSize === "Mobile" && true,
      xsDown: screenSize === "Normal" && true,
    };
    if (notificationStatus === "completed") {
      if (row.hasHealthData) {
        return (
          <Hidden {...hiddenProps}>
            <ExpTableCell style={{ padding: "6px" }}>
              {renderHealtDataButton(row)}
            </ExpTableCell>
          </Hidden>
        );
      } else {
        return (
          <Hidden {...hiddenProps}>
            <ExpTableCell />
          </Hidden>
        );
      }
    }
  };

  return (
    <Fragment>
      <HealthQuestionsModal
        open={open}
        healthData={healthData}
        onClose={() => setOpen(false)}
        productionLine={ProductionArea.Beef}
      />

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader level="1">{t("notificationList_pageHeader")}</PageHeader>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12}>
          <Button
            id="notification-list-newslaughternotification-button"
            style={{
              marginRight: "10px",
              marginTop: "5px",
              marginBottom: "5px",
            }}
            type="link"
            to="/beef/slaughternotifications">
            {t("animalNotification_newSlaughterNotification")}
          </Button>

          <Button type="link" to="/beef/forwardingnotifications">
            {t("animalNotification_newForwardingNotification")}
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DropdownMenu
            id="notification-list-status-select"
            singleLine={true}
            defaultValue={notificationStatus}
            displayEmpty={true}
            displayEmptyText={`-- ${t("ui_select")} --`}
            useCaret={false}
            label={t("animalNotification_status")}
            onChange={(value) => {
              setNotificationStatus(value);
              if (value) setPagination(paginationInit);
            }}
            options={dropdownOptions}
          />
        </Grid>

        {notificationStatus === "completed" && (
          <TimeframePicker
            selectedStartDate={currentStartDate}
            selectedEndDate={currentEndDate}
            startDateOnChange={startTimeOnChange}
            endDateOnChange={endTimeOnChange}
            onClick={onFetchClick}
            errorMsg={errorMsg}
          />
        )}

        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <ExpTableHeaderRow expandable={notificationStatus === "open"}>
                  <Fragment>
                    {notificationStatus === "completed" && (
                      <Hidden smUp>
                        <ExpTableCell></ExpTableCell>
                      </Hidden>
                    )}
                  </Fragment>
                  <ExpTableCell>{t("animalNotification_id")}</ExpTableCell>
                  <ExpTableCell>{t("animalNotification_type")}</ExpTableCell>
                  <ExpTableCell align="right">
                    {t("animalNotification_date")}
                  </ExpTableCell>
                  <ExpTableCell>{t("animalNotification_info")}</ExpTableCell>
                  <ExpTableCell>{t("animalNotification_created")}</ExpTableCell>
                  <ExpTableCell>
                    <Tooltip
                      title={t("notificationList_animalsLoadedInfoText")}
                      enterTouchDelay={100}
                      leaveTouchDelay={4000}>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("animalNotification_animalsLoaded")}{" "}
                        <HelpIcon
                          style={{
                            fontSize: 16,
                            verticalAlign: "sub",
                            color: "#777",
                          }}
                        />
                      </span>
                    </Tooltip>
                  </ExpTableCell>
                  <ExpTableCell>
                    <Tooltip
                      title={t("notificationList_animalsLeftInfoText")}
                      enterTouchDelay={100}
                      leaveTouchDelay={3000}>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {t("animalNotification_animalsLeft")}{" "}
                        <HelpIcon
                          style={{
                            fontSize: 16,
                            verticalAlign: "sub",
                            color: "#777",
                          }}
                        />
                      </span>
                    </Tooltip>
                  </ExpTableCell>
                  <Fragment>
                    {notificationStatus === "completed" && (
                      <Hidden xsDown>
                        <ExpTableCell></ExpTableCell>
                      </Hidden>
                    )}
                  </Fragment>
                </ExpTableHeaderRow>
              </TableHead>
              <TableBody>
                {!isLoading &&
                  notifications[notificationStatus]
                    ?.slice(
                      pagination.page * pagination.rowsPerPage,
                      pagination.page * pagination.rowsPerPage +
                        pagination.rowsPerPage
                    )
                    .map((n: BeefNotificationData) => (
                      <ExpTableRow
                        key={n.notificationNumber}
                        expandable={notificationStatus === "open"}
                        primary={
                          <Fragment>
                            {getHealthDataButtonJsx(n, "Mobile")}
                            <ExpTableCell>
                              {notificationStatus === "open" && (
                                <Link
                                  to={`/beef/${
                                    n.type === "BeefSlaughter"
                                      ? "slaughternotifications"
                                      : "forwardingnotifications"
                                  }/${n.notificationNumber}`}>
                                  {n.notificationNumber}
                                </Link>
                              )}
                              {notificationStatus === "completed" && (
                                <Fragment>{n.notificationNumber}</Fragment>
                              )}
                            </ExpTableCell>
                            <ExpTableCell>{texts[n.type]}</ExpTableCell>
                            <ExpTableCell align="right">
                              {moment(n.date).format("DD.MM.YYYY")}
                            </ExpTableCell>
                            <ExpTableCell>{n.furtherInfo}</ExpTableCell>
                            <ExpTableCell>{n.numAnimalsTotal}</ExpTableCell>
                            <ExpTableCell>{n.numAnimalsLoaded}</ExpTableCell>
                            <ExpTableCell>
                              {n.numAnimalsPlannedForLoading}
                            </ExpTableCell>
                            {getHealthDataButtonJsx(n, "Normal")}
                          </Fragment>
                        }
                        secondary={
                          <Table>
                            {/* when updating columns add or remove col and adjust the widths*/}

                            <ColumnGroup
                              columns={[
                                { width: "20%" },
                                { width: "20%" },
                                { width: "10%" },
                                { width: "10%" },
                                { width: "10%" },
                                { width: "10%" },
                                { width: "20%" },
                              ]}
                            />
                            <TableHead>
                              <TableHeaderRow
                                order={secondaryOrder.order}
                                orderBy={secondaryOrder.orderBy}
                                onRequestSort={(id) =>
                                  handleRequestSort(
                                    secondaryOrder,
                                    setSecondaryOrder,
                                    id
                                  )
                                }
                                columnInfo={[
                                  {},
                                  { id: "syntymatunnus" },
                                  {},
                                  { isNumeric: true },
                                  {},
                                  {},
                                  { id: "kuormausAikaCompare" },
                                ]}
                                sorting={true}>
                                <TableCell>
                                  {t("animalNotification_animalType")}
                                </TableCell>
                                <TableCell>
                                  {t("animalNotification_birthIdentification")}
                                </TableCell>
                                <TableCell>
                                  {t("animalNotification_animalBreed")}
                                </TableCell>
                                <TableCell>
                                  {t("animalNotification_animalBirthday")}
                                </TableCell>
                                <TableCell>
                                  {t("animalNotification_preferredWeek")}
                                </TableCell>
                                <TableCell>
                                  {n.type === "BeefSlaughter"
                                    ? t("animalNotification_expedited")
                                    : t("animalNotification_nupoutettu")}
                                </TableCell>
                                <TableCell>
                                  {t("animalNotification_loadingTime")}
                                </TableCell>
                              </TableHeaderRow>
                            </TableHead>
                            <TableBody>
                              {stableSort(
                                n.beefDetails.map((d) => ({
                                  ...d,
                                  kuormausAikaPvm:
                                    d.kuormausAika?.length > 0
                                      ? d.kuormausAika[0].pvm
                                      : "",
                                  kuormausAikaCompare:
                                    d.kuormausAika?.length > 0
                                      ? `${
                                          d.rowDispatchState !== "lo" ? 2 : 1
                                        }${
                                          d.rowDispatchState !== "lo"
                                            ? d.kuormausAika[0].pvm
                                            : "0000-01-01"
                                        }T00:00:00`
                                      : `${
                                          d.rowDispatchState !== "lo" ? 1 : 2
                                        }9999-12-30T00:00:00`,
                                })),
                                getComparator(
                                  secondaryOrder.order,
                                  secondaryOrder.orderBy,
                                  undefined,
                                  secondaryOrder.secOrder,
                                  secondaryOrder.secOrderBy
                                )
                              ).map((d, i) => (
                                <TableRow
                                  key={i}
                                  highlighted={d.rowDispatchState === "lo"}
                                  highlightColor="#c9c8c6">
                                  <TableCell>
                                    {elainlajit[d.elainlaji] || d.elainlaji}
                                  </TableCell>
                                  <TableCell>{d.syntymatunnus}</TableCell>
                                  <TableCell>{d.rotu}</TableCell>
                                  <TableCell align="right">
                                    {d.syntymapvm
                                      ? moment(d.syntymapvm).format(
                                          "DD.MM.YYYY"
                                        )
                                      : ""}
                                  </TableCell>
                                  <TableCell>{d.toivottuVko}</TableCell>
                                  {n.type === "BeefSlaughter" && (
                                    <TableCell>
                                      {d.kiire ? t("ui_yes") : ""}
                                    </TableCell>
                                  )}
                                  {n.type === "BeefDispatch" && (
                                    <TableCell>
                                      {d.nupoutettu ? t("ui_yes") : ""}
                                    </TableCell>
                                  )}
                                  <TableCell>
                                    {formatKuormausAika(
                                      d.kuormausAika,
                                      d.rowDispatchState
                                    ).map((a) => (
                                      <span
                                        style={{
                                          display: "block",
                                          whiteSpace: "nowrap",
                                        }}>
                                        {a}
                                      </span>
                                    ))}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        }
                      />
                    ))}
                {!isLoading && !notifications[notificationStatus]?.length && (
                  <TableRow style={{ height: 42 }}>
                    <TableCell colSpan={8}>
                      {t("animalNotification_noRowsFound")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rows={notifications[notificationStatus]}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            onChangePage={(e, page) =>
              setPagination((prevState) => ({ ...prevState, page }))
            }
            onChangeRowsPerPage={(e) =>
              setPagination({ page: 0, rowsPerPage: Number(e.target.value) })
            }
            labelRowsPerPage={t("ui_rows")}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default NotificationListPage;
