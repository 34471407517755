import React from "react";
import { getComparator, stableSort } from "../utils/sorting";

export interface WithTableSortingProps {
  rows: any[] | null;
  order?: string;
  orderBy?: string;
  enableSorting?: boolean;
  onSorting?: (sortedRows: any[] | null) => void;
  [x: string]: any;
}

const withTableSorting = <P extends object>(
  TableComponent: React.ComponentType<P>
): React.FC<P & WithTableSortingProps> => {
  return ({
    rows,
    order,
    orderBy,
    enableSorting = true,
    onSorting = () => {},
    ...props
  }: WithTableSortingProps) => {
    const [orderColumn, setOrderColumn] = React.useState<any>({
      order: order ? order : "",
      orderBy: orderBy ? orderBy : "",
    });

    const [sortCounter, setSortCounter] = React.useState<number>(0);

    const handleRequestSort = (id: number) => {
      const isAsc = orderColumn.orderBy === id && orderColumn.order === "asc";
      setOrderColumn({ order: isAsc ? "desc" : "asc", orderBy: id });
      setSortCounter(1 + sortCounter);
    };

    const sortedRows = (rows: any[] | null) => {
      let sortingMethod = props.headers?.find(
        (header) => header.id === orderColumn.orderBy
      )?.sortingMethod;
      if (!sortingMethod && props.headers) {
        sortingMethod = props.headers[orderColumn.orderBy]?.sortingMethod;
      }

      const sortedRows = rows
        ? stableSort(
            rows,
            getComparator(orderColumn.order, orderColumn.orderBy, sortingMethod)
          )
        : null;
      onSorting([...sortedRows]);
      return sortedRows;
    };

    return (
      <TableComponent
        {...(props as P)}
        rows={sortedRows(rows)}
        order={orderColumn.order}
        orderBy={orderColumn.orderBy}
        onRequestSort={handleRequestSort}
        enableSorting={enableSorting}
        sortCounter={sortCounter}
      />
    );
  };
};

export default withTableSorting;
