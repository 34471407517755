import React, { useState } from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import useStyles from "./ManagementContentStyles";
import CurrentFarmPage from "../CurrentFarmPage/CurrentFarmPage";
import CurrentFarmPageCooperative from "../CurrentFarmPage/CurrentFarmPageCooperative";
import UserManagementPage from "../UserManagementPage/UserManagementPage";
import Content from "../../shared/components/Content/Content";
import Notification from "../../shared/components/Notification/Notification";
import { errorHandlers } from "../../shared/utils/notificationHandler";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import { AuthorizationContext } from "../shared/models/Users";
import ProductionArea, {
  allProductionLines,
} from "../../shared/enums/productionLines";
import {
  ADUserRole,
  mainUserRoles,
  UserRole,
} from "../../shared/enums/CoreEnums";
import { RootState } from "../../shared/store/rootReducer";

const ManagementContent = () => {
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const classes = useStyles({ productionLine });
  const [showRoutes, setShowRoutes] = useState(true);

  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      {showRoutes && (
        <Switch>
          {productionLine === ProductionArea.Cooperative ? (
            <ProtectedRoute
              path="/management/currentfarm"
              component={CurrentFarmPageCooperative}
              allowedProductionLines={allProductionLines}
              allowedUserRoles={[
                ...mainUserRoles,
                ADUserRole.SinettiOsuuskunta,
                ADUserRole.SinettiHKScanOsuuskunta,
                UserRole.EmployeeUser,
                UserRole.EconomyUser,
                UserRole.JanToolMainUser,
              ]}
              authContext={AuthorizationContext.All}
            />
          ) : (
            <ProtectedRoute
              path="/management/currentfarm"
              component={CurrentFarmPage}
              allowedProductionLines={allProductionLines}
              allowedUserRoles={[
                ...mainUserRoles,
                ADUserRole.SinettiOsuuskunta,
                ADUserRole.SinettiHKScanOsuuskunta,
                UserRole.EmployeeUser,
                UserRole.EconomyUser,
                UserRole.JanToolMainUser,
              ]}
              authContext={AuthorizationContext.All}
            />
          )}

          <ProtectedRoute
            path="/management/users"
            component={UserManagementPage}
            authContext={AuthorizationContext.All}
            allowedProductionLines={allProductionLines}
            allowedUserRoles={[
              ...mainUserRoles,
              ADUserRole.SinettiOsuuskunta,
              ADUserRole.SinettiHKScanOsuuskunta,
              UserRole.OsuuskuntaUser,
              UserRole.JanToolMainUser,
            ]}
          />
        </Switch>
      )}
      <Notification
        onChange={(showNotification) => setShowRoutes(!showNotification)}
        errorHandlers={[errorHandlers.managementErrorHandler]}
      />
    </Content>
  );
};

export default ManagementContent;
