import React, { Fragment, useEffect, useState } from "react";
import { Grid, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { useSelector, useDispatch } from "react-redux";
import DropdownMenu from "../../../../shared/components/DropdownMenu/DropdownMenu";
import useStyles from "./AddAnimalStyles";
import WeekPicker from "../../../../shared/components/WeekPicker/WeekPicker";
import InputField from "../../../../shared/components/InputField/InputField";
import Button from "../../../../shared/components/Button/Button";
import AnimalList from "./AnimalList/AnimalList";
import { t } from "../../../../shared/services/langService";
import nautaTeurasElainlajit from "../../enums/nautaTeurasElainLajit";
import nautaValitysElainlajit from "../../enums/nautaValitysElainlajit";
import * as api from "../../../../shared/store/apiActions";
import moment from "moment";
import { latestBirthIdValidationResultCleared } from "../../../../shared/store/beef";
import Checkbox from "../../../../shared/components/Checkbox/Checkbox";
import PageText from "../../../../shared/components/PageText/PageText";
import NotificationMessage from "../../../../shared/components/NotificationMessage/NotificationMessage";
import { RootState } from "../../../../shared/store/rootReducer";

interface AddAnimalsProps {
  notificationType: "slaughter" | "forwarding";
  addedAnimals: any[];
  onAnimalsAdded: Function;
  onAnimalRemoved: Function;
  onAnimalEdited: Function;
  onWeekChange?: Function;
  onAnimalTypeChange?: Function;
  onAdditionalInfoChanged?: Function;
  modify?: boolean;
  readonly?: boolean;
}

const AddAnimals: React.FC<AddAnimalsProps> = ({
  notificationType,
  addedAnimals,
  onAnimalsAdded,
  onAnimalRemoved,
  onAnimalEdited,
  onWeekChange = () => {},
  onAnimalTypeChange = () => {},
  modify = false,
  readonly = false,
}) => {
  const classes = useStyles();
  const [selectedAnimalType, setSelectedAnimalType] = useState("");

  const getDefaultWeekValue = () => {
    const weeksToAdd = moment().isoWeekday() < 3 ? 1 : 2;
    let now = new Date();
    now.setDate(now.getDate() + weeksToAdd * 7);
    const year = moment(now).format("YYYY");
    const week = moment(now).format("W");
    return `${year}${parseInt(week) < 10 ? "0" : ""}${week}`;
  };

  const [selectedWeek, setSelectedWeek] = useState(getDefaultWeekValue());
  const [nupoutettuOrUrgent, setNupoutettuOrUrgent] = useState(false);

  const [animalsToAdd, setAnimalsToAdd] = useState<any[]>([]);
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [inputError, setInputError] = useState(false);
  const [inputErrorText, setInputErrorText] = useState("");
  const [inputSuccessText, setInputSuccessText] = useState("");

  const dispatch = useDispatch();

  const validatedBirthIds = useSelector(
    (state: RootState) => state.beef.latestBirthIdValidationResult
  );

  useEffect(() => {
    if (validatedBirthIds.length > 0) {
      const validIds = validatedBirthIds.filter((v) => v.isValid);

      const invalidIds = validatedBirthIds
        .filter((v) => !v.isValid)
        .map((v) => v.rawBirthId);

      let errorText =
        new Set(validIds.map((id) => id.formattedBirthId)).size !==
        validIds.length
          ? `${t("animalNotification_duplicateAnimalError")}`
          : "";

      const validAnimals = validIds
        .map((id) => {
          return {
            rawBirthId: id.rawBirthId,
            id: id.formattedBirthId,
            animalType: selectedAnimalType,
            selectedWeek: selectedWeek,
            ...(notificationType === "slaughter"
              ? { urgent: nupoutettuOrUrgent }
              : { nupoutus: nupoutettuOrUrgent }),
          };
        })
        .filter(
          (a) =>
            !addedAnimals.map((aa) => aa.id).includes(a.id) &&
            !addedAnimals.map((aa) => aa.id).includes(a.id)
        );

      const duplicateAnimals = addedAnimals
        .map((aa) => aa.id)
        .filter((dupId) =>
          validIds.map((vid) => vid.formattedBirthId).includes(dupId)
        );

      if (duplicateAnimals.length > 0) {
        errorText += ` ${t(
          "animalNotification_duplicateBirthIds"
        )} ${duplicateAnimals.join(", ")}.`;
      }

      const uniqueValidAnimals: any[] = [];
      for (let a of validAnimals) {
        if (!uniqueValidAnimals.map((u) => u.id).includes(a.id)) {
          uniqueValidAnimals.push(a);
        }
      }

      if (invalidIds.length > 0) {
        errorText += ` ${t("animalNotification_invalidBirthIds", {
          0: invalidIds.join(", "),
        })}`;
      }

      setInputErrorText(errorText);

      if (uniqueValidAnimals.length > 0) {
        let inputSuccessText = `${uniqueValidAnimals.length} ${t(
          "animalNotification_successfullyAdded"
        )}`;
        onAnimalsAdded(uniqueValidAnimals);

        if (invalidIds.length === 0) {
          setSelectedAnimalType("");
          setSelectedWeek(getDefaultWeekValue());
          setNupoutettuOrUrgent(false);
          inputSuccessText += ` ${t(
            "animalNotification_returnToAnimalTypeSelection"
          )}`;
        }

        setInputSuccessText(inputSuccessText);
      }

      setAnimalsToAdd([]);
      setInputFieldValue(invalidIds.length > 0 ? invalidIds.join("\n") : "");
      dispatch(latestBirthIdValidationResultCleared());
    }
  }, [validatedBirthIds]);

  const dropdownOptions =
    notificationType === "slaughter"
      ? Object.values(nautaTeurasElainlajit)
          .filter((el: any) => !isNaN(el))
          .map((el) => ({
            name: nautaTeurasElainlajit[el],
            text: t(`animalType_${nautaTeurasElainlajit[el]}`),
            value: el,
          }))
      : Object.values(nautaValitysElainlajit)
          .filter((el: any) => !isNaN(el))
          .map((el) => ({
            name: nautaValitysElainlajit[el],
            text: t(`animalType_${nautaValitysElainlajit[el]}`),
            value: el,
          }));

  const handleAnimalTypeChange = (value) => {
    setSelectedAnimalType(value);
    setInputSuccessText("");
    for (let animal of animalsToAdd) {
      animal.animalType = value;
    }
    onAnimalTypeChange(value);
  };

  const handleWeekChange = (value) => {
    setSelectedWeek(value);
    setInputSuccessText("");
    for (let animal of animalsToAdd) {
      animal.selectedWeek = value;
    }
    onWeekChange(value);
  };

  const handleAnimalIdsChange = (value) => {
    setInputSuccessText("");
    setInputFieldValue(value);
    if (value && value.trim() !== "") {
      const animals = value
        .split(/[\s,]+/)
        .map((id) => ({
          id: id,
          animalType: selectedAnimalType,
          selectedWeek: selectedWeek,
          ...(notificationType === "slaughter"
            ? { urgent: nupoutettuOrUrgent }
            : { nupoutus: nupoutettuOrUrgent }),
        }))
        .filter((a) => a.id.trim() !== "");
      setAnimalsToAdd(animals);
      setInputError(hasDuplicates(animals));
      setInputErrorText(
        hasDuplicates(animals)
          ? t("animalNotification_duplicateAnimalError")
          : ""
      );
    }
  };

  const hasDuplicates = (animals) => {
    return (
      new Set(animals.map((a) => a.id)).size !== animals.length ||
      addedAnimals
        .map((a) => a.id)
        .some((r) => animals.map((a) => a.id).includes(r)) ||
      addedAnimals
        .map((a) => a.rawBirthId)
        .some((r) => animals.map((a) => a.id).includes(r))
    );
  };

  const handleAnimalsAdded = () => {
    if (animalsToAdd && animalsToAdd.length > 0) {
      dispatch(api.beef.validateBirthIds(animalsToAdd.map((a) => a.id)));
    }
  };

  const handleAnimalRemoved = (id) => {
    setInputSuccessText("");
    onAnimalRemoved(id);
  };

  return (
    <Grid container spacing={2} alignItems={"flex-start"}>
      {!readonly && (
        <Fragment>
          <Grid
            className={classes.dropdownContainer}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}>
            <DropdownMenu
              id="add-animal-animaltype-select"
              label={t("animalNotification_selectAnimalType")}
              required={true}
              onChange={handleAnimalTypeChange}
              displayEmptyText={`-- ${t("ui_select")} --`}
              selectedValue={selectedAnimalType}
              options={dropdownOptions}
            />
          </Grid>
          <Grid
            className={classes.dropdownContainer}
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}>
            <WeekPicker
              id="add-animal-week-select"
              defaultValue={getDefaultWeekValue()}
              weeksToShow={53}
              label={t("animalNotification_selectWeek")}
              required={true}
              onChange={handleWeekChange}
              selectedValue={selectedWeek}
              showStartAndEndDate={true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            {(notificationType === "forwarding" ||
              selectedAnimalType == "25") && (
              <Fragment>
                <PageText type="bold">
                  {notificationType === "forwarding"
                    ? t("animalNotification_nupoutettu")
                    : t("animalNotification_expedited")}
                </PageText>
                <Checkbox
                  id="add-animal-nupotettuorurgent-checkbox"
                  onChange={(value) => setNupoutettuOrUrgent(value)}
                  checked={nupoutettuOrUrgent}
                />
              </Fragment>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.animalIdLabelContainer}>
            <Grid item>
              <PageText type={"bold"} required={true}>
                {t("animalNotification_addBirthIdentifications")}
              </PageText>
            </Grid>
            <Grid item>
              <Tooltip
                title={t("animalNotification_animalIdTooltip")}
                placement={"right"}
                enterTouchDelay={100}
                leaveTouchDelay={10000}>
                <span style={{ marginLeft: "5px", whiteSpace: "nowrap" }}>
                  <HelpIcon style={{ fontSize: 16, color: "#777" }} />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingTop: "0" }}>
            <InputField
              id="add-animal-birthidentification-input"
              margin={""}
              value={inputFieldValue}
              placeholder={t(
                "animalNotification_birthIdentificationPlaceholder"
              )}
              required={true}
              className={classes.animalIds}
              multiline={true}
              onChange={(e) => handleAnimalIdsChange(e.target.value)}
              rows={4}
              error={inputError}
              errorText={inputErrorText}
              fullWidth={true}
            />
            <NotificationMessage
              message={inputSuccessText}
              severity={"success"}
            />
          </Grid>
          <Grid item className={classes.animalAddButton}>
            <Button
              id="add-animal-add-button"
              disabled={
                inputError ||
                inputFieldValue.trim() === "" ||
                selectedAnimalType === ""
              }
              onClick={handleAnimalsAdded}
              type={"add"}
              className={classes.animalAddButton}>
              {t("animalNotification_addAnimals")}
            </Button>
          </Grid>
        </Fragment>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AnimalList
          notificationType={notificationType}
          animals={addedAnimals}
          onRemove={(id) => handleAnimalRemoved(id)}
          onEdit={(id, propertyValues) => onAnimalEdited(id, propertyValues)}
          modify={modify}
          dropdownOptions={dropdownOptions}
        />
      </Grid>
    </Grid>
  );
};

export default AddAnimals;
