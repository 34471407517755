import React from "react";
import { Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import useStyles from "./InfoTooltipStyles";

interface InfoTooltipProps {
  infoText: string;
  label: string;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ infoText, label }) => {
  const classes = useStyles();
  return (
    <Tooltip title={infoText} enterTouchDelay={100} leaveTouchDelay={4000}>
      <span style={{ whiteSpace: "nowrap" }}>
        {label}
        <HelpIcon
          style={{
            fontSize: 16,
            verticalAlign: "sub",
            color: "#777",
            marginLeft: "3px",
          }}
        />
      </span>
    </Tooltip>
  );
};

export default InfoTooltip;
