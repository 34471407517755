import { string } from "prop-types";

export enum PublicationType {
  Content = 0,
  News,
  Event,
  Training,
  Welcome,
  Carousel,
  TopBanner,
  PowerBIReports,
  AgreementTexts,
  Instructions,
}

export const publicationContainerUriMap = {
  Content: "content",
  News: "news",
  Event: "event",
  Training: "training",
  Welcome: "welcome",
  Carousel: "carousel",
};

export enum OtherPublicationContext {
  Osuuskunta = "Osuuskunta",
}

export interface IPowerBIIdentifier {
  workspaceId: string;
  reportId: string;
}

export interface IContent {
  referenceId: number;
  title?: string;
  body?: string | null;
  categories: string[];
  publishDate?: Date | null;
  imageUrl?: string | null;
  imageContent?: string | null;
  startTime?: Date | null;
  endTime?: Date | null;
  contentType?: PublicationType;
  externalLink?: string | null;
  powerBIIdentifier?: IPowerBIIdentifier;
}

export enum MediaType {
  Unknown,
  Image,
  Video,
  Vimeo,
  Youtube,
}
