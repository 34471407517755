import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  content: {
    height: "100%",
    width: "100%",
  },
  carousel: {
    backgroundColor: "#efefef",
    borderRadius: "5px",
  },
  advertisementContainer: {
    height: "250px",
    width: "100%",
    background: "black",
  },
  title: {
    fontFamily: "Avenir Next W1G Bold",
    fontSize: "1.6rem",
    fontWeight: "bolder",
    color: "white",
    textAlign: "center",
    paddingTop: "70px",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  controlLayer: {
    position: "absolute",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.25)",
    color: "#f1f1f1",
    width: "100%",
    height: "100%",
    padding: "20px",
    marginBottom: "30px",
  },
  playerWrapper: {
    position: "relative",
    paddingBottom: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
    height: 0,
    paddingTop: "30px",
    overflow: "hidden",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
});

export default useStyles;
