import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./ValidationPageStyles";
import InputField from "../../shared/components/InputField/InputField";
import * as api from "../../shared/store/apiActions";
import { RootState } from "../../shared/store/rootReducer";

const ValidationPage = () => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    phoneNumber: "",
    postalCode: "",
  });
  const [values, setValues] = useState({
    phoneNumber: "",
    postalCode: "",
  });
  const [birthIds, setBirthIds] = useState("");
  const validationRules = useSelector(
    (state: RootState) => state.ui.validation.validationRules.ContactInformation
  );
  const dispatch = useDispatch();
  const birthIdResult = useSelector(
    (state: RootState) => state.beef.latestBirthIdValidationResult
  );

  useEffect(() => {
    if (birthIdResult.length > 0) {
      console.log("birthIds", birthIdResult);
    }
  }, [birthIdResult]);

  const capitalizeFirstLetter = (string, mod = "upper") => {
    if (mod === "upper")
      return string.charAt(0).toUpperCase() + string.slice(1);
    else return string.charAt(0).toLowerCase() + string.slice(1);
  };

  const validate = (name, value) => {
    const rule = validationRules[name];
    name = capitalizeFirstLetter(name, "false");
    if (value.match(rule)) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "Invalid!",
      }));
    }
  };

  const handleChange = (name, value) => {
    setValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validate(name, value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    for (let key in values) {
      console.log("submit", key, values[key]);
      validate(capitalizeFirstLetter(key), values[key]);
    }
  };

  const validateBirthId = () => {
    const arr = birthIds.split(/[\s,]+/);
    dispatch(api.beef.validateBirthIds(arr));
    // validateBirthIds(arr).then(resp => {
    //     console.log(resp.data);
    // });
  };

  return (
    <Container maxWidth="xl" className={classes.gridContainer}>
      {/*         <Grid container spacing={5} alignItems="flex-start"> */}

      <form onSubmit={handleSubmit} noValidate>
        <Typography variant={"h5"} color={"primary"}>
          Form Validation Test
        </Typography>

        <Typography variant={"h6"} color={"primary"}>
          Contact Information
        </Typography>

        {/*                  <TextField
                        error={!!error}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="EmailAddress"
                        label="Email Address"
                        name="EmailAddress"
                        autoComplete="email"
                        autoFocus
                        helperText={error}
                        onChange={handleChange}
                    /> */}
        <TextField
          error={!!errors.phoneNumber}
          variant="outlined"
          margin="normal"
          fullWidth
          id="PhoneNumber"
          label="Phone Number"
          name="PhoneNumber"
          autoComplete="phone_number"
          helperText={errors.phoneNumber}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
        <TextField
          error={!!errors.postalCode}
          variant="outlined"
          margin="normal"
          fullWidth
          id="PostalCode"
          label="Postal Code"
          name="PostalCode"
          autoComplete="postal_code"
          helperText={errors.postalCode}
          onChange={(e) => {
            handleChange(e.target.name, e.target.value);
          }}
        />
        <Button type="submit" variant="contained" color="primary">
          Validate
        </Button>
        <br />
        <br />
        <InputField
          margin="normal"
          onChange={(e) => setBirthIds(e.target.value)}
        />
        <br />
        <Button onClick={validateBirthId}>Validate birth ids</Button>
      </form>

      {/*           </Grid> */}
    </Container>
  );
};

export default ValidationPage;
