import { Grid, Typography } from "@material-ui/core";
import React from "react";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import useStyles from "./ShopCardPageStyles";
import { ReactComponent as Logo } from "../../assets/images/HKScan.svg";
import PageText from "../../shared/components/PageText/PageText";
import { useSelector } from "react-redux";
import { t } from "../../shared/services/langService";
import { IOrganization } from "../../shared/store/user";
import { RootState } from "../../shared/store/rootReducer";

const ShopCardPage = () => {
  const classes = useStyles();
  const date = new Date();

  const { firstname, lastname } = useSelector(
    (state: RootState) => state.auth.currentUser
  );

  const organizations: IOrganization[] = useSelector(
    (state: RootState) => state.user.organizations
  );

  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const currentOrganization = organizations.find(
    (o) => o.producerNumber === Number(producerNumber)
  );

  return (
    <Grid container>
      <Grid item xs={"auto"} sm={1} md={3}></Grid>
      <Grid item xs={12} sm={10} md={6}>
        <Grid item xs={12} className={classes.header}>
          <PageHeader level="1">{t("user_shopCard_title")}</PageHeader>
        </Grid>
        <Grid className={classes.name} item xs={12}>
          <br />
          <PageHeader level="2">{`${firstname} ${lastname}`}</PageHeader>
          <hr className={classes.horizontalRuler} />
          <PageText type="normal">{t("user_shopCard_name")}</PageText>
          <Typography variant={"h6"} color={"primary"}>
            {currentOrganization?.name}
          </Typography>
          <hr className={classes.horizontalRuler} />
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={`${classes.subtextLeft} ${classes.textNormal}`}>
                {t("user_shopCard_organization")}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant={"h6"} color={"primary"}>
            {currentOrganization?.businessId}
          </Typography>
          <hr className={classes.horizontalRuler} />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                className={`${classes.subtextLeft} ${classes.textNormal}`}>
                {t("user_shopCard_businessId")}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                className={`${classes.subtextRight} ${classes.textNormal}`}>
                {t("user_shopCard_validUntil")}{" "}
                {`${date.getMonth() + 1}/${date.getFullYear()}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.logoContainer}>
            <Logo className={classes.logo} />
          </div>
        </Grid>
      </Grid>
      <Grid item xs={"auto"} sm={1} md={3}></Grid>
    </Grid>
  );
};

export default ShopCardPage;
