import React, {
  useRef,
  useState,
  Fragment,
  useEffect,
  cloneElement,
} from "react";
import {
  MenuItem,
  MenuList,
  ClickAwayListener,
  Paper,
  Popper,
  ButtonBase,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as ArrowDown } from "../../../assets/images/iconmonstr-arrow-65.svg";
import styles from "./ArrowMenuStyles";
import NestedMenuItem from "../ToggleMenu/NestedMenuItem/NestedMenuItem";

interface ArrowMenuProps {
  menuItems: any[];
  allMenuItems?: any[];
  label: any;
  icon: any;
  classes: any;
  onClose: Function;
  arrowIcon: boolean;
  showSelectedItem: boolean;
  showSelectedItemIcon?: boolean;
  showLabel?: boolean;
  defaultValue: string;
  menuButtonProps: any;
  menuPaperStyle: any;
  disabled?: boolean;
}

const ArrowMenu: React.FC<ArrowMenuProps> = ({
  menuItems,
  allMenuItems,
  label,
  icon,
  classes,
  onClose = () => {},
  arrowIcon = false,
  showSelectedItem = false,
  showSelectedItemIcon = false,
  showLabel = true,
  defaultValue = "",
  menuButtonProps = {},
  menuPaperStyle = {},
  disabled = false,
}) => {
  const anchorRef = useRef(null);
  const [arrowRef, setArrowRef] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [selectedItemValue, setSelectedItemValue] = useState(defaultValue);

  useEffect(() => {
    setSelectedItemValue(defaultValue);
  }, [defaultValue]);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (itemValue, itemId?) => {
    setOpen(false);
    if (itemValue) {
      setSelectedItemValue(itemValue);
      onClose(itemValue, itemId);
    }
  };

  const selectedItemNameByValue = (value) => {
    const menuItem = allMenuItems
      ? allMenuItems.find((i) => i.value === value)
      : menuItems.find((i) => i.value === value);
    return menuItem ? menuItem.name : "";
  };

  const selectedIconByValue = (value) => {
    const menuItem = allMenuItems
      ? allMenuItems.find((i) => i.value === value)
      : menuItems.find((i) => i.value === value);
    return menuItem?.invertedIcon ?? menuItem?.icon;
  };

  const getMenuItemElementLinkProps = (
    component: JSX.Element | string,
    to: string
  ) => {
    const isExternalLink = /^(https|http)?:\/\//.test(to);
    return {
      ...{
        component: isExternalLink ? "a" : component,
        [isExternalLink ? "href" : "to"]: to,
      },
      ...(isExternalLink ? { target: "_blank" } : {}),
    };
  };

  const getMenuItemJsx = (item, index) => {
    if (item.items) {
      return (
        <NestedMenuItem label={item.name} parentMenuOpen={true} key={index}>
          {item.items.map((child, subIndex) => (
            <MenuItem
              onClick={() => handleClose(child.value, item.value)}
              {...getMenuItemElementLinkProps(Link, child.link)}
              key={subIndex}>
              {child.name}
            </MenuItem>
          ))}
        </NestedMenuItem>
      );
    } else if (item.link) {
      return (
        <MenuItem
          onClick={() => handleClose(item.value)}
          {...getMenuItemElementLinkProps(Link, item.link)}
          key={index}
          style={{ fontSize: 15, fontWeight: "normal" }}>
          {item.icon && (
            <span className={classes.iconContainer}>
              {cloneElement(item.icon)}
            </span>
          )}
          <span className={classes.itemLabel}>{item.name}</span>
        </MenuItem>
      );
    }

    return (
      <MenuItem
        onClick={() => handleClose(item.value)}
        key={index}
        style={{ fontSize: 15, fontWeight: "normal" }}>
        {item.name}
      </MenuItem>
    );
  };

  return (
    <Fragment>
      <ButtonBase
        {...menuButtonProps}
        ref={anchorRef}
        className={classes.button}
        onClick={() => !disabled && handleClick()}>
        {icon && <span className={classes.svgIcon}>{icon}</span>}
        {showLabel && <span>{label}</span>}
        {showSelectedItem && (
          <span
            style={{
              fontWeight: "bold",
              marginLeft: "5px",
              marginRight: disabled ? "8px" : "",
            }}>
            {selectedItemNameByValue(selectedItemValue)}
          </span>
        )}
        {showSelectedItemIcon &&
          selectedItemValue &&
          selectedIconByValue(selectedItemValue) && (
            <span className={classes.toolBarIconContainer}>
              {cloneElement(selectedIconByValue(selectedItemValue))}
            </span>
          )}
        {!disabled && arrowIcon && (
          <ArrowDown
            className={classes.downArrow}
            fill={"#fff"}
            viewBox={"-20 -30 75 75"}
          />
        )}
      </ButtonBase>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom"
        className={classes.popper}
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}>
        <span className={classes.arrow} ref={setArrowRef} />
        <Paper style={menuPaperStyle}>
          <ClickAwayListener onClickAway={() => handleClose("")}>
            <MenuList>
              {menuItems.map((item, index) => getMenuItemJsx(item, index))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Fragment>
  );
};

const withStylesProps = (styles) => (Component) => (props) => {
  const Comp = withStyles(styles(props))(Component);
  return <Comp {...props} />;
};

export default withStylesProps(styles)((props) => <ArrowMenu {...props} />);
