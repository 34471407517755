import {makeStyles} from "@material-ui/core";
import theme from "../../shared/themes";

const useStyles = makeStyles({
  textField: {
    "& > *": {
      color: theme.palette.primary.main,
    }
  }
});

export default useStyles;