import { t } from "../../../shared/services/langService";

export interface IOrganizationRole {
  roles: IRole[];
}

export interface IUser {
  id?: string; // used with GET
  emailAddress: string;
  organizationRoles?: IOrganizationRole[]; // used with GET
}

export interface IUserRoles {
  roles: IRole[];
}

export interface IRole {
  id: string;
  name: string;
  mainRole: string;
  translationKey: string;
  descriptionKey: string;
  application: string;
  isEnabled?: boolean; // used with PUT
}

export interface IAssignableRoles {
  roleGroups: Map<string, IRole>;
}

export enum AuthorizationContext {
  All = 0,
  MainUser = 1,
  OsuuskuntaUser = 2,
  JanToolMainUser = 3,
}

export const getRoleTranslation = (role: string) => t(`role_${role}`);
