import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "8px",
    margin: "12px",
    maxWidth: "600px",
  },
  list: {
    width: "100%",
  },
}));

export default useStyles;
