import ProductionArea from "../enums/productionLines";
import { ITrainings } from "../models/Trainings";
import http from "./httpService";

const API_ENDPOINT = "trainings";

export const getAvailableTrainings = async (
  langCode: string,
  productionLine: ProductionArea
) => {
  return await http.get<ITrainings>(
    `${API_ENDPOINT}/${langCode}/${productionLine}`
  );
};

export default {
  getAvailableTrainings,
};
