import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import InfoModal from "../InfoModal/InfoModal";

interface RouteLeavingGuardProps {
  when: boolean | undefined,
  title: string,
  text: string | any,
  cancelButtonText: string,
  okButtonText: string,
  onClose: Function,
  onRouteChanged: Function,
  force?: number,
} 

const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({
  when,
  title,
  text,
  cancelButtonText,
  okButtonText,
  onClose,
  onRouteChanged = () => {},
  force = 0,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const history = useHistory();
  const [nextLocation, setNextLocation] = useState<string>("");
  const [prevLocation, setPrevLocation] = useState<string>("");
  const {pathname} = useLocation();

  const block = (location, action) => {
    if (when && action === "POP" /* browser back button */) {
      setIsActive(true);
      return false;
    }

    return true;
  };

  const redirect = () => {
    return (nextLocation?.split("/")?.slice(0,-1).join("/") || "/");
  };

  const handleClose = (button) => {
    setIsActive(false);
    onClose(button);
    if (button === "ok") 
      history.push(prevLocation ? prevLocation : redirect());
  };

  useEffect(() => {
    if (when) {
      setIsActive(Boolean(force));
    }
    else if (force === 1) {
      onClose("cancel");
      history.push(prevLocation ? prevLocation : redirect());
    }
  }, [force]);

  useEffect(() => {
    history.block(block);
  }, [when]);

  useEffect(() => {
    if (pathname) {
      setPrevLocation(nextLocation);
      setNextLocation(pathname);
      onRouteChanged();
    }
  }, [pathname]);

  if (!isActive) 
    return null; 

  return (
    <InfoModal 
      open={isActive}
      title={title} 
      text={text} 
      cancelButtonText={cancelButtonText}
      okButtonText={okButtonText}
      onClose={button => handleClose(button)}
    />
  );
};

export default RouteLeavingGuard;
