import React, { useEffect, useState } from "react";
import { TableContainer } from "@material-ui/core";
import {
  TableRow2 as ExpTableRow2,
  ExpTableRow,
} from "../ExpandableTable/ExpandableTable";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableCell2,
  TableHeaderRow,
} from "../Table/Table";
import useStyles from "./ExpandableListViewStyles";
import { WithTablePaginationTableInterfaceProps } from "../../hocs/withTablePagination";
import { WithTableSortingProps } from "../../hocs/withTableSorting";
import { WithTableRowHidingTableInterfaceProps } from "../../hocs/withTableRowHiding";
import { ComparatorFunctionType } from "../../../shared/utils/sorting";
import HighlightedText from "../HighlightedText/HighlightedText";

export interface ITableHeader {
  content: string;
  isNumeric?: boolean;
  highlightText?: string;
  centerContent?: boolean;
  sortingMethod?: ComparatorFunctionType;
}

interface ExpandableListViewProps
  extends WithTablePaginationTableInterfaceProps,
    WithTableSortingProps,
    WithTableRowHidingTableInterfaceProps {
  title: string;
  rows: any[] | null;
  rowWrappers?: any[];
  rowFormatters?: any[];
  headers?: ITableHeader[];
  emptyRowsText?: string;
  labelRowsPerPage?: string;
  onRowClicked?: Function;
  enableExpandable?: boolean;
  rowsPerPage?: number;
  page?: number;
  onRequestSort?: Function;
  enableSorting?: boolean;
  hideHeader?: boolean;
  onPageChange?: Function;
  sortCounter?: number;
  onSortingChange?: Function;
  useColorSeparation?: boolean;
}

const ExpandableListView = React.memo<ExpandableListViewProps>(
  ({
    title,
    rows = null,
    headers = [],
    rowWrappers = [],
    rowFormatters = [],
    emptyRowsText = "",
    onRowClicked = null,
    enableExpandable = true,
    order = "",
    orderBy = "",
    onRequestSort = () => {},
    enableSorting = false,
    onExpansionChanged = (isExpanded: boolean) => {},
    page = 1,
    sortCounter = 0,
    onPageChange = (value: any) => {},
    onSortingChange = (value: any) => {},
    hideHeader = false,
    useColorSeparation = false,
  }) => {
    const classes = useStyles();
    const [selectedRow, setSelectedRow] = useState<number>(-1);

    // clear selection on page change
    useEffect(() => {
      setSelectedRow(-1);
      onPageChange(-1);
    }, [page]);

    // clear selection on sorting change
    useEffect(() => {
      setSelectedRow(-1);
      onSortingChange(-1);
    }, [sortCounter]);

    const getSecondaryTableJsx = () => {
      return (
        <Table>
          <TableHead>
            <TableHeaderRow
              order={order}
              orderBy={orderBy}
              onRequestSort={(id) => onRequestSort(id)}
              columnInfo={headers.map((headerColumn, ind) => ({
                id: ind,
                isNumeric: headerColumn.isNumeric,
                centerContent: headerColumn.centerContent,
                highlightText: headerColumn.highlightText,
              }))}
              sorting={enableSorting}>
              {headers.map((headerColumn, ind) => (
                <TableCell key={ind}>{headerColumn.content}</TableCell>
              ))}
            </TableHeaderRow>
          </TableHead>

          <TableBody>
            {!!rows?.length &&
              rows.map((row, rowInd) => (
                <TableRow
                  key={rowInd}
                  style={{
                    cursor: onRowClicked ? "pointer" : "",
                    backgroundColor: useColorSeparation
                      ? rowInd % 2 === 0
                        ? "#efefef"
                        : "#fff"
                      : "unset",
                  }}
                  highlighted={rowInd === selectedRow}
                  highlightColor={"unset"}
                  highlightBackgroundColor={
                    useColorSeparation ? "#999" : "#efefef"
                  }
                  onClick={(e) => {
                    if (onRowClicked) {
                      onRowClicked(rows[rowInd], rowInd);
                      setSelectedRow(rowInd);
                    }
                  }}>
                  {row.map((cellContent, columnInd) => (
                    <TableCell
                      align={
                        headers[columnInd]?.centerContent
                          ? "center"
                          : headers[columnInd]?.isNumeric
                          ? "right"
                          : "left"
                      }
                      key={columnInd}>
                      {headers[columnInd]?.highlightText ? (
                        <HighlightedText
                          text={cellContent}
                          highlight={headers[columnInd]?.highlightText}
                        />
                      ) : (
                        cellContent
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            {rows && rows.length === 0 && emptyRowsText && (
              <TableRow>
                <TableCell colSpan={headers.length}>{emptyRowsText}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      );
    };

    return (
      <TableContainer>
        {!hideHeader && (
          <Table>
            <TableHead>
              <ExpTableRow
                expandable={enableExpandable}
                onExpansionChanged={(isExpanded) =>
                  onExpansionChanged(isExpanded)
                }
                cellComponent={TableCell2}
                rowComponent={ExpTableRow2}
                defaultOpen={true}
                primary={<TableCell2 colSpan={4}>{title}&nbsp;</TableCell2>}
                secondary={getSecondaryTableJsx()}
              />
            </TableHead>
          </Table>
        )}
        {hideHeader && getSecondaryTableJsx()}
      </TableContainer>
    );
  }
);

export default ExpandableListView;
