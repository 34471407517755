import { createSlice } from "@reduxjs/toolkit";
import PorkDispatchListing, {
  IDepartmentRange,
} from "../../pork/shared/models/PorkDispatchListing";
import PorkSlaughterNotificationResponseDTO from "../../pork/shared/models/PorkSlaughterNotificationResponseDTO";

interface IPork {
  healthQuestions: any[];
  porkDispatchListing: PorkDispatchListing;
  porkSlaughterNotification?: PorkSlaughterNotificationResponseDTO;
  porkTransportInfo: any;
  porkSlaughterInfoReport: any[];
  departmentRanges: IDepartmentRange[];
}

const initialState: IPork = {
  healthQuestions: [],
  porkDispatchListing: new PorkDispatchListing(),
  porkTransportInfo: [],
  porkSlaughterInfoReport: [],
  departmentRanges: [],
};

const slice = createSlice({
  name: "pork",
  initialState: initialState,
  reducers: {
    healthQuestionsReceived: (state, action) => {
      state.healthQuestions = action.payload.data.ketjukysymykset;
    },
    healthQuestionsCleared: (state) => {
      state.healthQuestions = initialState.healthQuestions;
    },
    porkDispatchListingReceived: (state, action) => {
      state.porkDispatchListing = action.payload.data;
    },
    porkSlaughterNotificationReceived: (state, action) => {
      state.porkSlaughterNotification = action.payload.data.content;
    },
    porkSlaughterNotificationCleared: (state) => {
      state.porkSlaughterNotification = initialState.porkSlaughterNotification;
    },
    porkTransportInfoReceived: (state, action) => {
      state.porkTransportInfo = action.payload.data;
    },
    porkSlaughterInfoReportReceived: (state, action) => {
      state.porkSlaughterInfoReport = action.payload.data;
    },
    porkSlaughterInfoReportCleared: (state) => {
      state.porkSlaughterInfoReport = initialState.porkSlaughterInfoReport;
    },
    porkDepartmentRangesReceived: (state, action) => {
      state.departmentRanges = action.payload.data.departmentRanges;
    },
    porkDepartmentRangesCleared: (state) => {
      state.departmentRanges = initialState.departmentRanges;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  healthQuestionsReceived,
  healthQuestionsCleared,
  porkDispatchListingReceived,
  porkSlaughterNotificationReceived,
  porkSlaughterNotificationCleared,
  porkTransportInfoReceived,
  porkSlaughterInfoReportReceived,
  porkSlaughterInfoReportCleared,
  porkDepartmentRangesReceived,
  porkDepartmentRangesCleared,
} = slice.actions;

export default slice.reducer;
