import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  reportContent: {
    width: "100%",
    height: "calc(100vw * 720/1280)",
  },
  fullscreen: {
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    overflow: "auto",
    background: "white",
    zIndex: 10,
  },
  instructions: {
    backgroundColor: "#E6E6E6",
    fontFamily: "Avenir Next W1G",
    fontSize: "auto",
    color: "#000D80",
    border: "1px solid",
    borderColor: "#000D80",
    display: "flex",
  },
}));

export default useStyles;
