import { useState } from "react";
import useStyles from "./ThumbnailArrayStyles";

import { Grid, Link } from "@material-ui/core";
import Button from "../Button/Button";
import { t } from "../../services/langService";

export interface IThumbnailItem {
  key?: any;
  src: string;
  alt: string;
  className?: string;
}

const ThumbnailItem = ({ src, alt, className, key }: IThumbnailItem) => {
  return (
    <Link id={key} key={key} style={{ color: "#fff" }}>
      <img
        style={{ objectFit: "scale-down", width: "100%", height: "100px" }}
        src={src}
        alt={alt}
        className={className}></img>
    </Link>
  );
};

const ThumbnailArray = (props) => {
  const classes = useStyles();
  const [enlargeItem, setEnlargeItem] = useState<IThumbnailItem>();

  return (
    <Grid container xs={12} className={classes.container}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {props.items &&
          props.items.map((item, index) => (
            <Grid
              item
              xs={12}
              md={2}
              key={index}
              onClick={() => {
                setEnlargeItem(props.items[index]);
              }}>
              <ThumbnailItem
                key={index}
                src={item.src}
                alt={item.alt}
                className={classes.thumbnailImage}
              />
            </Grid>
          ))}
      </Grid>
      {enlargeItem && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.overlay}>
          <Grid container item justifyContent="center" alignItems="center">
            <img
              src={enlargeItem.src}
              alt=""
              className={classes.overlayImage}
            />
          </Grid>
          <Grid container item className={classes.closeOverlayButton}>
            <Button
              type="ok"
              onClick={() => {
                setEnlargeItem(undefined);
              }}>
              {t("ui_close")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ThumbnailArray;
