import { Divider, Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../shared/components/Button/Button";
import FarmSelection from "../../shared/components/FarmSelection/FarmSelection";
import HealthQuestions from "../../shared/components/HealthQuestions/HealthQuestions";
import InfoModal from "../../shared/components/InfoModal/InfoModal";
import InputField from "../../shared/components/InputField/InputField";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import ProductionArea from "../../shared/enums/productionLines";
import { t } from "../../shared/services/langService";
import * as api from "../../shared/store/apiActions";
import {
  healthQuestionsCleared,
  porkSlaughterNotificationCleared,
} from "../../shared/store/pork";
import { RootState } from "../../shared/store/rootReducer";
import {
  routeLeavingGuardActivated,
  routeLeavingGuardDeactivated,
  routeLeavingGuardForced,
} from "../../shared/store/ui/common";
import { getOrganizationByProducerNumber } from "../../shared/store/user";
import { defaultComparator } from "../../shared/utils/sorting";
import AddAnimals from "../shared/components/AddAnimals/AddAnimals";
import PorkSlaughterNotificationDTO, {
  SikaKetjuvastausDTO,
} from "../shared/models/PorkSlaughterNotificationDTO";
import useStyles from "./SlaughterNotificationPageStyles";

const SlaughterNotificationPage = () => {
  const classes = useStyles();
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const {
    healthQuestions: initHealthQuestions,
    porkSlaughterNotification,
    departmentRanges,
  } = useSelector((state: RootState) => state.pork);

  const { livestockVenues: farms } = useSelector(
    getOrganizationByProducerNumber(producerNumber, "Sika")
  );
  const [notificationStatus, setNotificationStatus] = useState("");
  const [infoModal, setInfoModal] = useState<any>({
    open: false,
    title: "",
    text: "",
    okButtonText: "",
    onClose: () => {},
  });
  const [isDirty, setIsDirty] = useState(0);
  const [reset, setReset] = useState(new Date().getTime());
  const [selectedFarm, setSelectedFarm] = useState<any>({
    current: "",
    prev: "",
  });
  const [addedAnimals, setAddedAnimals] = useState<any[]>([]);
  const [healthQuestions, setHealthQuestions] = useState<any[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isDepartmentRangesLoaded, setIsDepartmentRangesLoaded] = useState<
    boolean
  >(false);
  const dispatch = useDispatch();
  const healthQuestionsRef = useRef<any>();
  const addAnimalsRef = useRef<any>();
  const { notificationId } = useParams();

  const departments =
    farms?.find((f) => f.unitNumber === selectedFarm.current)?.departments ||
    [];

  const getDepartmentsByAnimalType = (animalType) => {
    if (departmentRanges.length > 0) {
      const animalTypeDepartmentRange = departmentRanges.find(
        (row) => row.animalTypeId === Number(animalType)
      );

      if (
        animalTypeDepartmentRange &&
        animalTypeDepartmentRange.startValue !== undefined &&
        animalTypeDepartmentRange.endValue !== undefined
      ) {
        const processedDepartments = departments.filter(
          (value) =>
            value >= animalTypeDepartmentRange?.startValue &&
            value <= animalTypeDepartmentRange?.endValue
        );

        if (processedDepartments.length === 0) {
          processedDepartments[0] = "";
        }

        return processedDepartments;
      }
    }

    return departments;
  };

  const departmentsToOptions = (departments) => {
    return departments.map((v) => ({
      name: v.toString(),
      text: v.toString(),
      value: v.toString(),
    }));
  };

  useEffect(() => {
    if (departmentRanges.length > 0) {
      setIsDepartmentRangesLoaded(true);
    }
  }, [departmentRanges]);

  useEffect(() => {
    dispatch(api.pork.getPorkDepartmentRanges());
    if (notificationId) {
      dispatch(api.pork.getPorkSlaughterNotification(notificationId));
      return () => {
        dispatch(porkSlaughterNotificationCleared());
      };
    } else {
      dispatch(api.pork.getPorkHealthQuestions());
      return () => {
        dispatch(healthQuestionsCleared());
      };
    }
  }, []);

  useEffect(() => {
    if (notificationId && departments && departmentRanges) {
      const sikaTeurasIlmoitusRivit =
        porkSlaughterNotification?.animalData?.sikaTeurasIlmoitusRivit;
      const livestockVenue = porkSlaughterNotification?.animalData?.pitopaikka;
      const ilmoituksenTila =
        porkSlaughterNotification?.animalData?.ilmoituksenTila;
      const ketjukysymykset =
        porkSlaughterNotification?.healthData?.ketjukysymykset;
      if (
        sikaTeurasIlmoitusRivit &&
        sikaTeurasIlmoitusRivit?.length > 0 &&
        ketjukysymykset
      ) {
        const animals = sikaTeurasIlmoitusRivit
          .map((r) => ({
            animalType: r.elainlaji.toString(),
            department: r.osasto,
            departmentOptions: departmentsToOptions(
              getDepartmentsByAnimalType(r.elainlaji)
            ),
            count: r.kpl.toString(),
            week: r.toivottuVko.toString(),
            rowId: r.rowId,
            rowDispatchState: r.rowDispatchState,
            kuormausAika: (r as any).kuormausAika,
            numAnimalsIlmoitettuTotal: (r as any).numAnimalsIlmoitettuTotal,
            numAnimalsKuormattuTotal: (r as any).numAnimalsKuormattuTotal,
            numAnimalsJaljellaTotal: (r as any).numAnimalsJaljellaTotal,
            markingNumber: r.merkintatunnus,
            kuormausAikaCompare:
              (r as any).kuormausAika?.length > 0
                ? `${(r as any).rowDispatchState !== "lo" ? 1 : 2}${
                    (r as any).kuormausAika
                      .map((a) => ({ ...a }))
                      .sort((a, b) => -defaultComparator(a.pvm, b.pvm))[0].pvm
                  }T00:00:00`
                : `${r.rowDispatchState !== "lo" ? 1 : 2}9999-12-30T00:00:00`,
          }))
          .sort(
            (a, b) =>
              -defaultComparator(a.kuormausAikaCompare, b.kuormausAikaCompare)
          );
        setNotificationStatus(ilmoituksenTila ?? "");
        setSelectedFarm((prevState) => ({
          ...prevState,
          current: livestockVenue,
        }));
        setAddedAnimals(animals);
        setAdditionalInfo(
          porkSlaughterNotification?.animalData?.lisatiedot ?? ""
        );
        setHealthQuestions(ketjukysymykset);
      }
    } else {
      setHealthQuestions(initHealthQuestions);
    }
  }, [
    porkSlaughterNotification,
    initHealthQuestions,
    departments,
    departmentRanges,
  ]);

  useEffect(() => {
    if (isDirty > 2) dispatch(routeLeavingGuardActivated());
  }, [isDirty]);

  const handleFarmChange = (value, prevValue) => {
    setSelectedFarm({ current: value, prev: prevValue });
    if (isDirty) {
      setInfoModal({
        open: true,
        title: t("farmSelection_farmChangeTitle"),
        text: t("farmSelection_farmChangeText"),
        cancelButtonText: t("ui_cancel"),
        okButtonText: t("ui_rejectChanges"),
        onClose: (button) => handleInfoModalClose(button),
      });
    }
  };

  const resetForm = () => {
    setIsDirty(0);
    setAddedAnimals([]);
    setReset(new Date().getTime());
  };

  const handleInfoModalClose = (button) => {
    setInfoModal({ open: false });
    if (button === "ok") {
      resetForm();
      setSelectedFarm((prevState) => ({
        ...prevState,
        current: prevState.current,
      }));
    } else {
      setSelectedFarm((prevState) => ({
        ...prevState,
        current: prevState.prev,
      }));
    }
  };

  const handleCancel = () => {
    dispatch(routeLeavingGuardForced());
  };

  const handleAnimalRemoved = (id) => {
    setAddedAnimals((prevState) =>
      prevState.filter((e) => e !== prevState[id])
    );
    setIsDirty(3);
  };

  const handleAnimalEdited = (id, propertyValues) => {
    const modifiedAnimals = [...addedAnimals];
    const modifiedAnimal = (modifiedAnimals[id] = { ...modifiedAnimals[id] });
    for (let p of propertyValues) modifiedAnimal[p[0]] = p[1];
    setAddedAnimals(modifiedAnimals);
    setIsDirty(3);
  };

  const handleSubmit = () => {
    const addedAnimalsIsValid: boolean = addAnimalsRef.current?.validate();
    const porkHealthQuestions: SikaKetjuvastausDTO = healthQuestionsRef.current?.validate();
    if (porkHealthQuestions && addedAnimalsIsValid) {
      const notification: PorkSlaughterNotificationDTO = {
        animalData: {
          elaimet: addedAnimals.map((a) => ({
            elainlaji: parseInt(a.animalType),
            toivottuVko: parseInt(a.week),
            osasto: a.department,
            maaraKpl: parseInt(a.count),
            rowId: a.rowId,
          })),
          pitopaikka: selectedFarm.current,
          lisatiedot: additionalInfo,
        },
        healthData: porkHealthQuestions,
      };
      if (notificationId)
        dispatch(
          api.pork.updatePorkSlaughterInfo(notification, notificationId)
        );
      else dispatch(api.pork.createPorkSlaughterNotification(notification));
      dispatch(routeLeavingGuardDeactivated());
    }
  };

  return (
    <Fragment>
      <InfoModal
        open={infoModal.open}
        title={infoModal.title}
        text={infoModal.text}
        cancelButtonText={infoModal.cancelButtonText}
        okButtonText={infoModal.okButtonText}
        onClose={infoModal.onClose}
      />

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader level="1">
            {notificationId
              ? t("animalNotification_modifySlaughterNotification", {
                  0: notificationId,
                })
              : t("animalNotification_newSlaughterNotification")}
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <PageHeader level="2">
            {t("animalNotification_farmInformation")}
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <FarmSelection
            farms={farms}
            selectedFarm={selectedFarm.current}
            onChange={(value, prevValue) => handleFarmChange(value, prevValue)}
            readonly={Boolean(notificationId) || addedAnimals.length > 0}
            productionLine={ProductionArea.Pork}
          />
        </Grid>

        {selectedFarm.current && (
          <Fragment>
            <Grid item xs={12}>
              <PageHeader level="2" id="added-animals">
                {t("animalNotification_animalInformation")}
              </PageHeader>
            </Grid>

            <Grid item xs={12}>
              <AddAnimals
                key={reset}
                departments={departments}
                departmentRangesLoaded={isDepartmentRangesLoaded}
                getDepartmentsByAnimalType={(animalTypeId) =>
                  getDepartmentsByAnimalType(animalTypeId)
                }
                departmentsToOptions={(deps) => departmentsToOptions(deps)}
                ref={addAnimalsRef}
                markingNumber={
                  farms.find((f) => f.unitNumber === selectedFarm.current)
                    ?.markingNumber || ""
                }
                addedAnimals={addedAnimals}
                modify={Boolean(notificationId)}
                readonly={notificationStatus === "os"}
                onAnimalsAdded={(newRow) => {
                  setAddedAnimals((prevState) => [...prevState, newRow]);
                  setIsDirty(3);
                }}
                onAnimalTypeChange={(e) => setIsDirty(2)}
                onWeekChange={(e) => setIsDirty(2)}
                onAnimalRemoved={(id) => handleAnimalRemoved(id)}
                onAnimalEdited={(id, propertyValues) =>
                  handleAnimalEdited(id, propertyValues)
                }
              />
            </Grid>
            {addedAnimals?.length > 0 && (
              <Fragment>
                {
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <InputField
                      id="slaughter-notification-additionalinformation-input"
                      margin={""}
                      label={t("animalNotification_additionalInformation")}
                      multiline={true}
                      rows={3}
                      defaultValue={additionalInfo}
                      onChange={(e) => {
                        setAdditionalInfo(e.target.value);
                        setIsDirty(3);
                      }}
                      fullWidth={true}
                      readonly={notificationStatus === "os"}
                    />
                  </Grid>
                }
                <Grid item xs={12}>
                  <PageHeader level="2">
                    {t("animalNotification_chainInformation")}
                  </PageHeader>
                </Grid>

                <Grid item xs={12}>
                  <HealthQuestions
                    key={reset}
                    ref={healthQuestionsRef}
                    healthQuestions={healthQuestions}
                    animals={addedAnimals}
                    onValidate={(isValid) => setIsDirty(3)}
                    readonly={notificationStatus === "os"}
                  />
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item xs={12}>
            <Button
              id="slaughter-notification-cancel-button"
              type="cancel"
              style={{
                marginRight: "20px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              onClick={handleCancel}>
              {t("ui_cancel")}
            </Button>

            <Button
              id="slaughter-notification-save-button"
              type="ok"
              disabled={
                addedAnimals.length === 0 ||
                addedAnimals.some((a) => a.tila === "av") ||
                (notificationId && isDirty < 3)
              }
              onClick={handleSubmit}>
              {t("animalNotification_saveNotification")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SlaughterNotificationPage;
