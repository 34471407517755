import {createSlice} from "@reduxjs/toolkit";

interface IError {
    success: any,
    successTimestamp: string
    error: any,
    errorTimestamp: string
};

const initialState: IError = {
    success: null,
    successTimestamp: "",
    error: null,
    errorTimestamp: ""
};

const slice = createSlice({
    name: "api",
    initialState: initialState,
    reducers: {
        requestedError: (state, action) => {           
            state.error = action.payload;
            state.errorTimestamp = new Date().toISOString();
        },
        requestedSuccess: (state, action) => { 
            state.success = action.payload;
            state.successTimestamp = new Date().toISOString();
        },
        requested: (state, action) => {           
        },
        errorHandled: (state) => {   
            state.error = null;
            state.errorTimestamp = "";
        },
    },
});

export const {requestedError, requestedSuccess, requested, errorHandled} = slice.actions;

export default slice.reducer;
