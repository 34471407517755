import {makeStyles} from "@material-ui/core";
import theme from "../../themes";

const useStyles = makeStyles({
  farmSelectDropdown: {
    minWidth: "300px",
    [theme.breakpoints.down("sm")] : {
      minWidth: "200px"
    }
  }
});

export default useStyles;