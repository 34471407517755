import {
    ReportRowBaseType,
    SlaughterReportBaseType
} from "../../../shared/models/SlaughterReportListingDTO";

export class ReportRowBeef extends ReportRowBaseType {
    breed: string = "";
    fatClassification: string = "";
}

export class BeefStatisticsData {
    avgWeight?: number = 0;
    avgDailyGrowth?: number = 0;
}

export class SlaughterReportBeef extends SlaughterReportBaseType {
    statistics: BeefStatisticsData = new BeefStatisticsData();
    animalRows: Map<string, ReportRowBeef[]> = new Map<string, ReportRowBeef[]>();
}