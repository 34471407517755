import React from "react";
import { 
    Input,
    Container,
    Typography,
    Button,
    TextField,
    Paper,
    MenuItem
} from "@material-ui/core";
import useStyles from "./ThemeStyles";

const Theme = () => {  
    const classes = useStyles(); 

    return (
        <Container maxWidth="xl" className={classes.gridContainer}>

            <Typography variant={"h1"} color={"primary"}>Typography</Typography>

            <hr />

            <Typography variant={"h1"} color={"primary"}>Otsikko h1</Typography>

            <Typography variant={"h2"} color={"primary"}>Otsikko h2</Typography>

            <Typography variant={"h3"} color={"primary"}>Otsikko h3</Typography>

            <Typography variant={"h4"} color={"primary"}>Otsikko h4</Typography>

            <Typography variant={"h5"} color={"primary"}>Otsikko h5</Typography>

            <Typography variant={"h6"} color={"primary"}>Otsikko h6</Typography>

            <Typography variant={"subtitle1"} color={"primary"}>Otsikko subtitle1</Typography>

            <Typography variant={"subtitle2"} color={"primary"}>Otsikko subtitle2</Typography>

            <Typography variant={"body1"} color={"textPrimary"}>teksti body1</Typography>

            <Typography variant={"body2"} color={"textPrimary"}>teksti body2</Typography>

            <Typography variant={"body1"} color={"textSecondary"}>teksti body1 secondary</Typography>

            <Typography variant={"body2"} color={"textSecondary"}>teksti body2 secondary</Typography>

            <Typography variant={"srOnly"} color={"textPrimary"}>teksti srOnly</Typography>

            <Typography variant={"caption"} color={"textPrimary"}>teksti caption</Typography>

            <p></p>

            <Typography variant={"button"} color={"textPrimary"}>teksti button</Typography>

            <Typography variant={"h1"} color={"textPrimary"}>Button</Typography>

            <hr />

            <Button variant="contained">Default</Button>
            <Button variant="contained" color="primary">
            Primary
            </Button>
            <Button variant="contained" color="secondary">
            Secondary
            </Button>
            <Button variant="contained" disabled>
            Disabled
            </Button>
            <Button variant="contained" color="primary" href="#contained-buttons">
            Link
            </Button>

            <p></p>

            <Button>Default</Button>
            <Button color="primary">Primary</Button>
            <Button color="secondary">Secondary</Button>
            <Button disabled>Disabled</Button>
            <Button href="#text-buttons" color="primary">
            Link
            </Button>

            <p></p>

            <Button variant="outlined">Default</Button>
            <Button variant="outlined" color="primary">
            Primary
            </Button>
            <Button variant="outlined" color="secondary">
            Secondary
            </Button>
            <Button variant="outlined" disabled>
            Disabled
            </Button>
            <Button variant="outlined" color="primary" href="#outlined-buttons">
            Link
            </Button>


            <Typography variant={"h1"} color={"primary"}>Paper</Typography>

            <hr />

            <Paper style={{height: "100px"}}>
                Paper
            </Paper>

            <Typography variant={"h1"} color={"primary"}>Textfield</Typography>

            <hr />

            <form className={classes.root} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Standard" />
                <TextField id="filled-basic" label="Filled" variant="filled" />
                <TextField id="outlined-basic" label="Outlined" variant="outlined" />
            </form>

            <form className={classes.root} noValidate autoComplete="off">
                <TextField id="standard-secondary" label="Standard secondary" color="secondary" />
                <TextField
                    id="filled-secondary"
                    label="Filled secondary"
                    variant="filled"
                    color="secondary"
                />
                <TextField
                    id="outlined-secondary"
                    label="Outlined secondary"
                    variant="outlined"
                    color="secondary"
                />
            </form>

            <Typography variant={"h1"} color={"primary"}>Input</Typography>

            <hr />

            <form className={classes.root} noValidate autoComplete="off">
                <Input defaultValue="Hello world" inputProps={{ 'aria-label': 'description' }} />
                <Input placeholder="Placeholder" inputProps={{ 'aria-label': 'description' }} />
                <Input defaultValue="Disabled" disabled inputProps={{ 'aria-label': 'description' }} />
                <Input defaultValue="Error" error inputProps={{ 'aria-label': 'description' }} />
            </form>

            <Typography variant={"h1"} color={"primary"}>Select</Typography>

            <hr />

            <TextField
                select
                label="Select"
                helperText="helperText"
                >   
                <MenuItem value={"value"}>
                    label
                </MenuItem>
          </TextField>

          <Typography variant={"h1"} color={"primary"}>Datepicker</Typography>

          <hr />

          <form className={classes.form} noValidate>
            <TextField
                id="date"
                label="label"
                type="date"
                defaultValue="2017-05-24"
                InputLabelProps={{
                shrink: true,
                }}
            />
            </form>
            
        </Container>
    );
};

export default Theme;