import { makeStyles } from "@material-ui/core";
import Image from "../../assets/images/_58J2717.jpg";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#fff",
    padding: "5px",
  },
  image: {
    width: "100%",
    height: "300px",
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "-100px -50px",
    marginBottom: "20px",
  },
  table: {
    width: "100%",
  },
}));

export default useStyles;
