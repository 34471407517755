import { getIsLoading } from "../../../shared/store/ui/common";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../../../shared/services/langService";
import React, { useEffect } from "react";
import { TableCell, TableRow } from "../../../shared/components/Table/Table";
import Helper from "../../../shared/utils/helper";

import { Link } from "react-router-dom";
import GeneralDatalist from "../../../shared/components/GeneralDatalist/GeneralDatalist";
import * as api from "../../../shared/store/apiActions";
import { FrontpageInfoDTO } from "../../shared/models/FrontpageInfoDTO";
import InfoType from "../../shared/enums/InfoType";
import { RootState } from "../../../shared/store/rootReducer";

const FrontpageProductionSchedules = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => getIsLoading(state));
  const { frontpageInfo } = useSelector((state: RootState) => state.poultry);

  useEffect(() => {
    dispatch(api.poultry.getFrontpageInfo());
  }, []);

  const headerColumns = [
    {
      id: "grossPayment",
      label: t("general_type"),
      isNumeric: false,
      width: "33%",
    },
    {
      id: "infoDate",
      label: t("general_dateAbbrev"),
      isNumeric: true,
      width: "33%",
    },
    {
      id: "info",
      label: "",
      isNumeric: true,
      width: "34%",
    },
  ];

  const getRowJsx = (infoRow: FrontpageInfoDTO) => (
    <TableRow key={infoRow.id}>
      <TableCell>
        {infoRow.infoType
          ? t(`frontpageInfo_${InfoType[infoRow.infoType]}`)
          : ""}
      </TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(infoRow.infoDate)}
      </TableCell>
      <TableCell align="right">
        {infoRow.infoType && (
          <Link
            id="frontpage-production-schedules-page-link"
            to={
              infoRow.infoType === InfoType.ChickProgramUpdated
                ? "/poultry/arrivingbatches"
                : infoRow.infoType === InfoType.DeliveryScheduleUpdated
                ? "/poultry/arrivingbatches"
                : "/poultry/loadingtimetable"
            }>
            {t("frontpageInfo_details")}
          </Link>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <GeneralDatalist
      headerCells={headerColumns}
      columnGroup={headerColumns}
      rows={frontpageInfo ?? []}
      getRowJsx={getRowJsx}
      order="desc"
      orderBy="infoDate"
      isLoading={isLoading}
    />
  );
};

export default FrontpageProductionSchedules;
