export const REQUIRED = "ui_requiredField";
export const MIN_LENGTH = "ui_requiredField";

/**
 * This checks if field is required.
 * Required attribute is maintained by Yup validation schema
 * can be called with  objectidentifier || namespace.objectidentifier || namespace.n*.objectIdentifier
 */
export const isRequiredInYupSchema = (
  yupValidationSchema,
  objectIdentifier: string
) => {
  const objectKeys = objectIdentifier.split(".");

  const validationSchemas = yupValidationSchema.fields;
  let currentIndex = 0;
  let currentValidationSchema = validationSchemas[objectKeys[currentIndex]];

  /**
   * this should be able to handle all Yup schemas
   */
  if (currentValidationSchema) {
    // declared internally since logic is function specific
    const recursiveSearch = (
      currentValidationSchema,
      objectKeys,
      currentIndex
    ) => {
      if (currentValidationSchema.type === "object") {
        currentIndex++;
        for (const key in currentValidationSchema.fields) {
          if (key === objectKeys[currentIndex]) {
            if (currentValidationSchema.fields[key].type === "object") {
              return recursiveSearch(
                currentValidationSchema.fields[key],
                objectKeys,
                currentIndex++
              );
            } else {
              return currentValidationSchema.fields[key]._exclusive.required;
            }
          }
        }
      } else {
        return currentValidationSchema._exclusive.required;
      }
    };

    return recursiveSearch(currentValidationSchema, objectKeys, currentIndex);
  }
  return false;
};
