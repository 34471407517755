import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Notification from "../Notification/Notification";
import { errorHandlers } from "../../utils/notificationHandler";
import { getIsLoading } from "../../store/ui/common";

interface IAuthorizedProps {
  urlPattern?: string;
}

const Authorized: React.FC<IAuthorizedProps> = ({
  children,
  urlPattern = "",
}) => {
  const [failed, setFailed] = React.useState<boolean>(true);
  const isLoading = useSelector((state) => getIsLoading(state));

  return (
    <Fragment>
      {/* display: contents causes an element's children to appear as 
          if they were direct children of the element's parent, ignoring 
          the element itself. This can be useful when a wrapper element 
          should be ignored when using CSS grid or similar layout techniques. */}
      <section
        style={{
          visibility: failed || isLoading ? "hidden" : "visible",
          display: "contents",
        }}>
        {children}
      </section>

      <Notification
        onChange={(showNotification) => setFailed(!!showNotification)}
        errorHandlers={[errorHandlers.unauthorizedErrorHandler(urlPattern)]}
      />
    </Fragment>
  );
};

export default Authorized;
