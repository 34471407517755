import http from "../../../../shared/services/httpService";
import BeefSlaughterNotificationDTO from "../../models/BeefSlaughterNotificationDTO";
import BeefApiResponseDTO from "../../models/BeefApiResponseDTO";
import BeefForwardingNotificationDTO from "../../models/BeefForwardingNotificationDTO";
import BeefDispatchListing from "../../models/BeefDispatchListing";
import KetjukysymysData from "../../models/KetjukysymysData";
import CalfMedicalQuestionData from "../../models/CalfMedicalQuestionData";

const API_URL = `beef`;

export const getHealthQuestions = async () => {
  const resp = await http.get(`${API_URL}/slaughter/healthquestions`);
  return resp;
};

export const getCalfMedicalQuestions = async () => {
  const resp = await http.get(`${API_URL}/forwarding/medicalquestions`);
  return resp;
};

export const createBeefSlaughterNotification = async (
  notification: BeefSlaughterNotificationDTO
) => {
  const resp = await http.post<BeefApiResponseDTO>(
    `${API_URL}/slaughter`,
    notification
  );
  return resp;
};

export const createBeefForwardingNotification = async (
  notification: BeefForwardingNotificationDTO
) => {
  const resp = await http.post<BeefApiResponseDTO>(
    `${API_URL}/forwarding`,
    notification
  );
  return resp;
};

export const getBeefNotificationsList = async (
  notificationStatus: "open" | "completed",
  startDate?: string,
  endDate?: string
) => {
  let resp;
  if (startDate && endDate) {
    resp = await http.get<BeefDispatchListing>(
      `${API_URL}/list/${notificationStatus}?startDate=${startDate}&endDate=${endDate}`
    );
  } else {
    resp = await http.get<BeefDispatchListing>(
      `${API_URL}/list/${notificationStatus}`
    );
  }

  return resp;
};

export const getBeefSlaughterNotification = async (notificationId: number) => {
  const resp = await http.get<BeefApiResponseDTO>(
    `${API_URL}/slaughter/${notificationId}`
  );
  return resp;
};

export const getBeefForwardingNotification = async (notificationId: number) => {
  const resp = await http.get<BeefApiResponseDTO>(
    `${API_URL}/forwarding/${notificationId}`
  );
  return resp;
};

export const updateBeefSlaughterInfo = async (
  notification: BeefSlaughterNotificationDTO,
  notificationId: number
) => {
  const resp = await http.put<BeefApiResponseDTO>(
    `${API_URL}/slaughter/${notificationId}`,
    notification
  );
  return resp;
};

export const getBeefTransportInfo = async () => {
  const resp = await http.get<BeefApiResponseDTO>(`${API_URL}/transport`);
  return resp;
};

export const updateBeefForwardingInfo = async (
  notification: BeefForwardingNotificationDTO,
  notificationId: number
) => {
  const resp = await http.put<BeefApiResponseDTO>(
    `${API_URL}/forwarding/${notificationId}`,
    notification
  );
  return resp;
};

export const getBeefHealthInfo = async (notificationId: number) => {
  return await http.get<KetjukysymysData>(
    `${API_URL}/health/${notificationId}`
  );
};

export const getCalfMedicalInfo = async (notificationId: number) => {
  return await http.get<CalfMedicalQuestionData>(
    `${API_URL}/health/medical/${notificationId}`
  );
};

export default {
  getHealthQuestions,
  getCalfMedicalQuestions,
  createBeefSlaughterNotification,
  createBeefForwardingNotification,
  getBeefNotificationsList,
  getBeefSlaughterNotification,
  getBeefForwardingNotification,
  updateBeefSlaughterInfo,
  getBeefTransportInfo,
  updateBeefForwardingInfo,
  getBeefHealthInfo,
  getCalfMedicalInfo,
};
