import { GridRowData, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import FullFeaturedDataGrid, {
  EditToolbar,
  RenderActionButtons,
  RowMenuProps,
} from "../../../shared/components/DataGrid/FullFeaturedDataGrid";
import { t } from "../../../shared/services/langService";

interface IEditableTable {
  columns: any[];
  rows: any[];
  setRowData: Function;
  enableActionButtons?: boolean;
  validationSchema?: any;
  [x: string]: any;
}

const EditableTable: React.FC<IEditableTable> = ({
  columns,
  rows,
  setRowData,
  validationSchema,
  enableActionButtons,
  ...rest
}) => {
  const apiRef = useGridApiRef();

  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridRows, setGridRows] = useState<any[]>([]);

  // enable actionButtons
  useEffect(() => {
    if (enableActionButtons) {
      const actionsColumn = {
        field: "actions",
        headerName: t("farmContactsModal_selectAction"),
        renderCell: function (props: RowMenuProps) {
          const { api, id } = props;

          const isInEditMode = api.getRowMode(id) === "edit";

          const handleEditClick = (event) => {
            event.stopPropagation();
            api.setRowMode(id, "edit");
          };

          const handleSaveClick = (event) => {
            event.stopPropagation();
            api.commitRowChange(id);
            api.setRowMode(id, "view");
            const row = api.getRow(id);
            api.updateRows([{ ...row, isNew: false }]);
          };

          const handleDeleteClick = (event) => {
            event.stopPropagation();
            deleteRow(id);
            api.updateRows([{ id, _action: "delete" }]);
          };

          const handleCancelClick = (event) => {
            event.stopPropagation();
            api.setRowMode(id, "view");
            const row = api.getRow(id);
            if (row!.isNew) {
              api.updateRows([{ id, _action: "delete" }]);
            }
          };

          return (
            <RenderActionButtons
              handleCopyClick={() => {}}
              handleSaveClick={handleSaveClick}
              handleCancelClick={handleCancelClick}
              handleEditClick={handleEditClick}
              handleDeleteClick={handleDeleteClick}
              isInEditMode={isInEditMode}
              hasCopyButton={false}
              hasClearMode={false}
            />
          );
        },
        sortable: false,
        width: 100,
        headerAlign: "center",
        filterable: false,
        align: "center",
        disableColumnMenu: true,
        disableReorder: true,
      };

      setGridColumns([...columns, actionsColumn]);
    }
  }, [columns, enableActionButtons, gridRows]);

  useEffect(() => {
    setGridRows(rows);
  }, [rows]);

  const deleteRow = (id) => {
    setGridRows([...gridRows.filter((row) => row.id !== Number(id))]);
  };

  const handleOnEditRowCommit = (editedModel: GridRowData) => {
    // reformat object into {id:number, field:any ...}
    const id = Object.keys(editedModel)[0];
    const dataRow = editedModel[id];

    let newRowObject = { id: Number(id) };
    Object.keys(dataRow).forEach((obj, ind) => {
      let key = Object.keys(dataRow)[ind];
      newRowObject[key] = dataRow[obj]?.value;
    });

    setGridRows([
      ...gridRows.filter((row) => row.id !== Number(id)),
      newRowObject,
    ]);
  };

  /** This component has internal state. This state is mirrored to parent state */
  useEffect(() => {
    setRowData(gridRows);
  }, [gridRows]);

  return (
    <FullFeaturedDataGrid
      columns={gridColumns}
      rows={gridRows}
      editMode="row"
      components={{
        Toolbar: EditToolbar,
      }}
      componentsProps={{
        toolbar: { apiRef },
      }}
      pagination={undefined}
      hideFooterPagination={true}
      onEditRowCommit={handleOnEditRowCommit}
      validationSchema={validationSchema}
      {...rest}
    />
  );
};

export default EditableTable;
