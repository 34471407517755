import i18next from "i18next";
import { applicationConfig } from "../../config/config.json";
import fi from "../../assets/lang/fi.json";
import sv from "../../assets/lang/sv.json";

const { supportedLanguages } = applicationConfig.i18n;

export const getSupported = () => {
  return Object.keys(supportedLanguages);
};

export const nameFor = (langCode) => {
  return supportedLanguages[langCode];
};

const initI18next = (opt, onSuccess, onError) => {
  i18next.init(opt, (err) => {
    if (err && onError) onError(err);
    onSuccess();
  });
};

export const init = (
  opt: any,
  local: boolean = false,
  translations: any[] = []
) => {
  const lang = active();

  const commonOpt = {
    lng: lang,
    ns: "translation",
    defaultNS: "translation",
    debug: opt.debug || false,
  };

  return new Promise(async (resolve, reject) => {
    try {
      const resources = {};
      if (local) {
        resources["fi"] = fi;
        resources["sv"] = sv;
      } else {
        for (let l in translations) {
          resources[l] = {
            translation: translations[l],
          };
        }
      }
      initI18next(
        { resources, ...commonOpt },
        () => resolve(resolve),
        (err) => reject(err)
      );
    } catch (err) {
      reject(err);
    }
  });
};

const active = (newLocaleCode = i18next.language) => {
  if (newLocaleCode) i18next.language = newLocaleCode;

  return i18next.language;
};

export const t = (key, opt = {}): string => {
  return i18next.t(key, opt) || "";
};

export const changeLanguage = (langCode) => {
  return new Promise(async (resolve, reject) => {
    try {
      await i18next.changeLanguage(langCode);
      resolve(langCode);
    } catch (err) {
      console.log("changeLanguage failed!", err);
      reject();
    }
  });
};

export const detectBrowserLanguage = () => {
  let lang = navigator.language;
  if (lang && lang.indexOf("-") > -1) lang = lang.split("-")[0];
  return "fi"; //TODO: Remove this once the language selection is enabled in menu.
  return lang;
};

export default {
  getSupported,
  nameFor,
  init,
  t,
  changeLanguage,
  detectBrowserLanguage,
};
