import { t } from "../services/langService";

export enum PaymentType {
  All = 1,
  Slaughter,
  Forward,
  CreditNote,
}

export interface IPayment {
  paymentNumber: number;
  paymentType: PaymentType;
  purchaseDate: Date;
  purchaseAgreementNumber?: number;
  paymentDate: Date;
  grossPayment: number;
  paymentDeductions: number;
  netPayment: number;
  rotationNumber?: number;
}

export const getPaymentTypeTranslation = (el: any) => {
  return t(`economy_paymentType_${PaymentType[el]}`);
};
