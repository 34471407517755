import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import i18nReducer from "./i18n";
import settingsReducer, { ISettings } from "./settings";
import validationReducer from "./validation";
import commonReducer from "./common";

const config: any = {
  key: "ui",
  storage: storage,
};

const persistSettingsReducer = persistReducer<ISettings>(
  config,
  settingsReducer
);

export const uiReducer = combineReducers({
  i18n: i18nReducer,
  settings: persistSettingsReducer,
  validation: validationReducer,
  common: commonReducer,
});

export type UiState = ReturnType<typeof uiReducer>;
