import React from "react";
import ContentBox from "../components/ContentBox/ContentBox";

interface WithContentBoxProps {
  isExpandable?: boolean;
  isExpanded?: boolean;
  title: string;
  onExpansionChanged?: Function;
}

const withContentBox = <P extends object>(
  TableComponent: React.ComponentType<P>
): React.FC<P & WithContentBoxProps> => {
  return ({
    isExpandable,
    isExpanded,
    title,
    onExpansionChanged,
    ...props
  }: WithContentBoxProps) => {
    return (
      <ContentBox
        isExpandable={isExpandable}
        isExpanded={isExpanded}
        title={title}
        onExpansionChanged={onExpansionChanged}>
        <TableComponent {...(props as P)} />
      </ContentBox>
    );
  };
};

export default withContentBox;
