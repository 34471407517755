import React from "react";
import { Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import useStyles from "./FileListingContentStyles";
import Content from "../../shared/components/Content/Content";
import FileListingPage from "../../user/FileListingPage/FileListingPage";
import {
  ProductionArea,
  animalProductionLines,
} from "../../shared/enums/productionLines";
import { OperationUnit } from "../../shared/enums/CoreEnums";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import { AuthorizationContext } from "../../management/shared/models/Users";
import {
  ADUserRole,
  mainUserRoles,
  UserRole,
} from "../../shared/enums/CoreEnums";
import { t } from "../../shared/services/langService";
import { RootState } from "../../shared/store/rootReducer";

const FileListingContent = () => {
  const { productionLine, operationUnit } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const classes = useStyles({ productionLine });

  const getFileListingPath = (
    operationUnit: OperationUnit,
    productionLine: ProductionArea
  ) => {
    return `${
      OperationUnit[operationUnit] === "Sinetti"
        ? "HKScan"
        : OperationUnit[operationUnit] === "Kivikyla"
        ? "Kivikylä"
        : OperationUnit[operationUnit]
    }/${productionLine}`;
  };

  if (!operationUnit) {
    return null;
  }
  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      <Switch>
        <ProtectedRoute
          path="/user/files/economyreports"
          render={() => (
            <FileListingPage
              header={t("fileListing_economyreports")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Talousraportit`}
              key={1}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EconomyUser]}
        />
        <ProtectedRoute
          path="/user/files/pricelist"
          render={() => (
            <FileListingPage
              header={t("fileListing_pricelist")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Hinnastot`}
              key={2}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.SecondaryMainUser]}
        />
        <ProtectedRoute
          path="/user/files/productiontracking"
          render={() => (
            <FileListingPage
              header={t("fileListing_productiontracking")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                ProductionArea.Poultry
              )}/Tuotantoseurannat`}
              key={3}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={[ProductionArea.Poultry]}
          allowedUserRoles={[
            ADUserRole.SinettiHKScanBroileri,
            UserRole.EmployeeUser,
          ]}
        />
        <ProtectedRoute
          path="/user/files/reports"
          render={() => (
            <FileListingPage
              header={t("fileListing_reports")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Raportit`}
              key={4}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={[ProductionArea.Beef, ProductionArea.Pork]}
          allowedUserRoles={[
            ADUserRole.SinettiHKScanNauta,
            ADUserRole.SinettiKivinettiNauta,
            ADUserRole.SinettiHKScanSika,
            ADUserRole.SinettiKivinettiSika,
            UserRole.EmployeeUser,
          ]}
        />
        <ProtectedRoute
          path="/user/files/researchresults"
          render={() => (
            <FileListingPage
              header={t("fileListing_researchresults")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Tutkimustulokset`}
              key={5}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EmployeeUser]}
        />
        <ProtectedRoute
          path="/user/files/plans"
          render={() => (
            <FileListingPage
              header={t("fileListing_plans")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Suunnitelmat`}
              key={6}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EmployeeUser]}
        />
        <ProtectedRoute
          path="/user/files/lectures"
          render={() => (
            <FileListingPage
              header={t("fileListing_lectures")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Luennot`}
              key={7}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EmployeeUser]}
        />
        <ProtectedRoute
          path="/user/files/producermanual"
          render={() => (
            <FileListingPage
              header={t("fileListing_producermanual")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Tuottajan käsikirja`}
              key={8}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EmployeeUser]}
        />
        <ProtectedRoute
          path="/user/files/instructionsarchive"
          render={() => (
            <FileListingPage
              header={t("fileListing_instructionsarchive")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Ohjepankki`}
              key={9}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EmployeeUser]}
        />
        <ProtectedRoute
          path="/user/files/formsandtemplates"
          render={() => (
            <FileListingPage
              header={t("fileListing_formsandtemplates")}
              defaultFolderPath={`${getFileListingPath(
                operationUnit,
                productionLine
              )}/Lomakkeet ja pohjat`}
              key={10}
            />
          )}
          authContext={AuthorizationContext.MainUser}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EmployeeUser]}
        />
      </Switch>
    </Content>
  );
};

export default FileListingContent;
