
const scrollToLocation = (hash: string) => {
    const id = hash.replace("#", "");
    const scroll = () => {
        const element = document.getElementById(id);
        if (element) 
            setTimeout(() => element.scrollIntoView(), 0);
        else 
            setTimeout(scroll, 100);
        
    };
    scroll();
};

export default scrollToLocation;