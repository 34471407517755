import { createSlice } from "@reduxjs/toolkit";
import { ICauroselMediaItem } from "../components/CarouselComponent/CarouselComponent";
import { IContent } from "../models/Publications";
import Helper from "../utils/helper";

interface IPublicationState {
  publications: IContent[];
  publication?: IContent;
  topMenuReferenceId?: number;
  mainMenuReferenceId?: number;
  topMenuItems: IContent[];
  mainMenuItems: IContent[];
  carouselAdItems: ICauroselMediaItem[];
  bannerItems?: IBannerItems;
}

export interface IBannerItems {
  Infot: IContent[];
  Huomautukset: IContent[];
}

const initialState: IPublicationState = {
  publications: [],
  publication: undefined,
  topMenuReferenceId: undefined,
  mainMenuReferenceId: undefined,
  topMenuItems: [],
  mainMenuItems: [],
  carouselAdItems: [],
  bannerItems: undefined,
};

const slice = createSlice({
  name: "publications",
  initialState: initialState,
  reducers: {
    topMenuReferenceIdReceived: (state, action) => {
      state.topMenuReferenceId = action.payload.data;
    },
    mainMenuReferenceIdReceived: (state, action) => {
      state.mainMenuReferenceId = action.payload.data;
    },
    topMenuItemsCleared: (state) => {
      state.topMenuItems = initialState.topMenuItems;
    },
    mainMenuItemsCleared: (state) => {
      state.mainMenuItems = initialState.mainMenuItems;
    },
    topMenuItemsReceived: (state, action) => {
      state.topMenuItems = action.payload.data;
    },
    mainMenuItemsReceived: (state, action) => {
      state.mainMenuItems = action.payload.data;
    },
    carouselAdItemsReceived: (state, action) => {
      state.carouselAdItems = action.payload.data;
    },
    carouselAdItemsCleared: (state) => {
      state.carouselAdItems = initialState.carouselAdItems;
    },
    publicationListingReceived: (state, action) => {
      state.publications = action.payload.data;
    },
    publicationsCleared: (state) => {
      state.publications = initialState.publications;
    },
    publicationReceived: (state, action) => {
      state.publication = action.payload.data;
    },
    publicationCleared: (state) => {
      state.publication = initialState.publication;
    },
    fileDownloaded(state, action) {
      Helper.downloadFile(action.payload.data, action.payload.filename);
    },
    bannersReceived: (state, action) => {
      state.bannerItems = action.payload.data;
    },
  },
});

export const {
  publicationListingReceived,
  topMenuReferenceIdReceived,
  mainMenuReferenceIdReceived,
  topMenuItemsReceived,
  mainMenuItemsReceived,
  topMenuItemsCleared,
  mainMenuItemsCleared,
  publicationsCleared,
  publicationReceived,
  publicationCleared,
  carouselAdItemsReceived,
  carouselAdItemsCleared,
  fileDownloaded,
  bannersReceived,
} = slice.actions;

export default slice.reducer;
