import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: "100%",
    backgroundColor: "#E1E0DC" 
  },
  gridContainer: {
    [theme.breakpoints.down("xs")] : {
      padding: "0",
    },
    padding: "13% 2%",
    paddingBottom: "2%",
    height: "100%",
    width: "100%"
  },
  content: {
    minHeight: "300px",
    height: "100%",
    width: "100%"
  },
  container: {
    [theme.breakpoints.down("xs")] : {
      marginTop: "0px",
    },
    marginTop: "20px",
    marginBottom: "20px",
    paddingTop: "20px",
    paddingBottom: "20px"
  },
}));

export default useStyles;