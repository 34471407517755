import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(({

  labelContainer: {
    display: "block"
  },
  label: {
    marginRight: "0",
    "& > *": {
      color: "#535559",
      fontFamily: "Avenir Next W1G Bold",
      letterSpacing: "0.28px",
      fontSize: "0.8rem",
      fontWeight: "bold"
    }
  },

}));

export default useStyles;