import React, { useRef } from "react";
import { FormControl, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "./DropdownMenuStyles";

interface DropdownMenuOption {
  name: string;
  text: string;
  value: any;
}

interface DropdownMenuProps {
  label?: string;
  onChange: (value: any, prevValue: any) => void;
  required?: boolean;
  errorText?: string;
  options: DropdownMenuOption[];
  defaultValue?: any;
  useCaret?: boolean;
  displayEmpty?: boolean;
  displayEmptyText?: string;
  selectedValue?: string | number;
  singleLine?: boolean;
  fullWidth?: boolean;
  labelStyle?: object;
  [x: string]: any;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  label = "",
  required = false,
  errorText = "",
  onChange,
  options,
  defaultValue,
  useCaret = false,
  displayEmpty = true,
  displayEmptyText = "",
  selectedValue = null,
  singleLine = false,
  fullWidth = false,
  labelStyle = {},
  ...rest
}) => {
  const classes = useStyles(singleLine);
  const ref = useRef<any>();
  let prevValue = defaultValue;

  const handleChange = (name, value) => {
    const item = options.find((i) => i.name === name);
    const isNumber = typeof item?.value === "number";
    onChange(
      isNumber ? parseInt(value) : value,
      isNumber ? parseInt(prevValue) : prevValue
    );
  };

  const handleClick = (value) => {
    prevValue = value;
  };

  return (
    <div className={classes.container}>
      {label && (
        <Typography
          display={singleLine ? "inline" : "block"}
          variant={"body1"}
          style={labelStyle}
          className={classes.label}
          component="span">
          {label}

          {required && (
            <span style={{ color: "#cf2d3a", marginLeft: "4px" }}>*</span>
          )}
        </Typography>
      )}
      <FormControl
        fullWidth={fullWidth}
        variant="filled"
        className={classes.formControl}
        {...rest}>
        <select
          ref={ref}
          {...(selectedValue !== null ? { value: selectedValue } : {})}
          defaultValue={defaultValue}
          onChange={(event) =>
            handleChange(event.target.name, event.target.value)
          }
          onClick={(event) => handleClick(ref.current.value)}
          className={`${classes.dropdownInput} ${
            useCaret ? classes.caretIcon : ""
          }`}>
          {displayEmpty && (
            <option style={{ display: "none" }} value="">
              {displayEmptyText}
            </option>
          )}
          {options.map((option) => (
            <option value={option.value} key={option.name}>
              {option.text}
            </option>
          ))}
        </select>
      </FormControl>
      {errorText && (
        <Alert
          icon={false}
          color={"error"}
          classes={{ standardError: classes.alert }}>
          {errorText}
        </Alert>
      )}
    </div>
  );
};

export default DropdownMenu;
