// TODO: remove theme things, move styles to another file
import { createTheme, Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: "inline-flex",
      alignItems: "center",
      gap: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    textPrimary: {
      color: theme.palette.text.primary,
    },
    grid: {
      "& .MuiDataGrid-cell--editing": {
        backgroundColor: "rgb(255,215,115, 0.19)",
        color: "#1a3e72",
      },
      "& .Mui-error": {
        backgroundColor: `rgb(126,10,15,  0.1)`,
        color: "#750f0f",
      },
    },
  }),
  { defaultTheme }
);

export default useStyles;
