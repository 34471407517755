import { Box, Grid } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import HTMLReactParser from "html-react-parser";
import moment from "moment";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ReactComponent as Calendar } from "../../assets/images/iconmonstr-calendar-4.svg";
import CustomLink from "../../shared/components/CustomLink/CustomLink";
import PageHeader, {
  PageHeaderBox,
} from "../../shared/components/PageHeader/PageHeader";
import PageText from "../../shared/components/PageText/PageText";
import { OtherPublicationContext } from "../../shared/models/Publications";
import { t } from "../../shared/services/langService";
import * as api from "../../shared/store/apiActions";
import { publicationCleared } from "../../shared/store/publications";
import { RootState } from "../../shared/store/rootReducer";
import theme from "../../shared/themes";
import Helper from "../../shared/utils/helper";
import useStyles from "./PublicationPageStyles";
import useGTM, { IGTMContentFetchProps, GTMEvents } from "../../hooks/useGTM";

interface PublicationContentProps {
  publicationType: "news" | "event" | "content";
  specificContext?: OtherPublicationContext;
  headJsx?: JSX.Element;
}

const PublicationPage: React.FC<PublicationContentProps> = ({
  publicationType,
  specificContext,
  headJsx,
}) => {
  const classes = useStyles();
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const dispatch = useDispatch();
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { pageNameOrReferenceId } = useParams();
  const publication = useSelector(
    (state: RootState) => state.publications.publication
  );

  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const { gtmDispatch } = useGTM();

  const transform = (node) => {
    if (
      node.type === "tag" &&
      node.name === "a" &&
      node.attribs?.href?.startsWith("/download")
    ) {
      return (
        <Link
          id="publication-download-link"
          className={classes.downloadLink}
          to={node.attribs?.href}
          onClick={(e) =>
            download(e, node.attribs?.href.slice("/download".length))
          }>
          {node.children[0]?.data}
        </Link>
      );
    } else if (
      node.type === "tag" &&
      node.name === "a" &&
      node.attribs?.href?.startsWith("/")
    ) {
      return (
        <CustomLink to={node.attribs?.href} className={classes.downloadLink}>
          {node.children[0]?.data}
        </CustomLink>
      );
    }
  };

  const download = (e, filepath) => {
    e.preventDefault();
    dispatch(api.publication.getFile(filepath.substring(1)));
  };

  const formatEventTime = (
    startTime: string = "",
    endTime: string = ""
  ): string => {
    const separator = " - ";
    let formatted: string = "";

    if (startTime) {
      formatted += `${Helper.dateToLocale(startTime)} ${moment(
        startTime
      ).format("HH:mm")}`;
    }

    if (endTime) {
      formatted += separator;

      formatted += `${
        !moment(startTime).isSame(endTime, "day")
          ? Helper.dateToLocale(endTime)
          : ""
      } ${moment(endTime).format("HH:mm")}`;
    }

    return formatted;
  };

  useEffect(() => {
    if (pageNameOrReferenceId && langCode) {
      dispatch(
        api.publication.getPublication(
          langCode,
          specificContext ? specificContext : productionLine,
          pageNameOrReferenceId
        )
      );
    }
    return () => {
      dispatch(publicationCleared());
    };
  }, [pageNameOrReferenceId, langCode]);

  useEffect(() => {
    if (
      publication?.referenceId !== undefined &&
      publication?.title !== undefined
    ) {
      const gtmProps: IGTMContentFetchProps = {
        id: publication?.referenceId,
        title: publication?.title,
      };
      gtmDispatch(GTMEvents.publicationContentFetch, gtmProps);
    }
  }, [productionLine, langCode, publication?.referenceId, publication?.title]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        {headJsx && <Box displayPrint="none">{headJsx}</Box>}
      </Grid>
      {publication && (
        <Fragment>
          {publication?.imageContent && (
            <Grid
              style={{
                paddingLeft: "4%",
                paddingRight: "4%",
                paddingTop: "2%",
              }}
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              direction={"column"}>
              <img src={publication.imageContent} width={"100%"} />
            </Grid>
          )}

          <Grid container style={{ marginLeft: "10%", marginRight: "10%" }}>
            <Grid container alignItems={"flex-start"}>
              <Grid
                item
                xs={12}
                className={
                  publicationType === "news"
                    ? classes.htmlContentPositioning
                    : ""
                }>
                <PageHeader
                  align={"center"}
                  style={{ paddingTop: "50px" }}
                  level={isSmUp ? "1" : "2"}>
                  {publication?.title}
                </PageHeader>
              </Grid>

              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                direction={"column"}
                className={
                  publicationType === "news"
                    ? classes.htmlContentPositioning
                    : ""
                }>
                <PageHeaderBox />
              </Grid>

              <Grid
                item
                xs={"auto"}
                style={{ marginBottom: "8px", whiteSpace: "nowrap" }}>
                {publicationType !== "content" && (
                  <Calendar
                    viewBox={"0 -14 38 38"}
                    className={classes.calendar}
                  />
                )}

                <PageText
                  type={"normal"}
                  style={{
                    color: theme.palette.primary.main,
                    textTransform: "uppercase",
                  }}
                  containerStyle={{
                    display: "inline-block",
                  }}>
                  {publicationType === "news" && (
                    <Fragment>
                      {Helper.dateToLocale(publication?.publishDate ?? "")}
                      <span style={{ margin: "0 10px" }}>/</span>
                      {t(`publicationType_${publicationType}`)}
                    </Fragment>
                  )}
                  {(publication?.startTime || publication?.endTime) && (
                    <Fragment>
                      <PageText
                        type={"normal"}
                        style={{
                          color: theme.palette.primary.main,
                          whiteSpace: "nowrap",
                        }}>
                        {formatEventTime(
                          publication?.startTime?.toString(),
                          publication?.endTime?.toString()
                        )}
                      </PageText>
                    </Fragment>
                  )}
                </PageText>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                item
                className={`${classes.htmlContent} ${classes.htmlContentPositioning}`}>
                {HTMLReactParser(publication?.body ?? "", {
                  replace: transform,
                })}
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default PublicationPage;
