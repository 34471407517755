import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import DropdownMenu from "../../shared/components/DropdownMenu/DropdownMenu";
import GeneralDataList from "../../shared/components/GeneralDatalist/GeneralDatalist";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { TableCell, TableRow } from "../../shared/components/Table/Table";
import {
  getPaymentTypeTranslation,
  IPayment,
  PaymentType,
} from "../../shared/models/Payments";
import { t } from "../../shared/services/langService";
import { economy } from "../../shared/store/apiActions";
import { stateReseted } from "../../shared/store/createStore";
import { paymentTypeSelected } from "../../shared/store/economy";
import { getIsLoading } from "../../shared/store/ui/common";
import Helper from "../../shared/utils/helper";
import useStyles from "./PaymentsPageStyles";
import withTablePagination from "../../shared/hocs/withTablePagination";
import withTableSorting from "../../shared/hocs/withTableSorting";
import withContentBox from "../../shared/hocs/withContentBox";
import TimeframePicker from "../../shared/components/TimeframePicker/TimeframePicker";
import ProductionArea from "../../shared/enums/productionLines";
import { RootState } from "../../shared/store/rootReducer";

const GeneralDataListWithSortingPaginationContentBox = withTableSorting(
  withTablePagination(withContentBox(GeneralDataList))
);

interface IProps {
  productionLine: ProductionArea;
  [x: string]: any;
}

const PaymentsPage: React.FC<IProps> = ({
  productionLine,
  currentStartDate,
  currentEndDate,
  fetchCounter,
  startTimeOnChange,
  endTimeOnChange,
  onFetchClick,
  errorMsg,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getIsLoading(state));
  const { selectedPaymentType, payments } = useSelector(
    (state: RootState) => state.economy
  );

  const distpachPaymentFetch = () => {
    if (!errorMsg) {
      dispatch(
        economy.getPayments(
          productionLine,
          selectedPaymentType,
          Helper.dateToIsoString(currentStartDate),
          Helper.dateToIsoString(currentEndDate)
        )
      );
    }
  };

  const [paginationResetCounter, setResetCounter] = useState<any>(1);
  useEffect(() => {
    return () => {
      dispatch(stateReseted());
    };
  }, []);

  useEffect(() => {
    distpachPaymentFetch();
    setResetCounter(1 + paginationResetCounter);
  }, [fetchCounter, selectedPaymentType]);

  const handleDropdownMenuChange = async (value: number) => {
    dispatch(paymentTypeSelected(value));
  };

  const paymentsSelectListOptions = Object.values(PaymentType)
    .filter((el: any) => !isNaN(el))
    .map((el) => ({
      name: PaymentType[el],
      text: getPaymentTypeTranslation(el),
      value: el,
    }));

  const headerColumns = [
    {
      id: "paymentNumber",
      label: t("economy_paymentNumber"),
      isNumeric: false,
    },
    {
      id: "paymentDate",
      label: t("economy_paymentDate"),
      isNumeric: true,
    },
    {
      id: "netPayment",
      label: t("economy_netPayment"),
      isNumeric: true,
    },
    {
      id: "paymentType",
      label: t("economy_paymentType"),
      isNumeric: false,
    },
    {
      id: "purchaseDate",
      label: t("economy_purchaseDate"),
      isNumeric: true,
    },
    {
      id: "purchaseAgreementNumber",
      label: t("economy_purchaseAgreementNumber"),
      isNumeric: false,
    },
  ];

  const getRowJsx = (payment: IPayment) => (
    <TableRow key={payment.paymentNumber} style={{ whiteSpace: "nowrap" }}>
      <TableCell>
        <Link
          id="payments-download-link"
          to="#"
          onClick={() => download(payment.paymentNumber)}>
          {payment.paymentNumber}
        </Link>
      </TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(payment.paymentDate)}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(payment.netPayment.toFixed(2))}
      </TableCell>
      <TableCell>{getPaymentTypeTranslation(payment.paymentType)}</TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(payment.purchaseDate)}
      </TableCell>
      <TableCell>{payment.purchaseAgreementNumber}</TableCell>
    </TableRow>
  );

  const headerColumnsPoultry = [
    {
      id: "paymentNumber",
      label: t("economy_paymentNumber"),
      isNumeric: false,
    },
    {
      id: "paymentNumber",
      label: t("economy_rotationNumber"),
      isNumeric: false,
    },
    {
      id: "paymentDate",
      label: t("economy_paymentDate"),
      isNumeric: true,
    },

    {
      id: "grossPayment",
      label: t("economy_grossPayment_poultry"),
      isNumeric: true,
    },
    {
      id: "paymentDeductions",
      label: t("economy_paymentDeductions_poultry"),
      isNumeric: true,
    },
    {
      id: "netPayment",
      label: t("economy_netPayment"),
      isNumeric: true,
    },
  ];

  const getRowJsxPoultry = (payment: IPayment) => (
    <TableRow key={payment.paymentNumber} style={{ whiteSpace: "nowrap" }}>
      <TableCell>{payment.paymentNumber}</TableCell>
      <TableCell>{payment.rotationNumber}</TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(payment.paymentDate)}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(payment.grossPayment.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(payment.paymentDeductions.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(payment.netPayment.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  const download = (paymentNumber) => {
    dispatch(economy.generatePaymentPdf(paymentNumber));
  };

  const isPoultryProductionLine = () => {
    return productionLine === ProductionArea.Poultry;
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader level="1">{t("economy_Payments")}</PageHeader>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          {!isPoultryProductionLine() && (
            <DropdownMenu
              id="payments-menu-select"
              onChange={(value) => handleDropdownMenuChange(value)}
              selectedValue={selectedPaymentType}
              options={paymentsSelectListOptions}
              label={t("economy_choosePaymentType")}
              singleLine={true}
            />
          )}
        </Grid>
        <Grid item xs={9}></Grid>
        <TimeframePicker
          selectedStartDate={currentStartDate}
          selectedEndDate={currentEndDate}
          startDateOnChange={startTimeOnChange}
          endDateOnChange={endTimeOnChange}
          onClick={onFetchClick}
          errorMsg={errorMsg}
        />
      </Grid>
      <Grid>
        <GeneralDataListWithSortingPaginationContentBox
          title={getPaymentTypeTranslation(selectedPaymentType)}
          isExpandable={false}
          headerCells={
            isPoultryProductionLine() ? headerColumnsPoultry : headerColumns
          }
          rows={payments}
          order="desc"
          orderBy={isPoultryProductionLine() ? "paymentDate" : "purchaseDate"}
          getRowJsx={isPoultryProductionLine() ? getRowJsxPoultry : getRowJsx}
          isLoading={isLoading}
          forceResetPagination={paginationResetCounter}
          isShowAllDefault={true}
        />
      </Grid>
    </Fragment>
  );
};

export default PaymentsPage;
