export enum OperationUnit {
  Sinetti = 1,
  Kivikyla = 2,
}

export enum ADUserRole {
  SinettiHKScanNauta = "SinettiHKScanNauta",
  SinettiHKScanSika = "SinettiHKScanSika",
  SinettiHKScanBroileri = "SinettiHKScanBroileri",
  SinettiKivinettiNauta = "SinettiKivinettiNauta",
  SinettiKivinettiSika = "SinettiKivinettiSika",
  SinettiOsuuskunta = "SinettiOsuuskunta",
  SinettiHKScanOsuuskunta = "SinettiHKScanOsuuskunta",
}

export enum UserRole {
  MainUser = "MainUser",
  OsuuskuntaUser = "OsuuskuntaUser",
  SecondaryMainUser = "SecondaryMainUser",
  EconomyUser = "EconomyUser",
  EmployeeUser = "EmployeeUser",
  JanToolMainUser = "JanToolMainUser",
}

export type UserRoles = ADUserRole | UserRole;

export const mainUserRoles = [
  ADUserRole.SinettiHKScanNauta,
  ADUserRole.SinettiHKScanSika,
  ADUserRole.SinettiHKScanBroileri,
  ADUserRole.SinettiKivinettiNauta,
  ADUserRole.SinettiKivinettiSika,
  UserRole.MainUser,
];
