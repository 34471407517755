import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import useStyles from "./ArrivingBatchesPageStyles";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { t } from "../../shared/services/langService";
import { getIsLoading } from "../../shared/store/ui/common";
import { chickNotificationsCleared } from "../../shared/store/poultry";
import * as api from "../../shared/store/apiActions";
import Helper from "../../shared/utils/helper";
import WithTableSorting from "../../shared/hocs/withTableSorting";
import WithTablePagination from "../../shared/hocs/withTablePagination";
import ExpandableListView from "../../shared/components/ExpandableListView/ExpandableListView";
import { RootState } from "../../shared/store/rootReducer";

const ExpListViewWithSortingPagination = WithTableSorting(
  WithTablePagination(ExpandableListView)
);

const ArrivingBatchesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { chickNotifications } = useSelector(
    (state: RootState) => state.poultry
  );
  const isLoading = useSelector((state) => getIsLoading(state));

  useEffect(() => {
    dispatch(api.poultry.getChickNotifications());
    return () => {
      dispatch(chickNotificationsCleared());
    };
  }, []);

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <PageHeader level="1">
          {t("nav_productionTimetable_arrivingBatches")}
        </PageHeader>
        <Grid item xs={12}>
          <ExpListViewWithSortingPagination
            title={""}
            hideHeader
            headers={[
              {
                content: t("arrivingBatches_hall"),
              },
              {
                content: t("arrivingBatches_deliveryDate"),
                isNumeric: true,
              },
              {
                content: t("arrivingBatches_chickQuantity"),
              },
              {
                content: t("arrivingBatches_slaughterAge"),
              },
              {
                content: t("arrivingBatches_slaughterDate"),
                isNumeric: true,
              },
              {
                content: t("arrivingBatches_hatchery"),
              },
              {
                content: t("animalNotification_info"),
              },
            ]}
            rows={
              chickNotifications?.map((r) => [
                r.hall,
                Helper.dateToLocale(r.deliveryDate),
                r.plannedQuantity,
                r.butchingAge,
                Helper.dateToLocale(r.slaughterDate),
                r.hatchery,
                r.notes,
              ]) ?? []
            }
            enableExpandable={false}
            labelRowsPerPage={t("ui_rows")}
            emptyRowsText={
              !isLoading ? t("animalNotification_noRowsFound") : ""
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArrivingBatchesPage;
