import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  content: {
    height: "100%",
    width: "100%",
  },
  tableHead: {
    "&:last-child tr": {
      "&:last-child th": {
        borderBottom: "unset",
      },
    },
  },
  tableContainer: {
    borderRadius: "5px",
    width: "100%",
    overflowX: "unset",
  },
});

export default useStyles;
