import React, { Fragment } from "react";
import { Grid, Hidden } from "@material-ui/core";
import useStyles from "./MainPageStyles";
import TransportReminders from "../shared/components/TransportReminders/TransportReminders";
import FrontpageInvoices from "../../general/components/FrontpageInvoices/FrontpageInvoices";
import FrontpagePayments from "../../general/components/FrontpagePayments/FrontpagePayments";
import { useSelector } from "react-redux";
import WelcomePage from "../../general/WelcomePage/WelcomePage";
import ContentBox from "../../shared/components/ContentBox/ContentBox";
import { t } from "../../shared/services/langService";
import FrontpagePublicationListing from "../../general/components/FrontpagePublicationListing/FrontpagePublicationListing";
import Authorized from "../../shared/components/Authorized/Authorized";
import EpiserverCarouselComponent from "../../general/components/EpiserverCarouselComponent/EpiserverCarouselComponent";
import { RootState } from "../../shared/store/rootReducer";

const Content = () => {
  const classes = useStyles();
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const getAdCauroselJsx = () => (
    <EpiserverCarouselComponent productionLine={productionLine} />
  );

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid container spacing={2} item xs={12} sm={6} md={4} lg={4}>
          <Grid item xs={12}>
            <WelcomePage productionLine={productionLine} />
          </Grid>
          <Grid item xs={12}>
            {getAdCauroselJsx()}
          </Grid>
          <Hidden mdUp xsDown>
            <Grid item style={{ paddingLeft: "15px", paddingTop: "15px" }}>
              <Grid item style={{ paddingBottom: "30px" }}>
                <Authorized urlPattern="news">
                  <FrontpagePublicationListing
                    publicationType={"news"}
                    limit={5}
                  />
                </Authorized>
              </Grid>
              <Grid item>
                <Authorized urlPattern="event">
                  <FrontpagePublicationListing
                    publicationType={"event"}
                    limit={5}
                  />
                </Authorized>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12}>
              <Authorized urlPattern="transport">
                <ContentBox
                  isExpandable={true}
                  title={t("transportReminders_header")}>
                  <TransportReminders />
                </ContentBox>
              </Authorized>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Authorized urlPattern="payments">
                <ContentBox
                  isExpanded={true}
                  title={t("economy_frontpagePayments_header")}>
                  <FrontpagePayments />
                </ContentBox>
              </Authorized>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Authorized urlPattern="invoices">
                <ContentBox
                  isExpanded={true}
                  title={t("economy_frontpageInvocies_header")}>
                  <FrontpageInvoices productionLine={productionLine} />
                </ContentBox>
              </Authorized>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm"]}>
          <Grid
            md={4}
            lg={4}
            item
            style={{ paddingLeft: "15px", paddingTop: "10px" }}>
            <Grid item style={{ paddingBottom: "30px" }}>
              <Authorized urlPattern="news">
                <FrontpagePublicationListing
                  publicationType={"news"}
                  limit={5}
                />
              </Authorized>
            </Grid>
            <Grid item>
              <Authorized urlPattern="event">
                <FrontpagePublicationListing
                  publicationType={"event"}
                  limit={5}
                />
              </Authorized>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Fragment>
  );
};

export default Content;
