export interface CalfMedicalData {
  birthId: string;
  medicationDate: string;
  reason: string;
  productName: string;
  additionalInformation: string;
}

class CalfMedicalQuestionData {
  producerNumber: number = 0;
  medicated?: boolean = undefined;
  savedInNaseva?: boolean = undefined;
  grazedWithMother?: boolean = undefined;
  gmoNotUsed?: boolean = undefined;
  animalMedicalDataRows: CalfMedicalData[] = [];
}

export default CalfMedicalQuestionData;
