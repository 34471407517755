import React, { Fragment, useEffect, useState } from "react";
import { Container, Typography } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../../shared/components/Table/Table";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import PageText from "../../shared/components/PageText/PageText";
import InputField from "../../shared/components/InputField/InputField";
import Button from "../../shared/components/Button/Button";
import useStyles from "./GuiComponentsStyles";
import NotificationBanner from "../../shared/components/NotificationBanner/NotificationBanner";
import RadioButtonGroup from "../../shared/components/RadioButtonGroup/RadioButtonGroup";
import DropdownMenu from "../../shared/components/DropdownMenu/DropdownMenu";
import WeekPicker from "../../shared/components/WeekPicker/WeekPicker";
import Checkbox from "../../shared/components/Checkbox/Checkbox";
import DatePicker from "../../shared/components/DatePicker/DatePicker";
import ExpandableListView from "../../shared/components/ExpandableListView/ExpandableListView";
import WithTablePagination from "../../shared/hocs/withTablePagination";
import WithTableSorting from "../../shared/hocs/withTableSorting";
import Helper from "../../shared/utils/helper";

import {
  GridColumns,
  GridRenderCellParams,
  GridRowData,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid";
import FullFeaturedDataGrid, {
  EditToolbar,
  RenderActionButtons,
  RowMenuCell,
  RowMenuProps,
} from "../../shared/components/DataGrid/FullFeaturedDataGrid";

const ExpListViewWithSortingPagination = WithTableSorting(
  WithTablePagination(ExpandableListView)
);
const ExpListViewWithSorting = WithTableSorting(ExpandableListView);

const GuiComponents = () => {
  const classes = useStyles();

  const radioTitle = "Onko tilallanne esiintynyt elintarviketurvallisuuteen...";
  const radioItems = [
    { label: "1", name: "1", value: "1" },
    { label: "2", name: "2", value: 2 },
  ];

  const dropdownOptions = [
    { text: "Maatila 1", value: "maatila1", name: "maatila1" },
    { text: "Maatila 2", value: "maatila2", name: "maatila2" },
    { text: "Maatila 3", value: "maatila3", name: "maatila3" },
  ];

  const [selectedDate, setSelectedDate] = useState("2019-01-11");

  const handleDateChange = (e) => {
    console.log(Helper.dateToLocale(e));
    setSelectedDate(e);
  };

  return (
    <Container maxWidth="xl" className={classes.gridContainer}>
      <form noValidate>
        <Typography variant={"h2"} color={"inherit"}>
          Tekstit
        </Typography>

        <hr />

        <PageHeader level="1">Uusi teurasilmoitus (Otsikko 1)</PageHeader>

        <p></p>

        <PageHeader level="2">Valitse maatila (Otsikko 2)</PageHeader>

        <p></p>

        <PageText type="normal">
          Valittu maatila, Osoite, Paikkakunta (Normal)
        </PageText>

        <p></p>

        <PageText type="bold">
          Onko tilallanne esiintynyt elintarviketurvallisuuteen... (Bold)
        </PageText>

        <Typography variant={"h2"} color={"inherit"}>
          Syöttö- ja tekstikenttä
        </Typography>

        <hr />

        <InputField placeholder="Valittu maatila, Osoite, Paikkakunta" />

        <InputField label="Valitse maatila" />

        <InputField
          label="Valitse maatila"
          required={true}
          error={true}
          errorText={"Syntymätunnukset eivät ole hyväksyttävässä muodossa!"}
        />

        <InputField
          label="Valitse maatila (readonly)"
          readonly={true}
          value="Valittu maatila, Osoite, Paikkakunta"
        />

        <InputField
          label="Valitse maatila (disabled)"
          disabled={true}
          value="Valittu maatila, Osoite, Paikkakunta"
        />

        <InputField
          label="Valitse maatila (multiline)"
          placeholder="Valittu maatila, Osoite, Paikkakunta"
          multiline={true}
        />

        <InputField
          label="Valitse maatila (multiline, disabled)"
          disabled={true}
          placeholder="Valittu maatila, Osoite, Paikkakunta"
          multiline={true}
        />

        <Typography variant={"h2"} color={"inherit"}>
          Painikkeet
        </Typography>

        <hr />

        <Button type="ok">Tallenna ilmoitus</Button>

        <p></p>

        <Button type="cancel">Peruuta</Button>

        <p></p>

        <Button type="add">Lisää eläimet ilmoitukselle</Button>

        <p></p>

        <Button type="table" fn={"remove"}>
          Poista
        </Button>

        <p></p>

        <Button type="modal">Hylkää muutokset</Button>

        <Typography variant={"h2"} color={"inherit"}>
          Taulukko
        </Typography>

        <hr />

        <p></p>

        <p></p>

        <p></p>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Taulukon otsikko</TableCell>
              <TableCell>Arvo</TableCell>
              <TableCell>Arvo</TableCell>
              <TableCell>Arvo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>
                <Button type="table" fn={"remove"}>
                  Poista
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>
                <Button type="table" fn={"remove"}>
                  Poista
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>1</TableCell>
              <TableCell>2</TableCell>
              <TableCell>3</TableCell>
              <TableCell>
                <Button type="table" fn={"remove"}>
                  Poista
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <Typography variant={"h2"} color={"inherit"}>
          Ilmoitus ponnahdusikkuna
        </Typography>

        <hr />

        <p></p>

        <p></p>

        <p></p>

        {/*                 <InfoModal 
                    title={"Olet vaihtamassa maatilaa"} 
                    text={"Jos vaihdat maatilan tiedot nyt, tekemäsi muutokset poistetaan ja aloitat tekemäsi ilmoituksen teon alusta."} 
                    cancelButtonText={"Peruuta"}
                    okButtonText={"Hylkää muutokset"}
                    onClose={button => alert(button)}
                    button={<Button type="ok">Avaa info ponnahdusikkuna</Button>}
                /> */}

        <Typography variant={"h2"} color={"inherit"}>
          Ilmoituskomponentti
        </Typography>

        <hr />

        <p></p>

        <p></p>

        <p></p>

        <NotificationBanner
          type={"ok"}
          text={"Teurasilmoitus tallennettu onnistuneesti"}
          primaryButtonText={"Palaa ilmoitusten listausnäkymään"}
          secondaryButtonText={"Palaa etusivulle"}
          onClicked={(text) => {
            alert(text);
          }}
        />

        <NotificationBanner
          type={"error"}
          text={
            "Ilmoitusten tallennus epäonnistui. Ota yhteyttä numeroon 040 1234 567"
          }
          primaryButtonText={"Palaa ilmoitusten listausnäkymään"}
          secondaryButtonText={"Palaa etusivulle"}
          onClicked={(text) => {
            alert(text);
          }}
        />

        <Typography variant={"h2"} color={"inherit"}>
          Käyttöliittymäkomponentit
        </Typography>

        <hr />

        <RadioButtonGroup
          label={"Label"}
          groupItems={radioItems}
          required={true}
          onChange={(value) => {
            console.log(value);
          }}
        />
        <RadioButtonGroup
          label={radioTitle}
          groupItems={radioItems}
          defaultValue={2}
          onChange={(value) => {
            console.log(value);
          }}
        />

        <Checkbox
          label={"check1"}
          name={"check1"}
          value={"check1"}
          onChange={(value) => console.log(value)}
        />
        <Checkbox
          label={"check2"}
          disabled={true}
          onChange={(value) => console.log(value)}
        />
        <Checkbox
          label={"checked"}
          defaultChecked={true}
          onChange={(value) => console.log(value)}
        />
        <Checkbox
          label={"Hyväksyn ehdot"}
          onChange={(value) => console.log(value)}
          required={true}
        />

        <DropdownMenu
          label={"Valitse maatila"}
          required={true}
          options={dropdownOptions}
          onChange={(value) => console.log(value)}
        />
        <DropdownMenu
          label={"Valitse maatila"}
          required={true}
          options={dropdownOptions}
          useCaret={true}
          onChange={(value) => console.log(value)}
        />

        <WeekPicker
          label={"Valitse toivottu noutoviikko"}
          required={true}
          onChange={(value) => console.log(value)}
        />

        <DatePicker
          label={"Valitse päivämäärä"}
          required={true}
          onChange={handleDateChange}
          margin={"0"}
          selectedDate={selectedDate}
          variant="inline"
          //readonly={true}
          autoOk={true}
        />

        <Typography variant={"h2"} color={"inherit"}>
          Taulukko- ja listauskomponentit
        </Typography>

        <hr />

        <ExpandableListView
          title={"ExpandableListView"}
          headers={[{ content: "Sarake 1" }, { content: "Sarake 2" }]}
          rows={[
            ["a", "1"],
            ["b", "2"],
            ["c", "3"],
          ]}
        />

        <ExpListViewWithSortingPagination
          title={"ExpListViewWithSortingPagination"}
          headers={[{ content: "Sarake 1" }, { content: "Sarake 2" }]}
          rows={[
            ["a", "1"],
            ["b", "2"],
            ["c", "3"],
          ]}
        />

        <ExpListViewWithSorting
          title={"ExpListViewWithSorting"}
          headers={[{ content: "Sarake 1" }, { content: "Sarake 2" }]}
          rows={[
            ["a", "1"],
            ["b", "2"],
            ["c", "3"],
          ]}
        />
      </form>

      <DataGridSample />
    </Container>
  );
};

export default GuiComponents;

//GridRowsProp
const rows: GridRowData[] = [
  {
    id: 1,
    name: "name 1",
    age: 25,
  },
  {
    id: 2,
    name: "name 2",
    age: 36,
  },
];

/**
 * This is example how to use datagrid.
 * Since this is MIT version have limited access to apiRef
 */

const DataGridSample = () => {
  const apiRef = useGridApiRef();

  const [gridRows, setGridRows] = useState(rows);

  const handleClick = () => {
    console.log("Row count", gridRows);
  };

  const columns: GridColumns = [
    { field: "name", headerName: "Name", flex: 1, editable: true },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: function (props: RowMenuProps) {
        const { api, id } = props;

        const isInEditMode = api.getRowMode(id) === "edit";

        const handleEditClick = (event) => {
          event.stopPropagation();
          api.setRowMode(id, "edit");
        };

        const handleSaveClick = (event) => {
          event.stopPropagation();
          api.commitRowChange(id);

          api.setRowMode(id, "view");

          const row = api.getRow(id);
          api.updateRows([{ ...row, isNew: false }]);
        };

        const handleDeleteClick = (event) => {
          event.stopPropagation();
          api.updateRows([{ id, _action: "delete" }]);
          deleteRow(id);
        };

        const handleCancelClick = (event) => {
          event.stopPropagation();
          api.setRowMode(id, "view");

          const row = api.getRow(id);
          if (row!.isNew) {
            api.updateRows([{ id, _action: "delete" }]);
          }
        };

        return (
          <RenderActionButtons
            handleCopyClick={() => {}}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
            isInEditMode={isInEditMode}
            hasCopyButton={true}
            hasClearMode={false}
          />
        );
      },
      sortable: false,
      width: 100,
      headerAlign: "center",
      filterable: false,
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  const handleRowEditStop = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
    console.log("Event handle edit stop");
  };

  const deleteRow = (id) => {
    setGridRows([...gridRows.filter((row) => row.id !== Number(id))]);
    console.log("row deleted with id:", id);
  };

  const handleOnEditRowCommit = (editedModel: GridRowData) => {
    console.log("handleOnEditRowCommit before ", editedModel, gridRows);
    const id = Object.keys(editedModel)[0];
    const dataRow = editedModel[id];

    setGridRows([
      ...gridRows.filter((row) => row.id !== Number(id)),
      {
        id: Number(id),
        name: dataRow.name.value,
        age: dataRow.age.value,
      },
    ]);

    console.log("handleOnEditRowCommit after ", editedModel, gridRows);
  };

  useEffect(() => {
    console.log("gridrows changed ", gridRows);
  }, [gridRows]);

  return (
    <Fragment>
      <Button type={"ok"} onClick={handleClick}>
        Console log rows
      </Button>
      <FullFeaturedDataGrid
        columns={columns}
        rows={gridRows}
        editMode="row"
        components={{
          Toolbar: EditToolbar,
        }}
        componentsProps={{
          toolbar: { apiRef },
        }}
        // pagination={undefined}
        // hideFooterPagination={true}
        height={300}
        onRowEditStop={handleRowEditStop}
        onEditRowCommit={handleOnEditRowCommit}
      />
    </Fragment>
  );
};
