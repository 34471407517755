import { createSlice } from "@reduxjs/toolkit";
import { IDebtBreakdown } from "../models/DebtBreakdown";

import { IInvoice, InvoiceType } from "../models/Invoices";
import { IPayment, PaymentType } from "../models/Payments";
import Helper from "../utils/helper";

interface IEconomyState {
  invoices: IInvoice[];
  selectedInvoiceType: InvoiceType;
  payments: IPayment[];
  selectedPaymentType: PaymentType;
  isLoading: boolean;
  debtBreakdown: IDebtBreakdown[];
}

const economyState: IEconomyState = {
  selectedInvoiceType: InvoiceType.All,
  selectedPaymentType: PaymentType.All,
  invoices: [],
  payments: [],
  isLoading: false,
  debtBreakdown: [],
};

export const economySlice = createSlice({
  name: "economy",
  initialState: economyState,
  reducers: {
    invoicesReceived(state, action) {
      state.invoices = action.payload.data as IInvoice[];
    },
    invoiceTypeSelected(state, action) {
      state.selectedInvoiceType = Number(action.payload);
    },
    invoicePdfDownloaded(state, action) {
      Helper.downloadPdf(action.payload.data, action.payload.filename);
    },
    paymentsReceived(state, action) {
      state.payments = action.payload.data as IPayment[];
    },
    paymentTypeSelected(state, action) {
      state.selectedPaymentType = Number(action.payload);
    },
    debtBreakdownReceived(state, action) {
      state.debtBreakdown = action.payload.data as IDebtBreakdown[];
    },
    paymentPdfDownloaded(state, action) {
      Helper.downloadPdf(action.payload.data, action.payload.filename);
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...economyState };
    });
  },
});

export const {
  invoicesReceived,
  invoiceTypeSelected,
  invoicePdfDownloaded,
  paymentsReceived,
  paymentTypeSelected,
  paymentPdfDownloaded,
  debtBreakdownReceived,
} = economySlice.actions;

export default economySlice.reducer;
