import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PowerBIEmbed } from "powerbi-client-react";
import { Report } from "powerbi-client";
import * as models from "powerbi-models";
import {
  publicationCleared,
  publicationsCleared,
} from "../../shared/store/publications";
import { IContent, PublicationType } from "../../shared/models/Publications";
import { RootState } from "../../shared/store/rootReducer";
import * as api from "../../shared/store/apiActions";
import useStyles from "./ReportsPageStyles";
import { makeidp } from "../../shared/helpers/idgen";
import { IEmbedToken } from "../../shared/models/PowerBIEmbedding";
import { setTimeout } from "timers";
import Button from "../../shared/components/Button/Button";
import { t } from "../../shared/services/langService";
import { Grid } from "@material-ui/core";
import { clearToken } from "../../shared/store/powerbi";
import HTMLReactParser from "html-react-parser";
import InstructionContent from "../InstructionsContent/InstructionsContent";
import ThumbnailArray, {
  IThumbnailItem,
} from "../../shared/components/ThumbnailArray/ThumbnailArray";
import image1 from "../../assets/images/powerbi/zerocarbon/image1.png";
import image2 from "../../assets/images/powerbi/zerocarbon/image2.png";
import image3 from "../../assets/images/powerbi/zerocarbon/image3.png";

const thumbnailItems: IThumbnailItem[] = [
  {
    src: image1,
    alt: "image1",
  },
  {
    src: image2,
    alt: "image2",
  },
  {
    src: image3,
    alt: "image3",
  },
];

interface IReportPages {
  displayName: string;
  pageName: string;
  isActive: boolean;
}
interface IPanesSettings {
  filters: any;
  pageNavigation: any;
}

interface IReportSettings {
  panes: IPanesSettings;
  layoutType: models.LayoutType;
}

interface IEmbedConfig {
  type: string;
  id: string | undefined;
  embedUrl: string | undefined;
  accessToken: string | undefined;
  tokenType: number;
  settings: IReportSettings;
}

const initialEmbedConfig: IEmbedConfig = {
  type: "report",
  id: "",
  embedUrl: "",
  accessToken: "",
  tokenType: models.TokenType.Embed,
  settings: {
    panes: {
      filters: { visible: false },
      pageNavigation: { visible: true },
    },
    layoutType: models.LayoutType.Master,
  },
};

const generateKey = () => {
  var result = makeidp("powerbi-", 20);
  return result;
};

const ReportsPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { referenceId } = useParams();
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const publication = useSelector(
    (state: RootState) => state.publications.publication
  );

  const embedToken = useSelector(
    (state: RootState) => state.powerbi.embedToken
  );
  const fetchingToken = useSelector(
    (state: RootState) => state.powerbi.fetchingToken
  );
  const [embedConfig, setEmbedConfig] = useState<IEmbedConfig>(
    initialEmbedConfig
  );
  const [showReport, setShowReport] = useState<boolean>(false);
  const [reRenderReport, setReRenderReport] = useState<boolean>(false);
  const [reportKey, setReportKey] = useState(generateKey());
  const [isMobileScreenSize, setIsMobileScreenSize] = useState(false);
  const [fetchToken, setFetchToken] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [hasMobileLayout, setHasMobileLayout] = useState(false);
  const [windowResizeTimeoutId, setWindowResizeTimeoutId] = useState<
    NodeJS.Timeout
  >();
  const [tokenTimeoutId, setTokenTimeoutId] = useState<NodeJS.Timeout>();
  const [powerBIReport, setPowerBIReport] = useState<Report>();
  const [showInstructions, setShowInstructions] = useState<boolean>(false);
  const [isCarbonFootprintReport, setIsCarbonFootprintReport] = useState(false);
  const [reportPages, setReportPages] = useState<IReportPages[]>();
  const [reportContent, setReportContent] = useState<IContent>();
  const [selectedPageName, setSelectedPageName] = useState("");

  //////////////////////////////////////////
  //region timing related functions
  //////////////////////////////////////////

  const windowResizeTimerExpired = () => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const usingMobileLayout =
      embedConfig.settings.layoutType !== models.LayoutType.Master;
    const isDeviceTypeInLineWithLayoutType =
      (isMobile && usingMobileLayout) || (!isMobile && !usingMobileLayout);

    setReRenderReport(!isDeviceTypeInLineWithLayoutType);
    setIsMobileScreenSize(isMobile);
    if (windowResizeTimeoutId !== undefined) {
      clearTimeout(windowResizeTimeoutId);
    }
  };

  const tokenExpired = () => {
    setFetchToken(true);
  };

  //////////////////////////////////////////
  //endregion timing related functions
  //////////////////////////////////////////

  const handleWindowResize = () => {
    if (windowResizeTimeoutId !== undefined) {
      return;
    }
    setWindowResizeTimeoutId(setTimeout(windowResizeTimerExpired, 1000));
  };

  const validateEmbedToken = (
    token: IEmbedToken | null | undefined
  ): boolean => {
    return (
      token !== undefined &&
      token !== null &&
      token.embedUrl !== undefined &&
      token.token !== undefined &&
      token.expirationSec !== undefined
    );
  };

  const isValidReportContent = () => {
    return (
      reportContent !== undefined &&
      reportContent.contentType !== undefined &&
      reportContent.powerBIIdentifier !== undefined &&
      reportContent.contentType === PublicationType.PowerBIReports &&
      reportContent.powerBIIdentifier.workspaceId !== undefined &&
      reportContent.powerBIIdentifier.reportId !== undefined
    );
  };

  const getLayoutType = (): models.LayoutType => {
    return isMobileScreenSize && hasMobileLayout
      ? models.LayoutType.MobilePortrait
      : models.LayoutType.Master;
  };

  const getEmbedConfig = (): IEmbedConfig => {
    var result: IEmbedConfig = {
      ...initialEmbedConfig,
      id: embedToken?.reportId,
      embedUrl: embedToken?.embedUrl,
      accessToken: embedToken?.token,
      settings: { ...initialEmbedConfig.settings, layoutType: getLayoutType() },
    };
    return result;
  };

  /////////////////////////
  //region calls to API
  /////////////////////////

  useEffect(() => {
    if (referenceId && langCode && productionLine) {
      dispatch(
        api.publication.getPublication(langCode, productionLine, referenceId)
      );
    }
  }, [referenceId, langCode, productionLine]);

  useEffect(() => {
    if (
      publication &&
      publication.contentType === PublicationType.PowerBIReports
    ) {
      setReportContent(publication);
      setShowInstructions(false);
    }
  }, [publication]);

  useEffect(() => {
    if (reportContent) {
      setIsCarbonFootprintReport(reportContent.title === "Hiilijalanjälki");
    }
  }, [reportContent]);

  useEffect(() => {
    if (Number(referenceId) === Number(reportContent?.referenceId)) {
      dispatch(clearToken());
      setFetchToken(true);
    }
  }, [referenceId, reportContent]);

  useEffect(() => {
    if (!reportContent || !reportContent.powerBIIdentifier) {
      return;
    }

    if (
      !reportContent?.powerBIIdentifier?.workspaceId ||
      !reportContent?.powerBIIdentifier?.reportId ||
      !fetchToken
    ) {
      return;
    }

    if (tokenTimeoutId !== undefined) {
      clearTimeout(tokenTimeoutId);
    }

    dispatch(
      api.powerbi.getToken(
        langCode,
        productionLine,
        reportContent?.powerBIIdentifier.workspaceId !== undefined
          ? reportContent.powerBIIdentifier.workspaceId
          : "empty",
        reportContent?.powerBIIdentifier.reportId !== undefined
          ? reportContent.powerBIIdentifier.reportId
          : "empty",
        producerNumber
      )
    );
    setFetchToken(false);
  }, [fetchToken, reportContent]);

  /////////////////////////
  //endregion calls to API
  /////////////////////////

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    setIsMobileScreenSize(window.matchMedia("(max-width: 768px)").matches);

    //executed upon exit from page
    return () => {
      dispatch(publicationsCleared());
      dispatch(publicationCleared());
      dispatch(clearToken());
    };
  }, []);

  useEffect(() => {
    if (fetchingToken) {
      setFetchToken(false);
    }
  }, [fetchingToken]);

  useEffect(() => {
    if (!validateEmbedToken(embedToken)) {
      return;
    }

    const newSettings = {
      ...embedConfig.settings,
      layoutType: isMobileScreenSize
        ? models.LayoutType.MobilePortrait
        : models.LayoutType.Master,
    };

    setEmbedConfig({
      ...embedConfig,
      id: embedToken?.reportId,
      embedUrl: embedToken?.embedUrl,
      accessToken: embedToken?.token,
      settings: newSettings,
    });

    if (tokenTimeoutId !== undefined) {
      clearTimeout(tokenTimeoutId);
    }

    setTokenTimeoutId(
      setTimeout(
        tokenExpired,
        1000 *
          (embedToken?.expirationSec !== undefined
            ? embedToken?.expirationSec
            : 60)
      )
    );
  }, [embedToken]);

  useEffect(() => {
    setShowReport(isValidReportContent() && validateEmbedToken(embedToken));
  }, [referenceId, reportContent, embedToken]);

  useEffect(() => {
    if (!reRenderReport) {
      return;
    }
    setReportKey(generateKey());
    setReRenderReport(false);
  }, [reRenderReport]);

  useEffect(() => {
    setShowReport(isValidReportContent() && validateEmbedToken(embedToken));
  }, [embedToken]);

  useEffect(() => {
    setReportKey(generateKey());
  }, [hasMobileLayout]);

  const toggleFullscreen = () => {
    setShowFullScreen(!showFullScreen);
  };

  const toggleShowInstructions = () => {
    setShowInstructions(!showInstructions);
  };

  const handlePrintReport = () => {
    powerBIReport?.print();
  };

  const getButtonsRowJsx = () => {
    return (
      <Fragment>
        <Button type="ok" onClick={toggleShowInstructions}>
          {!showInstructions
            ? t("report_showInstructions")
            : t("report_hideInstructions")}
        </Button>

        <Button type="ok" onClick={handlePrintReport}>
          {t("report_printOrSaveReport")}
        </Button>

        <Button type="ok" onClick={toggleFullscreen}>
          {showFullScreen ? t("report_reduceSize") : t("report_enlargeSize")}
        </Button>
      </Fragment>
    );
  };

  const getReportPageSwitchButtonsJsx = () => {
    return (
      <Fragment>
        {reportPages &&
          reportPages.length > 1 &&
          powerBIReport &&
          reportPages.map((page) => {
            return (
              <Button
                type={
                  selectedPageName === page.pageName
                    ? "powerbi-page-select-clicked"
                    : "powerbi-page-select"
                }
                onClick={() => {
                  powerBIReport?.setPage(page.pageName);
                  setSelectedPageName(page.pageName);
                }}>
                {page.displayName}
              </Button>
            );
          })}
      </Fragment>
    );
  };

  const getInstructionsSectionJsx = () => {
    return (
      <Grid container xs={12} className={classes.instructions} spacing={1}>
        <Grid container item xs={12}>
          {HTMLReactParser(reportContent?.body ?? "")}
        </Grid>
      </Grid>
    );
  };

  const getEmbeddedReportJsx = () => {
    return (
      <Grid
        container
        xs={12}
        className={showFullScreen ? classes.fullscreen : ""}>
        {showFullScreen && (
          <Grid container item xs={12}>
            <Grid item>
              <Button
                type="ok"
                style={{ float: "left" }}
                onClick={toggleFullscreen}>
                {showFullScreen
                  ? t("report_reduceSize")
                  : t("report_enlargeSize")}
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          {getReportPageSwitchButtonsJsx()}
        </Grid>
        <Grid container item xs={12}>
          <PowerBIEmbed
            key={reportKey}
            embedConfig={{ ...getEmbedConfig(), pageView: "fitToWidth" }}
            eventHandlers={
              new Map([
                [
                  "loaded",
                  function (event, obj) {
                    const report = obj as Report;
                    report.getPages().then(function (pages) {
                      let _hasMobile = true;
                      let _reportPages: IReportPages[] = [];
                      pages.forEach((page) => {
                        if (page.visibility === 0) {
                          _reportPages = [
                            ..._reportPages,
                            {
                              displayName: page.displayName,
                              pageName: page.name,
                              isActive: page.isActive,
                            },
                          ];
                        }
                      });
                      setReportPages(_reportPages);
                      setSelectedPageName(() => {
                        let _pageName = _reportPages.find((p) => p.isActive)
                          ?.pageName;
                        if (_pageName !== undefined) {
                          return _pageName;
                        }
                        return "";
                      });
                      if (
                        pages.find(
                          (page) =>
                            page.mobileSize === null ||
                            page.mobileSize === undefined
                        )
                      ) {
                        _hasMobile = false;
                      }
                      setHasMobileLayout(_hasMobile);
                      setPowerBIReport(report);
                    });
                  },
                ],
                [
                  "rendered",
                  function () {
                    var iframeElements = document.getElementsByTagName(
                      "iframe"
                    );
                    if (iframeElements) {
                      iframeElements[0].style.borderColor = "#fff";
                      iframeElements[0].style.borderStyle = "none";
                    }
                  },
                ],
                [
                  "error",
                  function (event) {
                    console.log(event?.detail);
                  },
                ],
                [
                  "pageChanged",
                  function (event) {
                    setSelectedPageName(() => {
                      let _activePage = event?.detail?.newPage?.name;
                      if (_activePage !== undefined) {
                        return _activePage;
                      }
                      return "";
                    });
                  },
                ],
              ])
            }
            cssClassName={classes.reportContent}
            getEmbeddedComponent={(embeddedReport) => {
              if (this !== undefined && this !== null) {
                //@ts-ignore: Object is possibly 'null'
                this.report = embeddedReport as Report;
              }
            }}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      {!showReport && <Fragment />}
      {showReport && (
        <Grid container xs={12} spacing={2}>
          {reportContent &&
            reportContent.body &&
            reportContent.body.length > 0 && (
              <Grid container item xs={12}>
                {HTMLReactParser(reportContent.body)}
              </Grid>
            )}
          {isCarbonFootprintReport && <ThumbnailArray items={thumbnailItems} />}
          <Grid container item xs={12}>
            {getButtonsRowJsx()}
          </Grid>
          {showInstructions && (
            <InstructionContent title="PowerBITulostusOhje" />
          )}
          <Grid container item xs={12}>
            {getEmbeddedReportJsx()}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default ReportsPage;
