import {createSlice} from "@reduxjs/toolkit";

interface IValidation {
    validationRules: any
};

const initialState: IValidation = {
    validationRules: {}
};

const slice = createSlice({
    name: "userInterface/validation",
    initialState: initialState,
    reducers: {
        validationRulesReceived: (state, action) => {
            state.validationRules = action.payload.data
        }
    }
});

export const {
    validationRulesReceived
} = slice.actions;

export default slice.reducer;