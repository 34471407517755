import http from "../../../shared/services/httpService";
import {
  IUser,
  AuthorizationContext,
  IUserRoles,
  IAssignableRoles,
} from "../models/Users";

const API_URL = `userManagement`;

export const getAssignableRoles = async () => {
  const resp = await http.get<IAssignableRoles>(`${API_URL}/assignableRoles`);
  return resp;
};

export const getUsers = async () => {
  const resp = await http.get<IUser>(`${API_URL}/users`);
  return resp;
};

export const addUser = async (user: IUser) => {
  const resp = await http.post(`${API_URL}/users/add`, user);
  return resp;
};

export const updateUser = async (
  context: AuthorizationContext,
  id: string,
  user: IUserRoles
) => {
  const resp = await http.put(`${API_URL}/users/update/${context}/${id}`, user);
  return resp;
};

export const deleteUser = async (context: AuthorizationContext, id: string) => {
  const resp = await http.delete(`${API_URL}/users/delete/${context}/${id}`);
  return resp;
};

export default {
  getAssignableRoles,
  getUsers,
  addUser,
  updateUser,
  deleteUser,
};
