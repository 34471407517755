import { Hidden, TableContainer } from "@material-ui/core";
import moment from "moment";
import React, { Fragment, useImperativeHandle, useState } from "react";
import Button from "../../../../../shared/components/Button/Button";
import DropdownMenu from "../../../../../shared/components/DropdownMenu/DropdownMenu";
import InfoModal from "../../../../../shared/components/InfoModal/InfoModal";
import InputField from "../../../../../shared/components/InputField/InputField";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../../../../shared/components/Table/Table";
import WeekPicker from "../../../../../shared/components/WeekPicker/WeekPicker";
import { t } from "../../../../../shared/services/langService";
import { defaultComparator } from "../../../../../shared/utils/sorting";
import sikaTeurasElainLajit from "../../../enums/sikaTeurasElainLajit";
import useStyles from "./AnimalListStyles";

interface AnimalListProps {
  animals: any[];
  ref: any;
  onRemove: any;
  onEdit: Function;
  onAnimalChange: Function;
  modify?: boolean;
  dropdownAnimalTypeOptions?: any[];
}

const AnimalList: React.FC<AnimalListProps> = React.forwardRef(
  (
    {
      animals = [],
      onRemove,
      onEdit,
      onAnimalChange,
      modify = false,
      dropdownAnimalTypeOptions = [],
    },
    ref
  ) => {
    const classes = useStyles();
    const [infoModal, setInfoModal] = useState<any>({
      open: false,
      title: "",
      text: "",
      okButtonText: "",
      onClose: () => {},
    });
    const [errors, setErrors] = useState<any>({});

    useImperativeHandle(ref, () => ({
      validate() {
        return handleValidate();
      },
    }));

    const handleAnimalChange = (value, id) => {
      onAnimalChange(id, value);
    };

    const handleDepartmentChange = (value, id) => {
      onEdit(id, [["department", value]]);
    };

    const handleCountChange = (value, id) => {
      onEdit(id, [["count", value.replace(/[^0-9]/g, "")]]);
    };

    const handleWeekChange = (value, id) => {
      onEdit(id, [["week", value]]);
    };

    const handleRemoveAnimal = (id) => {
      if (modify && animals.length <= 1) {
        setInfoModal({
          open: true,
          title: t("animalNotification_cannotRemoveAnimalTitle"),
          text: t("animalNotification_cannotRemoveAnimalText"),
          okButtonText: t("ui_ok"),
          onClose: () =>
            setInfoModal((prevState) => ({ ...prevState, open: false })),
        });
      } else {
        onRemove(id);
      }
    };

    const formatKuormausAika = (
      ajat: any[],
      rowDispatchState: string
    ): string[] => {
      if (!ajat?.length) return [];

      const newArray: string[] = [];
      for (let aika of ajat)
        newArray.push(
          rowDispatchState === "lo"
            ? t("animalNotification_transported")
            : `${moment(aika.pvm).format("DD.MM.YYYY")} ${aika.klo || ""}`
        );

      return newArray;
    };

    const handleValidate = (): boolean => {
      const validationErrors = {};
      for (let i = 0; i < animals.length; i++) {
        const animal: any = animals[i];
        if (animal) {
          validationErrors[i] = {
            department:
              !animal.department &&
              animal.animalType != "12" &&
              animal.animalType != "16"
                ? t("ui_requiredField")
                : "",
            count: !animal.count
              ? t("ui_requiredField")
              : !animal.count.match(
                  /^([1-9]?|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])$/
                )
              ? t("ui_valueBetweenTwoNumbers", { 0: 1, 1: 9999 })
              : "",
          };
        }
      }

      setErrors(validationErrors);
      const isValid =
        Object.keys(validationErrors).filter(
          (p: any) =>
            validationErrors[p]?.department || validationErrors[p]?.count
        ).length === 0;
      return isValid;
    };

    const renderRemoveButton = (i) => {
      return (
        <Button
          id="animal-list-remove-button"
          value={i}
          onClick={() => handleRemoveAnimal(i)}
          type="table"
          fn={"remove"}>
          {t("ui_remove")}
        </Button>
      );
    };

    return (
      <Fragment>
        <InfoModal
          open={infoModal.open}
          title={infoModal.title}
          text={infoModal.text}
          cancelButtonText={infoModal.cancelButtonText}
          okButtonText={infoModal.okButtonText}
          onClose={infoModal.onClose}
        />

        {!!animals?.length && (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <Hidden smUp>
                    <TableCell></TableCell>
                  </Hidden>
                  <TableCell>{t("animalNotification_animalType")}</TableCell>
                  <TableCell>{t("animalNotification_department")}</TableCell>
                  {animals.every(
                    (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                  ) && <TableCell>{t("ui_count")}</TableCell>}
                  {!animals.every(
                    (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                  ) && (
                    <Fragment>
                      <TableCell>{t("animalNotification_created")}</TableCell>
                      <TableCell>
                        {t("animalNotification_animalsLoaded")}
                      </TableCell>
                      <TableCell>
                        {t("animalNotification_animalsLeft")}
                      </TableCell>
                    </Fragment>
                  )}
                  {animals.every(
                    (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                  ) && (
                    <TableCell>
                      {t("animalNotification_markingNumber")}
                    </TableCell>
                  )}
                  <TableCell>{t("animalNotification_preferredWeek")}</TableCell>
                  {!animals.every(
                    (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                  ) && (
                    <TableCell>
                      {t("animalNotification_markingNumber")}
                    </TableCell>
                  )}
                  {!animals.every(
                    (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                  ) && (
                    <TableCell>{t("animalNotification_loadingTime")}</TableCell>
                  )}
                  <Hidden xsDown>
                    <TableCell></TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {animals.map((animal: any, i) => (
                  <TableRow
                    key={i}
                    style={{ height: "68.8889px" }}
                    highlighted={animal.rowDispatchState === "lo"}
                    highlightColor="#c9c8c6">
                    <Hidden smUp>
                      <TableCell>
                        {(animal.rowDispatchState === "av" ||
                          !animal.rowDispatchState) &&
                          renderRemoveButton(i)}
                      </TableCell>
                    </Hidden>
                    <TableCell>
                      {animals.every(
                        (a) =>
                          a.rowDispatchState === "av" || !a.rowDispatchState
                      ) && (
                        <DropdownMenu
                          id="animal-list-animal-select"
                          onChange={(value) => handleAnimalChange(value, i)}
                          selectedValue={animal.animalType}
                          options={dropdownAnimalTypeOptions}
                        />
                      )}
                      {!animals.every(
                        (a) =>
                          a.rowDispatchState === "av" || !a.rowDispatchState
                      ) && (
                        <Fragment>
                          {t(
                            `animalType_${
                              sikaTeurasElainLajit[animal.animalType]
                            }`
                          )}
                        </Fragment>
                      )}
                    </TableCell>
                    <TableCell>
                      {animal.animalType != "12" &&
                        animal.animalType != "16" &&
                        animals.every(
                          (a) =>
                            a.rowDispatchState === "av" || !a.rowDispatchState
                        ) && (
                          <DropdownMenu
                            id="animal-list-department-select"
                            onChange={(value) =>
                              handleDepartmentChange(value, i)
                            }
                            selectedValue={animal.department}
                            options={animal.departmentOptions}
                            errorText={errors[i]?.department}
                          />
                        )}
                      {animal.animalType == "12" ||
                        animal.animalType == "16" ||
                        (!animals.every(
                          (a) =>
                            a.rowDispatchState === "av" || !a.rowDispatchState
                        ) && <Fragment>{animal.department}</Fragment>)}
                    </TableCell>
                    {animals.every(
                      (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                    ) && (
                      <TableCell>
                        {animals.every(
                          (a) =>
                            a.rowDispatchState === "av" || !a.rowDispatchState
                        ) && (
                          <InputField
                            id="animal-list-count-input"
                            margin={""}
                            onChange={(e) =>
                              handleCountChange(e.target.value, i)
                            }
                            value={animal.count}
                            errorText={errors[i]?.count}
                          />
                        )}
                        {!animals.every(
                          (a) =>
                            a.rowDispatchState === "av" || !a.rowDispatchState
                        ) && <Fragment>{animal.count}</Fragment>}
                      </TableCell>
                    )}
                    {!animals.every(
                      (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                    ) && (
                      <Fragment>
                        <TableCell>
                          {animal.numAnimalsIlmoitettuTotal}
                        </TableCell>
                        <TableCell>{animal.numAnimalsKuormattuTotal}</TableCell>
                        <TableCell>{animal.numAnimalsJaljellaTotal}</TableCell>
                      </Fragment>
                    )}
                    {animals.every(
                      (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                    ) && <TableCell>{animal.markingNumber}</TableCell>}
                    <TableCell>
                      {(animal.rowDispatchState === "av" ||
                        !animal.rowDispatchState) && (
                        <WeekPicker
                          id="animal-list-week-select"
                          onChange={(value) => handleWeekChange(value, i)}
                          weeksToShow={53}
                          selectedValue={animal.week}
                          {...(modify && animals.length
                            ? {
                                addWeekpickerOptions: Array.from(
                                  new Set<string>(animals.map((a) => a.week))
                                ),
                              }
                            : {})}
                          showStartAndEndDate={true}
                        />
                      )}
                      {!(
                        animal.rowDispatchState === "av" ||
                        !animal.rowDispatchState
                      ) && <Fragment>{animal.week}</Fragment>}
                    </TableCell>
                    {!animals.every(
                      (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                    ) && <TableCell>{animal.markingNumber}</TableCell>}
                    {!animals.every(
                      (a) => a.rowDispatchState === "av" || !a.rowDispatchState
                    ) && (
                      <TableCell>
                        {formatKuormausAika(
                          animal.kuormausAika
                            .map((a) => ({ ...a }))
                            .sort((a, b) => -defaultComparator(a.pvm, b.pvm)),
                          animal.rowDispatchState
                        ).map((a) => (
                          <span
                            style={{ display: "block", whiteSpace: "nowrap" }}>
                            {a}
                          </span>
                        ))}
                      </TableCell>
                    )}
                    <Hidden xsDown>
                      <TableCell>
                        {(animal.rowDispatchState === "av" ||
                          !animal.rowDispatchState) &&
                          renderRemoveButton(i)}
                      </TableCell>
                    </Hidden>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Fragment>
    );
  }
);

export default AnimalList;
