import React, { useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "./HealthQuestionsModalStyles";
import Button from "../Button/Button";
import PageHeader from "../PageHeader/PageHeader";
import { t } from "../../services/langService";
import HealthQuestions from "../HealthQuestions/HealthQuestions";
import * as api from "../../store/apiActions";
import {
  healthQuestionsCleared as beefHealthQuestionsCleared,
} from "../../store/beef";
import { healthQuestionsCleared as porkHealthQuestionsCleared } from "../../store/pork";
import ProductionArea from "../../enums/productionLines";
import { RootState } from "../../store/rootReducer";

interface HealthQuestionsModalProps {
  open: boolean;
  onClose: any;
  healthData: any;
  productionLine: ProductionArea;
}

const HealthQuestionsModal: React.FC<HealthQuestionsModalProps> = ({
  open,
  onClose,
  healthData,
  productionLine,
}) => {
  const classes = useStyles();
  const childRef = useRef<any>();

  const handleClose = (ok: boolean) => {
    onClose(ok);
  };

  const dispatch = useDispatch();

  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const organization = organizations.find(
    (o) => o.producerNumber == producerNumber
  );
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const { healthQuestions } = useSelector((state: RootState) => {
    if (productionLine === ProductionArea.Beef) {
      return state.beef;
    } else if (productionLine === ProductionArea.Pork) {
      return state.pork;
    }
    return { healthQuestions: undefined };
  });

  useEffect(() => {
    if (healthData.notificationId) {
      if (productionLine === ProductionArea.Beef) {
        dispatch(api.beef.getBeefHealthInfo(healthData.notificationId));
        return () => {
          dispatch(beefHealthQuestionsCleared());
        };
      } else if (productionLine == ProductionArea.Pork) {
        dispatch(api.pork.getPorkHealthInfo(healthData.notificationId));
        return () => {
          dispatch(porkHealthQuestionsCleared());
        };
      }
    }
  }, [healthData.notificationId]);

  if (!healthQuestions || healthQuestions.length === 0) return null;

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      classes={{ paper: classes.paper }}
      onClose={() => handleClose(false)}
      aria-labelledby="farm-selection-modal-title"
      aria-describedby="farm-selection-modal-description">
      <span id="hq-printinfo" className={classes.printInfo}>
        {organization ? organization.name : ""} /{" "}
        {organization ? organization.businessId : ""} / {currentUser.firstname}{" "}
        {currentUser.lastname}
      </span>
      <DialogTitle id="farm-selection-modal-title">
        <PageHeader level="2" component="div">
          {t("animalNotification_chainInformation")} {healthData.notificationId}
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="farm-selection-modal-description">
          <HealthQuestions
            ref={childRef}
            healthQuestions={healthQuestions}
            animals={healthData.animals}
            readonly={true}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(true)} type={"modal"} autoFocus>
          {t("ui_close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HealthQuestionsModal;
