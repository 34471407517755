import {makeStyles} from "@material-ui/core/styles";
import theme from "../../themes";

const useStyles = makeStyles(({
  radioTitle: {
    color: theme.palette.primary.main
  },
  labelContainer: {
    display: "block"
  },
  label: {
    fontFamily: "Avenir Next W1G Bold",
    letterSpacing: "0.28px",
    fontSize: "0.8rem",
    fontWeight: "bold"
  },
  FormControlLabel: {
    letterSpacing: "0.33px",
    fontSize: "0.8rem",
    color: "#777"
  },
  radioRoot: {
    display: "inline-block"
  },
  alert: {
    color: "#650000"
  },
}));

export default useStyles;