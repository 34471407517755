import ProductionArea, { ProductionLineUriMap } from "../enums/productionLines";
import { IDebtBreakdown } from "../models/DebtBreakdown";
import { IInvoice, InvoiceType } from "../models/Invoices";
import { IPayment, PaymentType } from "../models/Payments";
import http from "./httpService";

const API_ENDPOINT = "economy";

export const getInvoices = async (
  typeId: InvoiceType,
  startDate: string,
  endDate: string,
  productionLine: ProductionArea
) => {
  const productionLineUri = ProductionLineUriMap[productionLine];

  return await http.get<IInvoice[]>(
    `${API_ENDPOINT}/${productionLineUri}/invoices/${typeId}/${startDate}/${endDate}`
  );
};

export const getFrontPageInvoices = async (productionLine: ProductionArea) => {
  const productionLineUri = ProductionLineUriMap[productionLine];
  return await http.get<IInvoice[]>(
    `${API_ENDPOINT}/${productionLineUri}/invoices/frontpage`
  );
};

export const getPayments = async (
  productionLine: ProductionArea,
  typeId: PaymentType,
  startDate: string,
  endDate: string
) => {
  const productionLineUri = ProductionLineUriMap[productionLine];
  return await http.get<IPayment[]>(
    `${API_ENDPOINT}/${productionLineUri}/payments/${typeId}/${startDate}/${endDate}`
  );
};

export const getFrontPagePayments = async () => {
  return await http.get<IPayment[]>(`${API_ENDPOINT}/payments/frontpage`);
};

export const getFrontPagePaymentsPoultry = async () => {
  return await http.get<IPayment[]>(
    `${API_ENDPOINT}/poultry/payments/frontpage`
  );
};

export const getInvoiceImage = async (invoiceNumber: number | string) => {
  return await http.get<any>(`${API_ENDPOINT}/invoice/image/${invoiceNumber}`, {
    responseType: "blob",
  });
};

export const getPaymentImage = async (paymentNumber: number | string) => {
  return await http.get<any>(`${API_ENDPOINT}/payment/image/${paymentNumber}`, {
    responseType: "blob",
  });
};

export const getDebtBreakdown = async () => {
  return await http.get<IDebtBreakdown[]>(`${API_ENDPOINT}/debtBreakdown`);
};
