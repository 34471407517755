import http from "./httpService";
import { IFileStructContainer } from "../models/FileListing";
import { IFeedbackDto } from "../../general/UserFeedbackPage/UserFeedbackPage";
import { producerUpdated } from "../store/powerbi";

const API_URL = `user`;

export const getUserOrganization = async () => {
  const resp = await http.get(`${API_URL}/organization`);
  return resp;
};

export const getRoleMaps = async () => {
  const resp = await http.get(`${API_URL}/roles`);
  return resp;
};

export const getUserFilteredOrganization = async (
  authenticationSource: string,
  filter: string = ""
) => {
  if (authenticationSource !== "HKScanAzureAD" || filter === "") {
    return { data: [] };
  }
  const resp = await http.get(`${API_URL}/organization/${filter}`);
  return resp;
};

export const getFileListing = async (path: string) => {
  const resp = await http.get<IFileStructContainer>(
    `${API_URL}/file/list/${path}`
  );
  return resp;
};

export const getFile = async (name: string) => {
  const resp = await http.get(`${API_URL}/file/${name}`, {
    responseType: "blob",
  });
  return resp;
};

export const postFeedback = async (dto: IFeedbackDto) => {
  return await http.post(`${API_URL}/feedback`, dto);
};

export const getRegionalManagerInfo = async () => {
  const resp = await http.get(`${API_URL}/regionalManagerInfo`);
  return resp;
};

export const getLastVisitedOrganization = async () => {
  const resp = await http.get(`${API_URL}/lastVisitedOrganization`);
  return resp;
};

export const updateLastVisitedOrganization = async (producerNumber: number) => {
  const resp = await http.put(
    `${API_URL}/lastVisitedOrganization/${producerNumber}`
  );
  return resp;
};

export default {
  getUserOrganization,
  getRoleMaps,
  getUserFilteredOrganization,
  getFileListing,
  getFile,
  postFeedback,
  getRegionalManagerInfo,
  getLastVisitedOrganization,
  updateLastVisitedOrganization,
};
