import { makeStyles } from "@material-ui/core";
import theme from "../../shared/themes";

const useStyles = makeStyles({
  root: {
    paddingTop: "50px",
    marginLeft: "5%",
    marginRight: "5%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginRight: "0",
    },
  },
  content: {
    minWidth: "100%",
    minHeight: "40vh",
  },
});

export default useStyles;
