import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles<any, { singleLine: boolean; multiline: boolean }>({
  container: ({ singleLine }) => ({
    marginTop: singleLine ? "8px" : "",
    marginBottom: singleLine ? "8px" : "",
  }),
  formControl: ({ singleLine }) => ({
    appearance: "none",
    backgroundClip: "padding-box",
    backgroundImage: "none",
    borderBottomColor: "rgba(0, 0, 0, 0.15)",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: 4,
    padding: "0 4px",
    marginTop: singleLine ? 0 : "8px",
    marginBottom: singleLine ? 0 : "8px",
  }),
  formControlDisabled: {
    backgroundColor: "#f8f8f8",
  },
  labelContainer: {
    display: "block",
    fontFamily: "Avenir Next W1G Bold",
    fontSize: "0.8rem",
  },
  label: {
    fontFamily: "Avenir Next W1G Bold",
    letterSpacing: "0.28px",
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  root: {
    letterSpacing: "0.33px",
    fontSize: "0.9rem",
    color: "#4e5054",
    fontWeight: "normal",
    lineHeight: "1.5em",
  },
  inputMultiline: {
    "&::placeholder": {
      fontStyle: "italic",
    },
  },
  alert: {
    color: "#650000",
  },
  inputInput: ({ multiline }) => ({
    height: multiline ? "" : "1.158em",
  }),
  input: ({ singleLine }) => ({
    marginTop: singleLine ? "" : "8px",
    marginBottom: singleLine ? "" : "8px",
  }),
});

export default useStyles;
