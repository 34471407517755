import React from "react";
import {Paper} from "@material-ui/core";
import useStyles from "./GuidesPageStyles";

const GuidesPage = () => {
    const classes = useStyles();
    return (
        <Paper className={classes.content}>
            GuidesPage    
        </Paper>
    );
};

export default GuidesPage;