import React, { Fragment } from "react";

export interface IColumn {
  width: string;
}

interface IColumnGroupProps {
  columns: IColumn[];
}

const ColumnGroup: React.FC<IColumnGroupProps> = ({ columns }) => {
  return (
    <Fragment>
      <colgroup>
        {columns.map((column, id) => {
          return <col key={id} style={{ width: column.width }} />;
        })}
      </colgroup>
    </Fragment>
  );
};

export default ColumnGroup;
