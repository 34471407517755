import React, { Fragment } from "react";
import PageText from "../PageText/PageText";

interface ReadOnlyTextFieldProps {
  label: string;
  text: string | string[] | JSX.Element | JSX.Element[];
  required?: boolean;
}

const ReadOnlyTextField: React.FC<ReadOnlyTextFieldProps> = ({
  label,
  text,
  required = false,
}) => {
  return (
    <Fragment>
      <PageText type={"bold"} required={required}>
        {label}
      </PageText>
      <PageText type={"normal"}>{text}</PageText>
    </Fragment>
  );
};

export default ReadOnlyTextField;
