import http from "./httpService";
import {
  ICapitalInfo,
  IDebtInvoice,
  IInterestPayment,
} from "../models/Cooperative";
const API_ENDPOINT = "cooperative";

export const getCapitalInfo = async (startDate: string, endDate: string) => {
  return await http.get<ICapitalInfo>(
    `${API_ENDPOINT}/capitalInfo/${startDate}/${endDate}`
  );
};

export const getInterestPayments = async (
  startDate: string,
  endDate: string,
  limit?: number
) => {
  const params = {
    limit: limit,
  };
  return await http.get<IInterestPayment>(
    `${API_ENDPOINT}/interestPayments/${startDate}/${endDate}`,
    { params }
  );
};

export const getDebtInvoices = async (
  startDate: string,
  endDate: string,
  limit?: number
) => {
  const params = {
    limit: limit,
  };
  return await http.get<IDebtInvoice>(
    `${API_ENDPOINT}/debtInvoices/${startDate}/${endDate}`,
    { params }
  );
};

export const getMainPageDebtInvoices = async () => {
  return await http.get<IDebtInvoice>(`${API_ENDPOINT}/debtInvoices/main`);
};

export const getMainPageInterestPayments = async () => {
  return await http.get<IInterestPayment>(
    `${API_ENDPOINT}/interestPayments/main`
  );
};

export const getDebtInvoiceImage = async (invoiceNumber: number | string) => {
  return await http.get<any>(
    `${API_ENDPOINT}/debtInvoices/image/${invoiceNumber}`,
    {
      responseType: "blob",
    }
  );
};

export const getInterestPaymentImage = async (
  paymentNumber: number | string
) => {
  return await http.get<any>(
    `${API_ENDPOINT}/interestPayments/image/${paymentNumber}`,
    {
      responseType: "blob",
    }
  );
};

export default {
  getCapitalInfo,
  getInterestPayments,
  getDebtInvoices,
  getMainPageDebtInvoices,
  getMainPageInterestPayments,
  getDebtInvoiceImage,
  getInterestPaymentImage,
};
