import React from "react";
import { TableContainer } from "@material-ui/core";
import { Table, TableCell2, TableHead } from "../Table/Table";
import {
  TableRow2 as ExpTableRow2,
  ExpTableRow,
} from "../ExpandableTable/ExpandableTable";
import useStyles from "./ContentBoxStyles";

interface IContentBoxProps {
  isExpandable?: boolean;
  isExpanded?: boolean;
  title: string;
  onExpansionChanged?: Function;
}

const ContentBox: React.FC<IContentBoxProps> = ({
  isExpandable = true,
  isExpanded = true,
  title,
  children,
  onExpansionChanged = (isExpanded: boolean) => {},
}) => {
  const styles = useStyles();
  return (
    <TableContainer className={styles.tableContainer}>
      <Table style={{ tableLayout: "fixed" }}>
        <TableHead className={styles.tableHead}>
          <ExpTableRow
            expandable={isExpandable}
            onExpansionChanged={(isExpanded) => onExpansionChanged(isExpanded)}
            cellComponent={TableCell2}
            rowComponent={ExpTableRow2}
            defaultOpen={isExpanded}
            primary={
              <TableCell2
                colSpan={4}
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}>
                {title}
              </TableCell2>
            }
            secondary={children}
            isFixed={true}
          />
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default ContentBox;
