import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    zIndex: 1000,
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    backgroundColor: "#ccc",
    opacity: 0.4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export default useStyles;
