export class Tree {
  root: TreeNode;

  constructor(node: TreeNode) {
    this.root = node;
  }
}

class TreeNode {
  data: string = "";

  parent: TreeNode | undefined = undefined;

  children: TreeNode[] = [];

  constructor() {}

  GetHeight(): number {
    let height = 1;
    let current: TreeNode = this;

    if (current.data == null) {
      return -1;
    }

    while (current.parent != null) {
      height++;
      current = current.parent;
    }

    return height;
  }
}

class UserRoleDictionary {
  roleGroups: Map<string, Tree> = new Map<string, Tree>();

  fillRoleTree(roleMap: any): UserRoleDictionary {
    for (let key in roleMap) {
      let rootNode = new TreeNode();
      rootNode.data = roleMap[key].root.data;
      let tree = new Tree(rootNode);

      this.roleGroups.set(key, tree);
      let root = this.roleGroups.get(key)?.root;
      if (root) {
        this._fillTree(root, roleMap[key].root);
      }
    }
    return this;
  }

  protected _fillTree(root: TreeNode, contextData: any) {
    for (var key in contextData.children) {
      var child = new TreeNode();
      child.data = contextData.children[key].data;
      child.parent = root;

      root.children[key] = child;

      this._fillTree(child, contextData.children[key]);
    }
  }

  findTreeNode(context: string, nodeValue: string): TreeNode | undefined {
    let current = this.roleGroups.get(context)?.root;
    if (current) {
      if (current.data == nodeValue) {
        return current;
      }
      return this._findFromChildren(current, nodeValue);
    }

    return undefined;
  }

  protected _findFromChildren(
    node: TreeNode,
    searchValue: string
  ): TreeNode | undefined {
    if (node.data == searchValue) {
      return node;
    }
    let foundNode: TreeNode | undefined = undefined;
    for (let key in node.children) {
      foundNode = this._findFromChildren(node.children[key], searchValue);
      if (foundNode) {
        break;
      }
    }

    return foundNode;
  }

  findAllParents(context: string, searchValue: string): string[] {
    let parents: string[] = [];

    let node = this.findTreeNode(context, searchValue);

    if (!node) {
      return parents;
    }

    this._findAllParents(node, parents);

    return parents;
  }

  protected _findAllParents(node: TreeNode, foundRoles: string[]) {
    while (node.parent != null) {
      foundRoles.push(node.data);
      node = node.parent;
    }
    // add root node role
    foundRoles.push(node.data);
  }
}

export default UserRoleDictionary;
