import { createSlice } from "@reduxjs/toolkit";
import { IEmbedToken } from "../models/PowerBIEmbedding";

interface IPowerBIEmbedding {
  embedToken?: IEmbedToken;
  fetchingToken?: boolean;
  producerNumber?: number;
}

const initialState: IPowerBIEmbedding = {
  embedToken: undefined,
  fetchingToken: undefined,
  producerNumber: undefined,
};

const slice = createSlice({
  name: "powerbi",
  initialState: initialState,
  reducers: {
    tokenReceived: (state, action) => {
      state.embedToken = action.payload.data;
    },
    fetchingTokenOn: (state) => {
      state.fetchingToken = true;
    },
    fetchingTokenOff: (state) => {
      state.fetchingToken = false;
    },
    clearToken: (state) => {
      Object.assign(state, initialState);
    },

    producerUpdated: (state, action) => {
      state.producerNumber = action.payload.data;
    },
  },
});

export const {
  tokenReceived,
  fetchingTokenOn,
  fetchingTokenOff,
  clearToken,
  producerUpdated,
} = slice.actions;

export default slice.reducer;
