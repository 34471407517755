import React from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Grid } from "@material-ui/core";

import PageHeader, {
  PageHeaderBox,
} from "../../shared/components/PageHeader/PageHeader";
import useStyles from "./VideoPageStyles";
import Button from "../../shared/components/Button/Button";
import { t } from "../../shared/services/langService";
import ReactPlayer from "react-player/lazy";

interface IVideoPageProps {}

const VideoPage: React.FC<IVideoPageProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { name } = useParams();
  const { state } = useLocation();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <PageHeader align={"center"} level={"1"}>
            {name}
          </PageHeader>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}>
            <PageHeaderBox />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginBottom: "30px", paddingTop: "0" }}>
          <Button
            id="video-goback-button"
            type="ok"
            onClick={() => history.goBack()}>
            {t("ui_back")}
          </Button>
        </Grid>

        <Grid item xs={12} sm={10} className={classes.content}>
          <ReactPlayer
            url={decodeURIComponent(state?.videoUri)}
            config={{
              youtube: {
                playerVars: {
                  autoplay: false,
                  controls: true,
                  loop: false,
                  origin: window.location.origin,
                },
              },
              vimeo: {
                playerOptions: {
                  autoplay: false,
                  controls: true,
                  loop: false,
                },
              },
              file: {
                attributes: {
                  autoPlay: false,
                  controls: true,
                },
              },
            }}
            width="100%"
            height="100%"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default VideoPage;
