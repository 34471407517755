import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import withContentBox from "../../shared/hocs/withContentBox";

import DropdownMenu from "../../shared/components/DropdownMenu/DropdownMenu";
import GeneralDataList from "../../shared/components/GeneralDatalist/GeneralDatalist";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { TableCell, TableRow } from "../../shared/components/Table/Table";
import TimeframePicker from "../../shared/components/TimeframePicker/TimeframePicker";
import ProductionArea from "../../shared/enums/productionLines";
import withTablePagination from "../../shared/hocs/withTablePagination";
import withTableSorting from "../../shared/hocs/withTableSorting";
import {
  getInvoicePaymentMethodTypeTranslation,
  getInvoiceTypeTranslation,
  IInvoice,
  InvoiceType,
} from "../../shared/models/Invoices";
import { t } from "../../shared/services/langService";
import { economy } from "../../shared/store/apiActions";
import { stateReseted } from "../../shared/store/createStore";
import { invoiceTypeSelected } from "../../shared/store/economy";
import { getIsLoading } from "../../shared/store/ui/common";
import Helper from "../../shared/utils/helper";
import useStyles from "./InvoicesPageStyles";
import { RootState } from "../../shared/store/rootReducer";

const GeneralDataListWithSortingPaginationContentBox = withTableSorting(
  withTablePagination(withContentBox(GeneralDataList))
);

interface IProps {
  productionLine: ProductionArea;
  [x: string]: any;
}

const InvoicesPage: React.FC<IProps> = ({
  productionLine,
  currentStartDate,
  currentEndDate,
  fetchCounter,
  startTimeOnChange,
  endTimeOnChange,
  onFetchClick,
  errorMsg,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [paginationResetCounter, setResetCounter] = useState<any>(1);

  const { selectedInvoiceType, invoices } = useSelector(
    (state: RootState) => state.economy
  );
  const isLoading = useSelector((state: RootState) => getIsLoading(state));

  const dispatchInvoicesFetch = () => {
    if (!errorMsg) {
      dispatch(
        economy.getInvoices(
          selectedInvoiceType,
          Helper.dateToIsoString(currentStartDate),
          Helper.dateToIsoString(currentEndDate),
          productionLine
        )
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(stateReseted());
    };
  }, []);

  useEffect(() => {
    dispatchInvoicesFetch();
    setResetCounter(1 + paginationResetCounter);
  }, [fetchCounter, selectedInvoiceType]);

  const handleDropdownMenuChange = async (value: number) => {
    dispatch(invoiceTypeSelected(value));
  };

  const invoicesSelectListOptions = Object.values(InvoiceType)
    .filter((el: any) => !isNaN(el))
    .map((el) => ({
      name: InvoiceType[el],
      text: getInvoiceTypeTranslation(el),
      value: el,
    }));

  const headerColumns = [
    {
      id: "invoiceNumber",
      label: t("economy_invoiceNumber"),
      isNumeric: false,
    },

    {
      id: "voucherDate",
      label: t("economy_voucherDate"),
      isNumeric: true,
    },
    {
      id: "amountIncludingTaxes",
      label: t("economy_amountIncludingTaxes"),
      isNumeric: true,
    },
    {
      id: "dueDate",
      label: t("economy_dueDate"),
      isNumeric: true,
    },
    {
      id: "paymentMethod",
      label: t("economy_paymentMethod"),
      isNumeric: false,
    },
    {
      id: "voucherNumber",
      label: t("economy_voucherNumber"),
      isNumeric: false,
    },

    {
      id: "openBalance",
      label: t("economy_openBalance"),
      isNumeric: true,
    },
    {
      id: "interestRate",
      label: t("economy_interestRate"),
      isNumeric: true,
    },
  ];

  const getRowJsx = (invoice: IInvoice) => (
    <TableRow key={invoice.invoiceNumber} style={{ whiteSpace: "nowrap" }}>
      <TableCell>
        <Link
          id="invoices-download-link"
          to="#"
          onClick={() => download(invoice.invoiceNumber)}>
          {invoice.invoiceNumber}
        </Link>
      </TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(invoice.voucherDate)}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(invoice.amountIncludingTaxes.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(invoice.dueDate)}
      </TableCell>
      <TableCell style={{ whiteSpace: "normal" }}>
        {getInvoicePaymentMethodTypeTranslation(invoice.paymentMethod)}
      </TableCell>
      <TableCell>
        {invoice.voucherNumber > 0 ? invoice.voucherNumber : ""}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(invoice.openBalance.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(invoice.interestRate.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  //#region poultry
  const headerColumnsPoultry = [
    {
      id: "invoiceNumber",
      label: t("economy_poultry_invoiceNumber"),
      isNumeric: false,
    },

    {
      id: "voucherDate",
      label: t("economy_poultry_voucherDate"),
      isNumeric: true,
    },
    {
      id: "dueDate",
      label: t("economy_poultry_dueDate"),
      isNumeric: true,
    },
    {
      id: "quantity",
      label: t("economy_poultry_quantity"),
      isNumeric: true,
    },
    {
      id: "amountWithoutTaxes",
      label: t("economy_poultry_amountWithoutTaxes"),
      isNumeric: true,
    },
    {
      id: "taxAmount",
      label: t("economy_poultry_taxAmount"),
      isNumeric: true,
    },
    {
      id: "amountIncludingTaxes",
      label: t("economy_poultry_amountIncludingTaxes"),
      isNumeric: true,
    },
    {
      id: "voucherNumber",
      label: t("economy_poultry_voucherNumber"),
      isNumeric: false,
    },
  ];

  const getRowJsxPoultry = (invoice: IInvoice) => (
    <TableRow key={invoice.invoiceNumber} style={{ whiteSpace: "nowrap" }}>
      <TableCell>{invoice.invoiceNumber}</TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(invoice.voucherDate)}
      </TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(invoice.dueDate)}
      </TableCell>
      <TableCell align="right">{invoice.quantity}</TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(invoice.amountWithoutTaxes.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(invoice.taxAmount.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(invoice.amountIncludingTaxes.toFixed(2))}
      </TableCell>
      <TableCell>
        {invoice.voucherNumber > 0 ? invoice.voucherNumber : ""}
      </TableCell>
    </TableRow>
  );

  //#endregion

  const download = (invoiceNumber) => {
    dispatch(economy.generateInvoicePdf(invoiceNumber));
  };

  const isPoultryProductionLine = () => {
    return productionLine === ProductionArea.Poultry;
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader level="1"> {t("economy_Invoices")}</PageHeader>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          {!isPoultryProductionLine() && (
            <DropdownMenu
              onChange={(value) => handleDropdownMenuChange(value)}
              selectedValue={selectedInvoiceType}
              options={invoicesSelectListOptions}
              label={t("economy_chooseInvoiceType")}
              singleLine={true}
            />
          )}
        </Grid>
        <Grid item xs={9}></Grid>
        <TimeframePicker
          selectedStartDate={currentStartDate}
          selectedEndDate={currentEndDate}
          startDateOnChange={startTimeOnChange}
          endDateOnChange={endTimeOnChange}
          onClick={onFetchClick}
          errorMsg={errorMsg}
        />
      </Grid>
      <Grid>
        <GeneralDataListWithSortingPaginationContentBox
          title={
            isPoultryProductionLine()
              ? t("economy_poultry_all_invoices")
              : getInvoiceTypeTranslation(selectedInvoiceType)
          }
          isExpandable={false}
          headerCells={
            isPoultryProductionLine() ? headerColumnsPoultry : headerColumns
          }
          rows={invoices}
          isLoading={isLoading}
          getRowJsx={isPoultryProductionLine() ? getRowJsxPoultry : getRowJsx}
          order="desc"
          orderBy="voucherDate"
          forceResetPagination={paginationResetCounter}
          isShowAllDefault={true}
        />
      </Grid>
    </Fragment>
  );
};

export default InvoicesPage;
