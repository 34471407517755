import { Box, Container, Grid } from "@material-ui/core";
import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../shared/components/Button/Button";
import CustomLink from "../../shared/components/CustomLink/CustomLink";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import ProductionArea from "../../shared/enums/productionLines";
import {
  IContent,
  OtherPublicationContext,
  publicationContainerUriMap,
} from "../../shared/models/Publications";
import { t } from "../../shared/services/langService";
import { getListing } from "../../shared/services/publicationService";
import { requested } from "../../shared/store/api";
import { publication, user } from "../../shared/store/apiActions";
import { RootState } from "../../shared/store/rootReducer";
import { IOrganization } from "../../shared/store/user";
import useStyles from "./WelcomePageStyles";
import useGTM, { IGTMButtonClickProps, GTMEvents } from "../../hooks/useGTM";

interface IProps {
  productionLine?: ProductionArea;
  specificContext?: OtherPublicationContext;
}

const WelcomePage: React.FC<IProps> = ({ productionLine, specificContext }) => {
  const classes = useStyles();
  const { firstname, lastname } = useSelector(
    (state: RootState) => state.auth.currentUser
  );
  const dispatch = useDispatch();
  const {
    organizations,
    roleMap,
  }: { organizations: IOrganization[]; roleMap: any } = useSelector(
    (state: RootState) => state.user
  );
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const [buttonRefIndex, setButtonRefIndex] = useState<number | undefined>(
    undefined
  );

  const currentOrganisation = organizations.find(
    (o) => o.producerNumber === Number(producerNumber)
  );
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);

  const [publications, setPublications] = useState<IContent[]>([]);

  const { regionalManagerInfo } = useSelector((state: RootState) => state.user);

  const { gtmDispatch } = useGTM();

  const contextUri =
    specificContext && specificContext === OtherPublicationContext.Osuuskunta
      ? "/cooperative"
      : "";

  const fetchPublications = (context) => {
    return requested({
      fn: async (dispatch) => {
        const response = await getListing({
          langCode: langCode,
          publicationContext: context,
          publicationType: publicationContainerUriMap.Welcome,
          disableSort: true,
        });

        setPublications(response?.status === 200 ? response.data : []);
      },
    });
  };

  useEffect(() => {
    if (langCode) {
      dispatch(fetchPublications(productionLine));
    }
  }, [langCode]);

  useEffect(() => {
    dispatch(user.getUserRegionalManagerInfo());
  }, [producerNumber]);

  const handleButtonClick = (index) => {
    const pub = publications[index];
    const buttonTitle = `welcome-button-${pub.title?.replace(" ", "").trim()}`;
    const gtmProps: IGTMButtonClickProps = {
      id: buttonTitle,
      title: pub?.title,
    };
    gtmDispatch(GTMEvents.buttonClick, gtmProps);
  };

  const getEpiserverLinksJsx = () => {
    if (publications && publications.length > 0) {
      return (
        <Fragment>
          {publications.map((content, ind) => {
            return (
              <Grid item xs={12} key={ind}>
                {content.externalLink ? (
                  <CustomLink
                    to={content.externalLink}
                    className={classes.linkButton}>
                    <Button
                      type="ok"
                      onClick={() => handleButtonClick(ind)}
                      fullWidth>
                      {content.title}
                    </Button>
                  </CustomLink>
                ) : (
                  <Button
                    type="link"
                    to={`${contextUri}/content/${content.referenceId}`}
                    fullWidth
                    onClick={() => handleButtonClick(ind)}>
                    {content.title}
                  </Button>
                )}
              </Grid>
            );
          })}
        </Fragment>
      );
    }
  };

  const getPhoneNumberLinkJsx = () => {
    return (
      regionalManagerInfo && (
        <a
          href={`tel:${regionalManagerInfo.phoneNumber
            .replace("-", "")
            .replace(" ", "")
            .trim()}`}>
          {regionalManagerInfo.phoneNumber}
        </a>
      )
    );
  };

  const getEmailLinkJsx = () => {
    return (
      regionalManagerInfo && (
        <a href={`mailto:${regionalManagerInfo.emailAddress.trim()}`}>
          {regionalManagerInfo.emailAddress}
        </a>
      )
    );
  };

  const getRegionalManagerElementJsx = () => {
    const space = <span>&nbsp;</span>;
    const title: string = `${t("frontpage_welcome_regionalManager")}:`;
    {
      return (
        <Box display="flex" flexWrap="wrap">
          {regionalManagerInfo && (
            <Fragment>
              <span className={classes.bold}>{title}</span>
              {regionalManagerInfo.name && (
                <Fragment>
                  {space}
                  <span className={classes.bold}>
                    {regionalManagerInfo.name}
                  </span>
                </Fragment>
              )}
              {regionalManagerInfo.phoneNumber &&
                regionalManagerInfo.emailAddress && (
                  <Fragment>
                    {space}
                    <span className={classes.bold}>
                      {getPhoneNumberLinkJsx()}
                    </span>
                    {space}
                    <span>/</span>
                    {space}
                    <span className={classes.bold}>{getEmailLinkJsx()}</span>
                  </Fragment>
                )}
              {regionalManagerInfo.phoneNumber &&
                !regionalManagerInfo.emailAddress && (
                  <Fragment>
                    {space}
                    <span className={classes.bold}>
                      {getPhoneNumberLinkJsx()}
                    </span>
                  </Fragment>
                )}
              {!regionalManagerInfo.phoneNumber &&
                regionalManagerInfo.emailAddress && (
                  <Fragment>
                    {space}
                    <span className={classes.bold}>{getEmailLinkJsx()}</span>
                  </Fragment>
                )}
            </Fragment>
          )}
        </Box>
      );
    }
  };

  return (
    <Container className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageHeader level="2">{t("frontpage_welcome")},</PageHeader>
          <span className={classes.bold}>{`${firstname} ${lastname}`}</span>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.bold}>
            {currentOrganisation ? currentOrganisation.name : ""}
          </span>
        </Grid>
        <Grid item xs={12}>
          {getRegionalManagerElementJsx()}
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        {getEpiserverLinksJsx()}
      </Grid>
    </Container>
  );
};

export default WelcomePage;
