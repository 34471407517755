enum nautaValitysElainlajit {
  Ternisonnivasikka = 66,
  Ternilehmavasikka = 65,
  Sonnivasikka = 61,
  Lehmavasikka = 62,
  Rotusonnivasikka = 63,
  Rotulehmavasikka = 64,
  TeinivasikkaSonni6kk = 58,
  TeinivasikkaLehma6kk = 57,
  Siitossonni = 72,
  Siitoshieho = 73,
  Siitoslehma = 76,
}

export default nautaValitysElainlajit;
