import React from "react";

import TamminenBody from "../../../assets/images/Tamminen_body.png";
import HKBody from "../../../assets/images/HK_body.png";
import KariniemiBody from "../../../assets/images/Kariniemi_body.png";
import { Grid, Link } from "@material-ui/core";

const brandLinks: IBrandItem[] = [
  {
    src: HKBody,
    alt: "HK",
    link: "https://www.hk.fi",
  },
  {
    src: TamminenBody,
    alt: "Tamminen",
    link: "https://www.tamminen.fi",
  },
  {
    src: KariniemiBody,
    alt: "Tamminen",
    link: "https://www.kariniemen.fi/",
  },
];

interface IBrandItem {
  src: string;
  alt: string;
  link: string;
}

const BrandItem = ({ src, alt, link }: IBrandItem) => {
  return (
    <Link href={link} target="_blank" style={{ color: "#fff" }}>
      <img
        style={{ objectFit: "scale-down", width: "100%", height: "100px" }}
        src={src}
        alt="alt"></img>
    </Link>
  );
};

const BrandLogos = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      {brandLinks &&
        brandLinks.map((item, index) => (
          <Grid item xs={2} key={index}>
            <BrandItem
              key={index}
              src={item.src}
              alt={item.alt}
              link={item.link}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default BrandLogos;
