import React, {Fragment, useState} from "react";
import Navigation from "./Navigation/Navigation";
import Options from "./Options/Options";
import FarmSelectionModal from "./FarmSelectionModal/FarmSelectionModal";

const Header = () => {
  const [openFarmSelectionModal, setFarmSelectionModalOpen] = useState(false);

  return (
    <Fragment> 
      <Options setFarmSelectionModalOpen={setFarmSelectionModalOpen} />
      <Navigation />
      <FarmSelectionModal open={openFarmSelectionModal} onClose={() => {setFarmSelectionModalOpen(false)}} />
    </Fragment>
  );
}

export default Header;