import http from "./httpService";
import {
  IContactUpdateDto,
  IEInvoiceOrderDto,
  IBankAccountDto,
} from "../../shared/models/FarmData";

const API_URL = `organizationManagement`;

export const updateContactInformation = async (dto: IContactUpdateDto) => {
  const resp = await http.post(`${API_URL}/contactInformation`, dto);
  return resp;
};

export const updateEInvoiceInformation = async (dto: IEInvoiceOrderDto) => {
  const resp = await http.post(`${API_URL}/eInvoiceOrder`, dto);
  return resp;
};

export const updateBankAccountInformation = async (dto: IBankAccountDto) => {
  const resp = await http.post(`${API_URL}/bankAccount`, dto);
  return resp;
};

export const setDataChecked = async () => {
  const resp = await http.post(`${API_URL}/checked`);
  return resp;
};

export default {
  updateContactInformation,
  updateEInvoiceInformation,
  updateBankAccountInformation,
  setDataChecked,
};
