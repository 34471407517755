import React, {
  Fragment,
  useState,
  useImperativeHandle,
  useEffect,
} from "react";
import { Grid, TableContainer } from "@material-ui/core";
import { useSelector } from "react-redux";
import PageText from "../PageText/PageText";
import useStyles from "./HealthQuestionsStyles";
import Checkbox from "../Checkbox/Checkbox";
import InputField from "../InputField/InputField";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "../Table/Table";
import { t } from "../../services/langService";
import DatePicker from "../DatePicker/DatePicker";
import NotificationMessage from "../NotificationMessage/NotificationMessage";

import Helper from "../../utils/helper";
import { RootState } from "../../store/rootReducer";

const initState: any = {
  ilmoitustasonVastaukset: [],
  rivikohtaisetVastaukset: [],
};

type HiddenQuestionRelations = {
  [key: string]: string[];
};

const hiddenQuestionRelations: HiddenQuestionRelations = {
  b_112_20: ["b_112_10"],
  b_111_20: ["b_111_10"],
  b_161_81: ["b_160_80"],
  b_113_20: ["b_113_10"],
  p_12_70: ["p_12_69"],
  p_10_45: ["p_10_44", "p_10_41"],
};

const getChildQuestionPersistentId = (persistentId: string): string => {
  return (
    Object.keys(hiddenQuestionRelations).find(
      (key) => hiddenQuestionRelations[key].indexOf(persistentId) > -1
    ) || ""
  );
};

const showField = (persistentId: string, answered: string[]): boolean => {
  const keys =
    Object.keys(hiddenQuestionRelations).indexOf(persistentId) === -1;
  const relation = hiddenQuestionRelations[persistentId]?.some(
    (a) => answered.indexOf(a) > -1
  );

  return keys || relation;
};

const isTrueAnswerValue = (answer: string) => {
  const toLower = answer.toLowerCase();
  return toLower === "kyllä" || toLower === "ja" || toLower === "true";
};

const isFalseAnswerValue = (answer: string) => {
  const toLower = answer.toLowerCase();
  return (
    toLower === "ei" ||
    toLower === "nej" ||
    toLower === "" ||
    toLower === "false"
  );
};

const validateQuestion = (persistentId: string, answered: string[]) => {
  return (
    showField(persistentId, answered) ||
    Object.keys(hiddenQuestionRelations).indexOf(persistentId) === -1
  );
};

interface HealthQuestionsProps {
  healthQuestions: any;
  ref: any;
  animals: any[];
  onValidate?: Function;
  readonly?: boolean;
}

const HealthQuestions: React.FC<HealthQuestionsProps> = React.forwardRef(
  (
    { healthQuestions, animals, onValidate = () => {}, readonly = false },
    ref
  ) => {
    const classes = useStyles();
    const { langCode } = useSelector((state: RootState) => state.ui.i18n);
    const [answered, setAnswered] = useState<any[]>([]);
    const [errors, setErrors] = useState({});
    const [newHealthQuestions, setNewHealthQuestions] = useState<any>(
      initState
    );
    const [errorText, setErrorText] = useState("");
    let cachedNewHealthQuestions: any = initState;

    const findHealthQuestionAnswer = (questionId: number) => {
      const answer = newHealthQuestions.ilmoitustasonVastaukset.find(
        (question) => question.questionId === questionId
      );
      return answer?.vastaus === undefined ? null : answer?.vastaus;
    };

    const animalTypes = animals?.map((a) =>
      parseInt(a.animalType || a.elainlaji)
    );
    const mandatoryQuestions = healthQuestions?.length
      ? healthQuestions
          .filter(
            (q) =>
              q.kysymykset?.filter(
                (q) =>
                  q.elainlaji.filter((e) => animalTypes.indexOf(e) > -1)
                    .length > 0
              ).length > 0
          )
          .map((q) => q.kysymykset)
          .reduce((a, b) => a.concat(b))
          .filter((k) => k.isMandatory)
          .map((k) => ({
            questionId: k.questionId,
            persistentId: k.persistentId,
          }))
      : [];

    const relevantQuestions = healthQuestions
      .filter(
        (q) =>
          q.kysymykset?.filter(
            (q) =>
              q.elainlaji.filter((e) => animalTypes.indexOf(e) > -1).length > 0
          ).length > 0
      )
      .map((q) => q.kysymykset)
      .reduce((pre, cur) => pre.concat(cur), [])
      .map((k: any) => ({
        questionId: k.questionId,
        persistentId: k.persistentId,
      }));

    useImperativeHandle(ref, () => ({
      validate() {
        setErrorText("");
        const isValid = handleValidate(null, true);
        if (isValid) return newHealthQuestions;
      },
    }));

    // set answered questions when newHealthQuestions change
    useEffect(() => {
      const answeredIlmoitusQuestions = relevantQuestions.filter(
        (q) =>
          newHealthQuestions.ilmoitustasonVastaukset
            .filter((question) => !isFalseAnswerValue(question.vastaus))
            .map((question) => question.questionId)
            .indexOf(q.questionId) > -1
      );

      const answeredRivikohtaisetQuestions = relevantQuestions.filter(
        (q) =>
          newHealthQuestions.rivikohtaisetVastaukset
            .map((question) => question.elainrivinVastaukset)
            .map((answers) =>
              answers
                .filter((answer) => !isFalseAnswerValue(answer.vastaus))
                .map((answer) => answer.questionId)
            )
            .reduce((newArray, current) => newArray.concat(current), [])
            .indexOf(q.questionId) > -1
      );

      const answeredQuestions = answeredIlmoitusQuestions.concat(
        answeredRivikohtaisetQuestions
      );

      setAnswered((prevState) => {
        return answeredQuestions.map((q) => q.persistentId);
      });
    }, [newHealthQuestions]);

    // Update health questions if animals change
    useEffect(() => {
      const birthIds = animals.map((a) => a.id);

      setNewHealthQuestions((prevState) => ({
        ilmoitustasonVastaukset: prevState.ilmoitustasonVastaukset.filter(
          (q) =>
            relevantQuestions.map((q) => q.questionId).indexOf(q.questionId) >
            -1
        ),
        rivikohtaisetVastaukset: prevState.rivikohtaisetVastaukset.filter(
          (v) => birthIds.indexOf(v.key.syntymatunnus) > -1
        ),
      }));
    }, [animals, animals.length]);

    // first render.
    useEffect(() => {
      const answeredQuestions = healthQuestions.filter(
        (q) =>
          q.kysymykset?.filter((q) => q.answer.currentAnswers.length > 0)
            .length > 0
      );
      if (answeredQuestions?.length > 0) {
        const questions = answeredQuestions
          .map((question) => question.kysymykset)
          .reduce((accumulator, current) => accumulator.concat(current))
          .filter((question) => question.answer.currentAnswers?.length > 0);

        const notificationQuestions = questions.filter(
          (question) => !question.isRowSpecific
        );

        const animalQuestions = questions.filter(
          (question) => question.isRowSpecific
        );

        const notificationAnswers = notificationQuestions.map((question) =>
          question.answer.currentAnswers
            .map((answer) => ({
              questionId: question.questionId,
              vastausId: answer.answerId,
              vastaus: answer.value,
            }))
            .reduce((accumulator, current) => accumulator.concat(current))
        );

        let elainriviAnswers: any[] = [];

        if (animalQuestions.length > 0) {
          animals.forEach((animal) => {
            elainriviAnswers.push({
              key: { syntymatunnus: animal.id },
              elainrivinVastaukset: animalQuestions
                .filter((question) =>
                  question.answer.currentAnswers.find(
                    (answer) => answer.rowId === animal.rowId
                  )
                )
                .map((question) =>
                  question.answer.currentAnswers
                    .filter((answer) => answer.rowId === animal.rowId)
                    .map((answer) => ({
                      questionId: question.questionId,
                      vastausId: answer.answerId,
                      vastaus: answer.value,
                    }))
                )
                .reduce(
                  (accumulator, current) => accumulator.concat(current),
                  []
                ),
            });
          });
        }

        setNewHealthQuestions({
          ilmoitustasonVastaukset: notificationAnswers,
          rivikohtaisetVastaukset: elainriviAnswers,
        });

        setAnswered((prevState) => {
          return [
            ...prevState,
            ...questions
              .filter((q) =>
                isTrueAnswerValue(q.answer.currentAnswers[0]?.value)
              )
              .map((q) => q.persistentId),
          ];
        });
      }
    }, []);

    const handleValidate = (obj: any = null, setText = false) => {
      const validationErrors = {};
      for (let q of mandatoryQuestions.filter((q) =>
        validateQuestion(q.persistentId, answered)
      )) {
        const exists = !obj
          ? newHealthQuestions.ilmoitustasonVastaukset.find(
              (v) => v.questionId === q.questionId
            )
          : obj.ilmoitustasonVastaukset.find(
              (v) => v.questionId === q.questionId
            );
        if (!exists) validationErrors[q.questionId] = t("ui_requiredField");
      }

      if (!obj) setErrors(validationErrors);

      const isValid = Object.keys(validationErrors).length === 0;
      if (!isValid && setText)
        setErrorText(t("animalNotification_healthDataInsufficient"));
      onValidate(isValid);
      return isValid;
    };

    // TODO: This might be ok with old code...
    const handleChange = (
      questionId,
      persistentId: string,
      answer: string,
      birthId = ""
    ) => {
      const childQuestionId = isFalseAnswerValue(answer)
        ? healthQuestions
            .map((q) => q.kysymykset)
            .reduce((pre, cur) => pre.concat(cur))
            .find(
              (k) =>
                k.persistentId === getChildQuestionPersistentId(persistentId)
            )?.questionId
        : undefined;

      if (!birthId) {
        if (answer) {
          const oldAnswer = newHealthQuestions.ilmoitustasonVastaukset.find(
            (v) => v.questionId === questionId
          );
          cachedNewHealthQuestions = {
            ilmoitustasonVastaukset: [
              ...newHealthQuestions.ilmoitustasonVastaukset.filter(
                (v) =>
                  v.questionId !== questionId &&
                  (!childQuestionId || v.questionId !== childQuestionId)
              ),
              {
                ...{
                  questionId: questionId,
                  vastaus: answer,
                },
                ...(oldAnswer ? { vastausId: oldAnswer?.vastausId } : {}),
              },
            ],
            rivikohtaisetVastaukset: [
              ...newHealthQuestions.rivikohtaisetVastaukset,
            ],
          };
        } else {
          cachedNewHealthQuestions = {
            ilmoitustasonVastaukset: newHealthQuestions.ilmoitustasonVastaukset.filter(
              (v) => v.questionId !== questionId
            ),
            rivikohtaisetVastaukset: [
              ...newHealthQuestions.rivikohtaisetVastaukset,
            ],
          };
        }
      } else {
        const oldAnswer = newHealthQuestions.rivikohtaisetVastaukset
          .find((v) => v.key.syntymatunnus === birthId)
          ?.elainrivinVastaukset?.find((v) => v.questionId === questionId);

        cachedNewHealthQuestions = {
          ilmoitustasonVastaukset: [
            ...newHealthQuestions.ilmoitustasonVastaukset.filter(
              (v) => !childQuestionId || v.questionId !== childQuestionId
            ),
          ],
          rivikohtaisetVastaukset: [
            ...newHealthQuestions.rivikohtaisetVastaukset.filter(
              (v) => v.key.syntymatunnus !== birthId
            ),
            {
              key: { syntymatunnus: birthId },
              elainrivinVastaukset: [
                ...(newHealthQuestions.rivikohtaisetVastaukset
                  .find((v) => v.key.syntymatunnus === birthId)
                  ?.elainrivinVastaukset?.filter(
                    (v) => v.questionId !== questionId
                  ) || []),
                {
                  ...{
                    questionId: questionId,
                    vastaus: answer,
                  },
                  ...(oldAnswer ? { vastausId: oldAnswer?.vastausId } : {}),
                },
              ],
            },
          ],
        };
      }

      if (isTrueAnswerValue(answer)) {
        setAnswered((prevState) => [
          ...prevState.filter((q) => q !== persistentId),
          persistentId,
        ]);
      } else if (isFalseAnswerValue(answer)) {
        const rowWithExistingAnswer = cachedNewHealthQuestions?.rivikohtaisetVastaukset?.find(
          (rivi) =>
            rivi?.elainrivinVastaukset?.find(
              (vastaus) =>
                vastaus?.questionId === questionId && vastaus.vastaus === "true"
            )
        );

        if (!rowWithExistingAnswer) {
          setAnswered((prevState) => {
            const state = prevState.filter((q) => q !== persistentId);
            return state;
          });
        }
      }

      const clone = { ...newHealthQuestions, ...cachedNewHealthQuestions };
      handleValidate(clone);
      setNewHealthQuestions(clone);
    };

    if (!healthQuestions?.length) return null;

    return (
      <Grid container spacing={1} alignItems="flex-start">
        {errorText && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ paddingTop: "0" }}>
            <NotificationMessage message={errorText} severity={"error"} />
          </Grid>
        )}
        {healthQuestions
          .filter(
            (q) =>
              q.kysymykset?.filter(
                (q) =>
                  q.elainlaji.filter((e) => animalTypes.indexOf(e) > -1)
                    .length > 0
              ).length > 0
          )
          .map((q, i) => (
            <Fragment key={i}>
              {q.otsikko?.text[langCode] && (
                <Grid item xs={12}>
                  <PageText
                    type="bold"
                    gutterBottom={true}
                    required={
                      q.kysymykset?.length === 1 &&
                      !q.kysymykset[0].text[langCode]
                    }>
                    {q.otsikko?.text[langCode]}
                  </PageText>
                </Grid>
              )}
              {q.kysymykset
                ?.filter(
                  (q) =>
                    q.elainlaji.filter((e) => animalTypes.indexOf(e) > -1)
                      .length > 0
                )
                .map((sq, j) => (
                  <Fragment key={j}>
                    {!(
                      sq.answer?.inputType === "input" &&
                      sq.answer?.valueType === "string" &&
                      sq.isRowSpecific === true
                    ) && (
                      <Fragment>
                        {sq.answer?.inputType === "select" && (
                          <Grid item xs={12}>
                            <RadioButtonGroup
                              {...(sq.answer?.currentAnswers[0]?.value
                                ? {
                                    defaultValue:
                                      sq.answer?.currentAnswers[0]?.value,
                                  }
                                : {})}
                              key={sq.questionId}
                              disabled={readonly}
                              horizontal={true}
                              display={"block"}
                              margin={""}
                              label={sq.text[langCode]}
                              groupItems={sq.answer?.info[
                                langCode
                              ]?.options?.map((o) => ({
                                label: o,
                                name: o,
                                value: o,
                              }))}
                              required={sq.isMandatory}
                              onChange={(value) =>
                                handleChange(
                                  sq.questionId,
                                  sq.persistentId,
                                  value
                                )
                              }
                              errorText={errors[sq.questionId]}
                            />
                          </Grid>
                        )}
                        {sq.answer?.inputType === "input" &&
                          sq.answer?.valueType === "datetime" &&
                          showField(sq.persistentId, answered) && (
                            <Grid item xs={12} sm={12} md={3} lg={3}>
                              <DatePicker
                                selectedDate={findHealthQuestionAnswer(
                                  sq.questionId
                                )}
                                key={sq.questionId}
                                readonly={readonly}
                                margin={"0"}
                                fullWidth
                                label={sq.text[langCode]}
                                required={sq.isMandatory}
                                onChange={(momentDate) =>
                                  handleChange(
                                    sq?.questionId,
                                    sq.persistentId,
                                    Helper.dateToIsoString(momentDate)
                                  )
                                }
                                autoOk={true}
                                errorText={errors[sq.questionId]}
                              />
                            </Grid>
                          )}
                        {sq.answer.inputType === "input" &&
                          (sq.answer?.valueType === "string" ||
                            sq.answer?.valueType === "integer") &&
                          sq.isRowSpecific === false &&
                          showField(sq.persistentId, answered) && (
                            <Grid item xs={12}>
                              <InputField
                                {...(sq.answer?.currentAnswers[0]?.value
                                  ? {
                                      defaultValue:
                                        sq.answer?.currentAnswers[0]?.value,
                                    }
                                  : {})}
                                key={sq.questionId}
                                readonly={readonly}
                                margin={""}
                                label={sq.text[langCode]}
                                required={sq.isMandatory}
                                fullWidth
                                multiline={true}
                                onChange={(e) =>
                                  handleChange(
                                    sq.questionId,
                                    sq.persistentId,
                                    e.target.value
                                  )
                                }
                                errorText={errors[sq.questionId]}
                              />
                            </Grid>
                          )}
                      </Fragment>
                    )}
                    {sq.answer?.inputType === "input" &&
                      sq.answer?.valueType === "string" &&
                      sq.isRowSpecific === true && (
                        <Fragment>
                          {!j && (
                            <Grid item xs={12}>
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell style={{ width: "50px" }}>
                                        {t(
                                          "animalNotification_birthIdentification"
                                        )}
                                      </TableCell>
                                      {healthQuestions[i].kysymykset?.map(
                                        (k, i) => (
                                          <TableCell
                                            key={i}
                                            style={{ width: "50px" }}>
                                            {k?.text[langCode]}
                                          </TableCell>
                                        )
                                      )}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {animals.map((animal) => (
                                      <TableRow
                                        key={animal.id || animal.syntymatunnus}>
                                        <TableCell style={{ width: "50px" }}>
                                          {animal.id || animal.syntymatunnus}
                                        </TableCell>
                                        {healthQuestions[i].kysymykset?.map(
                                          (k, i) => (
                                            <TableCell
                                              key={i}
                                              style={{ width: "50px" }}>
                                              <Checkbox
                                                {...(k.answer?.currentAnswers.find(
                                                  (c) =>
                                                    c.rowId === animal.rowId
                                                )?.value === "true"
                                                  ? { defaultChecked: true }
                                                  : {})}
                                                disabled={readonly}
                                                name={k?.text[langCode]}
                                                onChange={(value) =>
                                                  handleChange(
                                                    k.questionId,
                                                    k.persistentId,
                                                    value.toString(),
                                                    animal.id
                                                  )
                                                }
                                              />
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          )}
                        </Fragment>
                      )}
                  </Fragment>
                ))}
            </Fragment>
          ))}
      </Grid>
    );
  }
);

export default HealthQuestions;
