import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
} from "@material-ui/core";
import useStyles from "./CookieDisclaimerModalStyles";
import Button from "../Button/Button";
import PageHeader from "../PageHeader/PageHeader";
import { t } from "../../services/langService";

const setCookie = (name: string, value: string, hoursToExpire: number) => {
  const date = new Date();
  date.setTime(date.getTime() + hoursToExpire * 60 * 60 * 1000);
  document.cookie = `${name} = ${value}; expires = ${date.toUTCString()}; path=/`;
};

const getCookie = (name: string): string | undefined => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  if (match) {
    return match[2];
  }
};

const cookieDisclaimerAnsweredId = "cookieDisclaimerAnswered";

export enum ButtonOption {
  NotAnswered,
  AllowAllCookies,
  UseNecessaryCookiesOnly,
}

interface ICookieDisclaimerModalProps {
  onSelection: (option: ButtonOption) => void;
  showModal?: boolean;
}

const CookieDisclaimerModal: React.FC<ICookieDisclaimerModalProps> = ({
  onSelection,
  showModal = true,
}) => {
  const cookieDisclaimerAnswered =
    ButtonOption[getCookie(cookieDisclaimerAnsweredId) || "NotAnswered"] ||
    ButtonOption.NotAnswered;
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(
    cookieDisclaimerAnswered === ButtonOption.NotAnswered && showModal
  );

  onSelection(cookieDisclaimerAnswered);

  const handleClose = (button: ButtonOption) => {
    setCookie(cookieDisclaimerAnsweredId, ButtonOption[button], 30 * 24);
    setOpen(false);
    onSelection(button);
  };

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      classes={{ paper: classes.paper }}
      aria-labelledby="cookie-disclaimer-modal-title"
      aria-describedby="cookie-disclaimer-modal-description">
      <DialogTitle id="cookie-disclaimer-modal-title">
        <PageHeader level="2" component="div">
          {t("cookieDisclaimerModal_title")}
        </PageHeader>
      </DialogTitle>
      <DialogContent id="cookie-disclaimer-modal-content">
        <DialogContentText id="cookie-disclaimer-modal-description">
          {t("cookieDisclaimerModal_description")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <List className={classes.list}>
          <ListItem>
            <Button
              id="cookie-disclaimer-modal-allowallcookies-button"
              fullWidth
              onClick={() => handleClose(ButtonOption.AllowAllCookies)}
              type={"ok"}>
              {t("cookieDisclaimerModal_allowAllCookies")}
            </Button>
          </ListItem>
          <ListItem>
            <Button
              id="cookie-disclaimer-modal-usenecessarycookiesonly-button"
              fullWidth
              onClick={() => handleClose(ButtonOption.UseNecessaryCookiesOnly)}
              type={"modal"}>
              {t("cookieDisclaimerModal_useNecessaryCookiesOnly")}
            </Button>
          </ListItem>
        </List>
      </DialogActions>
    </Dialog>
  );
};

export default CookieDisclaimerModal;
