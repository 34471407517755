import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import { userFileListingCleared } from "../../shared/store/user";
import PageHeader, {
  PageHeaderBox,
} from "../../shared/components/PageHeader/PageHeader";
import useStyles from "./FileListingPageStyles";
import * as api from "../../shared/store/apiActions";
import { t } from "../../shared/services/langService";
import FileListing from "../../shared/components/FileListing/FileListing";
import { RootState } from "../../shared/store/rootReducer";
import { IFileStructContainer } from "../../shared/models/FileListing";

interface IFileListingPageProps {
  header?: string;
  defaultFolderPath?: string;
}

const FileListingPage: React.FC<IFileListingPageProps> = ({
  header = "",
  defaultFolderPath = "",
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fileListing = useSelector((state: RootState) => state.user.fileListing);
  const { folderPath } = useParams();

  useEffect(() => {
    dispatch(
      api.user.getUserFileListing(folderPath || defaultFolderPath || "")
    );
    return () => {
      dispatch(userFileListingCleared());
    };
  }, []);

  const handleFileClicked = (name: string) => {
    dispatch(api.user.getUserFile(name));
  };

  return (
    <Grid container className={classes.root}>
      <Grid container alignItems="flex-start">
        <Grid item xs={10}>
          <PageHeader
            align={"center"}
            style={{ paddingTop: "50px" }}
            level="1"
            id="filelisting-header">
            {header || t("fileListing_pageHeader")}
          </PageHeader>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            direction={"column"}>
            <PageHeaderBox />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <FileListing
                directoryStructure={fileListing ?? ({} as IFileStructContainer)}
                onFileClicked={(name) => handleFileClicked(name)}
                defaultFolderPath={decodeURIComponent(
                  folderPath || defaultFolderPath || ""
                )}
                hideParentsFolders
                inputFieldPlaceholder={t("fileListing_filterPlaceholder")}
                headerLabels={[
                  "",
                  t("fileListing_nameHeader"),
                  t("fileListing_modifiedHeader"),
                  "",
                ]}
                metadataLabels={{
                  name: t("fileListing_metadataName"),
                  size: t("fileListing_metadataSize"),
                  kb: t("fileListing_metadataKb"),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FileListingPage;
