import http from "../../../../shared/services/httpService";
import rejectCodes from "../../enums/rejectCodes";
import {IBreedingBatch} from "../../models/IBreedingBatch";
import {ISlaughterRejectedData} from "../../models/ISlaughterRejectedData"; 
import {ISlaughterData} from "../../models/ISlaughterData"; 
import {ISlaughter} from "../../models/ISlaughter"; 

const API_URL = `poultry/`;

export const getChickNotifications = async () => {
    const resp = await http.get<any>(`${API_URL}batches/notifications`);
    return resp;
};

export const getBreedings = async (startDate: string, endDate: string) => {
    const resp = await http.get<IBreedingBatch>(`${API_URL}breedings?startDateString=${startDate}&endDateString=${endDate}`);
    return resp;
};

export const getSlaughterNotifications = async () => {
    const resp = await http.get<any>(`${API_URL}slaughternotifications`);
    return resp;
};

export const getSlaughters = async (slaughterId: number, breedingId: number) => {
    const resp = await http.get<ISlaughter>(`${API_URL}slaughters/${slaughterId}/${breedingId}`);
    return resp;
};

export const getSlaughterData = async (slaughterId: number, breedingId: number) => {
    const resp = await http.get<ISlaughterData>(`${API_URL}slaughters/${slaughterId}/${breedingId}`);
    return resp;
};

export const getRejectedDataForSlaughter = async (slaughterId: number, rejectCode: rejectCodes) => {
    const resp = await http.get<ISlaughterRejectedData>(`${API_URL}slaughters/rejected/${slaughterId}/${rejectCode}`);
    return resp;
};

export const getFrontpageInfo = async () => {
    const resp = await http.get<any>(`${API_URL}frontpageinfo`);
    return resp;
};

export default {
    getChickNotifications,
    getBreedings,
    getSlaughterNotifications,
    getSlaughters,
    getSlaughterData,
    getRejectedDataForSlaughter,
    getFrontpageInfo
};