class SlaughterInfoHelper {
  getPaymentOngoingCheckMark(paymentDateExists?: boolean) {
    return !paymentDateExists ? "X" : "";
  }

  constructLabelText(prefix: string, text: string) {
    if (prefix && text && text.length > 1) {
      return prefix + text[0].toLowerCase() + text.substring(1);
    } else if (prefix && text) {
      return prefix + text[0].toLowerCase();
    }
    return "";
  }
}

export default new SlaughterInfoHelper();
