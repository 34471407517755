import KetjukysymysData from "./KetjukysymysData";

export class KuormausAikaData {
  pvm: string = "";
  klo: string = "";
}

class BeefNotificationDetails {
  rowDispatchState: string = "";
  rowId: number = 0;
  elainlaji: number = 0;
  syntymatunnus: string = "";
  rotu: string = "";
  syntymapvm?: string = ""; // Date?!?
  toivottuVko: number = 0;
  kiire?: boolean = false;
  nupoutettu?: boolean = false;
  kuormausAika: KuormausAikaData[] = [];
  autoilijanTiedot: string[] = [];
}

export class BeefNotificationData {
  notificationNumber: number = 0;
  livestockVenue: string = "";
  type: string = "";
  state: string = "";
  date: string = "";
  furtherInfo: string = "";
  numAnimalsTotal: number = 0;
  numAnimalsLoaded: number = 0;
  numAnimalsPlannedForLoading: number = 0;
  beefDetails: BeefNotificationDetails[] = [];
  healthData: KetjukysymysData = new KetjukysymysData();
  hasHealthData: boolean = false;
}

export default class BeefDispatchListing {
  notifications: Map<string, BeefNotificationData[]> = new Map<
    string,
    BeefNotificationData[]
  >();
}
