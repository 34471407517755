import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Button from "../../../../shared/components/Button/Button";
import useStyles from "./EditDialogStyles";
import { t } from "../../../../shared/services/langService";

const EditDialog = ({ modal, handleClose, children }) => {
  const classes = useStyles();

  const getSubmitButtonJsxByModalType = () => {
    switch (modal.type) {
      case "bankAccount":
        return (
          <Button type="modal-submit" form="bank-account-form">
            {t("ui_save")}
          </Button>
        );
      case "eInvoice":
        return (
          <Button type="modal-submit" form="einvoice-form">
            {t("ui_save")}
          </Button>
        );
      default:
        return (
          <Button
            id="info-modal-ok-button"
            onClick={() => handleClose("ok")}
            type={"modal"}>
            {t("ui_save")}
          </Button>
        );
    }
  };

  return (
    <Dialog open={modal.open} PaperProps={{ className: classes.dialog }}>
      <DialogTitle>{modal.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{modal.description}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          id="info-modal-cancel-button"
          onClick={() => handleClose("cancel")}
          type={"modal"}
          autoFocus>
          {t("ui_cancel")}
        </Button>

        {getSubmitButtonJsxByModalType()}
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
