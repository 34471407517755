import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "./PageTextStyles";

interface PageTextProps {
  type: "normal" | "bold" | "link";
  required?: boolean;
  containerStyle?: any;
  [x: string]: any;
}

const PageText: React.FC<PageTextProps> = ({
  type = "normal",
  required = false,
  children,
  containerStyle,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div className={classes.labelContainer} style={containerStyle}>
      {type === "normal" && (
        <Typography
          display={"inline"}
          variant={"body1"}
          color={"textSecondary"}
          className={classes.textNormal}
          {...rest}>
          {children}
        </Typography>
      )}

      {type === "bold" && (
        <Typography
          display={"inline"}
          variant={"body1"}
          color={"textPrimary"}
          className={classes.textBold}
          {...rest}>
          {children}
        </Typography>
      )}

      {type === "link" && (
        <Typography
          display={"block"}
          variant={"body1"}
          className={classes.link}
          {...rest}>
          {children}
        </Typography>
      )}

      {required && (
        <Typography
          display={"inline"}
          variant={"body1"}
          className={classes.requiredLabel}
          style={{ color: "#cf2d3a", marginLeft: "4px" }}>
          *
        </Typography>
      )}
    </div>
  );
};

export default PageText;
