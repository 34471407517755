import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { t } from "../../shared/services/langService";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { publication } from "../../shared/store/apiActions";
import Helper from "../../shared/utils/helper";
import { Box, Grid } from "@material-ui/core";
import theme from "../../shared/themes";
import useStyles from "./PublicationListingPageStyles";
import PageText from "../../shared/components/PageText/PageText";
import { publicationsCleared } from "../../shared/store/publications";
import { OtherPublicationContext } from "../../shared/models/Publications";
import { RootState } from "../../shared/store/rootReducer";

interface PublicationsPageProps {
  publicationType: "news" | "event" | "content" | "training";
  specificContext?: OtherPublicationContext;
  headJsx?: JSX.Element;
}

const PublicationListingPage: React.FC<PublicationsPageProps> = ({
  publicationType,
  specificContext,
  headJsx,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { publications } = useSelector(
    (state: RootState) => state.publications
  );

  const linkPrefix = publicationType === "news" ? "newsletters" : "events";

  const contextUri =
    specificContext && specificContext === OtherPublicationContext.Osuuskunta
      ? "/cooperative"
      : "";

  useEffect(() => {
    if (langCode)
      dispatch(
        publication.getListing({
          langCode: langCode,
          publicationContext: specificContext
            ? specificContext
            : productionLine,
          publicationType: publicationType,
        })
      );
    return () => {
      dispatch(publicationsCleared());
    };
  }, [langCode]);

  return (
    <Grid
      container
      spacing={2}
      style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "2%" }}>
      <Grid item xs={12} style={{ marginBottom: "20px" }}>
        {headJsx && <Box displayPrint="none">{headJsx}</Box>}
      </Grid>
      <Grid item xs={12}>
        <PageHeader level={"1"}>
          {t(`publicationType_${publicationType}`)}
        </PageHeader>
      </Grid>
      <Grid
        item
        xs={12}
        alignItems={"center"}
        justifyContent={"center"}
        direction={"column"}></Grid>
      {publications?.map((p) => (
        <Grid item xs={12} style={{ marginBottom: "5px" }}>
          <Link
            id="publication-listing-page-link"
            className={classes.publicationLink}
            to={`${contextUri}/${linkPrefix}/${p.referenceId}`}>
            <PageText
              type={"bold"}
              style={{ color: theme.palette.primary.main }}>
              {Helper.dateToLocale(
                p.startTime ? p.startTime : p.publishDate ?? ""
              )}
              {p.startTime &&
                p.endTime &&
                Helper.dateToLocale(p.startTime) !==
                  Helper.dateToLocale(p.endTime) && (
                  <span> - {Helper.dateToLocale(p.endTime)}</span>
                )}
              <span> {p.title}</span>
            </PageText>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default PublicationListingPage;
