import { makeStyles } from "@material-ui/core";
import { ITheme } from "../../shared/themes/default";

const useStyles = makeStyles((theme: ITheme) => ({
  backGroundStyles: ({ productionLine }: any) => ({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundImage: `url(${theme.custom.images[productionLine]})`,
  }),
  gridContent: {
    width: "80%",
    marginLeft: "10%",
    marginRight: "10%",
    padding: "5% 2%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "0",
      marginRight: "0",
    },
  },
}));

export default useStyles;
