import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  hiddenRow: {
    display: "none",
  },
  transported: {
    "& > *": {
      color: "#c9c8c6",
    },
  },
});

export default useStyles;
