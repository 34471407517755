import { AuthorizationContext } from "../../management/shared/models/Users";
import { ADUserRole, mainUserRoles, UserRole, UserRoles } from "./CoreEnums";

export enum ProductionArea {
  None = "",
  Beef = "Nauta",
  Pork = "Sika",
  Poultry = "Broileri",
  Cooperative = "Osuuskunta",
  Other = "Muu",
}

export const ProductionLineUriMap = {
  Nauta: "beef",
  Sika: "pork",
  Broileri: "poultry",
  Osuuskunta: "cooperative",
};

export const animalProductionLines = [
  ProductionArea.Poultry,
  ProductionArea.Beef,
  ProductionArea.Pork,
];

export const allProductionLines = [
  ...animalProductionLines,
  ProductionArea.Other,
  ProductionArea.Cooperative,
];

interface IProductionAreaConfig {
  Beef: ISecurable;
  Pork: ISecurable;
  Poultry: ISecurable;
  Cooperative: ISecurable;
  Other: ISecurable;
}

interface ISecurable {
  allowedProductionAreas: ProductionArea[] | ProductionArea;
  allowedUserRoles: UserRoles[];
  authContext: AuthorizationContext;
  orgHasToBeMember?: boolean;
}

export const productionAreaConfig: IProductionAreaConfig = {
  Beef: {
    allowedProductionAreas: [ProductionArea.Beef],
    allowedUserRoles: [
      ADUserRole.SinettiHKScanNauta,
      ADUserRole.SinettiKivinettiNauta,
      UserRole.EmployeeUser,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
  Pork: {
    allowedProductionAreas: [ProductionArea.Pork],
    allowedUserRoles: [
      ADUserRole.SinettiHKScanSika,
      ADUserRole.SinettiKivinettiSika,
      UserRole.EmployeeUser,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
  Poultry: {
    allowedProductionAreas: [ProductionArea.Poultry],
    allowedUserRoles: [
      ADUserRole.SinettiHKScanBroileri,
      UserRole.EmployeeUser,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
  Cooperative: {
    allowedProductionAreas: [ProductionArea.Cooperative],
    allowedUserRoles: [
      ADUserRole.SinettiHKScanOsuuskunta,
      ADUserRole.SinettiOsuuskunta,
      UserRole.EmployeeUser,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.OsuuskuntaUser,
    orgHasToBeMember: true,
  },
  Other: {
    allowedProductionAreas: [ProductionArea.Other],
    allowedUserRoles: [
      // ...mainUserRoles,
      // UserRole.EmployeeUser,
      // UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
};

export default ProductionArea;
