//Generate random id consisting of numbers and letters of given length.
export const makeid = (length: number): string => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

//Generate random id with prefix
export const makeidp = (prefix: string, length: number): string => {
  return prefix + makeid(length);
};
