import { combineReducers } from "redux";
import { uiReducer } from "./ui/ui";
import authReducer from "./auth";
import userReducer from "./user";
import beefReducer from "./beef";
import porkReducer from "./pork";
import poultryReducer from "./poultry";
import apiReducer from "./api";
import economyReducer from "./economy";
import publicationReducer from "./publications";
import cooperativeReducer from "./cooperative";
import trainingsReducer from "./trainings";
import managementReducer from "./management";
import powerbiembeddingReducer from "./powerbi";

// global state
export const rootReducer = combineReducers({
  ui: uiReducer,
  auth: authReducer,
  user: userReducer,
  beef: beefReducer,
  pork: porkReducer,
  poultry: poultryReducer,
  api: apiReducer,
  economy: economyReducer,
  publications: publicationReducer,
  cooperative: cooperativeReducer,
  trainings: trainingsReducer,
  management: managementReducer,
  powerbi: powerbiembeddingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
