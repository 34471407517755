import React from "react";
import { Switch, Route } from "react-router-dom";
import useStyles from "./PoultryContentStyles";
import MainPage from "../MainPage/MainPage";
import RaisingBatchesPage from "../RaisingBatchesPage/RaisingBatchesPage";
import ArrivingBatchesPage from "../ArrivingBatchesPage/ArrivingBatchesPage";
import LoadingTimetablePage from "../LoadingTimetablePage/LoadingTimetablePage";
import GuidesPage from "../GuidesPage/GuidesPage";
import Content from "../../shared/components/Content/Content";

import ProductionArea from "../../shared/enums/productionLines";
import moment from "moment";

import withTimeFrame from "../../shared/hocs/withTimeframe";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import { AuthorizationContext } from "../../management/shared/models/Users";
import { ADUserRole, UserRole } from "../../shared/enums/CoreEnums";

const RaisingBatchesWithTimeframe = withTimeFrame(RaisingBatchesPage);

const PoultryContent = () => {
  const classes = useStyles();
  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      <Switch>
        <ProtectedRoute
          path="/poultry/guides"
          component={GuidesPage}
          allowedProductionLines={ProductionArea.Poultry}
          authContext={AuthorizationContext.MainUser}
          allowedUserRoles={[
            ADUserRole.SinettiHKScanBroileri,
            UserRole.EmployeeUser,
          ]}
        />
        <ProtectedRoute
          path="/poultry/arrivingbatches"
          component={ArrivingBatchesPage}
          allowedProductionLines={ProductionArea.Poultry}
          authContext={AuthorizationContext.MainUser}
          allowedUserRoles={[
            ADUserRole.SinettiHKScanBroileri,
            UserRole.EmployeeUser,
          ]}
        />
        <ProtectedRoute
          path="/poultry/raisingbatches/:rowId?/:slaughterId?"
          render={() => (
            <RaisingBatchesWithTimeframe
              startTime={moment()
                .subtract(4, "months")
                .startOf("month")
                .format("YYYY-MM-DD")}
              endTime={moment()
                .add(2, "months")
                .endOf("month")
                .format("YYYY-MM-DD")}
            />
          )}
          allowedProductionLines={ProductionArea.Poultry}
          authContext={AuthorizationContext.MainUser}
          allowedUserRoles={[
            ADUserRole.SinettiHKScanBroileri,
            UserRole.EmployeeUser,
          ]}
        />
        <ProtectedRoute
          path="/poultry/loadingtimetable"
          component={LoadingTimetablePage}
          allowedProductionLines={ProductionArea.Poultry}
          authContext={AuthorizationContext.MainUser}
          allowedUserRoles={[
            ADUserRole.SinettiHKScanBroileri,
            UserRole.EmployeeUser,
          ]}
        />

        <Route path="/poultry" component={MainPage} />
      </Switch>
    </Content>
  );
};

export default PoultryContent;
