import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../shared/store/rootReducer";
import useStyles from "./ReportContentStyles";
import Content from "../../shared/components/Content/Content";
import ReportsPage from "../ReportPage/ReportsPage";

const ReportContent = () => {
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const classes = useStyles({ productionLine });

  return (
    <Content
      backGroundStyles={classes.backGroundStyles}
      gridContainerStyles={classes.gridContainerStyles}>
      <Switch>
        <Route path="/reports/:referenceId" component={ReportsPage} />
      </Switch>
    </Content>
  );
};

export default ReportContent;
