import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10%",
      marginRight: "10%",
    },
  },
}));

export default useStyles;
