import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  content: {
    height: "100%",
    width: "100%",
  },
  logoContainer: {
    minWidth: "80px",
    width: "140px",
    display: "block",
    margin: "auto",
  },
  logo: {
    pointerEvents: "none",
    marginTop: "20px",
  },
  header: {
    textAlign: "center",
    marginTop:"20px"
  },
  subtextLeft: {},
  subtextRight: {
    textAlign: "right",
  },
  textNormal: {
    letterSpacing: "0.33px",
    fontSize: "0.9rem",
    color: "#777",
  },
  textBold: {
    fontFamily: "Avenir Next W1G Bold",
    letterSpacing: "0.28px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "#535559",
  },
  horizontalRuler: {
    marginBottom: "0",
    marginTop: "0",
  },
  name: {
    "& h3": {
      color: "black",
    },
  },
});

export default useStyles;
