import React from "react";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import moment from "moment";

interface WeekPickerOption {
  text: string;
  value: string;
  name: string;
}

interface WeekPickerProps {
  label?: string;
  startDate?: Date;
  weeksToShow?: number;
  defaultValue?: string;
  defaultIndex?: number;
  required?: boolean;
  onChange: any;
  addWeekpickerOptions?: string[];
  showStartAndEndDate?: boolean;
  [x: string]: any;
}

const WeekPicker: React.FC<WeekPickerProps> = ({
  label = "",
  startDate = new Date(),
  weeksToShow = 5,
  defaultValue = "",
  defaultIndex = 0,
  required = false,
  onChange,
  addWeekpickerOptions,
  showStartAndEndDate = false,
  ...rest
}) => {
  let date = new Date(startDate);
  let weekStartDate = moment(startDate).startOf("week").format("DD.MM");
  let weekEndDate = moment(startDate).endOf("week").format("DD.MM");
  let week = moment(startDate).format("W");
  let year = moment(startDate).format("YYYY");
  let weekpickerOptions: WeekPickerOption[] = [];

  for (let i = 0; i < weeksToShow; i++) {
    let primaryText = `${year}${parseInt(week) < 10 ? "0" : ""}${week}`;
    let secondaryText = showStartAndEndDate
      ? `${weekStartDate}. - ${weekEndDate}.`
      : "";
    weekpickerOptions.push({
      text: `${primaryText} ${secondaryText}`,
      value: `${primaryText}`,
      name: `${primaryText}`,
    });

    date = moment(date).add(7, "days").toDate();
    weekStartDate = moment(date).startOf("week").format("DD.MM");
    weekEndDate = moment(date).endOf("week").format("DD.MM");
    week = moment(date).format("W");
    year = moment(date).format("YYYY");
  }

  if (addWeekpickerOptions) {
    for (let week of [...addWeekpickerOptions].sort().reverse()) {
      if (!weekpickerOptions.some((o) => o.value === week))
        weekpickerOptions.unshift({ text: week, value: week, name: week });
    }
  }

  if (!defaultValue && defaultIndex > 0) {
    defaultValue = weekpickerOptions[defaultIndex].value;
  }

  return (
    <DropdownMenu
      label={label}
      options={weekpickerOptions}
      defaultValue={defaultValue}
      required={required}
      onChange={onChange}
      {...rest}
    />
  );
};

export default WeekPicker;
