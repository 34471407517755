import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  instructions: {
    marginTop: "1em",
    marginBottom: "1em",
    marginLeft: "1em",
    marginRight: "1em",
    backgroundColor: "#E6E6E6",
    fontFamily: "Avenir Next W1G",
    fontSize: "auto",
    color: "#000D80",
    border: "1px solid",
    borderColor: "#000D80",
    display: "flex",
  },
}));

export default useStyles;
