import { createSlice } from "@reduxjs/toolkit";
import { IAssignableRoles, IUser } from "../../management/shared/models/Users";

interface IUserState {
  assignableRoles?: IAssignableRoles;
  users?: IUser[];
}

const initialState: IUserState = {};

const slice = createSlice({
  name: "management",
  initialState: initialState,
  reducers: {
    assignableRolesReceived: (state, action) => {
      state.assignableRoles = action.payload.data;
    },
    assignableRolesCleared: (state) => {
      state.assignableRoles = initialState.assignableRoles;
    },
    usersReceived: (state, action) => {
      state.users = action.payload.data;
    },
    usersCleared: (state) => {
      state.users = initialState.users;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  assignableRolesReceived,
  assignableRolesCleared,
  usersReceived,
  usersCleared,
} = slice.actions;

export default slice.reducer;
