import { Alert } from "@material-ui/lab";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import * as api from "../../store/apiActions";
import HTMLReactParser from "html-react-parser";
import useStyles from "./StaticBannerStyles";
import Button from "../Button/Button";
import {
  storeInfoBannerId,
  infoBannerIdExists,
} from "../../helpers/localStorageHelpers";
import { t } from "../../services/langService";
import { UserRole } from "../../enums/CoreEnums";
import { isValidUserRole } from "../../helpers/navHelpers";
import { AuthorizationContext } from "../../../management/shared/models/Users";
import ProductionArea from "../../enums/productionLines";

const StaticBanner = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { roleMap } = useSelector((state: RootState) => state.user);

  const { roles, producers } = useSelector(
    (state: RootState) => state.auth.currentUser
  );
  const [refresh, setRefresh] = useState(false);
  const [isMainUser, setIsMainUser] = useState(false);

  const currentOrganization = organizations.find(
    (o) => o.producerNumber === Number(producerNumber)
  );
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { bannerItems } = useSelector((state: RootState) => state.publications);

  useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    if (productionLine === ProductionArea.None) {
      return;
    }
    dispatch(api.publication.getBannerContent(langCode, productionLine));
    setRefresh(false);
  }, [productionLine, langCode]);

  useEffect(() => {
    if (producerNumber === undefined) {
      setIsMainUser(false);
    } else {
      setIsMainUser(
        isValidUserRole(
          [UserRole.MainUser],
          roles,
          producers,
          producerNumber ?? 0,
          AuthorizationContext.MainUser,
          roleMap
        )
      );
    }
  }, [producerNumber, producers, roles, roleMap]);

  const handleInfoBannerAck = (event) => {
    storeInfoBannerId(event.currentTarget.id);
    setRefresh(true);
  };

  return (
    <Fragment>
      {isMainUser &&
        bannerItems?.Huomautukset &&
        bannerItems.Huomautukset.map((huom) => {
          return (
            huom.body && (
              <Alert
                key={huom.referenceId}
                className={classes.warning}
                icon={false}>
                {HTMLReactParser(huom?.body ?? "")}
              </Alert>
            )
          );
        })}

      {bannerItems?.Infot &&
        bannerItems.Infot.map(
          (info) =>
            info.body &&
            !infoBannerIdExists(info.referenceId.toString()) && (
              <Alert
                key={info.referenceId}
                id={info.referenceId.toString()}
                className={classes.info}
                icon={false}
                action={
                  <Button
                    id={info.referenceId.toString()}
                    type="ok"
                    onClick={handleInfoBannerAck}>
                    {t("infoBanner_infoRead")}
                  </Button>
                }>
                {HTMLReactParser(info?.body ?? "")}
              </Alert>
            )
        )}
    </Fragment>
  );
};

export default StaticBanner;
