import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CarouselComponent, {
  CarouselMediaItem,
  ICarouselComponentProps,
} from "../../../shared/components/CarouselComponent/CarouselComponent";
import ProductionArea from "../../../shared/enums/productionLines";
import {
  MediaType,
  publicationContainerUriMap,
} from "../../../shared/models/Publications";

import { publication } from "../../../shared/store/apiActions";
import { carouselAdItemsCleared } from "../../../shared/store/publications";
import { RootState } from "../../../shared/store/rootReducer";

interface IEpiserverCarouselComponentProps extends ICarouselComponentProps {
  productionLine: ProductionArea;
}

const EpiserverCarouselComponent = ({
  productionLine,
  animation = "slide",
  interval = 5000,
  ...rest
}: IEpiserverCarouselComponentProps) => {
  const dispatch = useDispatch();
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isNextChangeVideoCallback, setIsNextChangeVideoCallback] = useState<
    any
  >(false);

  const { carouselAdItems } = useSelector(
    (state: RootState) => state.publications
  );

  useEffect(() => {
    dispatch(
      publication.getCarouselItemListing({
        langCode: langCode,
        publicationContext: productionLine,
        publicationType: publicationContainerUriMap.Carousel,
      })
    );

    return () => {
      dispatch(carouselAdItemsCleared());
    };
  }, []);

  const customAnimationInterval = (ms: number) => {
    const nextIndex = currentIndex + 1;
    let timer;
    if (
      carouselAdItems[currentIndex]?.mediaType === MediaType.Video ||
      carouselAdItems[currentIndex]?.mediaType === MediaType.Vimeo ||
      carouselAdItems[currentIndex]?.mediaType === MediaType.Youtube ||
      carouselAdItems[currentIndex]?.mediaType === MediaType.Unknown
    ) {
      setIsNextChangeVideoCallback(true);
    } else if (isNextChangeVideoCallback) {
      setIsNextChangeVideoCallback(false);
      timer = setTimeout(() => {
        setCurrentIndex(nextIndex);
      }, ms);
    } else {
      timer = setTimeout(() => {
        setCurrentIndex(nextIndex);
      }, ms);
    }

    return timer;
  };

  const backToTheStart = () => {
    setCurrentIndex(0);
    setIsNextChangeVideoCallback(false);
  };

  useEffect(() => {
    let timer;
    clearTimeout(timer);
    if (currentIndex != undefined && carouselAdItems.length > 0) {
      // reset if we reach the end
      if (currentIndex >= carouselAdItems.length) {
        backToTheStart();
      } else {
        const displayDuration = carouselAdItems[currentIndex]?.displayDuration;
        if (displayDuration) {
          timer = customAnimationInterval(displayDuration);
        } else {
          timer = customAnimationInterval(interval);
        }
      }
    }
    return () => clearTimeout(timer);
  }, [currentIndex, carouselAdItems]);

  const changeIndexWithCheck = (newIndex: number) => {
    if (newIndex < 0) {
      const lastIndex = carouselAdItems.length - 1; // Jump to last index
      setCurrentIndex(lastIndex);
    } else {
      setCurrentIndex(newIndex);
    }
  };

  return (
    <Grid item xs={12}>
      {(carouselAdItems && carouselAdItems.length) > 0 && (
        <CarouselComponent
          autoPlay={false}
          animation={animation}
          interval={interval}
          index={currentIndex}
          next={() => changeIndexWithCheck(currentIndex + 1)}
          prev={() => changeIndexWithCheck(currentIndex - 1)}
          {...rest}>
          {carouselAdItems.map((item, index) => (
            <CarouselMediaItem
              onPlayingEnded={() => changeIndexWithCheck(currentIndex + 1)}
              key={index}
              item={item}
            />
          ))}
        </CarouselComponent>
      )}
    </Grid>
  );
};

export default EpiserverCarouselComponent;
