import React from "react";
import {
  FormControl,
  Input,
  InputProps,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "./InputFieldStyles";
import {
  DatePicker as MuiDatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const getInputJsx = (props: InputFieldProps, classes: any) => {
  const {
    label = "",
    placeholder = "",
    required = false,
    errorText = "",
    disabled = false,
    readonly = false,
    fullWidth = false,
    margin = "5px 10px",
    customStyle = "",
    type,
    selectedDate,
    onDateChange,
    format,
    singleLine,
    ...rest
  } = props;

  if (type === "textfield") {
    const InputProps: InputProps = {
      disableUnderline: true,
      readOnly: readonly,
    };
    const inputProps = {
      classes: [
        {
          root: classes.root,
          input: classes.input,
          inputMultiline: classes.inputMultiline,
        },
      ],
    };
    return (
      <TextField
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        inputProps={inputProps}
        InputProps={InputProps}
        {...rest}
      />
    );
  } else if (type === "date") {
    return (
      <DatePickerInput
        onDateChange={onDateChange}
        selectedDate={selectedDate}
        format={format}
        readOnly={readonly}
        className={classes.input}
        {...rest}
      />
    );
  } else if (type === "date-keyboard") {
    return (
      <DatePickerKeyboardInput
        onDateChange={onDateChange}
        selectedDate={selectedDate}
        format={format}
        readOnly={readonly}
        className={classes.input}
        {...rest}
      />
    );
  } else {
    return (
      <Input
        {...rest}
        readOnly={readonly}
        disabled={disabled}
        required={required}
        disableUnderline={true}
        placeholder={placeholder}
        classes={{
          root: classes.root,
          input: classes.inputInput,
          inputMultiline: classes.inputMultiline,
        }}
      />
    );
  }
};

export interface InputFieldProps {
  label?: string | React.Component;
  placeholder?: string;
  required?: boolean;
  errorText?: string | boolean;
  disabled?: boolean;
  readonly?: boolean;
  fullWidth?: boolean;
  margin?: string;
  customStyle?: string;
  type?: string;
  selectedDate?: any;
  onDateChange?: any;
  format?: string;
  singleLine?: boolean;
  [x: string]: any;
}

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    label = "",
    placeholder = "",
    required = false,
    errorText = "",
    disabled = false,
    readonly = false,
    fullWidth = false,
    margin = "5px 10px",
    customStyle = "",
    type,
    selectedDate,
    onDateChange,
    format,
    singleLine = false,
    ...rest
  } = props;
  const classes = useStyles({
    singleLine: singleLine,
    multiline: rest.multiline,
  });
  return (
    <div
      style={{
        display: "inline-block",
        width: fullWidth ? "100%" : "",
        margin: margin,
      }}
      className={`${customStyle} ${classes.container}`}>
      {label && (
        <div className={classes.labelContainer}>
          <Typography
            display={"inline"}
            variant={"body1"}
            className={classes.label}
            style={{ color: "#535559" }}
            component="span">
            {label}
          </Typography>
          {required && (
            <Typography
              display={"inline"}
              variant={"body1"}
              className={classes.label}
              style={{ color: "#cf2d3a", marginLeft: "4px" }}
              component="span">
              *
            </Typography>
          )}
        </div>
      )}
      <FormControl
        style={{ width: "100%" }}
        fullWidth={true}
        className={`${classes.formControl} ${
          (disabled || readonly) && classes.formControlDisabled
        }`}>
        {getInputJsx(props, classes)}
      </FormControl>
      {errorText && (
        <Alert
          style={{ marginBottom: "5px" }}
          icon={false}
          color={"error"}
          classes={{ standardError: classes.alert }}>
          {errorText}
        </Alert>
      )}
    </div>
  );
};

// search for material-ui-pickers api/DatePicker for API reference
interface IDatePickerProps {
  selectedDate?: Date;
  onDateChange: any;
  format?: string;
  variant?: "dialog" | "inline" | "static";
  readOnly?: boolean;
  [x: string]: any;
}

const DatePickerInput: React.FC<IDatePickerProps> = ({
  selectedDate = new Date(),
  onDateChange,
  format = "DD.MM.YYYY",
  variant = "inline",
  ...rest
}) => {
  return (
    <MuiDatePicker
      value={selectedDate}
      onChange={onDateChange}
      format={format}
      variant={variant}
      {...rest}
    />
  );
};

const DatePickerKeyboardInput: React.FC<IDatePickerProps> = ({
  selectedDate = new Date(),
  onDateChange,
  format = "DD.MM.YYYY",
  variant = "inline",
  readOnly = false,
  ...rest
}) => {
  return (
    <KeyboardDatePicker
      value={selectedDate}
      onChange={onDateChange}
      format={format}
      variant={variant}
      readOnly={readOnly}
      inputProps={{ readOnly: readOnly }}
      {...rest}
    />
  );
};

export default InputField;
