import React from "react";
import { Switch, Route } from "react-router-dom";
import useStyles from "./TestContentStyles";
import ValidationPage from "../ValidationPage/ValidationPage";
import Theme from "../Theme/Theme";
import GuiComponents from "../GuiComponents/GuiComponents";
import Content from "../../shared/components/Content/Content";

const TestContent = () => {
  const classes = useStyles();
  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      <Switch>
        <Route path="/test/components" component={GuiComponents} />
        <Route path="/test/validation" component={ValidationPage} />
        <Route path="/test/theme" component={Theme} />
      </Switch>
    </Content>
  );
};

export default TestContent;
