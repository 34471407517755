import React from "react";
import { Switch, Route } from "react-router-dom";
import Content from "../../shared/components/Content/Content";
import MainPage from "../MainPage/MainPage";

const OtherContent = () => {
  return (
    <Content>
      <Switch>
        <Route path={"/other"} component={MainPage} />
      </Switch>
    </Content>
  );
};

export default OtherContent;
