import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    letterSpacing: "0.8px",
    fontSize: "0.75rem",
    fontWeight: "bold",
    padding: "10px 20px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  okButton: {
    "&:disabled": {
      color: "#fff",
      backgroundColor: theme.palette.primary.main,
      opacity: "0.5",
    },
  },
  addButton: {
    backgroundColor: "#0077C6",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#0058C4",
    },
  },
  tableButton: {
    textTransform: "none",
    padding: "5px 2px",
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  modalButton: {
    padding: "5px 2px",
    color: theme.palette.primary.main,
    fontFamily: "Avenir Next W1G Medium",
    "&:focus": {
      color: theme.palette.primary.main,
    },
  },
  iconClass: {
    display: "inherit",
    marginLeft: "0",
    marginRight: "0",
    fill: "#787878",
  },
  linkButton: {
    "&:hover, &:visited, &:link, &:active": {
      boxShadow: "none",
      textDecoration: "none",
      color: "unset",
    },
  },
  powerBIPageSelect: {
    backgroundColor: "#efefef",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    borderStyle: "solid",
    border: "1px",
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: "#fff",
    },
  },
  powerBIPageSelectClicked: {
    backgroundColor: "#fff",
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
    border: "1px",
    textDecoration: "underline",
    textUnderlineOffset: "0.3em",
    textDecorationColor: theme.palette.primary.main,
    textDecorationThickness: "0.2em",
    "&:hover, &:visited, &:link, &:active": {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
      border: "1px",
      textDecoration: "underline",
      textUnderlineOffset: "0.3em",
      textDecorationColor: theme.palette.primary.main,
      textDecorationThickness: "0.2em",
    },
  },
}));

export default useStyles;
