import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  highlight: {
    fontWeight: "bold",
    color: "red",
    backgroundColor: "white",
    fontSize: "110%",
  },
}));

export default useStyles;
