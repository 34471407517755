import React, { useEffect, Fragment, useState } from "react";
import { Grid, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import useStyles from "./UserManagementPageStyles";
import * as api from "../../shared/store/apiActions";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { t } from "../../shared/services/langService";
import { TableRow, TableCell } from "../../shared/components/Table/Table";
import InputField from "../../shared/components/InputField/InputField";
import PageText from "../../shared/components/PageText/PageText";
import Checkbox from "../../shared/components/Checkbox/Checkbox";
import Button from "../../shared/components/Button/Button";
import GeneralDataList, {
  IHeadCell,
} from "../../shared/components/GeneralDatalist/GeneralDatalist";
import WithTableSorting from "../../shared/hocs/withTableSorting";
import InfoModal, {
  ButtonOption,
} from "../../shared/components/InfoModal/InfoModal";
import DropdownMenu from "../../shared/components/DropdownMenu/DropdownMenu";
import {
  AuthorizationContext,
  IUser,
  IUserRoles,
  IRole,
} from "../shared/models/Users";
import {
  assignableRolesCleared,
  usersCleared,
} from "../../shared/store/management";
import {
  getOrganizationByProducerNumber,
  IOrganization,
} from "../../shared/store/user";
import InfoTooltip from "../../shared/components/InfoTooltip/InfoTooltip";
import { isValidUserRole } from "../../shared/helpers/navHelpers";
import { UserRole } from "../../shared/enums/CoreEnums";
import { RootState } from "../../shared/store/rootReducer";

const GeneralDataListWithSorting = WithTableSorting(GeneralDataList);

interface IUserExtensions {
  isModified: any;
  [x: string]: any; // for roles
}

const UserManagementPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { roles, producers } = useSelector(
    (state: RootState) => state.auth.currentUser
  );
  const { roleMap } = useSelector((state: RootState) => state.user);

  const currentOrganization: IOrganization = useSelector(
    getOrganizationByProducerNumber(producerNumber)
  );

  const { users, assignableRoles } = useSelector(
    (state: RootState) => state.management
  );

  const [modal, setModal] = useState<{
    open: boolean;
    state?: "delete" | "save";
    onSuccess?: Function;
    text?: string;
    secondaryText?: string;
  }>({ open: false });

  const [userRows, setUserRows] = useState<any[]>([]);
  const [selectedContextRows, setSelectedContextRows] = useState<any[]>([]);
  const [userContext, setUserContext] = useState<
    AuthorizationContext | undefined
  >();

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const createUserFormInitialValues = { "user-management-email-input": "" };

  const [filteredDropDownOptions, setFilteredDropDownOptions] = useState<any[]>(
    []
  );

  const confirmModalCommon = {
    cancelButtonText: t("ui_cancel"),
    okButtonText: t("ui_ok"),
  };

  const confirmModal = {
    delete: {
      title: t("userManagement_deleteConfirmationTitle"),
    },
    save: {
      title: t("userManagement_updateConfirmationTitle"),
    },
  };

  const handleModalClose = (
    button: ButtonOption,
    onSuccess: Function = () => {}
  ) => {
    setModal({ open: false });
    if (button === "ok") {
      onSuccess(button);
    }
  };

  const dispatchGetUsers = () => {
    dispatch(api.management.getUsers());
  };

  useEffect(() => {
    if (producerNumber) {
      dispatchGetUsers();
      dispatch(api.management.getAssignableRoles());

      const hasValidRoleToEdit = isValidUserRole(
        [UserRole.MainUser, UserRole.OsuuskuntaUser, UserRole.JanToolMainUser],
        roles,
        producers,
        producerNumber,
        AuthorizationContext.All,
        roleMap
      );
      setIsDisabled(!hasValidRoleToEdit);
    }

    return () => {
      dispatch(usersCleared());
      dispatch(assignableRolesCleared());
    };
  }, []);

  useEffect(() => {
    const userRows = users
      ? users.map((user) => {
          const userRow = {
            id: user?.id,
            emailAddress: user.emailAddress,
            isModified: {},
          };
          const firstOrganization = user.organizationRoles?.length
            ? user.organizationRoles[0]
            : undefined;

          for (let role of firstOrganization?.roles || []) {
            userRow[role.mainRole] = {
              ...userRow[role.mainRole],
              [role.name]: true,
            };
          }
          return userRow;
        })
      : [];

    setUserRows([...userRows]);
  }, [users]);

  useEffect(() => {
    const dropdownOptions: any[] = [
      {
        name: "producer",
        text: t("userManagement_mainUserOption"),
        value: AuthorizationContext.MainUser,
      },
      {
        name: "cooperative",
        text: t("userManagement_osuuskuntaUserOption"),
        value: AuthorizationContext.OsuuskuntaUser,
      },
      {
        name: "jantool",
        text: t("userManagement_janToolMainUserOption"),
        value: AuthorizationContext.JanToolMainUser,
      },
    ];

    if (assignableRoles) {
      const filtered = dropdownOptions.filter((option) => {
        if (assignableRoles.roleGroups[AuthorizationContext[option.value]]) {
          return true;
        }

        return false;
      });

      setFilteredDropDownOptions(filtered);
    }
  }, [assignableRoles]);

  useEffect(() => {
    if (filteredDropDownOptions?.length) {
      setUserContext(filteredDropDownOptions[0].value);
    }
  }, [filteredDropDownOptions]);

  const getHeaderCellInfoJsx = (cell) => (
    <InfoTooltip infoText={t(cell.description)} label={t(cell.label)} />
  );

  const getHeaderCellJsx = (cell) => <Fragment>{cell.label}</Fragment>;

  const headerCells: IHeadCell[] = [
    { id: "", label: "", getCellJsx: getHeaderCellJsx },
    { id: "", label: "", getCellJsx: getHeaderCellJsx },
    { id: "emailAddress", label: t("userManagement_emailAddress") },
    ...(assignableRoles?.roleGroups && userContext
      ? assignableRoles.roleGroups[AuthorizationContext[userContext]]?.map(
          (role: IRole) => ({
            id: role.name,
            label: role.translationKey,
            getCellJsx: getHeaderCellInfoJsx,
            description: role.descriptionKey,
          })
        )
      : []),
    { id: "", label: "", getCellJsx: getHeaderCellJsx },
    { id: "", label: "", getCellJsx: getHeaderCellJsx },
  ];

  const handleDeleteUser = (user: IUser & IUserExtensions) => {
    setModal({
      open: true,
      state: "delete",
      text: t("userManagement_deleteConfirmationDescription", {
        0: user.emailAddress,
      }),
      onSuccess: async () => {
        if (userContext && user.id) {
          await dispatch(api.management.deleteUser(userContext, user.id));
          setUserRows((prevState) =>
            prevState.filter((userRow) => userRow.id !== user.id)
          );
        }
      },
    });
  };

  const getModalTexts = (user: IUser & IUserExtensions) => {
    let modalPrimaryText = t("userManagement_updateConfirmationDescription", {
      0: user.emailAddress,
    });

    let modalSecondaryText: string | undefined = undefined;

    if (
      userContext &&
      user[AuthorizationContext[AuthorizationContext.MainUser]]
        ?.SecondaryMainUser
    ) {
      modalPrimaryText = t(
        "userManagement_updateConfirmationSecondaryMainUserPrim",
        {
          0: user.emailAddress,
        }
      );

      modalSecondaryText = t(
        "userManagement_updateConfirmationSecondaryMainUserSec",
        {
          0: user.emailAddress,
        }
      );
    }

    return { primaryText: modalPrimaryText, secondaryText: modalSecondaryText };
  };

  const handleSaveUserRoles = (user: IUser & IUserExtensions) => {
    const modalTexts = getModalTexts(user);

    setModal({
      open: true,
      state: "save",
      text: modalTexts.primaryText,
      secondaryText: modalTexts.secondaryText,
      onSuccess: async () => {
        if (userContext && user.id) {
          const contextRoles =
            assignableRoles?.roleGroups[AuthorizationContext[userContext]];
          const userRoles: IUserRoles = {
            roles: contextRoles.map((role) => ({
              id: role.id,
              name: role.name,
              application: role.application,
              isEnabled:
                user[AuthorizationContext[userContext]][role.name] || false,
            })),
          };
          await dispatch(
            api.management.updateUser(userContext, user.id, userRoles)
          );
          setUserRows((prevState) => {
            const newState = [...prevState];
            const index = newState.findIndex(
              (userRow) => userRow.id === user.id
            );
            if (index > -1) {
              newState[index] = {
                ...newState[index],
              };
              delete newState[index].isModified[userContext];
            }
            return newState;
          });
        }
      },
    });
  };

  const getCreateUserFormJsx = ({
    errors,
    touched,
    handleSubmit,
    values,
    ...rest
  }) => (
    <Fragment>
      <Grid item xs={12} md={3}>
        <PageText type={"bold"} required>
          {t("userManagement_emailAddress")}
        </PageText>
        <InputField
          id="user-management-email-input"
          margin={""}
          value={values["user-management-email-input"]}
          required
          onChange={(e) => {
            rest.resetForm();
            rest.handleChange(e);
          }}
          onBlur={() => {
            values["user-management-email-input"] = values[
              "user-management-email-input"
            ].trim();
          }}
          errorText={errors["user-management-email-input"]}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} style={{ padding: "0" }} />

      <Grid item xs={12} md={2}>
        <Button
          id="user-management-add-button"
          disabled={isDisabled}
          onClick={handleSubmit}
          type={"add"}
          fullWidth>
          {t("userManagement_addButtonText")}
        </Button>
      </Grid>
    </Fragment>
  );

  const getButtonCellsJsx = (
    user: IUser & IUserExtensions,
    disableSaveButton: boolean,
    disabled: boolean = isDisabled
  ) => {
    const style = { width: "100px", maxWidth: "100px" };
    return (
      <Fragment>
        <TableCell style={style}>
          <Button
            id={"user-management-saveuser-button"}
            onClick={() => handleSaveUserRoles(user)}
            type="add"
            fn={"action"}
            disabled={!disabled ? disableSaveButton : disabled}>
            {t("ui_save")}
          </Button>
        </TableCell>
        <TableCell style={style}>
          <Button
            id={"user-management-removeuser-button"}
            onClick={() => handleDeleteUser(user)}
            type="table"
            fn={"remove"}
            disabled={disabled}>
            {t("ui_remove")}
          </Button>
        </TableCell>
      </Fragment>
    );
  };

  const getUserRowJsx = (
    userRow: IUser & IUserExtensions,
    index: number,
    disabled: boolean = isDisabled
  ) => (
    <TableRow key={index}>
      <Hidden smUp>
        {getButtonCellsJsx(userRow, !userRow.isModified[userContext || ""])}
      </Hidden>
      <TableCell style={{ minWidth: "50px", width: "250px" }}>
        {userRow.emailAddress}
      </TableCell>
      {(assignableRoles?.roleGroups && userContext
        ? assignableRoles.roleGroups[AuthorizationContext[userContext]]
        : []
      )?.map((role: any, index: number) => (
        <TableCell key={index}>
          <Checkbox
            id={`user-management-role-${index}-${role.name}`}
            checked={
              userContext &&
              userRow[AuthorizationContext[userContext]] &&
              userRow[AuthorizationContext[userContext]][role.name]
                ? userRow[AuthorizationContext[userContext]][role.name]
                : false
            }
            disabled={disabled}
            onChange={(value) => {
              setUserRows((prevState) => {
                const newState = [...prevState];
                const index = newState.findIndex(
                  (user) => user.id === userRow.id
                );
                if (index > -1 && userContext) {
                  newState[index] = {
                    ...newState[index],
                    [AuthorizationContext[userContext]]: {
                      ...newState[index][AuthorizationContext[userContext]],
                      [role.name]: value,
                    },
                  };
                  newState[index].isModified[userContext || ""] = true;
                }
                return newState;
              });
            }}
          />
        </TableCell>
      ))}
      <Hidden xsDown>
        {getButtonCellsJsx(userRow, !userRow.isModified[userContext || ""])}
      </Hidden>
    </TableRow>
  );

  return (
    <Fragment>
      {modal.state && (
        <InfoModal
          open={modal.open}
          text={modal.text}
          secondaryText={modal.secondaryText}
          onClose={(button) => handleModalClose(button, modal.onSuccess)}
          {...confirmModal[modal.state]}
          {...confirmModalCommon}
        />
      )}

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader
            level="1"
            component={"span"}
            style={{ display: "inline-block", marginRight: "10px" }}>
            {t("Käyttäjähallinta")}
          </PageHeader>
          <PageHeader
            level="2"
            component={"span"}
            style={{ whiteSpace: "nowrap" }}>
            ({currentOrganization?.name || ""})
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <PageHeader level="2">{t("userManagement_addUserHeader")}</PageHeader>
        </Grid>

        <Formik
          initialValues={createUserFormInitialValues}
          validationSchema={Yup.object().shape({
            "user-management-email-input": Yup.string()
              .email(t("ui_invalidEmailField"))
              .required(t("ui_requiredField")),
          })}
          onSubmit={async (values, { resetForm }) => {
            await dispatch(
              api.management.addUser({
                emailAddress: values["user-management-email-input"],
              })
            );
            resetForm({
              values: createUserFormInitialValues,
            });
            dispatchGetUsers();
          }}
          validateOnChange={false}
          children={getCreateUserFormJsx}
        />

        <Grid item xs={12} />

        <Grid item xs={12}>
          <PageHeader level="2">
            {t("userManagement_modifyUserHeader")}
          </PageHeader>
        </Grid>

        {userContext && filteredDropDownOptions.length > 1 && (
          <Grid item md={2} xs={12}>
            <DropdownMenu
              id="user-management-usercontext-select"
              defaultValue={userContext}
              useCaret={false}
              label={t("userManagement_userContextSelect")}
              onChange={(value) =>
                setUserContext(Number(value) as AuthorizationContext)
              }
              labelStyle={{ whiteSpace: "nowrap" }}
              options={filteredDropDownOptions}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <GeneralDataListWithSorting
            headerCells={headerCells.slice(
              isXsDown ? 0 : 2,
              headerCells.length - (isXsDown ? 2 : 0)
            )}
            rows={userRows}
            getRowJsx={getUserRowJsx}
            isLoading={false}
            order="desc"
            orderBy="emailAddress"
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserManagementPage;
