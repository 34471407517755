import TagManager from "react-gtm-module";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/sv";

const configureMoment = (defaultLocale: string) => {
  // fi

  moment.locale("fi");
  let momentConf = moment().localeData();

  let conf: moment.LocaleSpecification = {
    invalidDate: "Päivä ei kelpaa",
    weekdaysShort: momentConf
      .weekdaysShort()
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1)),
    weekdaysMin: momentConf
      .weekdaysMin()
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1)),
  };

  moment.updateLocale("fi", conf);

  // sv

  moment.locale("sv");
  momentConf = moment().localeData();

  conf = {
    weekdaysShort: momentConf
      .weekdaysShort()
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1)),
    weekdaysMin: momentConf
      .weekdaysMin()
      .map((v) => v.charAt(0).toUpperCase() + v.slice(1)),
  };

  moment.updateLocale("sv", conf);

  //

  moment.locale(defaultLocale);
};

const configureTagManager = (gtmId: string) => {
  const tagManagerArgs = {
    gtmId: gtmId,
  };

  TagManager.initialize(tagManagerArgs);
};

export default {
  configureMoment,
  configureTagManager,
};
