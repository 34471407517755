export class RejectionCauseDescription {
  reason: string = "";
  carcassPart: string = "";
}

export class CarcassRejectionCauseItem {
  details: Map<string, RejectionCauseDescription> = new Map<
    string,
    RejectionCauseDescription
  >();
  amountKg?: number = 0;
  isSerious: boolean = false;
}

export class CarcassRejectionCauses {
  carcassNumber: number = 0;
  rejectionCauses: CarcassRejectionCauseItem[] = [];
}

export class ReportRowBaseType {
  animalType: number = 0;
  slaughterDate: string = "";
  carcassNumber: number = 0;
  earNumber: string = "";
  carcassConformationClass: string = "";
  slaughterWeight?: number = 0;
  numRejectedItems: number = 0;
  paymentToProducer?: number = 0;
  dailyGrowth?: number = 0;
  paymentDateExists?: boolean;
  rejectionCauses: CarcassRejectionCauses = new CarcassRejectionCauses();
}

export class RejectionCausesDistributionItem {
  details: Map<string, RejectionCauseDescription> = new Map<
    string,
    RejectionCauseDescription
  >();
  count: number = 0;
  percentage?: number = 0;
  isSerious: boolean = false;
}

export class RejectionCausesDistribution {
  items: RejectionCausesDistributionItem[] = [];
}

export class SlaughterReportBaseType {
  contractNumber: number = 0;
  slaughterhouse: string = "";
  transportDate: string = "";
  animalType: number = 0;
  rowCount: number = 0;
  paymentDateExists?: boolean;
  rejectionCausesDistribution: RejectionCausesDistribution = new RejectionCausesDistribution();
}

export default class SlaughterReportListingDTO {
  reports: any[] = [];
}
