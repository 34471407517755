import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import useStyles from "./CurrentUserPageStyles";
import { t } from "../../shared/services/langService";
import ReadOnlyTextField from "../../shared/components/ReadOnlyTextField/ReadOnlyTextField";
import { RootState } from "../../shared/store/rootReducer";

const CurrentUserPage = () => {
  const classes = useStyles();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <PageHeader level="1">{t("currentUser_pageHeader")}</PageHeader>
      </Grid>

      <Grid item xs={12}>
        <PageHeader level="2">{t("currentUser_personInfoHeader")}</PageHeader>
      </Grid>

      <Grid item xs={12}>
        <ReadOnlyTextField
          label={t("currentUser_firstname")}
          text={currentUser.firstname}
        />
      </Grid>

      <Grid item xs={12}>
        <ReadOnlyTextField
          label={t("currentUser_lastname")}
          text={currentUser.lastname}
        />
      </Grid>

      <Grid item xs={12}>
        <ReadOnlyTextField
          label={t("currentUser_emailAddress")}
          text={currentUser.emailAddress}
        />
      </Grid>
    </Grid>
  );
};

export default CurrentUserPage;
