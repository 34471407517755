import { createSlice } from "@reduxjs/toolkit";
import { OperationUnit } from "../../enums/CoreEnums";
import ProductionArea from "../../enums/productionLines";

export interface ISettings {
  productionLine: ProductionArea;
  producerNumber?: number;
  operationUnit?: OperationUnit;
  allowAllCookies: boolean;
}

const initialState: ISettings = {
  productionLine: ProductionArea.None,
  operationUnit: undefined,
  allowAllCookies: false,
};

const slice = createSlice({
  name: "userInterface/settings",
  initialState: initialState,
  reducers: {
    productionLineUpdated: (state, action) => {
      state.productionLine = action.payload.productionLine;
    },
    producerNumberUpdated: (state, action) => {
      state.producerNumber = action.payload.producerNumber;
    },
    operationUnitUpdated: (state, action) => {
      state.operationUnit = action.payload.operationUnit;
    },
    organizationsUpdated: (state, action) => {
      const organizations = action.payload.data;

      const organization = organizations.find(
        (o) => o.producerNumber === state.producerNumber
      );
      if (organization) {
        state.operationUnit = organization.operationUnit;
      }
    },
    cookieUsageUpdated: (state, action) => {
      state.allowAllCookies = action.payload;
    },
  },
});

export const {
  productionLineUpdated,
  producerNumberUpdated,
  operationUnitUpdated,
  organizationsUpdated,
  cookieUsageUpdated,
} = slice.actions;

export default slice.reducer;
