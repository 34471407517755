import React, { useEffect, Fragment } from "react";
import { Grid, List, ListItem } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import useStyles from "./CurrentFarmPageStyles";
import * as api from "../../shared/store/apiActions";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { t } from "../../shared/services/langService";
import ReadOnlyTextField from "../../shared/components/ReadOnlyTextField/ReadOnlyTextField";
import ProductionArea from "../../shared/enums/productionLines";
import { RootState } from "../../shared/store/rootReducer";

const CurrentFarmPageCooperative = () => {
  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const dispatch = useDispatch();
  const { producerNumber, productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );

  useEffect(() => {
    const filter =
      currentUser.authenticationSource === "HKScanAzureAD"
        ? producerNumber
        : "";
    dispatch(
      filter
        ? api.user.getUserFilteredOrganizations(
            currentUser.authenticationSource,
            filter?.toString()
          )
        : api.user.getUserOrganizations()
    );
  }, []);

  const currentOrganization = organizations.find(
    (o) => o.producerNumber === Number(producerNumber)
  );
  let productionLinesNames: string[] = [];

  if (currentOrganization) {
    for (let key in currentOrganization.productionLines) {
      if (key !== ProductionArea.Cooperative) {
        productionLinesNames.push(
          ...currentOrganization.productionLines[key].map((p) => {
            return t(`productionLine_${p}`);
          })
        );
      }
    }
  }

  const classes = useStyles();
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <PageHeader level="1">{t("options_currentFarm")}</PageHeader>
      </Grid>

      <Grid container item xs={12} md={6}>
        <Grid item xs={12}>
          <PageHeader level="2">{t("currentFarm_basicInfo")}</PageHeader>
        </Grid>

        <Grid item xs={12}>
          <ReadOnlyTextField
            label={t("currentFarm_producerNumber")}
            text={
              currentOrganization
                ? currentOrganization.producerNumber.toString()
                : ""
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ReadOnlyTextField
            label={t("currentFarm_businessId")}
            text={currentOrganization ? currentOrganization.businessId : ""}
          />
        </Grid>

        <Grid item xs={12}>
          <ReadOnlyTextField
            label={t("currentFarm_companyName")}
            text={currentOrganization ? currentOrganization.name : ""}
          />
        </Grid>

        <Grid item xs={12} style={{ paddingBottom: "40px" }}>
          <ReadOnlyTextField
            label={t("currentFarm_productionLines")}
            text={
              productionLinesNames.length > 0
                ? productionLinesNames.sort().join(", ")
                : ""
            }
          />
        </Grid>

        {currentOrganization?.livestockVenues && (
          <Grid item xs={12}>
            <PageHeader level="2">
              {t("currentFarm_livestockVenues")}
            </PageHeader>
          </Grid>
        )}

        {currentOrganization?.livestockVenues?.map((lv, index) => (
          <Grid container item xs={12} md={6}>
            <Fragment key={index}>
              <Grid item xs={12}>
                <ReadOnlyTextField
                  label={t("currentFarm_farmName")}
                  text={lv.farmName}
                />
              </Grid>
              <Grid item xs={12}>
                <ReadOnlyTextField
                  label={t("currentFarm_farmNumber")}
                  text={lv.farmNumber}
                />
              </Grid>
              {lv.markingNumber && (
                <Grid item xs={12}>
                  <ReadOnlyTextField
                    label={t("animalNotification_markingNumber")}
                    text={lv.markingNumber}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <ReadOnlyTextField
                  label={t("currentFarm_unitNumber")}
                  text={lv.unitNumber}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "40px" }}>
                {lv.departments && lv.departments.length > 0 && (
                  <ReadOnlyTextField
                    label={t("currentFarm_departments")}
                    text={[...lv.departments].sort().join(", ")}
                  />
                )}
              </Grid>
            </Fragment>
          </Grid>
        ))}
      </Grid>

      <Grid container item xs={12} md={6}>
        {currentOrganization?.isMember &&
          productionLine === ProductionArea.Cooperative && (
            <Fragment>
              <Grid item xs={12}>
                <PageHeader level="2">
                  {t("currentFarm_cooperative_membership_title")}
                </PageHeader>
              </Grid>

              <Grid item xs={12}>
                <ReadOnlyTextField
                  label={t("currentFarm_cooperative_memberNumber")}
                  text={producerNumber?.toString() ?? ""}
                />
              </Grid>

              <Grid item xs={12}>
                <ReadOnlyTextField
                  label={t("currentFarm_cooperative_namedOsuuskuntaUser")}
                  text={
                    currentOrganization.osuuskuntaUsers ? (
                      <List dense={true}>
                        {currentOrganization.osuuskuntaUsers.map((user) => (
                          <ListItem>{user.emailAddress}</ListItem>
                        ))}
                      </List>
                    ) : (
                      ""
                    )
                  }
                />
              </Grid>
            </Fragment>
          )}
      </Grid>
    </Grid>
  );
};

export default CurrentFarmPageCooperative;
