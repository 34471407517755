import {
  PublicClientApplication,
  Configuration,
  RedirectRequest,
  SilentRequest,
  InteractionRequiredAuthError,
  BrowserAuthError,
  /* Logger,
  LogLevel */
} from "@azure/msal-browser";

import UserAccount from "../models/UserAccount";

const {
  REACT_APP_CLIENT_ID,
  REACT_APP_SIGN_IN_SIGN_UP_POLICY,
  REACT_APP_FORGOT_PASSWORD_POLICY,
  REACT_APP_EDIT_PROFILE_POLICY,
  REACT_APP_CHANGE_PASSWORD_POLICY,
  REACT_APP_APPLICATION_ID,
} = process.env;

const b2cAuthParams: RedirectRequest = {
  scopes: [
    `https://hkscanb2c.onmicrosoft.com/${REACT_APP_CLIENT_ID}/ProducerPortal`,
  ],
  extraQueryParameters: {
    appId: `${REACT_APP_APPLICATION_ID}`,
  },
};

interface IRequiredPolicies {
  signUpSignIn: string;
  forgotPassword: string;
  editProfile: string;
  changePassword: string;
}

interface IRequiredAuthorities {
  signUpSignIn: string;
  forgotPassword: string;
  editProfile: string;
  changePassword: string;
}

interface IB2cPolicy {
  names: IRequiredPolicies;
  authorities: IRequiredAuthorities;
  authorityDomain: string;
}

export const b2cPolicies: IB2cPolicy = {
  names: {
    signUpSignIn: <string>REACT_APP_SIGN_IN_SIGN_UP_POLICY,
    forgotPassword: <string>REACT_APP_FORGOT_PASSWORD_POLICY,
    editProfile: <string>REACT_APP_EDIT_PROFILE_POLICY,
    changePassword: <string>REACT_APP_CHANGE_PASSWORD_POLICY,
  },
  authorities: {
    signUpSignIn: `https://hkscanb2c.b2clogin.com/hkscanb2c.onmicrosoft.com/${REACT_APP_SIGN_IN_SIGN_UP_POLICY}`,
    forgotPassword: `https://hkscanb2c.b2clogin.com/hkscanb2c.onmicrosoft.com/${REACT_APP_FORGOT_PASSWORD_POLICY}`,
    editProfile: `https://hkscanb2c.b2clogin.com/hkscanb2c.onmicrosoft.com/${REACT_APP_EDIT_PROFILE_POLICY}`,
    changePassword: `https://hkscanb2c.b2clogin.com/hkscanb2c.onmicrosoft.com/${REACT_APP_CHANGE_PASSWORD_POLICY}`,
  },
  authorityDomain: "hkscanb2c.b2clogin.com",
};

const msalConfig: Configuration = {
  auth: {
    clientId: <string>REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.signUpSignIn,
    knownAuthorities: [b2cPolicies.authorityDomain],
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  /*  system: {
     tokenRenewalOffsetSeconds: 300,
    logger: new Logger((level, message) => {
      switch (level) {
        case LogLevel.Error:
          console.error(message);
          break;
        case LogLevel.Warning:
          console.warn(message);
          break;
        case LogLevel.Info:
        case LogLevel.Verbose:
        default:
          console.log(message);
          break;
      }
    }, { level: LogLevel.Verbose })
  } */
};

const msalResetPasswordConfig: Configuration = {
  auth: {
    clientId: <string>REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.forgotPassword,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalAuth = new PublicClientApplication(msalConfig);

const getMsalAccount = (homeAccountId: string = "") => {
  if (homeAccountId) {
    const account = msalAuth.getAccountByHomeId(homeAccountId);
    return account;
  }

  const accounts = msalAuth.getAllAccounts();
  if (accounts.length > 0) return accounts[0];
  return;
};

const loginRedirect = () => {
  msalAuth.loginRedirect(b2cAuthParams);
};

const getAccessTokenResponse = async (account: any) => {
  const msalAccount = getMsalAccount(account?.homeAccountId) || account;

  const tokenRequest: SilentRequest = {
    scopes: [
      `https://hkscanb2c.onmicrosoft.com/${REACT_APP_CLIENT_ID}/ProducerPortal`,
    ],
    account: msalAccount as any,
  };

  try {
    const resp: any = await msalAuth.acquireTokenSilent(tokenRequest);

    return {
      accessToken: resp.accessToken,
      userAccount: new UserAccount(resp),
    };
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      await msalAuth.acquireTokenRedirect(tokenRequest);
    } else if (err instanceof BrowserAuthError) {
      await msalAuth.acquireTokenRedirect(tokenRequest);
    }

    throw err;
  }
};

const logout = () => {
  msalAuth.logout();
};

const changePassword = () => {
  msalAuth.loginRedirect({
    authority: b2cPolicies.authorities.changePassword,
  } as RedirectRequest);
};

const forgotPassword = () => {
  msalAuth.loginRedirect({
    authority: b2cPolicies.authorities.forgotPassword,
  } as RedirectRequest);
};

const editProfile = () => {
  msalAuth.loginRedirect({
    authority: b2cPolicies.authorities.editProfile,
  } as RedirectRequest);
};

const handleRedirectCallback = (onSuccess, onError) => {
  msalAuth.handleRedirectPromise().then(onSuccess).catch(onError);
};

const getAccount = (resp): UserAccount => {
  return resp ? new UserAccount(resp) : <UserAccount>{};
};

export default {
  loginRedirect,
  logout,
  handleRedirectCallback,
  getAccount,
  getMsalAccount,
  getAccessTokenResponse,
  forgotPassword,
  editProfile,
  changePassword,
};
