import { Grid, Hidden } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import FrontpagePublicationListing from "../../general/components/FrontpagePublicationListing/FrontpagePublicationListing";
import WelcomePage from "../../general/WelcomePage/WelcomePage";

import ContentBox from "../../shared/components/ContentBox/ContentBox";
import { t } from "../../shared/services/langService";
import InterestPaymentGrid from "../shared/components/InterestPaymentGrid/InterestPaymentGrid";
import DebtInvoiceGrid from "../shared/components/DebtInvoiceGrid/DebtInvoiceGrid";




import { cooperative } from "../../shared/store/apiActions";
import ProductionArea from "../../shared/enums/productionLines";
import EpiserverCarouselComponent from "../../general/components/EpiserverCarouselComponent/EpiserverCarouselComponent";
import { RootState } from "../../shared/store/rootReducer";

const MainPage = () => {
  const dispatch = useDispatch();
  const { interestPayments, debtInvoices } = useSelector(
    (state: RootState) => state.cooperative
  );

  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const getAdCauroselJsx = () => (
    <EpiserverCarouselComponent productionLine={productionLine} />
  );

  useEffect(() => {
    dispatch(cooperative.getMainPageDebtInvoices());
    dispatch(cooperative.getMainPageInterestPayments());
  }, []);

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid container spacing={2} item xs={12} sm={6} md={4} lg={4}>
          <Grid item xs={12}>
            <WelcomePage productionLine={ProductionArea.Cooperative} />
          </Grid>
          <Grid item xs={12}>
            {getAdCauroselJsx()}
          </Grid>
          <Hidden mdUp xsDown>
            <Grid style={{ paddingLeft: "15px", paddingTop: "5px" }}>
              <Grid item style={{ paddingBottom: "30px" }}>
                <FrontpagePublicationListing
                  publicationType={"news"}
                  limit={5}
                />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={12}>
              <ContentBox
                isExpanded={true}
                title={t("cooperative_frontpagePayments_header")}>
                <InterestPaymentGrid
                  isFrontPage={true}
                  rows={interestPayments ?? []}
                />
              </ContentBox>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <ContentBox
                isExpanded={true}
                title={t("cooperative_frontpageInvoices_header")}>
                <DebtInvoiceGrid isFrontPage={true} rows={debtInvoices ?? []} />
              </ContentBox>
            </Grid>
          </Grid>
        </Grid>
        <Hidden only={["sm"]}>
          <Grid
            item
            md={4}
            lg={4}
            style={{ paddingLeft: "15px", paddingTop: "10px" }}>
            <Grid item style={{ paddingBottom: "30px" }}>
              <FrontpagePublicationListing publicationType={"news"} limit={5} />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </Fragment>
  );
};

export default MainPage;
