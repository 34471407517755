import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import { rootReducer } from "./rootReducer";
import template from "./middleware/template";
import api from "./middleware/api";

const config: any = {
  key: "root",
  storage: storage,
  whitelist: ["auth"],
};

export const stateReseted = () => ({ type: "StateReseted" });

export default () => {
  const persistRootReducer: any = persistReducer(config, rootReducer);

  const store = configureStore({
    reducer: persistRootReducer,
    middleware: [
      ...getDefaultMiddleware({ serializableCheck: false }),
      template,
      api,
    ],
  });

  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};
