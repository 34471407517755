import { Grid } from "@material-ui/core";
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { AuthorizationContext } from "../../management/shared/models/Users";
import Button from "../../shared/components/Button/Button";
import InputField from "../../shared/components/InputField/InputField";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import ProductionArea from "../../shared/enums/productionLines";
import { t } from "../../shared/services/langService";
import { user } from "../../shared/store/apiActions";
import { RootState } from "../../shared/store/rootReducer";
import {
  getOrganizationByProducerNumber,
  IOrganization,
} from "../../shared/store/user";
import useStyles from "./UserFeedbackPageStyles";

interface IUserFeedbackFormValues {
  subject: string;
  content: string;
  phoneNumber: string;
}

interface IInfoMessage {
  message: string;
  error: boolean;
}

export interface IFeedbackDto extends IUserFeedbackFormValues {
  authContext: AuthorizationContext;
}

const UserFeedbackPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { productionLine, producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const currentOrganization: IOrganization = useSelector(
    getOrganizationByProducerNumber(producerNumber, productionLine)
  );
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const [authContext, setAuthContext] = useState<AuthorizationContext>();

  const initialValues: IUserFeedbackFormValues = {
    subject: "",
    content: "",
    phoneNumber: "",
  };

  // Update translations
  const validationSchema = Yup.object({
    subject: Yup.string().required(t("ui_requiredField")),
    content: Yup.string()
      .min(8, `${t("validation_min_lenght")}: 8`)
      .required(t("ui_requiredField")),
    phoneNumber: Yup.string().min(7, `${t("validation_min_lenght")}: 7`),
  });

  useEffect(() => {
    if (productionLine === ProductionArea.Cooperative) {
      setAuthContext(AuthorizationContext.OsuuskuntaUser);
    } else {
      setAuthContext(AuthorizationContext.MainUser);
    }
  }, [productionLine]);

  const onSubmitFeedback = (values) => {
    const dto: IFeedbackDto = values;

    if (authContext) {
      dto.authContext = authContext;
    }

    dispatch(user.postUserFeedback(dto));
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmitFeedback,
    validationSchema: validationSchema,
  });

  const isRequired = (field) => {
    return validationSchema.fields[field]._exclusive.required || false;
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader level="1">{t("general_userFeedbackPage_title")}</PageHeader>
      </Grid>

      <Grid container spacing={1} style={{ paddingTop: "20px" }}>
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid item lg={4} md={6} xs={12}>
              <InputField
                id="subject"
                name="subject"
                label={t("feedback_form_subject")}
                disabled={false}
                value={formik.values.subject}
                onChange={formik.handleChange}
                error={formik.touched.subject && Boolean(formik.errors.subject)}
                errorText={formik.touched.subject && formik.errors.subject}
                fullWidth={true}
                required={isRequired("subject")}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <InputField
                id="content"
                name="content"
                label={t("feedback_form_content")}
                disabled={false}
                multiline={true}
                rows={4}
                value={formik.values.content}
                onChange={formik.handleChange}
                error={formik.touched.content && Boolean(formik.errors.content)}
                errorText={formik.touched.content && formik.errors.content}
                fullWidth={true}
                required={isRequired("content")}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <InputField
                id="phoneNumber"
                name="phoneNumber"
                label={t("feedback_form_phoneNumber")}
                disabled={false}
                multiline={false}
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                errorText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                fullWidth={true}
                required={isRequired("phoneNumber")}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <InputField
                id="organizationName"
                name="organizationName"
                label={t("feedback_form_organizationName")}
                disabled={false}
                readonly={true}
                fullWidth={true}
                value={currentOrganization.name}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <InputField
                id="businessId"
                name="businessId"
                label={t("feedback_form_businessId")}
                disabled={false}
                readonly={true}
                fullWidth={true}
                value={currentOrganization.businessId}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <InputField
                id="feedbackSender"
                name="feedbackSender"
                label={t("feedback_form_feedbackSender")}
                disabled={false}
                readonly={true}
                fullWidth={true}
                value={`${currentUser.firstname} ${currentUser.lastname}`}
              />
            </Grid>
            <Grid item lg={4} md={6} xs={12}>
              <Button type="submit">{t("feedback_form_submit")}</Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default UserFeedbackPage;
