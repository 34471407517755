import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { TimeframePicker } from "../../shared/components/TimeframePicker/TimeframePicker";
import withContentBox from "../../shared/hocs/withContentBox";
import withTablePagination from "../../shared/hocs/withTablePagination";
import withTableSorting from "../../shared/hocs/withTableSorting";
import { t } from "../../shared/services/langService";
import { cooperative } from "../../shared/store/apiActions";
import DebtInvoiceGrid from "../shared/components/DebtInvoiceGrid/DebtInvoiceGrid";
import { stateReseted } from "../../shared/store/createStore";
import { RootState } from "../../shared/store/rootReducer";

const DebtInvoicesGridWithSortingPaginationContentBox = withTableSorting(
  withTablePagination(withContentBox(DebtInvoiceGrid))
);

interface IProps {
  [x: string]: any;
}
const DebtInvoicesPage: React.FC<IProps> = ({
  currentStartDate,
  currentEndDate,
  fetchCounter,
  startTimeOnChange,
  endTimeOnChange,
  onFetchClick,
  errorMsg,
}) => {
  const dispatch = useDispatch();
  const { debtInvoices } = useSelector((state: RootState) => state.cooperative);
  const [paginationResetCounter, setResetCounter] = useState<any>(1);

  const dispatchInvoicesFetch = () => {
    if (!errorMsg) {
      dispatch(cooperative.getDebtInvoices(currentStartDate, currentEndDate));
    }
  };

  useEffect(() => {
    dispatchInvoicesFetch();
    setResetCounter(paginationResetCounter + 1);
  }, [fetchCounter]);

  useEffect(() => {
    return () => {
      dispatch(stateReseted());
    };
  }, []);

  return (
    <Fragment>
      <Grid item xs={12}>
        <PageHeader level="1"> {t("cooperative_debtInvoices")}</PageHeader>
      </Grid>
      <Grid container spacing={1}>
        <TimeframePicker
          selectedStartDate={currentStartDate}
          selectedEndDate={currentEndDate}
          startDateOnChange={startTimeOnChange}
          endDateOnChange={endTimeOnChange}
          onClick={onFetchClick}
          errorMsg={errorMsg}
        />
      </Grid>
      <Grid>
        <DebtInvoicesGridWithSortingPaginationContentBox
          title={t("cooperative_allInvoices")}
          isFrontPage={false}
          isExpandable={false}
          rows={debtInvoices ?? []}
          order="desc"
          orderBy="voucherDate"
          forceResetPagination={paginationResetCounter}
        />
      </Grid>
    </Fragment>
  );
};

export default DebtInvoicesPage;
