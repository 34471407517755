import React, { Fragment } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useStyles from "./InfoModalStyles";
import PageHeader from "../PageHeader/PageHeader";
import PageText from "../PageText/PageText";
import Button from "../Button/Button";

export type ButtonOption = "ok" | "cancel" | "";

export interface IInfoModalProps {
  open: boolean;
  title: string;
  text: string | any;
  okButtonText: string;
  cancelButtonText?: string;
  onClose: (button: ButtonOption) => void;
  secondaryText?: string;
}

const InfoModal: React.FC<IInfoModalProps> = ({
  open,
  title,
  text,
  okButtonText,
  cancelButtonText = "",
  onClose,
  secondaryText,
}) => {
  const classes = useStyles();

  const handleClose = (button: ButtonOption) => {
    onClose(button);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
      open={open}
      maxWidth={"md"}
      onClose={() => handleClose("")}
      aria-labelledby="info-modal-title"
      aria-describedby="info-modal-description">
      <DialogTitle id="info-modal-title">
        <PageHeader level="2" component="div">
          {title}
        </PageHeader>
      </DialogTitle>
      <DialogContent id="info-modal-content">
        <DialogContentText id="info-modal-description" component="div">
          <PageText
            type="normal"
            component="span"
            style={{ whiteSpace: "pre-line" }}>
            {text}
          </PageText>
          {secondaryText && (
            <Fragment>
              <br />
              <PageText
                type="normal"
                component="span"
                style={{ whiteSpace: "pre-line" }}>
                {secondaryText}
              </PageText>
            </Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelButtonText && (
          <Button
            id="info-modal-cancel-button"
            onClick={() => handleClose("cancel")}
            type={"modal"}
            autoFocus>
            {cancelButtonText}
          </Button>
        )}
        <Button
          id="info-modal-ok-button"
          onClick={() => handleClose("ok")}
          type={"modal"}>
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
