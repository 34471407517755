import {createSlice} from "@reduxjs/toolkit";

interface II8n {
    langCode: string,
    translations?: any,
    supportedLanguages: any
};

const initialState: II8n = {
    langCode: "",
    translations: {},
    supportedLanguages: []
};

const slice = createSlice({
    name: "userInterface/iI8n",
    initialState: initialState,
    reducers: {
        langCodeUpdated: (state, action) => {
            state.langCode = action.payload.langCode;
        },
        translationReceived: (state, action) => {
            state.supportedLanguages = Object.keys(action.payload.data);
            state.translations = action.payload.data; 
        }
    }
});

export const {
    langCodeUpdated, 
    translationReceived
} = slice.actions;

export default slice.reducer;