import { Grid, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import PageHeader from "../../../../shared/components/PageHeader/PageHeader";
import { t } from "../../../../shared/services/langService";
import { RootState } from "../../../../shared/store/rootReducer";
import { IOrganization } from "../../../../shared/store/user";

const CooperativeInfo = () => {
  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const currentOrganization: IOrganization | undefined = organizations.find(
    (o) => Number(o.producerNumber) === Number(producerNumber)
  );

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PageHeader level="2">{t("cooperative_memberNumber")}</PageHeader>
          <Typography variant={"h6"} color={"primary"}>
            {producerNumber}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PageHeader level="2">{t("cooperative_memberName")}</PageHeader>
          <Typography variant={"h6"} color={"primary"}>
            {currentOrganization ? currentOrganization.name : ""}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PageHeader level="2">
            {t("cooperative_organization_businessId")}
          </PageHeader>
          <Typography variant={"h6"} color={"primary"}>
            {currentOrganization ? currentOrganization.businessId : ""}
          </Typography>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CooperativeInfo;
