import moment from "moment";

let today = moment().format("YYYY-MM-DDT00:00:00");

var isDecimalString = new RegExp(/^\d[\d]*[.,]?[\d]*$/); // 123 123,123 123.123

function isString(value: any) {
  return typeof value === "string" || value instanceof String;
}

function isNumber(value: any) {
  return typeof value === "number" || value instanceof Number;
}

export type ComparatorFunctionType = (
  a: string | number,
  b: string | number
) => -1 | 0 | 1;

function tryConvertStringToNumber(value: any): any {
  if (value && isString(value) && isDecimalString.test(value)) {
    return Number(value.replace(",", "."));
  }
  return value;
}

export const defaultComparator = (a, b) => {
  const aAsNumber = tryConvertStringToNumber(a);
  if (isNumber(aAsNumber)) {
    const bAsNumber = tryConvertStringToNumber(b);
    if (isNumber(bAsNumber)) {
      a = aAsNumber;
      b = bAsNumber;
    }
  }

  if (a === undefined) {
    a = 0;
  }
  if (b === undefined) {
    b = 0;
  }

  if (b < a) return -1;
  else if (b > a) return 1;
  return 0;
};

export const customDateComparator = (a, b) => {
  if ((b || today) < (a || today)) return -1;
  else if ((b || today) > (a || today)) return 1;
  return 0;
};

export const beefCarcassClassComparator: ComparatorFunctionType = (a, b) => {
  const _getCarcassClassIndex = (x): number => {
    if (!isString(x)) {
      return 0;
    }
    const xStr = x as string;
    if (xStr.length == 0) {
      return 0;
    }
    const result = xStr.charCodeAt(0) * 10;
    if (xStr.length == 1) {
      return result;
    }
    return xStr[1] == "+" ? result + 1 : result - 1;
  };

  return defaultComparator(_getCarcassClassIndex(a), _getCarcassClassIndex(b));
};

export const beefFatClassComparator: ComparatorFunctionType = (a, b) => {
  const _getFatClassIndex = (x): number => {
    if (!x) {
      return 0;
    }
    const result = parseInt(x[0]) * 10;
    if (x.length == 1) {
      return result;
    }
    return x[1] == "-" ? result - 1 : result + 1;
  };

  return defaultComparator(_getFatClassIndex(a), _getFatClassIndex(b));
};

const secondaryComparator = (v, a, b, order) => {
  if (v === 0)
    v = order === "desc" ? defaultComparator(a, b) : -defaultComparator(a, b);
  return v;
};

/**
 * Adds support to sort by deeper row objects
 * Sort by row.foo.bar
 */
const getOrderBy = (row: object, orderBy: string | number | undefined) => {
  if (orderBy && !isNaN(Number(orderBy)) && Number.isInteger(Number(orderBy))) {
    return row[orderBy];
  }
  let parts = String(orderBy).split(".");
  let currentObj = row;
  if (!parts) {
    return undefined;
  }
  for (let i = 0; i < parts.length; i++) {
    if (currentObj[parts[i]] == undefined) {
      return undefined;
    } else {
      currentObj = currentObj[parts[i]];
    }
  }
  return currentObj;
};

export const getComparator = (
  order: "desc" | "asc",
  orderBy: string | number | undefined,
  comparator?: ComparatorFunctionType,
  secOrder: "desc" | "asc" = "asc",
  secOrderBy: string | number | undefined = "",
  secComparator?: ComparatorFunctionType
) => (a, b) =>
  secondaryComparator(
    (order === "desc" ? 1 : -1) *
      (comparator || defaultComparator)(
        getOrderBy(a, orderBy),
        getOrderBy(b, orderBy)
      ),
    a[secOrderBy],
    b[secOrderBy],
    secOrder
  );

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
