import React, { Fragment } from "react";
import { Grid, Paper, Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import useStyles from "./ContentStyles";
import { RootState } from "../../store/rootReducer";

const Content = ({
  children,
  backGroundStyles = "",
  gridContainerStyles = "",
}) => {
  const classes = useStyles();
  const { refreshTimestamp } = useSelector(
    (state: RootState) => state.ui.common
  );

  return (
    <Fragment key={refreshTimestamp}>
      <Paper
        className={`${classes.contentContainer} ${backGroundStyles}`}
        elevation={0}>
        <Grid
          container
          className={`${classes.gridContainer} ${gridContainerStyles}`}
          direction="row"
          alignItems="center"
          justifyContent="center">
          <Grid item xs={12} style={{ height: "100%" }}>
            <Paper className={classes.content}>
              <Container maxWidth="xl" className={classes.container}>
                {children}
              </Container>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default Content;
