import {
  GridColumns,
  GridRowData,
  GridRowParams,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid";
import { CalfMedicalData } from "../../../beef/shared/models/CalfMedicalQuestionData";
import FullFeaturedDataGrid, {
  RenderActionButtons,
  RowMenuProps,
} from "../DataGrid/FullFeaturedDataGrid";

import Helper from "../../utils/helper";
import moment from "moment";
import { t } from "../../services/langService";
import { useState } from "react";

interface IAnimalHealthDataGrid {
  canEdit: boolean;
  validationSchema: any; // yupValidationSchema
  medicalData: CalfMedicalData[];
  handleCalfMedicalDataChange: (newMedicalData: CalfMedicalData[]) => void;
  handleError: (error: string) => void;
}

const CalfMedicalDataGrid = ({
  canEdit,
  validationSchema,
  medicalData,
  handleCalfMedicalDataChange,
  handleError,
}: IAnimalHealthDataGrid) => {
  const mapMedicalDataToGrid = (medicalData: CalfMedicalData[]) => {
    return medicalData.map((dataEntry, index) => {
      return {
        id: index,
        birthId: dataEntry.birthId,
        medicationDate: dataEntry.medicationDate,
        reason: dataEntry.reason,
        productName: dataEntry.productName,
        additionalInformation: dataEntry.additionalInformation,
      };
    }) as GridRowData[];
  };

  const mapGridToMedicalData = (dataRow: GridRowData[]) => {
    return dataRow.map((dataEntry, index) => {
      return {
        id: index,
        birthId: dataEntry.birthId,
        medicationDate: dataEntry.medicationDate,
        reason: dataEntry.reason,
        productName: dataEntry.productName,
        additionalInformation: dataEntry.additionalInformation,
      };
    }) as CalfMedicalData[];
  };

  let gridRows: GridRowData[] = mapMedicalDataToGrid(medicalData);

  const apiRef = useGridApiRef();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const columns: GridColumns = [
    {
      field: "birthId",
      headerName: t("animalNotification_birthIdentification"),
      minWidth: 180,
      editable: true,
      type: "string",
    },
    {
      field: "medicationDate",
      headerName: t("animalMedicalData_medicationDate"),
      minWidth: 180,
      editable: true,
      type: "date",
      valueFormatter: (params) =>
        params?.value === ""
          ? ""
          : moment(
              params?.value as string,
              Helper.getDateFormat(params?.value as string)
            ).format("DD.MM.YYYY"),
    },
    {
      field: "reason",
      headerName: t("animalMedicalData_reason"),
      type: "string",
      flex: 1,
      minWidth: 180,
      editable: true,
    },
    {
      field: "productName",
      headerName: t("animalMedicalData_productName"),
      type: "string",
      flex: 1,
      minWidth: 180,
      editable: true,
    },
    {
      field: "additionalInformation",
      headerName: t("animalMedicalData_additionalInformation"),
      type: "string",
      flex: 1,
      minWidth: 180,
      editable: true,
    },
    {
      field: "actions",
      headerName: t("animalMedicalData_gridActions"),
      renderCell: function (props: RowMenuProps) {
        const { api, id } = props;

        const isInEditMode = api.getRowMode(id) === "edit";

        const handleEditClick = (event) => {
          event.stopPropagation();
          api.setRowMode(id, "edit");
        };

        const handleSaveClick = (event) => {
          event.stopPropagation();
          api.commitRowChange(id);

          api.setRowMode(id, "view");

          const row = api.getRow(id);
          api.updateRows([{ ...row, isNew: false }]);
        };

        const handleClearClick = (event) => {
          event.stopPropagation();
          const row = api.getRow(id) as GridRowData;
          api.updateRows([
            {
              ...{
                ...row,
                medicationDate: "",
                reason: "",
                productName: "",
                additionalInformation: "",
              },
              isNew: false,
            },
          ]);

          const updatedRow = api.getRow(id) as GridRowData;
          const updatedGridRows = gridRows.map(
            (obj) => [updatedRow].find((o) => o.id === obj.id) || obj
          );
          const newMedicalData = mapGridToMedicalData([...updatedGridRows]);
          handleCalfMedicalDataChange(newMedicalData);
        };

        const handleCancelClick = (event) => {
          event.stopPropagation();
          api.setRowMode(id, "view");

          const row = api.getRow(id);
          if (row!.isNew) {
            api.updateRows([{ id, _action: "delete" }]);
          }
        };

        const handleCopyClick = (event) => {
          event.stopPropagation();
          const row = api.getRow(id) as GridRowData;
          const gridToModify = [...gridRows];
          gridToModify.splice(row.id + 1, 0, {
            birthId: row.birthId,
            medicationDate: row.medicationDate,
            reason: row.reason,
            productName: row.productName,
            additionalInformation: row.additionalInformation,
          });
          const newMedicalData = mapGridToMedicalData([...gridToModify]);
          handleCalfMedicalDataChange(newMedicalData);
        };

        return (
          <RenderActionButtons
            handleCopyClick={handleCopyClick}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleClearClick}
            isInEditMode={isInEditMode}
            hasCopyButton={true}
            hasClearMode={true}
          />
        );
      },
      sortable: false,
      width: 100,
      headerAlign: "center",
      filterable: false,
      align: "center",
      disableColumnMenu: true,
      disableReorder: true,
    },
  ];

  const handleRowEditStop = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const deleteRow = (id) => {
    const newMedicalData = mapGridToMedicalData([
      ...gridRows.filter((row) => row.id !== Number(id)),
    ]);
    handleCalfMedicalDataChange(newMedicalData);
  };

  const handleOnEditRowCommit = (editedModel: GridRowData) => {
    // // reformat object into {id:number, field:any ...}
    const id = Object.keys(editedModel)[0];
    const dataRow = editedModel[id];
    let newRowObject = { id: Number(id) };
    Object.keys(dataRow).forEach((obj, ind) => {
      let key = Object.keys(dataRow)[ind];
      newRowObject[key] = dataRow[obj]?.value;
    });
    const updatedGridRows = gridRows.map(
      (obj) => [newRowObject].find((o) => o.id === obj.id) || obj
    );
    const newMedicalData = mapGridToMedicalData([...updatedGridRows]);
    handleCalfMedicalDataChange(newMedicalData);
  };

  return (
    gridRows && (
      <FullFeaturedDataGrid
        style={{
          opacity: canEdit === false ? 0.15 : 1,
          pointerEvents: canEdit === false ? "none" : "initial",
        }}
        columns={columns}
        rows={gridRows}
        editMode="row"
        height={650}
        onRowEditStop={handleRowEditStop}
        onEditRowCommit={handleOnEditRowCommit}
        validationSchema={validationSchema}
        autoPageSize={true}
        pagination={true}
        rowsPerPageOptions={[5, 10, 25]}
        getRowId={(row) => row.id}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
      />
    )
  );
};

export default CalfMedicalDataGrid;
