export default class UserAccount {
  emailAddress: any;
  firstname: any;
  lastname: any;
  producers: any;
  authenticationSource: any;
  roles: [];

  constructor(account) {
    const idTokenClaims = account.idTokenClaims;
    this.producers = idTokenClaims.authorizationData
      ? this.extractProducerInfo(idTokenClaims.authorizationData)
      : [];
    this.emailAddress = idTokenClaims.email;
    this.firstname = idTokenClaims.given_name;
    this.lastname = idTokenClaims.family_name;
    this.roles = idTokenClaims.roles;
    this.authenticationSource = idTokenClaims.authenticationSource;
  }

  private extractProducerInfo(data) {
    try {
      const json = JSON.parse(data);
      return json.Producers || [];
    } catch (err) {
      console.error("Extract producerinfo failed!", err);
    }
  }
}
