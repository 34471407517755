import { t } from "../services/langService";

interface INotificationHandler {
  onChange: (bool: boolean) => void;
}

export interface IErrorHandler extends INotificationHandler {
  error: any;
  setShowError: (object: INotificationProperties) => void;
}
export interface ISuccessHandler extends INotificationHandler {
  success: any;
  setShowSuccess: (object: INotificationProperties) => void;
}
interface INotificationProperties {
  text: string;
  primaryButtonText: string;
  secondaryButtonText?: string;
  primaryPath: string;
  secondaryPath?: string;
}

const economyErrorHandler = ({
  error,
  setShowError,
  onChange,
}: IErrorHandler) => {
  if (error?.url?.indexOf("economy/invoice/image") > -1) {
    setShowError({
      text: t("economy_couldNotGetInvoiceImage"),
      primaryButtonText: t("economy_returnToInvoices"),
      primaryPath: "reset",
    });
    onChange(true);
  } else if (error?.url?.indexOf("economy/payment/image") > -1) {
    setShowError({
      text: t("economy_couldNotGetPaymentImage"),
      primaryButtonText: t("economy_returnToPayments"),
      primaryPath: "reset",
    });
    onChange(true);
  }
};

const beefErrorHandler = ({ error, setShowError, onChange }: IErrorHandler) => {
  if (
    (error?.url?.indexOf("beef/slaughter") > -1 ||
      error?.url?.indexOf("beef/forwarding") > -1) &&
    error.method === "get"
  ) {
    setShowError({
      text: t("animalNotification_notificationInitFailed"),
      primaryButtonText: t("animalNotification_goToFrontPage"),
      primaryPath: "/beef",
    });
    onChange(true);
  } else if (
    (error?.url?.indexOf("beef/slaughter") > -1 ||
      error?.url?.indexOf("beef/forwarding") > -1) &&
    error.status !== 409 &&
    (error.method === "post" || error.method === "put")
  ) {
    setShowError({
      text: t("animalNotification_notificationSaveFailed"),
      primaryButtonText: t("animalNotification_goToListView"),
      secondaryButtonText: t("animalNotification_goToFrontPage"),
      primaryPath: "/beef/notificationlist",
      secondaryPath: "/beef",
    });
    onChange(true);
  }
};

const beefSuccessHandler = ({
  success,
  setShowSuccess,
  onChange,
}: ISuccessHandler) => {
  if (
    (success?.url?.indexOf("beef/slaughter") > -1 ||
      success?.url?.indexOf("beef/forwarding") > -1) &&
    (success.method === "post" || success.method === "put")
  ) {
    setShowSuccess({
      text: t("animalNotification_notificationSaved"),
      primaryButtonText: t("animalNotification_goToListView"),
      secondaryButtonText: t("animalNotification_goToFrontPage"),
      primaryPath: "/beef/notificationlist",
      secondaryPath: "/beef",
    });
    onChange(true);
  }
};

const porkErrorHandler = ({ error, setShowError, onChange }: IErrorHandler) => {
  if (error?.url?.indexOf("pork/slaughter") > -1 && error.method === "get") {
    setShowError({
      text: t("animalNotification_notificationInitFailed"),
      primaryButtonText: t("animalNotification_goToFrontPage"),
      primaryPath: "/pork",
    });
    onChange(true);
  } else if (
    error?.url?.indexOf("pork/slaughter") > -1 &&
    error.status !== 409 &&
    (error.method === "post" || error.method === "put")
  ) {
    setShowError({
      text: t("animalNotification_notificationSaveFailed"),
      primaryButtonText: t("animalNotification_goToListView"),
      secondaryButtonText: t("animalNotification_goToFrontPage"),
      primaryPath: "/pork/notificationlist",
      secondaryPath: "/pork",
    });
    onChange(true);
  }
};

const porkSuccessHandler = ({
  success,
  setShowSuccess,
  onChange,
}: ISuccessHandler) => {
  if (
    success?.url?.indexOf("pork/slaughter") > -1 &&
    (success.method === "post" || success.method === "put")
  ) {
    setShowSuccess({
      text: t("animalNotification_notificationSaved"),
      primaryButtonText: t("animalNotification_goToListView"),
      secondaryButtonText: t("animalNotification_goToFrontPage"),
      primaryPath: "/pork/notificationlist",
      secondaryPath: "/pork",
    });
    onChange(true);
  }
};

const cooperativeErrorHandler = ({
  error,
  setShowError,
  onChange,
}: IErrorHandler) => {
  if (error?.url?.indexOf("cooperative/debtInvoices/image") > -1) {
    setShowError({
      text: t("economy_couldNotGetInvoiceImage"),
      primaryButtonText: t("ui_back"),
      primaryPath: "reset",
    });
    onChange(true);
  } else if (error?.url?.indexOf("cooperative/interestPayments/image") > -1) {
    setShowError({
      text: t("economy_couldNotGetPaymentImage"),
      primaryButtonText: t("ui_back"),
      primaryPath: "reset",
    });
    onChange(true);
  }
};

const unauthorizedErrorHandler = (urlPattern: string) => ({
  error,
  setShowError,
  onChange,
}: IErrorHandler) => {
  if (error?.url?.match(new RegExp(urlPattern, "i")) && error?.status === 403) {
    onChange(true);
  }
};

const managementErrorHandler = ({
  error,
  setShowError,
  onChange,
}: IErrorHandler) => {
  if (
    error?.url?.indexOf("userManagement/users/add") > -1 &&
    error.method === "post"
  ) {
    setShowError({
      text: t("userManagement_userSaveFailed"),
      primaryButtonText: t("ui_back"),
      primaryPath: "reset",
    });
    onChange(true);
  }
};

const feedbackSuccesshandler = ({
  success,
  setShowSuccess,
  onChange,
}: ISuccessHandler) => {
  if (
    success?.url?.indexOf("user/feedback") > -1 &&
    (success.method === "post" || success.method === "put")
  ) {
    setShowSuccess({
      text: t("feedback_success_message"),
      primaryButtonText: t("animalNotification_goToFrontPage"),
      primaryPath: "/",
    });
    onChange(true);
  }
};

const feedbackErrorHandler = ({
  error,
  setShowError,
  onChange,
}: IErrorHandler) => {
  if (error?.url?.indexOf("user/feedback") > -1) {
    setShowError({
      text: t("feedback_error_message"),
      primaryButtonText: t("ui_back"),
      primaryPath: "reset",
      secondaryButtonText: t("animalNotification_goToFrontPage"),
      secondaryPath: "/",
    });
    onChange(true);
  }
};

export const errorHandlers = {
  unauthorizedErrorHandler,
  economyErrorHandler,
  beefErrorHandler,
  porkErrorHandler,
  cooperativeErrorHandler,
  managementErrorHandler,
  feedbackErrorHandler,
};

export const successHandlers = {
  beefSuccessHandler,
  porkSuccessHandler,
  feedbackSuccesshandler,
};
