import { Grid } from "@material-ui/core";
import { FieldHookConfig, Form, Formik, useField } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import DatePicker from "../../../../shared/components/DatePicker/DatePicker";
import InputField, {
  InputFieldProps,
} from "../../../../shared/components/InputField/InputField";
import { t } from "../../../../shared/services/langService";
import { RootState } from "../../../../shared/store/rootReducer";
import { isRequiredInYupSchema, REQUIRED } from "../../utils/validationSchemas";

export const bankAccountFormValidationSchema = Yup.object().shape({
  bankAccountNumber: Yup.string().required(() => t(REQUIRED)),
});

const bankAccountFormInitialValues = {
  bankAccountNumber: "",
  bankAccountDate: moment().format("YYYY-MM-DD"),
};

const MyInputField = (props: InputFieldProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props);

  return (
    <InputField
      {...props}
      {...field}
      error={meta.touched && Boolean(meta.error)}
      errorText={meta.touched && meta.error}
      required={isRequiredInYupSchema(
        bankAccountFormValidationSchema,
        props.name
      )}
      singleLine
      disabled={props.disabled ?? false}
    />
  );
};

const BankAccountForm = (props) => {
  const { handleSubmit } = props;
  const [initialValues, setInitialValues] = useState(
    bankAccountFormInitialValues
  );
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const { validationRules } = useSelector(
    (state: RootState) => state.ui.validation
  );

  const ibanRule = Yup.object().shape({
    bankAccountNumber: Yup.string()
      .required(() => t(REQUIRED))
      .matches(
        new RegExp(validationRules["EconomyInformation"]["IbanNumber"]),
        t("bankAccount_invalidIbanLength")
      ),
  });

  //Handling of the date may seem and is bit awkward, but seemed to be a working way to pass the user selected data. Otherwise,
  //the date assigned in the initialValues (current date) is passed.
  const handleBankAccountFormSubmit = (e) => {
    handleSubmit({
      bankAccountNumber: e.bankAccountNumber,
      bankAccountDate: selectedDate,
    });
  };

  const handleBankAccountDateChange = (e) => {
    const updatedDate = e.format("YYYY-MM-DD");
    setInitialValues({
      ...initialValues,
      bankAccountDate: updatedDate,
    });
    setSelectedDate(updatedDate);
  };

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Formik
        initialValues={initialValues}
        validationSchema={ibanRule}
        onSubmit={handleBankAccountFormSubmit}>
        <Grid item xs={12}>
          <Form id="bank-account-form" noValidate>
            <MyInputField
              id="bankAccountNumber"
              name="bankAccountNumber"
              label={t("bankAccount_bankAccountNumber")}
              fullWidth
            />
            <DatePicker
              id="bankAccountDate"
              name="bankAccountDate"
              label={t("bankAccountNumber_startingFrom")}
              value={selectedDate}
              onChange={handleBankAccountDateChange}
              autoOk={true}
            />
          </Form>
        </Grid>
      </Formik>
    </Grid>
  );
};

export default BankAccountForm;
