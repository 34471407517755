import http from "./httpService";

const API_URL = `translation/`;

export const getAllTranslations = async () => {
  const resp = await http.get(`${API_URL}alltranslations`);
  return resp;
};

export default {
  getAllTranslations,
};
