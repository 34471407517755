import { Grid, Hidden, TableContainer } from "@material-ui/core";
import moment from "moment";
import React, { Fragment, useState } from "react";
import Button from "../../../../../shared/components/Button/Button";
import Checkbox from "../../../../../shared/components/Checkbox/Checkbox";
import DropdownMenu from "../../../../../shared/components/DropdownMenu/DropdownMenu";
import InfoModal from "../../../../../shared/components/InfoModal/InfoModal";
import InputField from "../../../../../shared/components/InputField/InputField";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "../../../../../shared/components/Table/Table";
import WeekPicker from "../../../../../shared/components/WeekPicker/WeekPicker";
import { t } from "../../../../../shared/services/langService";
import nautaTeurasElainlajit from "../../../enums/nautaTeurasElainLajit";
import nautaValitysElainlajit from "../../../enums/nautaValitysElainlajit";
import useStyles from "./AnimalListStyles";

interface AnimalListProps {
  notificationType: "slaughter" | "forwarding";
  animals: any[];
  onRemove: any;
  onEdit: Function;
  modify?: boolean;
  dropdownOptions?: any[];
}

const AnimalList: React.FC<AnimalListProps> = ({
  notificationType,
  animals = [],
  onRemove,
  onEdit,
  modify = false,
  dropdownOptions = [],
}) => {
  const classes = useStyles();

  const [filter, setFilter] = useState("");
  const [infoModal, setInfoModal] = useState<any>({
    open: false,
    title: "",
    text: "",
    okButtonText: "",
    onClose: () => {},
  });

  const handleAnimalChange = (value, id) => {
    const newValues = [["animalType", value.toString()]];
    if (notificationType === "slaughter" && value != "25")
      newValues.push(["urgent", false]);
    onEdit(id, newValues);
  };

  const handleWeekChange = (value, id) => {
    onEdit(id, [["selectedWeek", value]]);
  };

  const handleUrgentChange = (value, id) => {
    onEdit(id, [["urgent", value]]);
  };

  const handleNupoutettuChange = (value, id) => {
    onEdit(id, [["nupoutus", value]]);
  };

  const removeAnimal = (id) => {
    if (modify && animals.length <= 1) {
      setInfoModal({
        open: true,
        title: t("animalNotification_cannotRemoveAnimalTitle"),
        text: t("animalNotification_cannotRemoveAnimalText"),
        okButtonText: t("ui_ok"),
        onClose: () =>
          setInfoModal((prevState) => ({ ...prevState, open: false })),
      });
    } else {
      onRemove(id);
    }
  };

  const filterChanged = (e) => {
    setFilter(e.target.value);
  };

  return (
    <Fragment>
      <InfoModal
        open={infoModal.open}
        title={infoModal.title}
        text={infoModal.text}
        cancelButtonText={infoModal.cancelButtonText}
        okButtonText={infoModal.okButtonText}
        onClose={infoModal.onClose}
      />

      {animals && animals.length > 0 && (
        <Fragment>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <InputField
              id="animal-list-searchanimalrows-input"
              margin={"0px 5px 0px 0px"}
              label={t("animalNotification_searchAnimalRows")}
              placeholder={t("animalNotification_searchAnimalRowsPlaceholder")}
              fullWidth={true}
              onChange={filterChanged}
            />
          </Grid>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <Hidden smUp>
                    <TableCell style={{ width: "50px" }}></TableCell>
                  </Hidden>

                  <TableCell style={{ width: "50px" }}>
                    {t("animalNotification_animalType")}
                  </TableCell>
                  <TableCell style={{ width: "50px" }}>
                    {t("animalNotification_birthIdentification")}
                  </TableCell>
                  {modify && (
                    <Fragment>
                      <TableCell style={{ width: "50px" }}>
                        {t("animalNotification_animalBreed")}
                      </TableCell>
                      <TableCell style={{ width: "50px" }}>
                        {t("animalNotification_animalBirthday")}
                      </TableCell>
                    </Fragment>
                  )}
                  <TableCell style={{ width: "50px" }}>
                    {t("animalNotification_preferredWeek")}
                  </TableCell>
                  <TableCell style={{ width: "50px" }}>
                    {notificationType === "forwarding"
                      ? t("animalNotification_nupoutettu")
                      : animals.some((a) => a.animalType === "25")
                      ? t("animalNotification_expedited")
                      : ""}
                  </TableCell>
                  {animals.some(
                    (a) => a.rowDispatchState && a.rowDispatchState !== "av"
                  ) && (
                    <TableCell style={{ width: "50px" }}>
                      {t("animalNotification_loadingTime")}
                    </TableCell>
                  )}
                  <Hidden xsDown>
                    <TableCell style={{ width: "50px" }}></TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {animals?.map((animal) => (
                  <TableRow
                    style={{ height: "68.8889px" }}
                    key={animal.id}
                    className={
                      filter !== "" && !animal.id.includes(filter)
                        ? classes.hiddenRow
                        : ""
                    }
                    highlighted={
                      animal.tila === "av" || animal.rowDispatchState === "lo"
                    }
                    highlightColor={
                      animal.tila === "av" ? "#cf2d3a" : "#c9c8c6"
                    }>
                    <Hidden smUp>
                      <TableCell style={{ width: "50px" }}>
                        <Button
                          id={"animal-list-remove-button"}
                          value={animal.id}
                          onClick={() => removeAnimal(animal.id)}
                          type="table"
                          fn={"remove"}>
                          {t("ui_remove")}
                        </Button>
                      </TableCell>
                    </Hidden>
                    <TableCell style={{ width: "50px" }}>
                      {((animals.every(
                        (a) =>
                          a.rowDispatchState === "av" || !a.rowDispatchState
                      ) &&
                        notificationType === "slaughter") ||
                        (!["lo", "os"].includes(animal.rowDispatchState) &&
                          notificationType === "forwarding")) && (
                        <DropdownMenu
                          id={"animal-list-animalchange-select"}
                          onChange={(value) =>
                            handleAnimalChange(value, animal.id)
                          }
                          selectedValue={animal.animalType}
                          options={dropdownOptions}
                        />
                      )}
                      {((animals.some(
                        (a) => a.rowDispatchState && a.rowDispatchState !== "av"
                      ) &&
                        notificationType === "slaughter") ||
                        (["lo", "os"].includes(animal.rowDispatchState) &&
                          notificationType === "forwarding")) && (
                        <Fragment>
                          {notificationType === "slaughter"
                            ? t(
                                `animalType_${
                                  nautaTeurasElainlajit[animal.animalType]
                                }`
                              )
                            : t(
                                `animalType_${
                                  nautaValitysElainlajit[animal.animalType]
                                }`
                              )}
                        </Fragment>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "50px" }}>{animal.id}</TableCell>
                    {modify && (
                      <Fragment>
                        <TableCell style={{ width: "50px" }}>
                          {animal.breed}
                        </TableCell>
                        <TableCell style={{ width: "50px" }}>
                          {animal.birthday
                            ? moment(animal.birthday).format("DD.MM.YYYY")
                            : ""}
                        </TableCell>
                      </Fragment>
                    )}
                    <TableCell style={{ width: "50px" }}>
                      {!["lo", "os"].includes(animal.rowDispatchState) && (
                        <WeekPicker
                          id={"animal-list-week-select"}
                          onChange={(value) =>
                            handleWeekChange(value, animal.id)
                          }
                          weeksToShow={53}
                          selectedValue={animal.selectedWeek}
                          {...(modify && animals.length
                            ? {
                                addWeekpickerOptions: Array.from(
                                  new Set<string>(
                                    animals.map((a) => a.selectedWeek)
                                  )
                                ),
                              }
                            : {})}
                          showStartAndEndDate={true}
                        />
                      )}
                      {["lo", "os"].includes(animal.rowDispatchState) && (
                        <Fragment>{animal.selectedWeek}</Fragment>
                      )}
                    </TableCell>
                    <TableCell style={{ width: "50px" }}>
                      {notificationType === "slaughter" &&
                        animal.animalType == "25" && (
                          <Checkbox
                            id={"animal-list-urgent-checkbox"}
                            checked={animal.urgent}
                            onChange={() =>
                              handleUrgentChange(!animal.urgent, animal.id)
                            }
                            disabled={["lo", "os"].includes(
                              animal.rowDispatchState
                            )}
                          />
                        )}
                      {notificationType === "forwarding" && (
                        <Checkbox
                          id={"animal-list-nupoutus-checkbox"}
                          checked={animal.nupoutus}
                          onChange={() =>
                            handleNupoutettuChange(!animal.nupoutus, animal.id)
                          }
                          disabled={["lo", "os"].includes(
                            animal.rowDispatchState
                          )}
                        />
                      )}
                    </TableCell>
                    {animals.some(
                      (a) => a.rowDispatchState && a.rowDispatchState !== "av"
                    ) && (
                      <TableCell style={{ width: "50px" }}>
                        {animal.rowDispatchState === "lo" && (
                          <span>{t("animalNotification_transported")}</span>
                        )}
                        {animal.rowDispatchState === "os" &&
                          animal.kuormausAika.length > 0 && (
                            <span>
                              {moment(animal.kuormausAika[0].pvm).format(
                                "DD.MM.YYYY"
                              )}{" "}
                              {animal.kuormausAika[0].klo}
                            </span>
                          )}
                      </TableCell>
                    )}
                    <Hidden xsDown>
                      <TableCell style={{ width: "50px" }}>
                        {!["lo", "os"].includes(animal.rowDispatchState) && (
                          <Button
                            id={"animal-list-remove-button"}
                            value={animal.id}
                            onClick={() => removeAnimal(animal.id)}
                            type="table"
                            fn={"remove"}>
                            {t("ui_remove")}
                          </Button>
                        )}
                      </TableCell>
                    </Hidden>
                  </TableRow>
                ))}
                {filter !== "" && !animals.some((a) => a.id.includes(filter)) && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      {t("animalNotification_noRowsFound")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </Fragment>
  );
};

export default AnimalList;
