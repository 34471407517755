import React, { useEffect, useState } from "react";
import { AppBar, Toolbar, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useStyles from "./NavigationStyles";
import ToggleMenu from "../../ToggleMenu/ToggleMenu";
import { ReactComponent as Logo } from "../../../../assets/images/HKScan.svg";
import KivinettiLogo from "../../../../assets/images/kivinetti.png";
import ArrowMenu from "../../ArrowMenu/ArrowMenu";
import { t } from "../../../services/langService";
import ProductionArea, {
  allProductionLines,
  animalProductionLines,
} from "../../../enums/productionLines";
import navHelpers from "../../../../shared/helpers/navHelpers";
import {
  ADUserRole,
  mainUserRoles,
  OperationUnit,
  UserRole,
  UserRoles,
} from "../../../enums/CoreEnums";
import { publication } from "../../../store/apiActions";
import { mainMenuItemsCleared } from "../../../store/publications";
import { AuthorizationContext } from "../../../../management/shared/models/Users";
import CustomLink from "../../../components/CustomLink/CustomLink";
import { RootState } from "../../../store/rootReducer";

const optionsMenuItems = [
  {
    id: "contactInfo",
    name: "options_contactInfo",
    link: "/contacts",
    divider: true,
    hide: true,
    authContext: AuthorizationContext.MainUser,
  },
];

interface INavigationSubItem {
  name: string;
  link: string;
  productionLines?: ProductionArea[];
  operationUnits?: OperationUnit[];
  absolutePath?: boolean;
  visibleToUserRoles?: UserRoles[];
  hide?: boolean;
  authContext?: AuthorizationContext;
}
interface INavigationItem {
  name: string;
  authContext: AuthorizationContext;
  link?: string;
  productionLines?: ProductionArea[];
  operationUnits?: OperationUnit[];
  absolutePath?: boolean;
  items?: INavigationSubItem[];
  hide?: boolean;
  visibleToUserRoles?: UserRoles[];
  orgHasToBeMember?: boolean;
}

const defaultNavigationItems: INavigationItem[] = [
  {
    name: "nav_frontPage",
    productionLines: allProductionLines,
    link: "",
    visibleToUserRoles: [
      ...mainUserRoles,
      ADUserRole.SinettiOsuuskunta,
      ADUserRole.SinettiHKScanOsuuskunta,
      UserRole.EmployeeUser,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.All,
  },
  {
    name: "nav_forwardingAndSlaughterNotification",
    productionLines: [ProductionArea.Beef, ProductionArea.Pork],
    items: [
      {
        name: "nav_slaughterNotification",
        link: "/slaughternotifications",
      },
      {
        name: "nav_forwardingNotification",
        link: "/forwardingnotifications",
        productionLines: [ProductionArea.Beef],
      },
      {
        name: "nav_notificationList",
        link: "/notificationlist",
      },
    ],
    visibleToUserRoles: [
      ADUserRole.SinettiHKScanNauta,
      ADUserRole.SinettiKivinettiNauta,
      ADUserRole.SinettiHKScanSika,
      ADUserRole.SinettiKivinettiSika,
      UserRole.EmployeeUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
  {
    name: "nav_productionTimetable",
    productionLines: [ProductionArea.Poultry],
    items: [
      {
        name: "nav_productionTimetable_arrivingBatches",
        link: "/arrivingbatches",
      },
      {
        name: "nav_productionTimetable_loadingTimetable",
        link: "/loadingtimetable",
      },
    ],
    visibleToUserRoles: [
      ADUserRole.SinettiHKScanBroileri,
      UserRole.EmployeeUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
  {
    name: "nav_economy",
    productionLines: animalProductionLines,
    items: [
      {
        name: "nav_economy_payments",
        link: "/economy/payments",
        absolutePath: true,
      },
      {
        name: "nav_economy_invoices",
        link: "/economy/invoices",
        absolutePath: true,
      },
      {
        name: "nav_economy_debtBreakdown",
        link: "/economy/debtbreakdown",
        absolutePath: true,
        productionLines: [ProductionArea.Beef, ProductionArea.Pork],
      },
      {
        name: "nav_fileListing_economyreports",
        link: "/user/files/economyreports",
        absolutePath: true,
      },
      {
        name: "nav_fileListing_pricelist",
        link: "/user/files/pricelist",
        absolutePath: true,
        visibleToUserRoles: [
          ADUserRole.SinettiHKScanNauta,
          ADUserRole.SinettiKivinettiNauta,
          ADUserRole.SinettiHKScanSika,
          ADUserRole.SinettiKivinettiSika,
          ADUserRole.SinettiHKScanBroileri,
          UserRole.SecondaryMainUser,
        ],
        operationUnits: [OperationUnit.Sinetti],
      },
    ],
    visibleToUserRoles: [
      ADUserRole.SinettiHKScanNauta,
      ADUserRole.SinettiKivinettiNauta,
      ADUserRole.SinettiHKScanSika,
      ADUserRole.SinettiKivinettiSika,
      ADUserRole.SinettiHKScanBroileri,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
  {
    name: "nav_productionInfo",
    productionLines: animalProductionLines,
    items: [
      {
        name: "nav_slaughterinfo",
        productionLines: [ProductionArea.Beef, ProductionArea.Pork],
        link: "/slaughterinfo",
        visibleToUserRoles: [
          ADUserRole.SinettiHKScanNauta,
          ADUserRole.SinettiKivinettiNauta,
          ADUserRole.SinettiHKScanSika,
          ADUserRole.SinettiKivinettiSika,
          UserRole.EmployeeUser,
        ],
      },
      {
        name: "nav_productionTimetable_raisingBatches",
        productionLines: [ProductionArea.Poultry],
        visibleToUserRoles: [
          ADUserRole.SinettiHKScanBroileri,
          UserRole.EmployeeUser,
        ],
        link: "/raisingbatches",
      },
      {
        name: "nav_fileListing_reports",
        productionLines: [ProductionArea.Beef, ProductionArea.Pork],
        link: "/user/files/reports",
        absolutePath: true,
        visibleToUserRoles: [
          ADUserRole.SinettiHKScanNauta,
          ADUserRole.SinettiKivinettiNauta,
          ADUserRole.SinettiHKScanSika,
          ADUserRole.SinettiKivinettiSika,
          UserRole.EmployeeUser,
        ],
      },
      {
        name: "nav_fileListing_productiontracking",
        productionLines: [ProductionArea.Poultry],
        link: "/user/files/productiontracking",
        absolutePath: true,
        visibleToUserRoles: [
          ADUserRole.SinettiHKScanBroileri,
          UserRole.EmployeeUser,
        ],
      },
      {
        name: "nav_fileListing_researchresults",
        link: "/user/files/researchresults",
        absolutePath: true,
        operationUnits: [OperationUnit.Sinetti],
      },
      {
        name: "nav_fileListing_plans",
        link: "/user/files/plans",
        absolutePath: true,
        operationUnits: [OperationUnit.Sinetti],
      },
    ],
    visibleToUserRoles: [
      ADUserRole.SinettiHKScanNauta,
      ADUserRole.SinettiKivinettiNauta,
      ADUserRole.SinettiHKScanSika,
      ADUserRole.SinettiKivinettiSika,
      ADUserRole.SinettiHKScanBroileri,
      UserRole.EmployeeUser,
    ],
    authContext: AuthorizationContext.MainUser,
  },
  {
    name: "nav_learnSomethingNew",
    productionLines: animalProductionLines,
    operationUnits: [OperationUnit.Sinetti],
    items: [
      {
        name: "nav_learnSomethingNew",
        link: "/learnsomethingnew",
        absolutePath: true,
      },
      {
        name: "nav_fileListing_lectures",
        link: "/user/files/lectures",
        absolutePath: true,
      },
    ],
    visibleToUserRoles: [...mainUserRoles, UserRole.EmployeeUser],
    authContext: AuthorizationContext.MainUser,
  },
  {
    name: "nav_instructions",
    productionLines: animalProductionLines,
    operationUnits: [OperationUnit.Sinetti, OperationUnit.Kivikyla],
    items: [
      {
        name: "nav_fileListing_producermanual",
        link: "/user/files/producermanual",
        absolutePath: true,
        operationUnits: [OperationUnit.Sinetti],
      },
      {
        name: "nav_fileListing_instructionsarchive",
        link: "/user/files/instructionsarchive",
        absolutePath: true,
      },
      {
        name: "nav_fileListing_formsandtemplates",
        link: "/user/files/formsandtemplates",
        absolutePath: true,
      },
    ],
    visibleToUserRoles: [...mainUserRoles, UserRole.EmployeeUser],
    authContext: AuthorizationContext.MainUser,
  },
  {
    name: "nav_cooperative_capitalInfo",
    link: "/capitalinfo",
    productionLines: [ProductionArea.Cooperative],
    visibleToUserRoles: [
      ADUserRole.SinettiHKScanOsuuskunta,
      ADUserRole.SinettiOsuuskunta,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.OsuuskuntaUser,
    orgHasToBeMember: true,
  },
  {
    name: "nav_cooperative_debtInvoices",
    link: "/debtInvoices",
    productionLines: [ProductionArea.Cooperative],
    visibleToUserRoles: [
      ADUserRole.SinettiHKScanOsuuskunta,
      ADUserRole.SinettiOsuuskunta,
      UserRole.EconomyUser,
    ],
    authContext: AuthorizationContext.OsuuskuntaUser,
    orgHasToBeMember: true,
  },
];

interface NavigationProps {}

const Navigation: React.FC<NavigationProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { producerNumber, productionLine, operationUnit } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const { organizations, roleMap } = useSelector(
    (state: RootState) => state.user
  );
  const { roles, producers } = useSelector(
    (state: RootState) => state.auth.currentUser
  );
  const [isMember, setIsMember] = useState<boolean>(false);

  const [isCooperativeContent, setIsCooperativeContent] = useState<boolean>(
    false
  );

  useEffect(() => {
    setIsCooperativeContent(productionLine === ProductionArea.Cooperative);
  }, [productionLine]);

  useEffect(() => {
    const producerTmp = organizations?.find(
      (org) => org.producerNumber == producerNumber
    );

    if (producerTmp) {
      setIsMember(producerTmp.isMember);
    }
  }, [organizations, producerNumber]);

  const { langCode } = useSelector((state: RootState) => state.ui.i18n);

  const [filteredNavMenuItems, setFilteredNavMenuItems] = useState<any[]>([]);
  const { mainMenuReferenceId, mainMenuItems } = useSelector(
    (state: RootState) => state.publications
  );
  const [navigationMenuItems, setNavigationMenuItems] = useState<any[]>([]);

  useEffect(() => {
    if (operationUnit && producerNumber) {
      const filteredNavigationMenuItems = defaultNavigationItems
        .filter(
          (item: INavigationItem) =>
            !item.hide &&
            (!item.productionLines?.length ||
              item.productionLines?.indexOf(productionLine) > -1) &&
            (!item.operationUnits?.length ||
              item.operationUnits?.indexOf(operationUnit) > -1) &&
            (item.orgHasToBeMember ? isMember : true) &&
            (!item.visibleToUserRoles?.length ||
              navHelpers.isValidUserRole(
                item.visibleToUserRoles,
                roles,
                producers,
                producerNumber,
                item.authContext,
                roleMap
              ))
        )
        .map((item: INavigationItem) => ({
          items: item.items
            ?.filter(
              (subItem: INavigationSubItem) =>
                !subItem.hide &&
                (!subItem.productionLines?.length ||
                  subItem.productionLines.indexOf(productionLine) > -1) &&
                (!subItem.operationUnits?.length ||
                  subItem.operationUnits?.indexOf(operationUnit) > -1) &&
                (!subItem.visibleToUserRoles?.length ||
                  navHelpers.isValidUserRole(
                    subItem.visibleToUserRoles,
                    roles,
                    producers,
                    producerNumber,
                    subItem.authContext ?? item.authContext,
                    roleMap
                  ))
            )
            .map((subItem: INavigationSubItem) => ({
              name: t(subItem.name),
              link: !subItem.absolutePath
                ? navHelpers.addProductionLine(productionLine, subItem.link)
                : subItem.link,
            })),
          link:
            item.link || item.link === ""
              ? !item.absolutePath
                ? navHelpers.addProductionLine(productionLine, item.link)
                : item.link
              : null,
          name: t(item.name),
        }));

      setFilteredNavMenuItems([...filteredNavigationMenuItems]);
    }
  }, [
    producerNumber,
    langCode,
    operationUnit,
    productionLine,
    roles,
    producers,
    isMember,
    roleMap,
  ]);

  const toptionsMenuItems = optionsMenuItems
    .filter((item: INavigationItem) => !item.hide)
    .map((item: INavigationItem) => ({
      ...item,
      name: t(item.name),
      authContext: item.authContext,
      items: item.items?.map((subItem: INavigationSubItem) => ({
        ...subItem,
        name: t(subItem.name),
      })),
    }));

  useEffect(() => {
    if (productionLine) {
      dispatch(publication.getMainMenuReferenceId(productionLine));
    }
  }, [productionLine]);

  useEffect(() => {
    setNavigationMenuItems([...filteredNavMenuItems]);
  }, [operationUnit, filteredNavMenuItems]);

  useEffect(() => {
    if (mainMenuReferenceId) {
      dispatch(
        publication.getMainMenuItems(
          langCode,
          productionLine,
          mainMenuReferenceId
        )
      );
    }
  }, [langCode, mainMenuReferenceId]);

  useEffect(() => {
    if (mainMenuItems?.length) {
      const navigationMenuItemsFromContentProvider = navHelpers.mapCmsNavItemsToMenuItems(
        [...mainMenuItems],
        producerNumber
      );
      setNavigationMenuItems([
        ...filteredNavMenuItems,
        ...navigationMenuItemsFromContentProvider,
      ]);
    }
  }, [mainMenuItems, filteredNavMenuItems]);

  const toggleMenuItems = productionLine
    ? [...toptionsMenuItems, ...navigationMenuItems]
    : toptionsMenuItems;

  const logo =
    operationUnit === OperationUnit.Kivikyla ? (
      <img src={KivinettiLogo} className={classes.kivinettiLogo} alt="" />
    ) : (
      <Logo className={classes.logo} />
    );

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}>
      <Toolbar>
        <div className={classes.logoContainer}>
          <Link to={"/"}>{logo}</Link>
        </div>
        {isCooperativeContent ? (
          <Typography variant="h6" className={classes.title}>
            <span>
              <span className={classes.titleSeparator}>|</span>
              <span className={classes.subTitle}>LSO Osuuskunta</span>
            </span>
          </Typography>
        ) : (
          <div className={classes.titleContainer}>
            <Typography variant="h6" className={classes.title}>
              {(operationUnit === OperationUnit.Kivikyla && <span></span>) || (
                <span>
                  <span className={classes.titleSeparator}>|</span>
                  <span className={classes.subTitle}>Sinetti</span>
                </span>
              )}
            </Typography>
          </div>
        )}

        {productionLine && (
          <div className={classes.menuItemContainer}>
            {navigationMenuItems.map(
              (item, index) =>
                ((item.link || item.link === "") && (
                  <CustomLink
                    key={index}
                    to={item.link}
                    className={classes.link}>
                    {item.name}
                  </CustomLink>
                )) || (
                  <ArrowMenu
                    key={index}
                    menuItems={item.items}
                    label={item.name}
                    menuButtonProps={{
                      classes: { root: classes.menuButton },
                    }}
                    menuPaperStyle={{
                      backgroundColor: "#152B62",
                      color: "#fff",
                      padding: "10px 5px",
                    }}
                  />
                )
            )}
          </div>
        )}
        <div className={classes.toggleMenu}>
          <ToggleMenu
            menuItems={toggleMenuItems}
            menuPaperProps={{
              style: {
                marginTop: "40px",
              },
            }}
          />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
