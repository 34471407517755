import { AuthorizationContext } from "../../management/shared/models/Users";
import { ADUserRole, UserRole } from "../enums/CoreEnums";
import ProductionArea, { productionAreaConfig } from "../enums/productionLines";
import UserRoleDictionary, { Tree } from "../utils/userRoleDictionary";
import { PublicationType } from "../models/Publications";

const productionAreas = {
  beef: ProductionArea.Beef,
  pork: ProductionArea.Pork,
  poultry: ProductionArea.Poultry,
  cooperative: ProductionArea.Cooperative,
  other: ProductionArea.Other,
};

const roleLandingPages = {
  cooperative: [ADUserRole.SinettiOsuuskunta, UserRole.OsuuskuntaUser],
};

export const addProductionLine = (productionLine, link = "") => {
  let value = "";
  for (let p in productionAreas) {
    if (productionAreas[p] === productionLine) {
      value = p;
      break;
    }
  }
  return `/${value}${link}`;
};

export const hasRoleWithLandingPage = (roles, landingUri) => {
  const hasLandingUri = roles.some((role) =>
    roleLandingPages[landingUri].includes(role)
  );

  return hasLandingUri;
};

export const getCurrentProducerRoles = (
  producers,
  currentProducerNumber,
  authContext: AuthorizationContext = AuthorizationContext.All
) => {
  const producer = producers.find(
    (role) => Number(role.Number) === Number(currentProducerNumber)
  );

  let claimUserRoles: string[] = [];
  if (producer) {
    if (authContext === AuthorizationContext.All) {
      for (let roleGroup in producer.Roles) {
        claimUserRoles.push(
          producer.Roles[roleGroup].find((role) => role.Role).Role
        );
      }
    } else {
      const roles = producer.Roles[AuthorizationContext[authContext]];

      if (roles) {
        for (let key in roles) {
          claimUserRoles.push(roles[key].Role);
        }
      }
    }
  }

  return claimUserRoles;
};

export const getLandingPage = (
  productionLine: ProductionArea,
  roles: UserRole[],
  producers: any[],
  currentProducerNumber: number,
  roleMap: UserRoleDictionary | any[]
) => {
  let value = "";

  let allowedProductionAreas = getAllowedProductionAreas(
    roles,
    producers,
    currentProducerNumber,
    roleMap
  );

  for (let key in productionAreas) {
    if (
      productionAreas[key] === productionLine &&
      allowedProductionAreas.includes(productionLine)
    ) {
      value = key;
      break;
    }
  }

  if (!value) {
    for (let key in productionAreas) {
      if (allowedProductionAreas.includes(productionAreas[key])) {
        value = key;
        break;
      }
    }
  }

  return `/${value}`;
};

export const getProductionLine = (locationPathName): ProductionArea => {
  const array = locationPathName.split("/");
  return array && array.length > 1 ? productionAreas[array[1]] || "" : "";
};

export const isValidUserRole = (
  allowedUserRoles: string[],
  roles: string[],
  producers,
  currentProducerNumber: number,
  authContext: AuthorizationContext,
  applicationRoleMap
) => {
  let isValidADRole = false;
  let isValidUserRole = false;

  if (roles && roles.length > 0) {
    for (let key in allowedUserRoles) {
      if (roles.some((role) => role === allowedUserRoles[key])) {
        isValidADRole = true;
        break;
      }
    }
  }

  if (producers && producers.length > 0) {
    const currentProducerRoles = getCurrentProducerRoles(
      producers,
      currentProducerNumber,
      authContext
    );

    const userRoleDictionary = new UserRoleDictionary().fillRoleTree(
      applicationRoleMap
    );

    for (let key in allowedUserRoles) {
      if (currentProducerRoles.includes(allowedUserRoles[Number(key)])) {
        isValidUserRole = true;
        break;
      } else if (
        hasValidParentRole(
          currentProducerRoles,
          userRoleDictionary,
          authContext,
          allowedUserRoles[Number(key)]
        )
      ) {
        isValidUserRole = true;
        break;
      }
    }
  }

  return isValidADRole || isValidUserRole;
};

const hasValidParentRole = (
  currentUserRoles: any[],
  userRoleDictionary: UserRoleDictionary,
  authContext: AuthorizationContext,
  requiredRole: string
) => {
  let parentRoles: string[] = [];
  if (AuthorizationContext.All === authContext) {
    userRoleDictionary.roleGroups.forEach((tree: Tree, context: string) => {
      parentRoles.push(
        ...userRoleDictionary.findAllParents(context, requiredRole)
      );
    });
  } else {
    parentRoles.push(
      ...userRoleDictionary.findAllParents(
        AuthorizationContext[authContext],
        requiredRole
      )
    );
  }

  if (parentRoles.length == 0) {
    return false;
  }

  let hasValidParentRole = false;
  for (let key in parentRoles) {
    if (currentUserRoles.includes(parentRoles[key])) {
      hasValidParentRole = true;
      break;
    }
  }

  return hasValidParentRole;
};

export const getAllowedProductionAreas = (
  roles: string[],
  producers: any,
  currentProducerNumber: number,
  roleMap
) => {
  let allowedProductionAreas: string[] = [];

  for (let productionArea in productionAreaConfig) {
    let currentProductionAreaCfg = productionAreaConfig[productionArea];
    if (
      isValidUserRole(
        currentProductionAreaCfg.allowedUserRoles,
        roles,
        producers,
        currentProducerNumber,
        currentProductionAreaCfg.authContext,
        roleMap
      )
    ) {
      allowedProductionAreas.push(ProductionArea[productionArea]);
    }
  }

  return allowedProductionAreas;
};

export const mapCmsNavItemsToMenuItems = (items: any[], producerNumber) => {
  const mapItems = (children: any[]): any[] => {
    const mapChildren = (result, object) => {
      const children = object.children.reduce(mapChildren, []);
      const mappedObject = children.length
        ? { items: children }
        : {
            link: !object.externalLink
              ? object.contentType === PublicationType.PowerBIReports
                ? `/reports/${object.referenceId}`
                : `/content/${object.referenceId}`
              : String(object.externalLink).toLowerCase().includes("carbo")
              ? `${object.externalLink}?selectedProducerNumber=${producerNumber}`
              : object.externalLink,
          };
      result.push({ ...mappedObject, name: object.title });
      return result;
    };

    return children.reduce(mapChildren, []);
  };

  return mapItems(items);
};

export default {
  mapCmsNavItemsToMenuItems,
  addProductionLine,
  getProductionLine,
  getLandingPage,
  isValidUserRole,
  getAllowedProductionAreas,
};
