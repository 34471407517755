import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  optionsBar: {
    height: "40px",
    "& > *": {
      minHeight: "100%",
    },
  },
  button: {
    lineHeight: 1,
    borderRadius: 0,
    fontFamily: "Avenir Next W1G",
    fontWeight: 500,
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#0B2158",
    },
  },
  leftMenuButton: {
    fontSize: "12px",
    fontFamily: "Avenir Next W1G",
    padding: "10px 0px !important",
  },
  buttonDisabled: {
    "&:hover": {
      backgroundColor: "unset",
      cursor: "default",
    },
  },
  highlighted: {
    backgroundColor: "#0077C6",
    "&:hover": {
      backgroundColor: "#0077C6",
    },
  },
  borderedButton: {
    borderRight: "1px #777 solid",
  },
  link: {
    display: "block",
    whiteSpace: "nowrap",
    fontSize: "12px",
    textDecoration: "none",
    color: "#FFFFFF !important",
    fontFamily: "Avenir Next W1G",
    fontWeight: 500,
  },
  linkSeparator: {
    color: "#777",
    margin: theme.spacing(1),
  },
  collapsingLink: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuItemContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    display: "contents",
  },
  toggleMenu: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    width: "100%",
  },
  options: {
    position: "absolute",
    right: "0px",
    height: "100%",
    "& > *": {
      textDecoration: "none",
      color: "#FFFFFF",
      fontSize: "12px",
    },
    overflow: "hidden",
    display: "inherit",
    textAlign: "left",
  },
}));

export default useStyles;
