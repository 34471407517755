import { createSlice } from "@reduxjs/toolkit";
import BeefDispatchListing from "../../beef/shared/models/BeefDispatchListing";
import BeefSlaughterNotificationResponseDTO from "../../beef/shared/models/BeefSlaughterNotificationResponseDTO";
import BeefForwardingNotificationResponseDTO from "../../beef/shared/models/BeefForwardingNotificationResponseDTO";
import CalfMedicalQuestionData from "../../beef/shared/models/CalfMedicalQuestionData";

export interface IBeef {
  healthQuestions: any[];
  medicalDataOfCalves: CalfMedicalQuestionData;
  beefDispatchListing: BeefDispatchListing;
  latestBirthIdValidationResult: any[];
  beefSlaughterNotification?: BeefSlaughterNotificationResponseDTO;
  beefTransportInfo: any;
  beefForwardingNotification?: BeefForwardingNotificationResponseDTO;
  beefSlaughterInfoReport: any[];
}

const initialState: IBeef = {
  healthQuestions: [],
  medicalDataOfCalves: new CalfMedicalQuestionData(),
  beefDispatchListing: new BeefDispatchListing(),
  latestBirthIdValidationResult: [],
  beefTransportInfo: [],
  beefSlaughterInfoReport: [],
};

const slice = createSlice({
  name: "beef",
  initialState: initialState,
  reducers: {
    healthQuestionsReceived: (state, action) => {
      state.healthQuestions = action.payload.data.ketjukysymykset;
    },
    healthQuestionsCleared: (state) => {
      state.healthQuestions = initialState.healthQuestions;
    },
    calfMedicalQuestionsReceived: (state, action) => {
      state.medicalDataOfCalves = action.payload.data.medicalDataOfCalves;
    },
    calfMedicalQuestionsCleared: (state, action) => {
      state.medicalDataOfCalves = initialState.medicalDataOfCalves;
    },
    beefDispatchListingReceived: (state, action) => {
      state.beefDispatchListing = action.payload.data;
    },
    beefSlaughterNotificationReceived: (state, action) => {
      state.beefSlaughterNotification = action.payload.data.content;
    },
    beefSlaughterNotificationCleared: (state) => {
      state.beefSlaughterNotification = initialState.beefSlaughterNotification;
    },
    beefForwardingNotificationReceived: (state, action) => {
      state.beefForwardingNotification = action.payload.data.content;
    },
    beefForwardingNotificationCleared: (state) => {
      state.beefForwardingNotification =
        initialState.beefForwardingNotification;
    },
    beefTransportInfoReceived: (state, action) => {
      state.beefTransportInfo = action.payload.data;
    },
    latestBirthIdValidationResultReceived: (state, action) => {
      state.latestBirthIdValidationResult = action.payload.data;
    },
    beefSlaughterInfoReportReceived: (state, action) => {
      state.beefSlaughterInfoReport = action.payload.data;
    },
    beefSlaughterInfoReportCleared: (state) => {
      state.beefSlaughterInfoReport = initialState.beefSlaughterInfoReport;
    },
    latestBirthIdValidationResultCleared: (state) => {
      state.latestBirthIdValidationResult =
        initialState.latestBirthIdValidationResult;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const {
  healthQuestionsReceived,
  healthQuestionsCleared,
  calfMedicalQuestionsReceived,
  calfMedicalQuestionsCleared,
  beefDispatchListingReceived,
  latestBirthIdValidationResultReceived,
  latestBirthIdValidationResultCleared,
  beefSlaughterNotificationReceived,
  beefSlaughterNotificationCleared,
  beefTransportInfoReceived,
  beefForwardingNotificationReceived,
  beefSlaughterInfoReportReceived,
  beefSlaughterInfoReportCleared,
  beefForwardingNotificationCleared,
} = slice.actions;

export default slice.reducer;
