import React from "react";
import { Grid } from "@material-ui/core";
import DatePicker from "../DatePicker/DatePicker";
import useStyles from "./TimeframePickerStyles";
import { Alert } from "@material-ui/lab";
import { t } from "../../services/langService";
import Button from "../Button/Button";
import InfoTooltip from "../InfoTooltip/InfoTooltip";

interface ITimeframePickerProps {
  selectedStartDate: string;
  selectedEndDate: string;
  startDateOnChange: Function;
  endDateOnChange: Function;
  onClick: Function;
  errorMsg?: string;
  startDateInfoText?: string;
  endDateInfoText?: string;
}

export const TimeframePicker: React.FC<ITimeframePickerProps> = ({
  selectedStartDate,
  selectedEndDate,
  startDateOnChange,
  endDateOnChange,
  onClick,
  errorMsg,
  startDateInfoText,
  endDateInfoText,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      spacing={1}
      className={classes.container}>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <DatePicker
          label={
            startDateInfoText ? (
              <InfoTooltip
                infoText={startDateInfoText}
                label={t("timeframePicker_startDate_label")}
              />
            ) : (
              t("timeframePicker_startDate_label")
            )
          }
          views={["month", "year"]}
          selectedDate={selectedStartDate}
          onChange={(e) => startDateOnChange(e)}
          readonly={false}
          autoOk={true}
          singleLine={true}
          margin="5px 5px"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3} lg={3}>
        <DatePicker
          label={
            endDateInfoText ? (
              <InfoTooltip
                infoText={endDateInfoText}
                label={t("timeframePicker_endDate_label")}
              />
            ) : (
              t("timeframePicker_endDate_label")
            )
          }
          views={["month", "year"]}
          selectedDate={selectedEndDate}
          onChange={(e) => endDateOnChange(e)}
          readonly={false}
          autoOk={true}
          singleLine={true}
          margin="5px 5px"
          fullWidth
        />
      </Grid>
      <Grid style={{ alignSelf: "flex-end" }} item xs={12} sm={6} md={2} lg={1}>
        <Button
          style={{ margin: "5px 5px" }}
          fullWidth
          type="ok"
          onClick={() => onClick()}>
          {t("timeframePicker_fetchButton_label")}
        </Button>
      </Grid>
      {errorMsg && (
        <Grid item xs={12} className={classes.errorMsg}>
          <Alert
            style={{ marginBottom: "5px" }}
            icon={false}
            color={"error"}
            classes={{ standardError: classes.alert }}>
            {errorMsg}
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default TimeframePicker;
