import React, { useEffect, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import { Box, Grid, Hidden, Tooltip, useTheme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../shared/components/Button/Button";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import ExpandableListView from "../../shared/components/ExpandableListView/ExpandableListView";
import WithTablePagination from "../../shared/hocs/withTablePagination";
import WithTableSorting from "../../shared/hocs/withTableSorting";
import useStyles from "./RaisingBatchesPageStyles";
import * as api from "../../shared/store/apiActions";
import { t } from "../../shared/services/langService";
import { getIsLoading } from "../../shared/store/ui/common";
import Helper from "../../shared/utils/helper";
import NotificationMessage from "../../shared/components/NotificationMessage/NotificationMessage";
import {
  breedingsCleared,
  hallFilterSelected,
  slaughterDataCleared,
  slaughterInfoSelected,
} from "../../shared/store/poultry";
import { IBreedingBatch } from "../shared/models/IBreedingBatch";
import { ISlaughter } from "../shared/models/ISlaughter";
import TimeframePicker from "../../shared/components/TimeframePicker/TimeframePicker";
import GeneralDataList, {
  IHeadCell,
} from "../../shared/components/GeneralDatalist/GeneralDatalist";
import { TableCell, TableRow } from "../../shared/components/Table/Table";
import WithContentBox from "../../shared/hocs/withContentBox";
import HelpIcon from "@material-ui/icons/Help";
import { ITimeframePickerUserProps } from "../../shared/hocs/withTimeframe";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DropdownMenu from "../../shared/components/DropdownMenu/DropdownMenu";
import { RootState } from "../../shared/store/rootReducer";

const GeneralDataListWithSortingPagination = WithTableSorting(
  WithTablePagination(GeneralDataList)
);

const GeneralDataListWithSortingContentBox = WithTableSorting(
  WithContentBox(GeneralDataList)
);

const ExpListViewWithSorting = WithTableSorting(ExpandableListView);

const RaisingBatchesPage = ({
  currentStartDate,
  currentEndDate,
  fetchCounter,
  startTimeOnChange,
  endTimeOnChange,
  onFetchClick,
  errorMsg,
}: ITimeframePickerUserProps) => {
  const classes = useStyles();
  const {
    filteredBreedings,
    halls,
    selectedHall,
    slaughterData,
    slaughters,
    diedInTransport,
    meatInspectionRejected,
  } = useSelector((state: RootState) => state.poultry);

  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const currentOrganisation = organizations.find(
    (o) => o.producerNumber === producerNumber
  );

  const isLoading = useSelector((state) => getIsLoading(state));
  const dispatch = useDispatch();
  const { rowId, slaughterId } = useParams();

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    if (!slaughterId) {
      dispatch(api.poultry.getBreedings(currentStartDate!, currentEndDate!));
    }
  }, [fetchCounter]);

  useEffect(() => {
    return () => {
      dispatch(breedingsCleared());
      dispatch(slaughterDataCleared());
    };
  }, []);

  useEffect(() => {
    if (slaughterId && rowId) {
      if (filteredBreedings && filteredBreedings?.length > 0) {
        dispatch(
          slaughterInfoSelected({ slaughterId: slaughterId, breedingId: rowId })
        );
      } else {
        dispatch(api.poultry.getSlaughterData(slaughterId, rowId));
      }
    }
    return () => {
      dispatch(slaughterDataCleared());
    };
  }, [slaughterId]);

  const handleDropdownMenuChange = (value) => {
    dispatch(hallFilterSelected({ selectedHall: value }));
  };

  const hallSelectListOptions = halls?.map((hall, ind) => ({
    name: ind.toString(),
    text: hall.label,
    value: hall.hall,
  }));

  const getRaisingBatchRowJsx = (row: IBreedingBatch, ind: number) => (
    <TableRow key={ind}>
      <Hidden smUp>
        <TableCell>
          {row.slaughterQuantity > 0 &&
            row.slaughterData &&
            row.slaughterData.length > 0 && (
              <Link
                id="raising-batches-row-link"
                to={`/poultry/raisingbatches/${row.breedingId}/${row.slaughterData[0].slaughterId}`}>
                {t("raisingBatches_slaughterInfo")}
              </Link>
            )}
        </TableCell>
      </Hidden>
      <TableCell>{row.hall}</TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(row.deliveryDate)}
      </TableCell>
      <TableCell>{row.chickQuantity}</TableCell>
      <TableCell>{row.butchingAge}</TableCell>
      <TableCell align="right">
        {Helper.dateToLocale(row.slaughterDate)}
      </TableCell>
      <TableCell>
        {row.slaughterQuantity > 0
          ? row.slaughterQuantity
          : row.estimatedSlaughterQuantity}
      </TableCell>
      <TableCell>{row.calcAverageWeight?.toFixed(3)}</TableCell>
      <TableCell>{row.calcDeathRatePercent?.toFixed(2)}</TableCell>
      <TableCell>{row.calcInControlAbandonedPercentage?.toFixed(2)}</TableCell>
      <TableCell>{row.calcPaidSlaughterQty?.toFixed(0)}</TableCell>
      <TableCell>{row.calcPaidSlaughterWeight?.toFixed(0)}</TableCell>
      <Hidden only="xs">
        <TableCell>
          {row.slaughterQuantity > 0 &&
            row.slaughterData &&
            row.slaughterData.length > 0 && (
              <Link
                id="raising-batches-row-link"
                to={`/poultry/raisingbatches/${row.breedingId}/${row.slaughterData[0].slaughterId}`}>
                {t("raisingBatches_slaughterInfo")}
              </Link>
            )}
        </TableCell>
      </Hidden>
    </TableRow>
  );

  const getSlaughtersRowJsx = (row: ISlaughter, ind: number) => (
    <TableRow key={ind}>
      <TableCell>
        <Link
          id="raising-batches-slaughtersrow-link"
          to={`/poultry/raisingbatches/${rowId}/${row.slaughterId}`}>
          {row.slaughterId}
        </Link>
      </TableCell>
      <TableCell>{row.hall}</TableCell>
    </TableRow>
  );

  const getSlaughterQuantityInfoCellJsx = (cell: IHeadCell) => (
    <Tooltip
      title={t("poultry_slaughterQuantity_info")}
      enterTouchDelay={100}
      leaveTouchDelay={4000}>
      <span style={{ whiteSpace: "nowrap" }}>
        {cell.label}{" "}
        <HelpIcon
          style={{
            fontSize: 16,
            verticalAlign: "sub",
            color: "#777",
          }}
        />
      </span>
    </Tooltip>
  );

  const raisingBatchesHeaderCells = () => {
    if (isSmUp) {
      return [
        { id: "hall", label: t("arrivingBatches_hall") },
        {
          id: "deliveryDate",
          label: t("arrivingBatches_deliveryDate"),
          isNumeric: true,
        },
        {
          id: "chickQuantity",
          label: t("arrivingBatches_chickQuantity"),
        },
        { id: "butchingAge", label: t("arrivingBatches_slaughterAge") },
        {
          id: "slaughterDate",
          label: t("arrivingBatches_slaughterDate"),
          isNumeric: true,
        },
        {
          id: "slaughterQuantity",
          label: t("raisingBatches_slaughterQuantity"),
          getCellJsx: getSlaughterQuantityInfoCellJsx,
        },
        {
          id: "calcAverageWeight",
          label: t("poultry_calcAverageWeight"),
        },
        {
          id: "calcDeathRatePercent",
          label: t("poultry_calcDeathRatePercent"),
        },
        {
          id: "calcInControlAbandonedPercentage",
          label: t("poultry_calcInControlAbandonedPercentage"),
        },
        {
          id: "calcPaidSlaughterQty",
          label: t("poultry_calcPaidSlaughterQty"),
        },
        {
          id: "calcPaidSlaughterWeight",
          label: t("poultry_calcPaidSlaughterWeight"),
        },
        {
          id: "slaughterInfo",
          label: t("raisingBatches_slaughterBatchInfo"),
        },
      ];
    } else {
      return [
        {
          id: "slaughterInfo",
          label: t("raisingBatches_slaughterBatchInfo"),
        },
        { id: "hall", label: t("arrivingBatches_hall") },
        {
          id: "deliveryDate",
          label: t("arrivingBatches_deliveryDate"),
          isNumeric: true,
        },
        {
          id: "chickQuantity",
          label: t("arrivingBatches_chickQuantity"),
        },
        { id: "butchingAge", label: t("arrivingBatches_slaughterAge") },
        {
          id: "slaughterDate",
          label: t("arrivingBatches_slaughterDate"),
          isNumeric: true,
        },
        {
          id: "slaughterQuantity",
          label: t("raisingBatches_slaughterQuantity"),
          getCellJsx: getSlaughterQuantityInfoCellJsx,
        },
        {
          id: "calcAverageWeight",
          label: t("poultry_calcAverageWeight"),
        },
        {
          id: "calcDeathRatePercent",
          label: t("poultry_calcDeathRatePercent"),
        },
        {
          id: "calcInControlAbandonedPercentage",
          label: t("poultry_calcInControlAbandonedPercentage"),
        },
        {
          id: "calcPaidSlaughterQty",
          label: t("poultry_calcPaidSlaughterQty"),
        },
        {
          id: "calcPaidSlaughterWeight",
          label: t("poultry_calcPaidSlaughterWeight"),
        },
      ];
    }
  };

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={6}>
          {slaughterId ? (
            <Fragment>
              <PageHeader level="1">
                {t("slaughterInfo_pageHeader")} {slaughterId}
              </PageHeader>
            </Fragment>
          ) : (
            <PageHeader level="1">
              {t("poultry_raisingBatches_title")}
            </PageHeader>
          )}
        </Grid>

        {slaughterId && (
          <Fragment>
            <Hidden smDown>
              <Grid item md={6}>
                <Fragment>
                  <Box displayPrint="none">
                    <PageHeader level="2" align="right">
                      {currentOrganisation ? currentOrganisation.name : ""}
                    </PageHeader>
                  </Box>
                  <PageHeader level="3" align="right">
                    {slaughterData ? slaughterData.notes : ""}
                  </PageHeader>
                </Fragment>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item xs={12}>
                <Fragment>
                  <Box displayPrint="none">
                    <PageHeader level="2" align="left">
                      {currentOrganisation ? currentOrganisation.name : ""}
                    </PageHeader>
                  </Box>
                  <PageHeader level="3" align="left">
                    {slaughterData ? slaughterData.notes : ""}
                  </PageHeader>
                </Fragment>
              </Grid>
            </Hidden>
          </Fragment>
        )}

        {rowId > -1 && (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Box displayPrint="none">
              <Button
                id="raising-batches-back-button"
                type="link"
                to={"/poultry/raisingbatches"}>
                {t("ui_back")}
              </Button>
            </Box>
          </Grid>
        )}

        {!rowId && (
          <Grid container>
            <Grid item xs={12}>
              <TimeframePicker
                selectedStartDate={currentStartDate!}
                selectedEndDate={currentEndDate!}
                startDateOnChange={startTimeOnChange!}
                endDateOnChange={endTimeOnChange!}
                onClick={onFetchClick!}
                errorMsg={errorMsg!}
                startDateInfoText={t("poultry_timeframe_startDateInfoText")}
                endDateInfoText={t("poultry_Timeframe_endDateInfoText")}
              />
            </Grid>
            {halls && halls.length > 0 && (
              <Grid
                item
                style={{
                  alignSelf: "center",
                  paddingLeft: "5px",
                  marginTop: "0px",
                }}
                xs={12}
                sm={6}
                md={3}>
                <DropdownMenu
                  id="raising-batches-hall-select"
                  onChange={(value) => handleDropdownMenuChange(value)}
                  selectedValue={selectedHall}
                  options={hallSelectListOptions ?? []}
                  label={t("poultry_hallFilter_label")}
                  singleLine={true}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <GeneralDataListWithSortingPagination
                headerCells={raisingBatchesHeaderCells()}
                rows={filteredBreedings ?? []}
                getRowJsx={getRaisingBatchRowJsx}
                isLoading={isLoading}
                order="desc"
                orderBy="slaughterDate"
                isShowAllDefault={true}
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      {rowId > -1 && (
        <Grid
          container
          spacing={0}
          alignItems="flex-start"
          justifyContent="space-between">
          <Grid
            container
            spacing={1}
            xs={12}
            sm={12}
            md={slaughterId ? 4 : 12}
            justifyContent="space-around"
            style={{ marginBottom: "8px" }}>
            <Grid item xs={12}>
              <GeneralDataListWithSortingContentBox
                isExpanded={true}
                title={t("raisingBatches_raisingBatchSlaughterInfo")}
                headerCells={[
                  {
                    id: "slaughterId",
                    label: t("raisingBatches_slaughterId"),
                  },
                  { id: "hall", label: t("arrivingBatches_hall") },
                ]}
                rows={slaughters ?? []}
                getRowJsx={getSlaughtersRowJsx}
                isLoading={isLoading}
                order="desc"
                orderBy="slaughterId"
              />
            </Grid>

            {slaughterId && (
              <Grid item xs={12}>
                <ExpListViewWithSorting
                  title={t("raisingBatches_slaughterInfo")}
                  rows={[
                    [
                      `${t("raisingBatches_slaughterId")}:`,
                      slaughterData?.slaughterId,
                    ],
                    [`${t("arrivingBatches_hall")}:`, slaughterData?.hall],
                    [
                      `${t("raisingBatches_rotation")}:`,
                      slaughterData?.rotationNr,
                    ],
                    [
                      `${t("arrivingBatches_slaughterDate")}:`,
                      Helper.dateToLocale(slaughterData?.slaughterDate),
                    ],
                    [
                      `${t("raisingBatches_targetAge")}:`,
                      slaughterData?.targetAge,
                    ],
                    [`${t("raisingBatches_age")}:`, slaughterData?.age],
                    [
                      `${t("raisingBatches_averageWeight")}:`,
                      slaughterData?.averageWeight.toFixed(3),
                    ],
                    [
                      `${t("raisingBatches_deliveredAmount")}:`,
                      slaughterData?.totalQuantity,
                    ],
                    [
                      `${t("raisingBatches_beforeSlaughterAbandoned")}:`,
                      slaughterData?.beforeSlaughterAbandoned,
                    ],
                    [
                      `${t("raisingBatches_inControlAbandoned")}:`,
                      `${slaughterData?.inControlAbandoned || ""} / ${
                        slaughterData?.inControlAbandonedPercent.toFixed(2) ||
                        ""
                      }`,
                    ],
                    [
                      `${t("raisingBatches_removedDuringRaising")} %:`,
                      slaughterData?.deathRatePercent.toFixed(2),
                    ],
                  ]}
                />
              </Grid>
            )}
          </Grid>

          {slaughterId && (
            <Grid
              container
              spacing={1}
              xs={12}
              sm={12}
              md={8}
              justifyContent="space-around">
              <Grid item style={{ paddingTop: "0", width: "100%" }}>
                <NotificationMessage
                  message={t("raisingBatches_vetInfo")}
                  severity={"info"}
                  style={{ fontSize: "1.15rem" }}
                />
              </Grid>
              <Grid item xs={12}>
                <ExpListViewWithSorting
                  title={t("raisingBatches_inspectionAbandonReasons")}
                  headers={[
                    //feature-5276: { content: t("raisingBatches_code") },
                    { content: t("raisingBatches_abandonReason") },
                    { content: t("raisingBatches_abandonedQuantity") },
                    { content: t("raisingBatches_abandonedPercent") },
                  ]}
                  rows={
                    meatInspectionRejected?.map((r) => [
                      //feature-5276: r.faultCode,
                      r.fault,
                      r.quantity,
                      r.abandonedPercent.toFixed(2),
                    ]) || []
                  }
                  emptyRowsText={""}
                />
              </Grid>

              <Grid item xs={12}>
                <ExpListViewWithSorting
                  title={t("raisingBatches_beforeSlaughterAbandoned")}
                  headers={[
                    //feature-5276: { content: t("raisingBatches_code") },
                    { content: t("raisingBatches_abandonReason") },
                    { content: t("raisingBatches_abandonedQuantity") },
                    { content: t("raisingBatches_abandonedPercent") },
                  ]}
                  rows={
                    diedInTransport?.map((r) => [
                      //feature-5276: r.faultCode,
                      r.fault,
                      r.quantity,
                      r.abandonedPercent.toFixed(2),
                    ]) || []
                  }
                  labelRowsPerPage={t("ui_rows")}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Fragment>
  );
};

export default RaisingBatchesPage;
