import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getListing } from "../../../shared/services/publicationService";
import Helper from "../../../shared/utils/helper";
import { Grid } from "@material-ui/core";
import PageHeader from "../../../shared/components/PageHeader/PageHeader";
import PageText from "../../../shared/components/PageText/PageText";
import useStyles from "./FrontpagePublicationListingStyles";
import theme from "../../../shared/themes";
import { getIsLoading } from "../../../shared/store/ui/common";
import { t } from "../../../shared/services/langService";
import { requested } from "../../../shared/store/api";
import { RootState } from "../../../shared/store/rootReducer";

interface PublicationListingProps {
  publicationType: "news" | "event" | "training";
  limit?: number;
}

const FrontpagePublicationListing: React.FC<PublicationListingProps> = ({
  publicationType,
  limit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [publications, setPublications] = useState<any[]>([]);

  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const isLoading = useSelector((state: RootState) => getIsLoading(state));

  const linkPrefix =
    publicationType === "news"
      ? "newsletters"
      : publicationType === "event"
      ? "events"
      : "trainings";

  const fetchPublications = (context) => {
    return requested({
      fn: async (dispatch) => {
        const response = await getListing({
          langCode: langCode,
          publicationContext: context,
          publicationType: publicationType,
          limit: limit,
        });

        setPublications(response?.status === 200 ? response.data : []);
      },
    });
  };

  useEffect(() => {
    if (langCode) {
      dispatch(fetchPublications(productionLine));
    }
  }, [langCode]);

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <PageHeader level={"1"} className={classes.header1}>
          {t(`publicationType_${publicationType}`)}
        </PageHeader>
      </Grid>
      {!isLoading &&
        publications?.map((p, ind) => (
          <Grid item xs={12} key={ind}>
            <PageText type={"normal"}>
              {Helper.dateToLocale(p.startTime ? p.startTime : p.publishDate)}
              {p.startTime &&
                p.endTime &&
                Helper.dateToLocale(p.startTime) !==
                  Helper.dateToLocale(p.endTime) && (
                  <span> - {Helper.dateToLocale(p.endTime)}</span>
                )}
            </PageText>

            <Link
              className={classes.publicationLink}
              to={`/${linkPrefix}/${p.referenceId}`}>
              <PageText
                type={"bold"}
                style={{
                  color: theme.palette.primary.main,
                  fontSize: "0.92rem",
                }}>
                {p.title}
              </PageText>
            </Link>
          </Grid>
        ))}
      <Grid item xs={12}>
        {publications && publications.length > 0 && (
          <Link
            to={`/${linkPrefix}`}
            style={{ textDecorationColor: theme.palette.secondary.main }}>
            <PageText
              style={{ color: theme.palette.secondary.main }}
              type={"normal"}>
              {t("publicationContent_showAll")}
            </PageText>
          </Link>
        )}
        {!publications ||
          (publications.length < 1 && (
            <PageText
              style={{ color: theme.palette.primary.main }}
              type={"normal"}>
              {publicationType === "news"
                ? t("publicationContent_noNews")
                : publicationType === "event"
                ? t("publicationContent_noEvents")
                : t("publicationContent_noTrainings")}
            </PageText>
          ))}
      </Grid>
    </Grid>
  );
};

export default FrontpagePublicationListing;
