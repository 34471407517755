import { Grid } from "@material-ui/core";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import GeneralDataList from "../../../../shared/components/GeneralDatalist/GeneralDatalist";
import { TableCell, TableRow } from "../../../../shared/components/Table/Table";
import { IDebtInvoice } from "../../../../shared/models/Cooperative";
import { t } from "../../../../shared/services/langService";
import { getIsLoading } from "../../../../shared/store/ui/common";
import helper from "../../../../shared/utils/helper";
import { cooperative } from "../../../../shared/store/apiActions";
interface IProps {
  isFrontPage: boolean;
  rows: IDebtInvoice[];
  [x: string]: any;
}

const DebtInvoiceGrid: React.FC<IProps> = ({ isFrontPage, rows, ...rest }) => {
  const isLoading = useSelector((state) => getIsLoading(state));

  const dispatch = useDispatch();
  const download = (invoiceNumber) => {
    dispatch(cooperative.downloadDebtInvoicePdf(invoiceNumber));
  };

  const getFrontpageRowJsx = (row: IDebtInvoice, ind: number) => (
    <TableRow key={ind}>
      <TableCell>
        <Link to="#" onClick={() => download(row.invoiceNumber)}>
          {row.invoiceNumber}
        </Link>
      </TableCell>
      <TableCell align="right">
        {helper.dateToLocale(row.dueDate.toString())}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.amountIncludingTaxes.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  const getRowJsx = (row: IDebtInvoice, ind: number) => (
    <TableRow key={ind}>
      <TableCell>
        <Link to="#" onClick={() => download(row.invoiceNumber)}>
          {row.invoiceNumber}
        </Link>
      </TableCell>
      <TableCell align="right">
        {helper.dateToLocale(row.voucherDate.toString())}
      </TableCell>
      <TableCell align="right">
        {helper.dateToLocale(row.dueDate.toString())}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.amountIncludingTaxes.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.openBalance.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  const headerCells = {
    frontPage: [
      {
        id: "invoiceNumber",
        label: t("cooperative_frontpageInvoices_invoiceNumber"),
        isNumeric: false,
        width: "33%",
      },
      {
        id: "dueDate",
        label: t("cooperative_dueDate"),
        isNumeric: true,
        width: "33%",
      },
      {
        id: "amountIncludingTaxes",
        label: t("economy_frontpageInvocies_amountIncludingTaxes"),
        isNumeric: true,
        width: "34%",
      },
    ],
    main: [
      {
        id: "invoiceNumber",
        label: t("cooperative_invoiceNumber"),
        isNumeric: false,
      },

      {
        id: "voucherDate",
        label: t("cooperative_voucherDate"),
        isNumeric: true,
      },

      {
        id: "dueDate",
        label: t("cooperative_dueDate"),
        isNumeric: true,
      },
      {
        id: "amountIncludingTaxes",
        label: t("cooperative_amountIncludingTaxes"),
        isNumeric: true,
      },
      {
        id: "openBalance",
        label: t("cooperative_openBalance"),
        isNumeric: true,
      },
    ],
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <GeneralDataList
          headerCells={isFrontPage ? headerCells.frontPage : headerCells.main}
          rows={rows}
          getRowJsx={isFrontPage ? getFrontpageRowJsx : getRowJsx}
          isLoading={isLoading}
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

export default DebtInvoiceGrid;
