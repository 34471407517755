import React from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import useStyles from "./UserContentStyles";
import CurrentUserPage from "../CurrentUserPage/CurrentUserPage";
import Content from "../../shared/components/Content/Content";
import ShopCardPage from "../ShopCardPage/ShopCardPage";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import { allProductionLines } from "../../shared/enums/productionLines";
import { mainUserRoles, UserRole } from "../../shared/enums/CoreEnums";
import { AuthorizationContext } from "../../management/shared/models/Users";
import { RootState } from "../../shared/store/rootReducer";

const UserContent = () => {
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const classes = useStyles({ productionLine });

  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      <Switch>
        <Route path="/user/current" component={CurrentUserPage} />
        <ProtectedRoute
          path="/user/shopcard"
          component={ShopCardPage}
          allowedProductionLines={allProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.SecondaryMainUser]}
          authContext={AuthorizationContext.MainUser}
        />
      </Switch>
    </Content>
  );
};

export default UserContent;
