import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React from "react";
import useStyles from "./CheckboxStyles";

export interface CheckboxProps {
  label?: string;
  onChange?: any;
  onChangeAsBoolean?: boolean;
  defaultChecked?: boolean;
  required?: boolean;
  [x: string]: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange = {},
  defaultChecked,
  required = false,
  onChangeAsBoolean = true,
  ...rest
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    if (onChangeAsBoolean) {
      onChange(event.target.checked);
    } else {
      onChange(event);
    }
  };

  return (
    <div>
      <FormControlLabel
        color={"primary"}
        className={classes.label}
        label={label}
        control={
          <MuiCheckbox
            defaultChecked={defaultChecked}
            onChange={(event) => handleChange(event)}
            color={"secondary"}
            disableFocusRipple={true}
            disableRipple={true}
            disableTouchRipple={true}
          />
        }
        {...rest}
      />
      {required && (
        <Typography
          display={"inline"}
          variant={"body1"}
          style={{ color: "#cf2d3a", marginLeft: "4px" }}>
          *
        </Typography>
      )}
    </div>
  );
};

export default Checkbox;
