import { makeStyles } from "@material-ui/core";
import theme from "../../themes";

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: "#afd3cc",
    fontFamily: "Avenir Next W1G",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000D80",
    border: "1px solid",
    borderColor: "#000D80",
    padding: "5px 5px 5px 5px",
  },
  info: {
    backgroundColor: "#E6E6E6",
    fontFamily: "Avenir Next W1G",
    fontSize: "14px",
    color: "#000D80",
    border: "1px solid",
    borderColor: "#000D80",
  },
}));

export default useStyles;
