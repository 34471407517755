import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: "8px"
  },
  scrollPaper: {
    marginTop: "10vh",
    alignItems: "baseline"
  }
}));

export default useStyles;