import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tablePaginationToolbar: {
    "& > *:nth-child(2), & > *:nth-child(3)": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
}));

export default useStyles;
