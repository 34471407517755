import React, { Fragment, useState } from "react";
import { Grid } from "@material-ui/core";

import InputField from "../components/InputField/InputField";

export interface IWithTableFilteringProps {
  rows: any[] | null;
  filterByProperty: string;
  filterTermPrefixRegex?: string;
  onFilterTyped?: Function;
  inputFieldLabel?: string;
  inputFieldPlaceholder?: string;
}

const withTableFiltering = <P extends object>(
  TableComponent: React.ComponentType<P>
): React.FC<P & IWithTableFilteringProps> => {
  return ({
    rows,
    filterByProperty,
    filterTermPrefixRegex = "",
    onFilterTyped = () => {},
    inputFieldLabel = "",
    inputFieldPlaceholder = "",
    ...props
  }: IWithTableFilteringProps) => {
    const [filterTerm, setFilterTerm] = useState<string>("");

    const filterChanged = (term: string) => {
      const regex = new RegExp("^[a-zA-Z0-9_.-]*$");
      const isMatch = regex.test(term);
      if (isMatch) {
        onFilterTyped(term);
        setFilterTerm(term);
      }
    };

    const getFilter = (rows: any): any[] | null =>
      rows?.filter((row) => {
        const regex = new RegExp(`${filterTermPrefixRegex}${filterTerm}`, "i");
        const isMatch = regex.test(row[filterByProperty]);
        return isMatch;
      }) || null;

    return (
      <Fragment>
        <Grid item xs={12} md={3}>
          <InputField
            margin={"0px 5px 0px 0px"}
            label={inputFieldLabel}
            placeholder={inputFieldPlaceholder}
            fullWidth={true}
            onChange={(e) => filterChanged(e.target.value)}
            value={filterTerm}
          />
        </Grid>
        <TableComponent {...(props as P)} rows={getFilter(rows)} />
      </Fragment>
    );
  };
};

export default withTableFiltering;
