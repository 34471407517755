import http from "../../../../shared/services/httpService";
import SlaughterReportBaseType from "../../../../shared/models/SlaughterReportListingDTO";

const API_URL = `report`;

export const getPorkSlaughterInfoReport = async (
  startDate: string,
  endDate: string
) => {
  const resp = await http.get<SlaughterReportBaseType>(
    `${API_URL}/pork/${startDate}/${endDate}`
  );
  return resp;
};

export default {
  getPorkSlaughterInfoReport,
};
