import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import useStyles from "./GeneralContentStyles";
import GuidesPage from "../GuidesPage/GuidesPage";
import ContactsPage from "../ContactsPage/ContactsPage";
import LearnSomethingNewPage from "../LearnSomethingNewPage/LearnSomethingNewPage";
import Content from "../../shared/components/Content/Content";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import { animalProductionLines } from "../../shared/enums/productionLines";
import { AuthorizationContext } from "../../management/shared/models/Users";
import UserFeedbackPage from "../UserFeedbackPage/UserFeedbackPage";
import Notification from "../../shared/components/Notification/Notification";
import {
  errorHandlers,
  successHandlers,
} from "../../shared/utils/notificationHandler";
import { RootState } from "../../shared/store/rootReducer";

const GeneralContent = () => {
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const classes = useStyles({ productionLine });
  const [showRoutes, setShowRoutes] = useState(true);
  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      {showRoutes && (
        <Switch>
          <Route path="/contacts" component={ContactsPage} />
          <Route path="/guides" component={GuidesPage} />
          <Route path="/feedback" component={UserFeedbackPage} />
          <ProtectedRoute
            path="/learnsomethingnew/:categoryInd?"
            component={LearnSomethingNewPage}
            allowedProductionLines={animalProductionLines}
            authContext={AuthorizationContext.MainUser}
          />
        </Switch>
      )}
      <Notification
        onChange={(showNotification) => setShowRoutes(!showNotification)}
        errorHandlers={[errorHandlers.feedbackErrorHandler]}
        successHandlers={[successHandlers.feedbackSuccesshandler]}
      />
    </Content>
  );
};

export default GeneralContent;
