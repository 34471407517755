import React from "react";
import { Switch, Route } from "react-router-dom";
import { useState } from "react";
import Content from "../../shared/components/Content/Content";
import CapitalInfoPage from "../CapitalInfoPage/CapitalInfoPage";
import MainPage from "../MainPage/MainPage";
import { useDispatch } from "react-redux";

import useStyles from "./CooperativeContentStyles";
import DebtInvociesPage from "../DebtInvoicesPage/DebtInvoicesPage";
import withTimeframe from "../../shared/hocs/withTimeframe";
import moment from "moment";
import Button from "../../shared/components/Button/Button";
import { t } from "../../shared/services/langService";
import Notification from "../../shared/components/Notification/Notification";
import { errorHandlers } from "../../shared/utils/notificationHandler";

const DebtInvoicesWithTimeFrame = withTimeframe(DebtInvociesPage);

const CooperativeContent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showRoutes, setShowRoutes] = useState(true);

  const getBackButton = () => (
    <Button id="cooperative-backtomain-button" type="link" to={"/cooperative"}>
      {t("cooperative_backToMain")}
    </Button>
  );
  // TODO: Route protection after Osuuskunta changes
  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      {showRoutes && (
        <Switch>
          <Route path="/cooperative/capitalinfo" component={CapitalInfoPage} />
          <Route
            path="/cooperative/debtInvoices"
            render={() => (
              <DebtInvoicesWithTimeFrame
                startTime={moment()
                  .subtract(2, "years")
                  .startOf("year")
                  .format("YYYY-MM-DD")}
                endTime={moment().endOf("year").format("YYYY-MM-DD")}
              />
            )}
          />
          <Route path="/cooperative" component={MainPage} />
        </Switch>
      )}
      <Notification
        onChange={(showNotification) => setShowRoutes(!showNotification)}
        errorHandlers={[errorHandlers.cooperativeErrorHandler]}
      />
    </Content>
  );
};

export default CooperativeContent;
