import { requested, requestedError, requestedSuccess } from "../api";
import { loadingBegan, loadingEnded } from "../ui/common";
import http from "../../services/httpService";
import auth from "../../services/authService";

const createConfigObj = (resp, errResp: any = null) => {
  return {
    url: resp?.config?.url,
    method: resp?.config?.method,
    data: errResp?.data || resp?.config?.data,
    status: errResp?.status,
  };
};

export const getPublicAsyncActionWrapper = (dispatch, fn, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      getPublicActionWrapper(
        dispatch,
        async () => {
          const response = await fn();
          return resolve(response);
        },
        getState
      );
    } catch (error) {
      return reject();
    }
  });

const getPublicActionWrapper = async (dispatch, fn, getState) => {
  dispatch({ type: loadingBegan.type });
  getActionWrapper(dispatch, fn, getState);
};

const getActionWrapper = async (dispatch, fn, getState) => {
  try {
    const tokenResp = await auth.getAccessTokenResponse(
      getState().auth.account
    );
    http.setRequestHeaders({
      authorization: `Bearer ${tokenResp.accessToken}`,
      "x-producerportal-currentproducer": getState().ui.settings.producerNumber,
    });
    const resp = await fn(dispatch, getState);
    dispatch({ type: loadingEnded.type });
    dispatch(requestedSuccess(createConfigObj(resp)));
  } catch (err) {
    dispatch(requestedError(createConfigObj(err, err.response)));
    dispatch({ type: loadingEnded.type });
    throw err;
  }
};

const api = ({ dispatch, getState }) => (next) => async (action) => {
  if (action.type !== requested.type) return next(action);

  dispatch({ type: loadingBegan.type });

  next(action);

  const { fn } = action.payload;

  try {
    getActionWrapper(dispatch, fn, getState);
  } catch (error) {}
};

export default api;
