import { makeStyles } from "@material-ui/core";
import theme from "../../../shared/themes";

const useStyles = makeStyles({

  header1: {
    color: theme.palette.primary.main,
    paddingTop: "8px",
    fontSize: "1.15rem",
    fontWeight: "bolder",
    letterSpacing: "0.1px",
    fontFamily: "Avenir Next W1G Bold",
  },

  content: {
    height: "100%",
    width: "100%",
  },

  publicationLink: {
    textDecoration: "none"
  }
});

export default useStyles;
