import React, { Fragment } from "react";
import useStyles from "./GeneralDatalistStyles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderRow,
  TableRow,
} from "../Table/Table";

import { TableContainer } from "@material-ui/core";
import { t } from "../../services/langService";
import ColumnGroup, { IColumn } from "../ColumnGroup/ColumnGroup";
import { WithTableSortingProps } from "../../hocs/withTableSorting";

interface IWithTableSortingProps extends WithTableSortingProps {
  onRequestSort?: Function;
  enableSorting?: boolean;
}

interface ITableProps extends IWithTableSortingProps {
  rows: any[];
  headerText?: string;
  headerCells: IHeadCell[];
  columnGroup?: IColumn[];
  getRowJsx: (row, index) => {};
  setPagination?: React.Dispatch<any>;
  isLoading: boolean;
}

export interface IHeadCell {
  id: string;
  label: string;
  isNumeric?: boolean;
  centerContent?: boolean;
  description?: string;
  getCellJsx?: (cell, index) => {};
}

const GeneralDataList = React.memo<ITableProps>(
  ({
    rows,
    headerText,
    headerCells,
    columnGroup,
    isLoading,
    getRowJsx,
    order = "",
    orderBy = "",
    onRequestSort = () => {},
    enableSorting = true,
  }) => {
    const classes = useStyles();

    return (
      <Fragment>
        <TableContainer>
          <Table>
            {columnGroup && <ColumnGroup columns={columnGroup} />}

            <TableHead>
              {headerText && (
                <TableRow>
                  <TableCell colSpan={12}>{headerText}</TableCell>
                </TableRow>
              )}
              <TableHeaderRow
                onRequestSort={(id) => onRequestSort(id)}
                order={order}
                orderBy={orderBy}
                columnInfo={headerCells}
                sorting={enableSorting}>
                {headerCells.map((cell, index) => {
                  if (cell.getCellJsx) {
                    return (
                      <TableCell
                        key={cell.id}
                        align={
                          cell?.centerContent
                            ? "center"
                            : cell.isNumeric
                            ? "right"
                            : "left"
                        }>
                        {cell.getCellJsx(cell, index)}
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={cell.id}
                      align={
                        cell?.centerContent
                          ? "center"
                          : cell.isNumeric
                          ? "right"
                          : "left"
                      }>
                      {cell.label}
                    </TableCell>
                  );
                })}
              </TableHeaderRow>
            </TableHead>

            <TableBody>
              {!isLoading &&
                rows.length > 0 &&
                rows.map((row, index) => {
                  return getRowJsx(row, index);
                })}
              {!isLoading && Number(rows.length) === 0 && (
                <TableRow style={{ height: 42 }}>
                  <TableCell colSpan={8}>
                    {t("animalNotification_noRowsFound")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Fragment>
    );
  }
);

export default GeneralDataList;
