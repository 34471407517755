import React from "react";
import { Switch, Route } from "react-router-dom";
import useStyles from "./PublicationContentStyles";
import { useSelector } from "react-redux";
import Content from "../../shared/components/Content/Content";
import PublicationPage from "../../publications/PublicationPage/PublicationPage";
import PublicationListingPage from "../../publications/PublicationListingPage/PublicationListingPage";
import VideoPage from "../VideoPage/VideoPage";
import { RootState } from "../../shared/store/rootReducer";

const PublicationContent = () => {
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const classes = useStyles({ productionLine });
  return (
    <Content
      backGroundStyles={classes.backGroundStyles}
      gridContainerStyles={classes.gridContent}>
      <Switch>
        <Route
          path="/newsletters/:pageNameOrReferenceId"
          render={() => <PublicationPage publicationType={"news"} />}
        />
        <Route
          path="/newsletters"
          render={() => <PublicationListingPage publicationType={"news"} />}
        />
        <Route
          path="/events/:pageNameOrReferenceId"
          render={() => <PublicationPage publicationType={"event"} />}
        />
        <Route
          path="/events"
          render={() => <PublicationListingPage publicationType={"event"} />}
        />
        <Route
          path="/content/:pageNameOrReferenceId"
          render={() => <PublicationPage publicationType={"content"} />}
        />
        <Route
          path="/trainings/:pageNameOrReferenceId"
          render={() => <PublicationPage publicationType={"event"} />}
        />
        <Route
          path="/trainings"
          render={() => <PublicationListingPage publicationType={"training"} />}
        />
        <Route path="/video/:name" component={VideoPage} />
      </Switch>
    </Content>
  );
};

export default PublicationContent;
