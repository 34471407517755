import { createSlice } from "@reduxjs/toolkit";
import { OperationUnit } from "../enums/CoreEnums";
import { IUserEntity } from "../models/Cooperative";
import Helper from "../utils/helper";
import { IFileStructContainer } from "../models/FileListing";
import { IFarmCapacity, IFarmContactItemsGroup } from "../models/FarmData";

//TODO: Modify IOrganization as per 3392 backend api changes, once available.
export interface IOrganization {
  name: string;
  businessId: string;
  producerNumber: number;
  isMember: boolean;
  operationUnit: OperationUnit;
  checkRequired: boolean;
  productionLines: any;
  livestockVenues: any[];
  osuuskuntaUsers: IUserEntity[];
  farmCapacity: IFarmCapacity;
  phoneNumbers: IFarmContactItemsGroup[];
  emailAddresses: IFarmContactItemsGroup[];
  eInvoiceInUse: boolean;
  bankAccountNumber: string;
  producerAddress: IPostalAddress;
  generalAgreement: IGeneralAgreement[];
}

export interface IPostalAddress {
  streetAddress: string;
  postNumber: string;
  postOffice: string;
}

export interface IGeneralAgreement {
  id: string;
  name: string;
}

export interface IRegionalManagerInfo {
  name: string;
  phoneNumber: string;
  emailAddress: string;
}

interface IUser {
  organizations: IOrganization[];
  organizationsForAutocompleteOptions: IOrganization[];
  fileListing?: IFileStructContainer;
  roleMap: any[];
  regionalManagerInfo: IRegionalManagerInfo | undefined;
  organizationDataUpdatedByUser: boolean;
  lastVisitedOrganizationChecked: boolean | undefined;
  lastVisitedOrganization: number | undefined;
}

const initialState: IUser = {
  organizations: [],
  organizationsForAutocompleteOptions: [],
  roleMap: [],
  regionalManagerInfo: undefined,
  organizationDataUpdatedByUser: false,
  lastVisitedOrganizationChecked: undefined,
  lastVisitedOrganization: undefined,
};

const slice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    userOrganizationsReceived: (state, action) => {
      state.organizations = action.payload.data;
      state.organizationDataUpdatedByUser = false;
    },
    userRoleMapReceived: (state, action) => {
      state.roleMap = action.payload.data;
    },
    userOrganizationAutocompleteOptionsReceived: (state, action) => {
      state.organizationsForAutocompleteOptions = action.payload.data;
    },
    userFileListingReceived: (state, action) => {
      state.fileListing = action.payload.data;
    },
    userFileListingCleared: (state) => {
      state.fileListing = initialState.fileListing;
    },
    userFileDownloaded(state, action) {
      Helper.downloadFile(action.payload.data, action.payload.filename);
    },
    userRegionalManagerInfoReceived: (state, action) => {
      state.regionalManagerInfo = action.payload.data;
    },
    userOrganizationDataUpdatedByUser(state, action) {
      state.organizationDataUpdatedByUser = action.payload.data;
    },
    userLastVisitedOrganizationUpdated: (state, action) => {
      state.lastVisitedOrganizationChecked = true;
      state.lastVisitedOrganization = action.payload.data;
    },
  },
});

export const {
  userOrganizationsReceived,
  userRoleMapReceived,
  userOrganizationAutocompleteOptionsReceived,
  userFileListingReceived,
  userFileDownloaded,
  userFileListingCleared,
  userRegionalManagerInfoReceived,
  userOrganizationDataUpdatedByUser,
  userLastVisitedOrganizationUpdated,
} = slice.actions;

export default slice.reducer;

export const getOrganizationByProducerNumber = (
  producerNumber,
  productionLine = ""
) => (state) =>
  state.user.organizations?.find(
    (o) =>
      o.producerNumber == producerNumber &&
      (!productionLine || o.productionLines[productionLine])
  ) || {};
