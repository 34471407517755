import React from "react";
import ClearRoundedlcon from "@material-ui/icons/ClearRounded";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import useStyles from "./NotificationBannerStyles";
import PageHeader from "../PageHeader/PageHeader";
import Button from "../Button/Button";

export interface NotificationBannerProps {
  text: string;
  type: "ok" | "error";
  primaryButtonText: string;
  secondaryButtonText?: string;
  onClicked: any;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({
  text,
  type,
  primaryButtonText,
  secondaryButtonText,
  onClicked,
}) => {
  const classes = useStyles();

  const handleClicked = (buttonClicked: string) => {
    onClicked(buttonClicked);
  };

  return (
    <div className={classes.root}>
      {type === "ok" && (
        <CheckOutlinedIcon
          style={{
            fontSize: 50,
            marginBottom: "15px",
            backgroundColor: "#E2F5DA",
            padding: "8px",
            borderRadius: "100px",
            color: "274E04",
          }}
        />
      )}

      {type === "error" && (
        <ClearRoundedlcon
          style={{
            fontSize: 50,
            marginBottom: "15px",
            backgroundColor: "#FAE9EB",
            padding: "8px",
            borderRadius: "100px",
            color: "#923F52",
          }}
        />
      )}

      <PageHeader id="notification-banner-description" level="2">
        {text}
      </PageHeader>

      <div className={classes.buttonContainer}>
        <div className={classes.primaryButtonContainer}>
          <Button
            onClick={() => handleClicked("primary")}
            type={"ok"}
            fullWidth>
            {primaryButtonText}
          </Button>
        </div>
        {secondaryButtonText && (
          <div className={classes.secondaryButtonContainer}>
            <Button
              onClick={() => handleClicked("secondary")}
              type={"ok"}
              fullWidth>
              {secondaryButtonText}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationBanner;
