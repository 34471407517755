import axios from "axios";
import authenticator from "../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.response.use(
  (resp) => resp,
  (err) => {
    if (err.response.status === 401) {
      authenticator.logout();
    }
    console.error("response error", err);
    return Promise.reject(err);
  }
);

const setRequestHeaders = (headers: any) => {
  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    ...headers,
  };
};

export default {
  setRequestHeaders,
  get: axios.get,
  put: axios.put,
  post: axios.post,
  delete: axios.delete,
  request: axios.request,
};
