import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";

import ContentBox from "../../shared/components/ContentBox/ContentBox";
import DatePicker from "../../shared/components/DatePicker/DatePicker";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import withTableSorting from "../../shared/hocs/withTableSorting";
import {
  ICapitalInfo,
} from "../../shared/models/Cooperative";
import { t } from "../../shared/services/langService";
import { cooperative } from "../../shared/store/apiActions";
import { getIsLoading } from "../../shared/store/ui/common";
import helper from "../../shared/utils/helper";
import useStyles from "../CapitalInfoPage/CapitalInfoPageStyles";
import CooperativeInfo from "../shared/components/CooperativeInfo/CooperativeInfo";
import InterestPaymentGrid from "../shared/components/InterestPaymentGrid/InterestPaymentGrid";
import { stateReseted } from "../../shared/store/createStore";
import { RootState } from "../../shared/store/rootReducer";

const InterestPaymentGridWithSortingPagination = withTableSorting(
  InterestPaymentGrid
);

const CapitalInfoPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => getIsLoading(state));

  const [selectedDate, setSelectedDate] = useState<string>(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState<string>("");

  const selectedDateOnChange = (momentDate) => {
    if (momentDate) {
      setSelectedDate(momentDate.startOf("month").format("YYYY-MM-DD"));
    }
  };

  const { capitalInfo, interestPayments } = useSelector(
    (state: RootState) => state.cooperative
  );

  useEffect(() => {
    const tmpSelectedEndDate = moment(selectedDate)
      .endOf("year")
      .format("YYYY-MM-DD");
    setSelectedEndDate(tmpSelectedEndDate);
    dispatch(cooperative.getCapitalInfo(selectedDate, tmpSelectedEndDate));
    dispatch(cooperative.getInterestPayments(selectedDate, tmpSelectedEndDate));
  }, [selectedDate]);

  useEffect(() => {
    return () => {
      dispatch(stateReseted());
    };
  }, []);

  return (
    <Fragment>
      <Grid container alignItems="flex-end" spacing={1}>
        <Grid item xs={12}>
          <PageHeader level="1">{t("cooperative_capital_info_h1")}</PageHeader>
        </Grid>

        <Grid item xs={12} md={6}>
          <CooperativeInfo />
        </Grid>
        <Grid item xs={12} md={6}>
          <DatePicker
            id="cooperative-info-date-select"
            label={t("cooperative_chosenYear")}
            views={["year"]}
            selectedDate={selectedDate}
            onChange={(e) => selectedDateOnChange(e)}
            readonly={false}
            autoOk={true}
            singleLine={true}
            margin="5px 5px"
            format={"YYYY"}
            fullWidth
            minDate={moment("01.01.1998").format("YYYY-MM-DD")}
            maxDate={moment().format("YYYY-MM-DD")}
          />
        </Grid>
        <Grid item xs={12}>
          <CapitalInfoDetails
            selectedEndDate={selectedEndDate}
            capitalInfo={capitalInfo}
          />
        </Grid>
        <Grid item xs={12}>
          <ContentBox
            title={t("cooperative_interestPayment")}
            isExpandable={false}>
            <InterestPaymentGridWithSortingPagination
              isFrontPage={false}
              rows={interestPayments ?? []}
              enableSorting={true}
              orderBy="paymentDate"
              order="desc"
            />
          </ContentBox>
        </Grid>
      </Grid>
    </Fragment>
  );
};

interface ICapitalInfoDetailProps {
  selectedEndDate: string;
  capitalInfo?: ICapitalInfo;
}

const CapitalInfoDetails: React.FC<ICapitalInfoDetailProps> = ({
  selectedEndDate,
  capitalInfo,
}) => {
  const addCurrencySuffix = (value: string | number) => {
    return `${value} €`;
  };

  const addValueFont = (value) => (
    <Typography
      style={{
        fontFamily: "Avenir Next W1G Bold",
        fontSize: "1.1rem",
        fontWeight: "bolder",
      }}
      variant={"h4"}
      color={"primary"}>
      {helper.addThousandSeparator(value)}
    </Typography>
  );

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <ContentBox title={t("cooperative_shareNumber")} isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(capitalInfo?.shareCount)}
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox
            title={t("cooperative_paidShareCapitalDebt")}
            isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(
                  capitalInfo
                    ? addCurrencySuffix(
                        capitalInfo.paidShareCapitalDebt.toFixed(2)
                      )
                    : ""
                )}
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox
            title={t("cooperative_paidVoluntaryAShare")}
            isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(
                  capitalInfo
                    ? addCurrencySuffix(
                        capitalInfo.paidVoluntaryAShare.toFixed(2)
                      )
                    : ""
                )}
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox
            title={t("cooperative_oneShareReferenceValue")}
            isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(
                  capitalInfo
                    ? capitalInfo.oneShareReferenceValue > 0
                      ? addCurrencySuffix(
                          capitalInfo.oneShareReferenceValue.toFixed(2)
                        )
                      : t("cooperative_noOneShareValue")
                    : ""
                )}
              </Grid>
            </Grid>
          </ContentBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentBox
            title={t("cooperative_shareCapitalDebt")}
            isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(
                  capitalInfo
                    ? addCurrencySuffix(
                        capitalInfo.totalShareCapitalDebt.toFixed(2)
                      )
                    : ""
                )}
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox
            title={t("cooperative_unpaidShareCapitalDebt")}
            isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(
                  capitalInfo
                    ? addCurrencySuffix(
                        capitalInfo.unpaidShareCapitalDebt.toFixed(2)
                      )
                    : ""
                )}
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox
            title={t("cooperative_paidVoluntaryBShare")}
            isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(
                  capitalInfo
                    ? addCurrencySuffix(
                        capitalInfo.paidVoluntaryBShare.toFixed(2)
                      )
                    : ""
                )}
              </Grid>
            </Grid>
          </ContentBox>
          <ContentBox
            title={t("cooperative_oneShareNominalValue")}
            isExpandable={false}>
            <Grid container>
              <Grid item xs={12}>
                {addValueFont(
                  capitalInfo
                    ? capitalInfo.oneShareNominalValue
                      ? addCurrencySuffix(
                          capitalInfo.oneShareNominalValue.toFixed(2)
                        )
                      : t("cooperative_noOneShareValue")
                    : ""
                )}
              </Grid>
            </Grid>
          </ContentBox>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CapitalInfoPage;
