import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import GeneralDataList from "../../../shared/components/GeneralDatalist/GeneralDatalist";
import { TableCell, TableRow } from "../../../shared/components/Table/Table";
import ProductionArea from "../../../shared/enums/productionLines";
import { IInvoice } from "../../../shared/models/Invoices";
import { t } from "../../../shared/services/langService";
import { economy } from "../../../shared/store/apiActions";
import { getIsLoading } from "../../../shared/store/ui/common";
import Helper from "../../../shared/utils/helper";

import withTableSorting from "../../../shared/hocs/withTableSorting";
import { RootState } from "../../../shared/store/rootReducer";

const GeneralDataListWithSorting = withTableSorting(GeneralDataList);
interface IProps {
  productionLine: ProductionArea;
}

const FrontpageInvoices: React.FC<IProps> = ({ productionLine }) => {
  const dispatch = useDispatch();

  const { invoices } = useSelector((state: RootState) => state.economy);
  const isLoading = useSelector((state: RootState) => getIsLoading(state));
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );

  useEffect(() => {
    dispatch(economy.getFrontPageInvoices(productionLine));
  }, [producerNumber]);

  const headerColumns = [
    {
      id: "invoiceNumber",
      label: t("economy_invoiceNumber"),
      isNumeric: false,
      width: "33%",
    },
    {
      id: "dueDate",
      label: t("economy_dueDate"),
      isNumeric: true,
      width: "33%",
    },
    {
      id: "amountIncludingTaxes",
      label: t("economy_frontpageInvocies_amountIncludingTaxes"),
      isNumeric: true,
      width: "34%",
    },
  ];

  const download = (invoiceNumber) => {
    dispatch(economy.generateInvoicePdf(invoiceNumber));
  };

  const getRowJsx = (invoice: IInvoice) => (
    <TableRow key={invoice.invoiceNumber}>
      {productionLine !== ProductionArea.Poultry ? (
        <TableCell>
          <Link to="#" onClick={() => download(invoice.invoiceNumber)}>
            {invoice.invoiceNumber}
          </Link>
        </TableCell>
      ) : (
        <TableCell>{invoice.invoiceNumber}</TableCell>
      )}
      <TableCell align="right">
        {Helper.dateToLocale(invoice.dueDate)}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(invoice.amountIncludingTaxes.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  return (
    <GeneralDataListWithSorting
      headerCells={headerColumns}
      rows={invoices}
      columnGroup={headerColumns}
      getRowJsx={getRowJsx}
      order="desc"
      orderBy="dueDate"
      isLoading={isLoading}
    />
  );
};

export default FrontpageInvoices;
