import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Grid, Box, useTheme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import PageHeader, {
  PageHeaderBox,
} from "../../shared/components/PageHeader/PageHeader";
import PageText from "../../shared/components/PageText/PageText";
import Button from "../../shared/components/Button/Button";
import useStyles from "./LearnSomethingNewPageStyles";
import * as api from "../../shared/store/apiActions";
import { t } from "../../shared/services/langService";
import EmptyImage from "../../assets/images/noimage.jpg";
import FrontpagePublicationListing from "../../general/components/FrontpagePublicationListing/FrontpagePublicationListing";
import CourseTile from "./CourseTile/CourseTile";
import { trainingsCleared } from "../../shared/store/trainings";
import { ITrainingContent } from "../../shared/models/Trainings";
import scrollToLocation from "../../shared/utils/scrollToLocation";
import { RootState } from "../../shared/store/rootReducer";

interface ILearnSomethingNewPageProps {}

const LearnSomethingNewPage: React.FC<ILearnSomethingNewPageProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { categoryInd } = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { trainings } = useSelector((state: RootState) => state.trainings);
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const currentPageRoute: string = "/learnsomethingnew";
  const courseTileSize: number = 155;

  useEffect(() => {
    if (langCode && productionLine) {
      dispatch(
        api.trainings.getProductionLineTrainings(langCode, productionLine)
      );
    }

    return () => {
      dispatch(trainingsCleared());
    };
  }, [langCode, productionLine]);

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  const handleShowAll = (ind: number) => {
    history.push(`${currentPageRoute}/${ind}`);
    handleClick();
  };

  const handleClick = (id?: string) => {
    if (isSmDown) scrollToLocation(id || "#learnsomethingnew-header");
  };

  const flatMap = (obj: any, property: string) =>
    obj.map((g) => g[property]).reduce((a, b) => a.concat(b), []);

  const getBoxContainerJsx = (
    training: ITrainingContent,
    key: number | string
  ) => (
    <Box
      display="flex"
      width={courseTileSize}
      height={courseTileSize}
      mt={1}
      ml={1}
      mb={1}
      mr={2}
      key={key}>
      <CourseTile
        name={training.title}
        image={training.imageContent || EmptyImage}
        progress={training.userTrainingData?.progress}
        maxPages={training.userTrainingData?.maxPages}
        trainingUrl={training.trainingUrl}
        isEmbedded={training.isEmbedded}
      />
    </Box>
  );

  return (
    <Grid container className={classes.root}>
      <Grid container alignItems="flex-start">
        <Grid item xs={12} sm={8}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <PageHeader
                align={"center"}
                style={{ paddingTop: "50px" }}
                level="1"
                id="learnsomethingnew-header">
                {!categoryInd
                  ? t("learnSomethingNew_pageHeader")
                  : trainings?.level1TrainingContainers[categoryInd]?.title}
              </PageHeader>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                direction={"column"}>
                <PageHeaderBox />
              </Grid>
            </Grid>

            {categoryInd && (
              <Grid
                item
                xs={12}
                style={{ marginBottom: "30px", paddingTop: "0" }}>
                <Button
                  id="learn-something-new-back-button"
                  type="link"
                  to={currentPageRoute}
                  onClick={() => handleClick(`category-${categoryInd}`)}>
                  {t("ui_back")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Grid container spacing={5} style={{ marginBottom: "20px" }}>
            {!categoryInd &&
              trainings?.level1TrainingContainers?.map((container, ind) => (
                <Grid item xs={12} key={ind}>
                  <PageHeader
                    level="2"
                    style={{ marginBottom: "15px" }}
                    id={`category-${ind}`}>
                    {container.title}
                  </PageHeader>
                  <Grid container spacing={2}>
                    {flatMap(container.level2TrainingContainers, "trainings")
                      .slice(0, 8 /* max number of courses */)
                      .map((training, subInd) =>
                        getBoxContainerJsx(training, subInd)
                      )}
                  </Grid>
                  {flatMap(container.level2TrainingContainers, "trainings")
                    ?.length > 0 ? (
                    <PageText
                      type="link"
                      style={{ marginTop: "10px" }}
                      onClick={() => handleShowAll(ind)}>
                      {t("publicationContent_showAll")}
                    </PageText>
                  ) : (
                    <PageText
                      type="bold"
                      containerStyle={{ marginTop: "20px" }}>
                      {t("trainingContent_noTrainings")}
                    </PageText>
                  )}
                </Grid>
              ))}

            {categoryInd &&
              trainings?.level1TrainingContainers[
                categoryInd
              ]?.level2TrainingContainers.map((level2Container, ind) => (
                <Grid item xs={12} key={ind}>
                  <PageHeader level="2" style={{ marginBottom: "15px" }}>
                    {level2Container.title}
                  </PageHeader>
                  {level2Container.trainings?.length ? (
                    <Grid container spacing={2}>
                      {level2Container.trainings?.map((training, subInd) =>
                        getBoxContainerJsx(training, subInd)
                      )}
                    </Grid>
                  ) : (
                    <PageText
                      type="bold"
                      containerStyle={{ marginTop: "20px" }}>
                      {t("trainingContent_noTrainings")}
                    </PageText>
                  )}
                </Grid>
              ))}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <FrontpagePublicationListing
                publicationType={"training"}
                limit={5}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LearnSomethingNewPage;
