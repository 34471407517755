import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  table: {
    width: "100%",
  },
  tableCellName: {
    cursor: "pointer",
  },
  breadcrumbTrailList: {
    fontSize: "15px",
    display: "flex",
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  breadcrumbTrailItem: {
    "&::before": {
      content: "'/'",
      margin: "0 5px",
    },
  },
});

export default useStyles;
