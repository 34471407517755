import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../../../shared/components/Button/Button";
import DropdownMenu from "../../../../shared/components/DropdownMenu/DropdownMenu";
import InputField from "../../../../shared/components/InputField/InputField";
import NotificationMessage from "../../../../shared/components/NotificationMessage/NotificationMessage";
import WeekPicker from "../../../../shared/components/WeekPicker/WeekPicker";
import { OperationUnit } from "../../../../shared/enums/CoreEnums";
import { t } from "../../../../shared/services/langService";
import { RootState } from "../../../../shared/store/rootReducer";
import sikaTeurasElainLajit from "../../enums/sikaTeurasElainLajit";
import useStyles from "./AddAnimalStyles";
import AnimalList from "./AnimalList/AnimalList";

interface AddAnimalsProps {
  departments: any[];
  departmentRangesLoaded: boolean;
  getDepartmentsByAnimalType: Function;
  departmentsToOptions: Function;
  ref: any;
  markingNumber: string;
  addedAnimals: any[];
  onAnimalsAdded: any;
  onAnimalRemoved: any;
  onAnimalEdited: Function;
  onAnimalTypeChange?: Function;
  onDepartmentChange?: Function;
  onCountChange?: Function;
  onWeekChange?: Function;
  modify?: boolean;
  readonly?: boolean;
}

const getDefaultWeekValue = () => {
  const weeksToAdd = moment().isoWeekday() < 3 ? 1 : 2;
  let now = new Date();
  now.setDate(now.getDate() + weeksToAdd * 7);
  const year = moment(now).format("YYYY");
  const week = moment(now).format("W");
  return `${year}${parseInt(week) < 10 ? "0" : ""}${week}`;
};

const initRow = {
  animalType: "11",
  department: "",
  count: "",
  week: getDefaultWeekValue(),
  departmentOptions: [{ name: "", text: "", value: "" }],
};

const AddAnimals: React.FC<AddAnimalsProps> = React.forwardRef(
  (
    {
      departments,
      departmentRangesLoaded,
      getDepartmentsByAnimalType = () => {},
      departmentsToOptions = () => {},
      markingNumber,
      addedAnimals,
      onAnimalsAdded,
      onAnimalRemoved,
      onAnimalEdited,
      onAnimalTypeChange = () => {},
      onDepartmentChange = () => {},
      onCountChange = () => {},
      onWeekChange = () => {},
      modify = false,
      readonly = false,
    },
    ref
  ) => {
    const classes = useStyles();
    const [newRow, setNewRow] = useState<any>(initRow);
    const [errors, setErrors] = useState<any>({});
    const [inputSuccessText, setInputSuccessText] = useState("");

    const [filteredDepartments, setFilteredDepartments] = useState<any[]>(
      () => {
        const departmentsByAnimalType = getDepartmentsByAnimalType(
          initRow.animalType
        );
        return departmentsByAnimalType;
      }
    );

    const [dropdownDepartmentOptions, setDropdownDepartmentOptions] = useState<
      any[]
    >([]);

    useEffect(() => {
      if (departmentRangesLoaded) {
        setFilteredDepartments(getDepartmentsByAnimalType(initRow.animalType));
      }
    }, [departmentRangesLoaded]);

    useEffect(() => {
      setDropdownDepartmentOptions(departmentsToOptions(filteredDepartments));
    }, [filteredDepartments]);

    const { operationUnit } = useSelector(
      (state: RootState) => state.ui.settings
    );

    const isEmakkoOrKarju =
      newRow.animalType == "12" || newRow.animalType == "16";

    const dropdownAnimalTypeOptions = Object.values(sikaTeurasElainLajit)

      .filter((el: any) => {
        return !isNaN(el);
      })
      .map((el) => ({
        name: sikaTeurasElainLajit[el],
        text: t(`animalType_${sikaTeurasElainLajit[el]}`),
        value: el,
      }));

    const handleAnimalTypeChange = (value) => {
      const departmentsByAnimalType = getDepartmentsByAnimalType(value);

      setFilteredDepartments(departmentsByAnimalType);

      setNewRow((prevState) => ({
        ...prevState,
        animalType: value,
        department: !isEmakkoOrKarju ? prevState.department : null,
      }));

      setInputSuccessText("");
      onAnimalTypeChange(value);
    };

    const handleListAnimalTypeChange = (id, animalTypeId) => {
      const newDepartments = getDepartmentsByAnimalType(animalTypeId);
      const newValues = [["animalType", animalTypeId.toString()]];

      if (
        animalTypeId == "12" ||
        animalTypeId == "16" ||
        newDepartments[0] === ""
      ) {
        newValues.push(["department", ""]);
      }

      newValues.push([
        "departmentOptions",
        departmentsToOptions(newDepartments),
      ]);

      onAnimalEdited(id, newValues);
    };

    const handleDepartmentChange = (value) => {
      setNewRow((prevState) => ({ ...prevState, department: value }));
      setInputSuccessText("");
      onDepartmentChange(value);
    };

    const handleCountChange = (value) => {
      setNewRow((prevState) => ({
        ...prevState,
        count: value.replace(/[^0-9]/g, ""),
      }));
      setInputSuccessText("");
      onCountChange(value);
    };

    const handleWeekChange = (value) => {
      setNewRow((prevState) => ({ ...prevState, week: value }));
      setInputSuccessText("");
      onWeekChange(value);
    };

    const handleAnimalsAdded = () => {
      setInputSuccessText("");
      const isValid = handleValidate();
      if (isValid) {
        const inputSuccessText = `${t(
          "animalNotification_successfullyAddedCount",
          { 0: newRow.count }
        )} ${t("animalNotification_returnToAnimalTypeSelection")}`;
        setInputSuccessText(inputSuccessText);

        const animalDepartments = getDepartmentsByAnimalType(newRow.animalType);

        onAnimalsAdded({
          ...newRow,
          markingNumber: markingNumber,
          departmentOptions: departmentsToOptions(animalDepartments),
        });

        setNewRow(initRow);
        setFilteredDepartments(getDepartmentsByAnimalType(initRow.animalType));
      }
    };

    const handleValidate = (): boolean => {
      let validationErrors;
      if (newRow) {
        validationErrors = {
          department:
            !newRow.department &&
            newRow.animalType != "12" &&
            newRow.animalType != "16"
              ? t("ui_requiredField")
              : "",
          count: !newRow.count
            ? t("ui_requiredField")
            : !newRow.count.match(
                /^([1-9]?|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])$/
              )
            ? t("ui_valueBetweenTwoNumbers", { 0: 1, 1: 9999 })
            : "",
        };
      }

      setErrors(validationErrors);
      const isValid = !validationErrors.department && !validationErrors.count;
      return isValid;
    };

    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        {!readonly && (
          <Fragment>
            <Grid
              className={classes.dropdownContainer}
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}>
              <DropdownMenu
                id="add-animal-animaltype-select"
                label={t("animalNotification_selectAnimalType")}
                required={true}
                onChange={handleAnimalTypeChange}
                displayEmptyText={`-- ${t("ui_select")} --`}
                selectedValue={newRow.animalType}
                options={dropdownAnimalTypeOptions}
              />
            </Grid>
            {!isEmakkoOrKarju && (
              <Grid
                className={classes.dropdownContainer}
                item
                xs={12}
                sm={12}
                md={2}
                lg={2}>
                <DropdownMenu
                  id="add-animal-department-select"
                  label={t("animalNotification_selectDepartment")}
                  required={true}
                  onChange={handleDepartmentChange}
                  displayEmptyText={`-- ${t("ui_select")} --`}
                  selectedValue={newRow.department}
                  options={dropdownDepartmentOptions}
                  errorText={errors.department}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <InputField
                id="add-animal-count-input"
                margin={""}
                label={t("ui_count")}
                required={true}
                fullWidth
                onChange={(e) => handleCountChange(e.target.value)}
                value={newRow.count}
                errorText={errors.count}
              />
            </Grid>
            <Grid
              className={classes.dropdownContainer}
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}>
              <WeekPicker
                id="add-animal-week-select"
                defaultValue={getDefaultWeekValue()}
                weeksToShow={53}
                label={t("animalNotification_selectWeek")}
                required={true}
                onChange={handleWeekChange}
                selectedValue={newRow.week}
                showStartAndEndDate={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ paddingTop: "0" }}>
              <NotificationMessage
                message={inputSuccessText}
                severity={"success"}
              />
            </Grid>
            <Grid
              item
              className={classes.animalAddButton}
              xs={12}
              sm={12}
              md={12}
              lg={12}>
              <Button
                id="add-animal-add-button"
                onClick={handleAnimalsAdded}
                type={"add"}
                className={classes.animalAddButton}>
                {t("animalNotification_addAnimals")}
              </Button>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AnimalList
            animals={addedAnimals}
            ref={ref}
            onRemove={(id) => onAnimalRemoved(id)}
            onEdit={(id, propertyValues) => onAnimalEdited(id, propertyValues)}
            onAnimalChange={(id, propertyValues) =>
              handleListAnimalTypeChange(id, propertyValues)
            }
            modify={modify}
            dropdownAnimalTypeOptions={dropdownAnimalTypeOptions}
          />
        </Grid>
      </Grid>
    );
  }
);

export default AddAnimals;
