import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    textAlign: "center",
    color: "#fff",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  content: {
    height: "100%",
    backgroundColor: "#cdcdcd",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center left",
  },
  contentText: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    padding: "2px",
    textAlign: "center",
    width: "100%",
    minHeight: "30%",
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    verticalAlign: "middle",
  },
  colorPrimary: {
    backgroundColor: "#fff",
  },
  barColorPrimary: ({ maxPages }: any) => ({
    backgroundSize: `${100 / maxPages}% 100%, cover`,
    backgroundRepeat: "repeat-x, no-repeat",
    backgroundImage: "linear-gradient(to right, #fff 1px, transparent 1px)",
    backgroundPosition: "0%, center",
    backgroundColor: "#63A9FF",
  }),
  root: {
    height: "5px",
    overflow: "hidden",
    position: "relative",
  },
}));

export default useStyles;
