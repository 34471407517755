import React, { Fragment, useRef, createRef } from "react";
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableSortLabel,
} from "@material-ui/core";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";

export const Table = MuiTable;

export const TableHead = MuiTableHead;

export const TableBody = MuiTableBody;

interface ICustomMuiTableRow {
  highlighted?: boolean;
  highlightColor?: string;
  highlightBackgroundColor?: string;
  [x: string]: any;
}

export const TableRow: React.FC<ICustomMuiTableRow> = ({
  highlighted = false,
  highlightColor = "",
  highlightBackgroundColor = "",
  ...rest
}) => {
  const classes = makeStyles({
    root: {
      "&:last-child td": {
        borderBottom: "unset",
      },
    },
    highlightedRoot: {
      "&:last-child td": {
        borderBottom: "unset",
      },
      "& td": {
        color: highlightColor ? highlightColor : "#cf2d3a",
        backgroundColor: highlightBackgroundColor
          ? highlightBackgroundColor
          : "",
      },
    },
    head: {},
    body: {},
  })();

  return (
    <MuiTableRow
      classes={{ root: highlighted ? classes.highlightedRoot : classes.root }}
      {...rest}
    />
  );
};

export const TableRow2: React.FC<ICustomMuiTableRow> = ({
  highlighted = false,
  highlightColor = "",
  ...rest
}) => {
  const classes = makeStyles({
    root: {},
    head: {},
    body: {},
  })();

  return <MuiTableRow classes={{ root: classes.root }} {...rest} />;
};

export const TableCell = (props) => {
  const classes = makeStyles({
    root: {},
    head: {
      padding: "2px 6px",
      backgroundColor: "#fff",
      color: "#4e5054",
      letterSpacing: "0.28px",
      fontSize: "0.8rem",
      fontWeight: "bolder",
      borderBottom: "solid 1px #ddd",
    },
    body: {
      padding: "10px 6px",
      fontSize: 14,
      fontWeight: "normal",
      borderRight: "none",
      borderLeft: "none",
      borderTop: "dashed 1px #ddd",
      borderBottom: "dashed 1px #ddd",
    },
  })();

  return (
    <MuiTableCell
      classes={{ root: classes.root, head: classes.head, body: classes.body }}
      {...props}
    />
  );
};

export const TableCell2 = (props) => {
  const classes = makeStyles({
    root: {},
    head: {
      color: "#152B62",
      padding: "8px 15px",
      fontSize: "1.15rem",
      fontWeight: "bolder",
      letterSpacing: "0.1px",
      backgroundColor: "#efefef",
      fontFamily: "Avenir Next W1G Bold",
    },
    body: {
      padding: "10px 6px",
      fontSize: 14,
      fontWeight: "normal",
      borderRight: "none",
      borderLeft: "none",
      borderTop: "dashed 1px #ddd",
      borderBottom: "dashed 1px #ddd",
    },
  })();

  return (
    <MuiTableCell
      classes={{ root: classes.root, head: classes.head, body: classes.body }}
      {...props}
    />
  );
};

interface TableHeaderRowProps {
  onRequestSort: Function;
  order: string; // "asc" | "desc",
  orderBy: string;
  children: any[];
  columnInfo: any[];
  sorting?: boolean;
}

const getOrderBy = (orderBy: string) => {
  if (Number.isNaN(Number(orderBy))) {
    return orderBy;
  }
  return Number(orderBy);
};

export const TableHeaderRow: React.FC<TableHeaderRowProps> = ({
  order,
  orderBy,
  onRequestSort,
  children,
  columnInfo,
  sorting = false,
}) => {
  const ref = useRef<any>(
    Array.from({ length: children?.length || 0 }).map(() => createRef())
  );
  const childrenWithProps = React.Children.map(
    children.filter((c) => c.props && c.props.children),
    (el: any, i) =>
      React.cloneElement(el, { ...el.props.rest, ref: ref.current[i] })
  );

  const createSortHandler = (property) => (e) => {
    onRequestSort(property);
  };

  if (!ref.current) return null;

  return (
    <MuiTableRow>
      {children &&
        childrenWithProps?.map((c, i) => (
          <TableCell
            key={columnInfo[i]?.id || i}
            {...(sorting
              ? {
                  sortDirection: (orderBy === columnInfo[i]?.id
                    ? order
                    : "asc") as any,
                }
              : {})}
            align={
              columnInfo[i]?.centerContent
                ? "center"
                : columnInfo[i]?.isNumeric
                ? "right"
                : "left"
            }>
            {sorting && (columnInfo[i]?.id || columnInfo[i]?.id === 0) && (
              <TableSortLabel
                active={getOrderBy(orderBy) === columnInfo[i]?.id}
                direction={
                  (orderBy === columnInfo[i]?.id ? order : "asc") as any
                }
                onClick={createSortHandler(columnInfo[i]?.id)}
                disableRipple={true}
                disableTouchRipple={true}
                IconComponent={ArrowDropDown}>
                {c.props.children}
              </TableSortLabel>
            )}
            {(!sorting || (!columnInfo[i]?.id && columnInfo[i]?.id !== 0)) && (
              <Fragment>{c.props.children}</Fragment>
            )}
          </TableCell>
        ))}
    </MuiTableRow>
  );
};
