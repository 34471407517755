import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  content: {
    height: "100%",
    width: "100%",
  },
  paper: {
    padding: "20px",
    backgroundColor: "#efefef",
    borderRadius: "5px",
  },
  button: {
    padding: "20px",
  },
  bold: {
    fontFamily: "Avenir Next W1G Bold",
    fontWeight: "bolder",
  },
  linkButton: {
    "&:hover, &:visited, &:link, &:active": {
      boxShadow: "none",
      textDecoration: "none",
      color: "unset",
    },
  },
});

export default useStyles;
