import {
  IUser,
  AuthorizationContext,
  IUserRoles,
} from "./../../management/shared/models/Users";
import { translationReceived } from "./ui/i18n";
import { validationRulesReceived } from "./ui/validation";
import {
  userOrganizationsReceived,
  userOrganizationAutocompleteOptionsReceived,
  userFileListingReceived,
  userFileDownloaded,
  userRoleMapReceived,
  userRegionalManagerInfoReceived,
  userOrganizationDataUpdatedByUser,
  userLastVisitedOrganizationUpdated,
} from "./user";
import { operationUnitUpdated, organizationsUpdated } from "./ui/settings";
import {
  fileDownloaded,
  publicationListingReceived,
  topMenuReferenceIdReceived,
  mainMenuReferenceIdReceived,
  topMenuItemsReceived,
  mainMenuItemsReceived,
  publicationReceived,
  carouselAdItemsReceived,
  bannersReceived,
} from "./publications";
import {
  healthQuestionsReceived,
  calfMedicalQuestionsReceived,
  beefDispatchListingReceived,
  latestBirthIdValidationResultReceived,
  beefSlaughterNotificationReceived,
  beefTransportInfoReceived,
  beefSlaughterInfoReportReceived,
  beefForwardingNotificationReceived,
} from "./beef";
import {
  porkDispatchListingReceived,
  porkTransportInfoReceived,
  healthQuestionsReceived as porkHealthQuestionsReceived,
  porkSlaughterNotificationReceived,
  porkSlaughterInfoReportReceived,
  porkDepartmentRangesReceived,
} from "./pork";
import {
  breedingsReceived,
  slaughterDataReceived,
  chickNotificationsReceived,
  slaughterNotificationsReceived,
  frontpageInfoReceived,
} from "./poultry";
import { assignableRolesReceived, usersReceived } from "./management";
import {
  capitalInfoReceived,
  debtInvociesReceived,
  interestPaymentsReceived,
  fileDownloaded as cooperativeFileDownloaded,
} from "./cooperative";
import beefs from "../../beef/shared/services/generated/beefService";
import beefReports from "../../beef/shared/services/generated/reportService";
import porkReports from "../../pork/shared/services/generated/reportService";
import porks from "../../pork/shared/services/generated/porkService";
import poultrys from "../../poultry/shared/services/generated/poultryService";
import users from "../services/userService";
import publications, {
  IGetListingParams,
} from "../services/publicationService";
import trans from "../services/translationsService";
import validation from "../services/validationService";
import { requested, requestedSuccess } from "./api";
import BeefSlaughterNotificationDTO from "../../beef/shared/models/BeefSlaughterNotificationDTO";
import BeefForwardingNotificationDTO from "../../beef/shared/models/BeefForwardingNotificationDTO";
import {
  invoicePdfDownloaded,
  invoicesReceived,
  paymentPdfDownloaded,
  paymentsReceived,
  debtBreakdownReceived,
} from "../store/economy";
import { trainingsReceived } from "../store/trainings";
import {
  getInvoices as getInvoicesReq,
  getFrontPageInvoices as getFrontPageInvoicesReq,
  getPayments as getPaymentsReq,
  getFrontPagePayments as getFrontPagePaymentsReq,
  getFrontPagePaymentsPoultry as getFrontPagePaymentsPoultryReq,
  getPaymentImage,
  getInvoiceImage,
  getDebtBreakdown as getDebtBreakdownReq,
} from "../services/economyService";
import { InvoiceType } from "../models/Invoices";
import { PaymentType } from "../models/Payments";
import ProductionArea from "../enums/productionLines";
import trainingss from "../services/trainingsService";
import userManagements from "../../management/shared/services/userManagementService";
import cooperativeHttpService from "../services/cooperativeService";
import { getPublicAsyncActionWrapper } from "./middleware/api";
import { IFeedbackDto } from "../../general/UserFeedbackPage/UserFeedbackPage";
import { IFarmContactItem, IContactUpdateDto } from "../models/FarmData";
import organizationManagements from "../services/organizationManagementService";
import { getToken as getTokenReq } from "../services/powerbiService";
import {
  tokenReceived,
  fetchingTokenOn,
  fetchingTokenOff,
  producerUpdated,
} from "../store/powerbi";

//#region general

const getTranslations = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await trans.getAllTranslations();
      dispatch({
        type: translationReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getValidationRules = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await validation.getValidationRules();
      dispatch({
        type: validationRulesReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

export const ui = {
  getTranslations,
  getValidationRules,
};

const getUserOrganizations = () => {
  console.log("apiActions:getUserOrganizations:sending");
  return requested({
    fn: async (dispatch) => {
      const resp = await users.getUserOrganization();
      dispatch({
        type: userOrganizationsReceived.type,
        payload: { data: resp.data },
      });
      dispatch({
        type: organizationsUpdated.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getUserRoleMaps = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await users.getRoleMaps();
      dispatch({
        type: userRoleMapReceived.type,
        payload: { data: resp.data },
      });

      return resp;
    },
  });
};

const getUserFilteredOrganizations = (
  authenticationSource: string,
  filter?: string,
  updateProducerNumber?: Function
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await users.getUserFilteredOrganization(
        authenticationSource,
        filter
      );
      if (filter && updateProducerNumber) {
        updateProducerNumber(filter);
      }
      dispatch({
        type: userOrganizationsReceived.type,
        payload: { data: resp.data },
      });
      dispatch({
        type: organizationsUpdated.type,
        payload: { data: resp.data },
      });
      dispatch({
        type: operationUnitUpdated.type,
        payload: { operationUnit: resp.data[0].operationUnit },
      });
      return resp;
    },
  });
};

const getUserAutocompleteOrganizations = (
  authenticationSource: string,
  filter?: string
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await users.getUserFilteredOrganization(
        authenticationSource,
        filter
      );
      dispatch({
        type: userOrganizationAutocompleteOptionsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getUserFileListing = (path: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await users.getFileListing(path);
      dispatch({
        type: userFileListingReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getUserFile = (name: string) => {
  return requested({
    fn: async (dispatch) => {
      const response = await users.getFile(name);
      const fileName = name.split("/").slice(-1)[0];
      dispatch({
        type: userFileDownloaded.type,
        payload: {
          filename: fileName,
          data: response.data,
        },
      });
    },
  });
};

const postUserFeedback = (dto: IFeedbackDto) => {
  return requested({
    fn: async (dispatch) => {
      const response = await users.postFeedback(dto);
      dispatch({
        type: requestedSuccess.type,
        payload: {
          data: response.data,
        },
      });
      return response;
    },
  });
};

const getUserRegionalManagerInfo = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await users.getRegionalManagerInfo();
      dispatch({
        type: userRegionalManagerInfoReceived.type,
        payload: {
          data: response.data,
        },
      });
      return response;
    },
  });
};

const getUserLastVisitedOrganization = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await users.getLastVisitedOrganization();
      dispatch({
        type: userLastVisitedOrganizationUpdated.type,
        payload: { data: response.data === 0 ? undefined : response.data },
      });
      return response;
    },
  });
};

const updateUserLastVisitedOrganization = (
  producerNumber: number | undefined
) => {
  if (producerNumber === undefined) {
    return;
  }
  return requested({
    fn: async (dispatch) => {
      const response = await users.updateLastVisitedOrganization(
        producerNumber
      );
      dispatch({
        type: userLastVisitedOrganizationUpdated.type,
        payload: {
          data: response.data,
        },
      });
      return response;
    },
  });
};

export const user = {
  getUserOrganizations,
  getUserRoleMaps,
  getUserAutocompleteOrganizations,
  getUserFilteredOrganizations,
  getUserFileListing,
  getUserFile,
  postUserFeedback,
  getUserRegionalManagerInfo,
  getUserLastVisitedOrganization,
  updateUserLastVisitedOrganization,
};

const getListing = ({
  langCode,
  publicationContext,
  publicationType,
  limit,
  disableSort,
}: IGetListingParams) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await publications.getListing({
        langCode,
        publicationContext,
        publicationType,
        limit,
        disableSort,
      });
      dispatch({
        type: publicationListingReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getTopMenuReferenceId = (productionLine: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await publications.getMenuContainerReferenceId(
        productionLine,
        "VasenYlavalikko"
      );
      dispatch({
        type: topMenuReferenceIdReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getMainMenuReferenceId = (productionLine: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await publications.getMenuContainerReferenceId(
        productionLine,
        "Paavalikko"
      );
      dispatch({
        type: mainMenuReferenceIdReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getMenuItems = (
  langCode: string,
  productionLine: string,
  referenceId: number,
  actionType: string
) => {
  const filterContentItemBranches = (children: any[]): any[] => {
    const getChildren = (result, object) => {
      if (!object.isContainer) {
        result.push(object);
        return result;
      }
      const children = object.children.reduce(getChildren, []);
      if (children.length) {
        result.push({ ...object, children });
      }

      return result;
    };

    return children.reduce(getChildren, []);
  };

  return requested({
    fn: async (dispatch) => {
      const resp = (await publications.getMenuItemTree(
        langCode,
        productionLine,
        referenceId
      )) as any;
      dispatch({
        type: actionType,
        payload: { data: filterContentItemBranches(resp.data.children || []) },
      });
    },
  });
};

const getTopMenuItems = (
  langCode: string,
  productionLine: string,
  referenceId: number
) =>
  getMenuItems(
    langCode,
    productionLine,
    referenceId,
    topMenuItemsReceived.type
  );

const getMainMenuItems = (
  langCode: string,
  productionLine: string,
  referenceId: number
) =>
  getMenuItems(
    langCode,
    productionLine,
    referenceId,
    mainMenuItemsReceived.type
  );

const getPublication = (
  langCode: string,
  productionLine: string,
  pageNameOrReferenceId: string
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await publications.getPublication(
        langCode,
        productionLine,
        pageNameOrReferenceId
      );
      dispatch({
        type: publicationReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getCarouselItemListing = ({
  langCode,
  publicationContext,
  publicationType,
  limit,
  disableSort,
}: IGetListingParams) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await publications.getListing({
        langCode,
        publicationContext,
        publicationType,
        limit,
        disableSort,
      });
      dispatch({
        type: carouselAdItemsReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getFile = (filePath) => {
  return requested({
    fn: async (dispatch) => {
      const response = await publications.getFile(filePath);
      const decodedFilePath = decodeURIComponent(escape(window.atob(filePath)));
      const filePathParts = decodedFilePath.split("/");
      const fileName = filePathParts[filePathParts.length - 1];
      dispatch({
        type: fileDownloaded.type,
        payload: {
          filename: fileName,
          data: response.data,
        },
      });
    },
  });
};

const getBannerContent = (langCode: string, productionLine: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await publications.getBanners(langCode, productionLine);
      dispatch({
        type: bannersReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

export const publication = {
  getListing,
  getCarouselItemListing,
  getTopMenuReferenceId,
  getMainMenuReferenceId,
  getTopMenuItems,
  getMainMenuItems,
  getPublication,
  getFile,
  getBannerContent,
};

//#endregion general

//#region beef
const getHealthQuestions = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getHealthQuestions();
      dispatch({
        type: healthQuestionsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getCalfMedicalQuestions = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getCalfMedicalQuestions();
      dispatch({
        type: calfMedicalQuestionsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const createBeefSlaughterNotification = (
  notification: BeefSlaughterNotificationDTO
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.createBeefSlaughterNotification(notification);
      return resp;
    },
  });
};

const createBeefForwardingNotification = (
  notification: BeefForwardingNotificationDTO
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.createBeefForwardingNotification(notification);
      return resp;
    },
  });
};

const getBeefNotificationsList = (
  notificationStatus: "open" | "completed",
  startDate?: string,
  endDate?: string
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getBeefNotificationsList(
        notificationStatus,
        startDate,
        endDate
      );
      dispatch({
        type: beefDispatchListingReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getBeefSlaughterNotification = (notificationId: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getBeefSlaughterNotification(notificationId);
      dispatch({
        type: beefSlaughterNotificationReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getBeefForwardingNotification = (notificationId: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getBeefForwardingNotification(notificationId);
      dispatch({
        type: beefForwardingNotificationReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const updateBeefSlaughterInfo = (
  notification: BeefSlaughterNotificationDTO,
  notificationId: number
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.updateBeefSlaughterInfo(
        notification,
        notificationId
      );
      return resp;
    },
  });
};

const getBeefTransportInfo = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getBeefTransportInfo();
      dispatch({
        type: beefTransportInfoReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const updateBeefForwardingInfo = (
  notification: BeefForwardingNotificationDTO,
  notificationId: number
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.updateBeefForwardingInfo(
        notification,
        notificationId
      );
      return resp;
    },
  });
};

const getBeefSlaughterInfoReport = (startDate: string, endDate: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefReports.getBeefSlaughterInfoReport(
        startDate,
        endDate
      );
      dispatch({
        type: beefSlaughterInfoReportReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const validateBirthIds = (birthIds: string[]) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await validation.validateBirthIds(birthIds);
      dispatch({
        type: latestBirthIdValidationResultReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getBeefHealthInfo = (notificationId: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getBeefHealthInfo(notificationId);
      dispatch({
        type: healthQuestionsReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getCalfMedicalInfo = (notificationId: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await beefs.getCalfMedicalInfo(notificationId);
      dispatch({
        type: calfMedicalQuestionsReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

export const beef = {
  getHealthQuestions,
  getCalfMedicalQuestions,
  createBeefSlaughterNotification,
  createBeefForwardingNotification,
  getBeefNotificationsList,
  getBeefSlaughterNotification,
  getBeefForwardingNotification,
  updateBeefSlaughterInfo,
  getBeefTransportInfo,
  updateBeefForwardingInfo,
  getBeefSlaughterInfoReport,
  validateBirthIds,
  getBeefHealthInfo,
  getCalfMedicalInfo,
};

//#endregion beef

//#region pork

const getPorkHealthQuestions = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.getHealthQuestions();
      dispatch({
        type: porkHealthQuestionsReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const getPorkDepartmentRanges = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.getPorkDepartmentRanges();
      dispatch({
        type: porkDepartmentRangesReceived.type,
        payload: { data: resp.data },
      });
      return resp;
    },
  });
};

const createPorkSlaughterNotification = (notification: any) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.createPorkSlaughterNotification(notification);
      return resp;
    },
  });
};

const getPorkNotificationsList = (
  notificationStatus: "open" | "completed",
  startDate?: string,
  endDate?: string
) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.getPorkNotificationsList(
        notificationStatus,
        startDate,
        endDate
      );
      dispatch({
        type: porkDispatchListingReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getPorkSlaughterNotification = (notificationId: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.getPorkSlaughterNotification(notificationId);
      dispatch({
        type: porkSlaughterNotificationReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const updatePorkSlaughterInfo = (notification: any, notificationId: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.updatePorkSlaughterInfo(
        notification,
        notificationId
      );
      return resp;
    },
  });
};

const getPorkTransportInfo = () => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.getPorkTransportInfo();
      dispatch({
        type: porkTransportInfoReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getPorkSlaughterInfoReport = (startDate: string, endDate: string) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porkReports.getPorkSlaughterInfoReport(
        startDate,
        endDate
      );
      dispatch({
        type: porkSlaughterInfoReportReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

const getPorkHealthInfo = (notificationId: number) => {
  return requested({
    fn: async (dispatch) => {
      const resp = await porks.getPorkHealthInfo(notificationId);
      dispatch({
        type: porkHealthQuestionsReceived.type,
        payload: { data: resp.data },
      });
    },
  });
};

export const pork = {
  getPorkHealthQuestions,
  createPorkSlaughterNotification,
  getPorkNotificationsList,
  getPorkSlaughterNotification,
  updatePorkSlaughterInfo,
  getPorkTransportInfo,
  getPorkSlaughterInfoReport,
  getPorkHealthInfo,
  getPorkDepartmentRanges,
};

//#endregion pork

//#region economy

const getInvoices = (
  invoiceTypeId: InvoiceType,
  startDate: string,
  endDate: string,
  productionLine: ProductionArea
) => {
  return requested({
    fn: async (dispatch) => {
      const response = await getInvoicesReq(
        invoiceTypeId,
        startDate,
        endDate,
        productionLine
      );
      dispatch({
        type: invoicesReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getFrontPageInvoices = (productionLine: ProductionArea) => {
  return requested({
    fn: async (dispatch) => {
      const response = await getFrontPageInvoicesReq(productionLine);
      dispatch({
        type: invoicesReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getPayments = (
  productionLine: ProductionArea,
  paymentTypeId: PaymentType,
  startDate: string,
  endDate: string
) => {
  return requested({
    fn: async (dispatch) => {
      const response = await getPaymentsReq(
        productionLine,
        paymentTypeId,
        startDate,
        endDate
      );
      dispatch({
        type: paymentsReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getDebtBreakdown = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await getDebtBreakdownReq();
      dispatch({
        type: debtBreakdownReceived.type,
        payload: { data: response.data },
      });
      return response;
    },
  });
};

const getFrontPagePayments = (productionLine?) => {
  return requested({
    fn: async (dispatch) => {
      const request =
        productionLine === ProductionArea.Poultry
          ? getFrontPagePaymentsPoultryReq()
          : getFrontPagePaymentsReq();
      const response = await request;
      dispatch({
        type: paymentsReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const generatePaymentPdf = (paymentNumber) => {
  return requested({
    fn: async (dispatch) => {
      const response = await getPaymentImage(paymentNumber);
      dispatch({
        type: paymentPdfDownloaded.type,
        payload: {
          filename: `payment_${paymentNumber}`,
          data: response.data,
        },
      });
    },
  });
};

const generateInvoicePdf = (invoiceNumber) => {
  return requested({
    fn: async (dispatch) => {
      const response = await getInvoiceImage(invoiceNumber);
      dispatch({
        type: invoicePdfDownloaded.type,
        payload: {
          filename: `invoice_${invoiceNumber}`,
          data: response.data,
        },
      });
    },
  });
};

export const economy = {
  getInvoices,
  getFrontPageInvoices,
  getPayments,
  getFrontPagePayments,
  generateInvoicePdf,
  generatePaymentPdf,
  getDebtBreakdown,
};

//#endregion

//#region poultry

const getBreedings = (startDate: string, endDate: string) => {
  return requested({
    fn: async (dispatch) => {
      const response = await poultrys.getBreedings(startDate, endDate);
      dispatch({
        type: breedingsReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getSlaughterData = (slaughterId: number, breedingId: number) => {
  return requested({
    fn: async (dispatch) => {
      const response = await poultrys.getSlaughterData(slaughterId, breedingId);
      dispatch({
        type: slaughterDataReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getChickNotifications = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await poultrys.getChickNotifications();
      dispatch({
        type: chickNotificationsReceived.type,

        payload: { data: response.data },
      });
    },
  });
};

const getSlaughterNotifications = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await poultrys.getSlaughterNotifications();
      dispatch({
        type: slaughterNotificationsReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getFrontpageInfo = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await poultrys.getFrontpageInfo();
      dispatch({
        type: frontpageInfoReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

export const poultry = {
  getBreedings,
  getSlaughterData,
  getChickNotifications,
  getSlaughterNotifications,
  getFrontpageInfo,
};

//#endregion

//#region Cooperative

const getCapitalInfo = (startDate: string, endDate: string) => {
  return requested({
    fn: async (dispatch) => {
      const response = await cooperativeHttpService.getCapitalInfo(
        startDate,
        endDate
      );
      dispatch({
        type: capitalInfoReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getInterestPayments = (
  startDate: string,
  endDate: string,
  limit?: number
) => {
  return requested({
    fn: async (dispatch) => {
      const response = await cooperativeHttpService.getInterestPayments(
        startDate,
        endDate,
        limit
      );
      dispatch({
        type: interestPaymentsReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getDebtInvoices = (
  startDate: string,
  endDate: string,
  limit?: number
) => {
  return requested({
    fn: async (dispatch) => {
      const response = await cooperativeHttpService.getDebtInvoices(
        startDate,
        endDate,
        limit
      );
      dispatch({
        type: debtInvociesReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getMainPageInterestPayments = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await cooperativeHttpService.getMainPageInterestPayments();
      dispatch({
        type: interestPaymentsReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getMainPageDebtInvoices = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await cooperativeHttpService.getMainPageDebtInvoices();
      dispatch({
        type: debtInvociesReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const downloadInterestPaymentPdf = (paymentNumber) => {
  return requested({
    fn: async (dispatch) => {
      const response = await cooperativeHttpService.getInterestPaymentImage(
        paymentNumber
      );
      dispatch({
        type: cooperativeFileDownloaded.type,
        payload: {
          filename: `payment_${paymentNumber}`,
          data: response.data,
        },
      });
    },
  });
};

const downloadDebtInvoicePdf = (invoiceNumber) => {
  return requested({
    fn: async (dispatch) => {
      const response = await cooperativeHttpService.getDebtInvoiceImage(
        invoiceNumber
      );
      dispatch({
        type: cooperativeFileDownloaded.type,
        payload: {
          filename: `invoice_${invoiceNumber}`,
          data: response.data,
        },
      });
    },
  });
};

export const cooperative = {
  getCapitalInfo,
  getInterestPayments,
  getDebtInvoices,
  getMainPageDebtInvoices,
  getMainPageInterestPayments,
  downloadDebtInvoicePdf,
  downloadInterestPaymentPdf,
};

//#endregion

//#region trainings

const getProductionLineTrainings = (langCode, productionLine) => {
  return requested({
    fn: async (dispatch) => {
      const response = await trainingss.getAvailableTrainings(
        langCode,
        productionLine
      );
      dispatch({
        type: trainingsReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

export const trainings = {
  getProductionLineTrainings,
};

//#endregion

//#region management

const getAssignableRoles = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await userManagements.getAssignableRoles();
      dispatch({
        type: assignableRolesReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const getUsers = () => {
  return requested({
    fn: async (dispatch) => {
      const response = await userManagements.getUsers();
      dispatch({
        type: usersReceived.type,
        payload: { data: response.data },
      });
    },
  });
};

const addUser = (user: IUser) => (dispatch, getState) =>
  getPublicAsyncActionWrapper(
    dispatch,
    async () => {
      const response = await userManagements.addUser(user);
      return response;
    },
    getState
  );

const updateUser = (
  context: AuthorizationContext,
  id: string,
  user: IUserRoles
) => (dispatch, getState) =>
  getPublicAsyncActionWrapper(
    dispatch,
    async () => {
      const response = await userManagements.updateUser(context, id, user);
      return response;
    },
    getState
  );

const deleteUser = (context: AuthorizationContext, id: string) => (
  dispatch,
  getState
) =>
  getPublicAsyncActionWrapper(
    dispatch,
    async () => {
      const response = await userManagements.deleteUser(context, id);
      return response;
    },
    getState
  );

export const management = {
  getAssignableRoles,
  getUsers,
  addUser,
  updateUser,
  deleteUser,
};

//#endregion management

//#region organizationManagement: farm's phone&email info, banking details

const updateContactInformation = (
  groupId: string,
  values: IFarmContactItem[]
) => {
  return requested({
    fn: async (dispatch) => {
      await organizationManagements.updateContactInformation({
        groupId: groupId,
        values: values,
      });
      dispatch({
        type: userOrganizationDataUpdatedByUser.type,
        payload: { data: true },
      });
    },
  });
};

const updateEInvoiceInformation = (
  brokerIdentifier: string,
  eInvoiceOperator: string,
  eInvoiceAddress: string
) => {
  return requested({
    fn: async (dispatch) => {
      await organizationManagements.updateEInvoiceInformation({
        context: AuthorizationContext.MainUser,
        intermediatorID: brokerIdentifier,
        operatorID: eInvoiceOperator,
        ovt: eInvoiceAddress,
      });
      dispatch({
        type: userOrganizationDataUpdatedByUser.type,
        payload: { data: true },
      });
    },
  });
};

const updateBankAccoutInformation = (
  bankAccountNumber: string,
  bankAccountDate: string
) => {
  return requested({
    fn: async (dispatch) => {
      await organizationManagements.updateBankAccountInformation({
        context: AuthorizationContext.MainUser,
        bankAccountNumber: bankAccountNumber,
        bankAccountDate: bankAccountDate,
      });
      dispatch({
        type: userOrganizationDataUpdatedByUser.type,
        payload: { data: true },
      });
    },
  });
};

const setFarmDataChecked = () => {
  return requested({
    fn: async (dispatch) => {
      await organizationManagements.setDataChecked();
      dispatch({
        type: userOrganizationDataUpdatedByUser.type,
        payload: { data: true },
      });
    },
  });
};

export const organizationManagement = {
  updateContactInformation,
  updateEInvoiceInformation,
  updateBankAccoutInformation,
  setFarmDataChecked,
};

//#endregion organizationManagement

//#region powerBI embedding

const getToken = (
  langCode: string,
  productionLine: string,
  workspaceId: string,
  reportId: string,
  producerNumber: number | undefined
) => {
  return requested({
    fn: async (dispatch) => {
      dispatch({ type: fetchingTokenOn.type });
      const response = await getTokenReq(
        langCode,
        productionLine,
        workspaceId,
        reportId
      );
      dispatch({ type: fetchingTokenOff.type });
      dispatch({
        type: tokenReceived.type,
        payload: { data: response.data },
      });
      dispatch({
        type: producerUpdated.type,
        payload: { data: producerNumber },
      });
    },
  });
};

//#endregion powerBI embedding

export const powerbi = {
  getToken,
};
