import * as React from "react";
import { Link } from "react-router-dom";

export interface ICustomLinkProps {
  [x: string]: any;
}

const CustomLink: React.FC<ICustomLinkProps> = ({ to, children, ...props }) => {
  if (!to) {
    return <span {...props}>{children}</span>;
  } else if (/^(https|http)?:\/\//.test(to)) {
    return (
      <a href={to} target="_blank" {...props}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default CustomLink;
