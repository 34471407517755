import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    maxWidth: "450px",
    marginTop: "40px",
    textAlign: "center"
  },
  buttonContainer: {
    display: "block",
    marginTop: "30px",
    marginBottom: "15px",
    margin: "0 60px"
  },
  primaryButtonContainer: {
    display: "block",
    marginBottom: "10px"
  },
  secondaryButtonContainer: {
    display: "block"
  }
}));

export default useStyles;