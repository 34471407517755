import { Paper } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import BeefContent from "../../../beef/BeefContent/BeefContent";
import CooperativeContent from "../../../cooperative/CooperativeContent/CooperativeContent";
import ProductionAreaRouter from "../../../general/components/ProductionAreaRouter/ProductionAreaRouter";
import EconomyContent from "../../../general/EconomyContent/EconomyContent";
import GeneralContent from "../../../general/GeneralContent/GeneralContent";
import ManagementContent from "../../../management/ManagementContent/ManagementContent";
import { AuthorizationContext } from "../../../management/shared/models/Users";
import PorkContent from "../../../pork/PorkContent/PorkContent";
import PoultryContent from "../../../poultry/PoultryContent/PoultryContent";
import PublicationContent from "../../../publications/PublicationContent/PublicationContent";
import TestContent from "../../../test/TestContent/TestContent";
import FileListingContent from "../../../user/FileListingContent/FileListingContent";
import UserContent from "../../../user/UserContent/UserContent";
import OtherContent from "../../../other/OtherContent/OtherContent";
import ReportContent from "../../../reports/ReportContent/ReportContent";
import { ADUserRole, mainUserRoles, UserRole } from "../../enums/CoreEnums";
import {
  allProductionLines,
  animalProductionLines,
  productionAreaConfig,
} from "../../enums/productionLines";
import navHelpers from "../../helpers/navHelpers";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import useStyles from "./MainStyles";
import { RootState } from "../../store/rootReducer";

const Content = () => {
  const classes = useStyles();
  const { productionLine, producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { roles, producers } = useSelector(
    (state: RootState) => state.auth.currentUser
  );
  const { roleMap } = useSelector((state: RootState) => state.user);

  return (
    <Paper className={classes.content} elevation={0}>
      <Switch>
        <ProtectedRoute
          path="/beef"
          component={BeefContent}
          allowedProductionLines={
            productionAreaConfig.Beef.allowedProductionAreas
          }
          allowedUserRoles={productionAreaConfig.Beef.allowedUserRoles}
          authContext={productionAreaConfig.Beef.authContext}
        />
        <ProtectedRoute
          path="/pork"
          component={PorkContent}
          allowedProductionLines={
            productionAreaConfig.Pork.allowedProductionAreas
          }
          allowedUserRoles={productionAreaConfig.Pork.allowedUserRoles}
          authContext={productionAreaConfig.Pork.authContext}
        />
        <ProtectedRoute
          path="/poultry"
          component={PoultryContent}
          allowedProductionLines={
            productionAreaConfig.Poultry.allowedProductionAreas
          }
          allowedUserRoles={productionAreaConfig.Poultry.allowedUserRoles}
          authContext={productionAreaConfig.Poultry.authContext}
        />
        <ProtectedRoute
          path="/economy"
          component={EconomyContent}
          allowedProductionLines={animalProductionLines}
          allowedUserRoles={[...mainUserRoles, UserRole.EconomyUser]}
          authContext={AuthorizationContext.MainUser}
        />
        <ProtectedRoute
          path="/other"
          component={OtherContent}
          allowedProductionLines={
            productionAreaConfig.Other.allowedProductionAreas
          }
          allowedUserRoles={productionAreaConfig.Other.allowedUserRoles}
          authContext={productionAreaConfig.Other.authContext}
        />
        <Route path="/management" component={ManagementContent} />
        <ProtectedRoute
          path={[
            "/newsletters",
            "/events",
            "/trainings",
            "/content",
            "/download",
            "/video",
          ]}
          component={PublicationContent}
          allowedProductionLines={allProductionLines}
          allowedUserRoles={[
            ...mainUserRoles,
            ADUserRole.SinettiOsuuskunta,
            ADUserRole.SinettiHKScanOsuuskunta,
            UserRole.SecondaryMainUser,
            UserRole.EconomyUser,
            UserRole.EmployeeUser,
          ]}
          authContext={AuthorizationContext.All}
        />
        <ProtectedRoute
          orgHasToBeMember={productionAreaConfig.Cooperative.orgHasToBeMember}
          allowedProductionLines={
            productionAreaConfig.Cooperative.allowedProductionAreas
          }
          allowedUserRoles={productionAreaConfig.Cooperative.allowedUserRoles}
          authContext={productionAreaConfig.Cooperative.authContext}
          path="/cooperative"
          component={CooperativeContent}
        />
        <ProtectedRoute
          path="/reports"
          component={ReportContent}
          allowedProductionLines={allProductionLines}
          allowedUserRoles={[
            ...mainUserRoles,
            ADUserRole.SinettiOsuuskunta,
            ADUserRole.SinettiHKScanOsuuskunta,
            UserRole.SecondaryMainUser,
            UserRole.EconomyUser,
            UserRole.EmployeeUser,
          ]}
          authContext={AuthorizationContext.All}
        />
        <Route path="/landingPage/:context" component={ProductionAreaRouter} />
        <Route path="/user/files" component={FileListingContent} />
        <Route path="/user" component={UserContent} />
        <Route path="/test" component={TestContent} />
        <Route
          path="/"
          exact
          render={() => (
            <Redirect
              to={navHelpers.getLandingPage(
                productionLine,
                roles,
                producers,
                Number(producerNumber),
                roleMap
              )}
            />
          )}
        />
        <Route path="/" component={GeneralContent} />
      </Switch>
    </Paper>
  );
};

export default Content;
