import React, { Fragment } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import NotificationBanner from "../NotificationBanner/NotificationBanner";
import DropdownMenu from "../DropdownMenu/DropdownMenu";
import useStyles from "./FarmSelectionStyles";
import { getIsLoading } from "../../store/ui/common";
import { t } from "../../services/langService";
import ReadOnlyTextField from "../ReadOnlyTextField/ReadOnlyTextField";
import ProductionArea, {
  ProductionLineUriMap,
} from "../../enums/productionLines";

interface FarmSelectionProps {
  farms: any[];
  onChange: Function;
  selectedFarm: string;
  readonly?: boolean;
  productionLine?: ProductionArea.Pork | ProductionArea.Beef;
}

const FarmSelection: React.FC<FarmSelectionProps> = ({
  farms,
  onChange,
  selectedFarm,
  readonly = false,
  productionLine = ProductionArea.Beef,
}) => {
  const classes = useStyles();
  const isLoading = useSelector((state) => getIsLoading(state));
  const history = useHistory();

  const handleFarmChange = (value, prevValue) => {
    onChange(value, prevValue);
  };

  const filteredFarms = farms?.filter(
    (f) =>
      f.unitNumber &&
      (productionLine === ProductionArea.Beef ||
        (productionLine === ProductionArea.Pork && f.markingNumber))
  );
  const noFarms = !filteredFarms?.length;
  const onlyOneFarmWithoutLivestockVenue =
    farms?.length === 1 && !farms[0].unitNumber;

  if (
    !isLoading &&
    !readonly &&
    (noFarms || onlyOneFarmWithoutLivestockVenue)
  ) {
    return (
      <Grid container spacing={2} alignItems="flex-start">
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
          <NotificationBanner
            type={"error"}
            text={t("farmSelection_noLivestockVenueError")}
            primaryButtonText={t("animalNotification_goToFrontPage")}
            onClicked={() =>
              history.push(`/${ProductionLineUriMap[productionLine]}`)
            }
          />
        </Grid>
      </Grid>
    );
  }

  const dropdownOptions = filteredFarms?.map((p, i) => ({
    name: p.unitNumber,
    text: p.farmName || t("farmSelection_mainFarm"),
    value: p.unitNumber,
  }));

  if (dropdownOptions?.length === 1 && !selectedFarm)
    handleFarmChange(dropdownOptions[0].value, "");

  return (
    <Fragment>
      {(readonly || dropdownOptions?.length === 1) && (
        <ReadOnlyTextField
          label={t("farmSelection_selectedFarm")}
          text={
            dropdownOptions?.find((o) => o.value === selectedFarm)?.text || ""
          }
        />
      )}
      {!readonly && dropdownOptions?.length > 1 && (
        <DropdownMenu
          selectedValue={selectedFarm}
          displayEmpty={true}
          displayEmptyText={`-- ${t("ui_select")} --`}
          useCaret={false}
          label={t("farmSelection_selectFarm")}
          required={true}
          onChange={(value, prevValue) => handleFarmChange(value, prevValue)}
          options={dropdownOptions}
          className={classes.farmSelectDropdown}
        />
      )}
    </Fragment>
  );
};

export default FarmSelection;
