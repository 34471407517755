import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import moment from "moment";
import GeneralDatalist, {
  IHeadCell,
} from "../../shared/components/GeneralDatalist/GeneralDatalist";
import { TableRow, TableCell } from "../../shared/components/Table/Table";
import { IDebtBreakdown } from "../../shared/models/DebtBreakdown";
import { useDispatch, useSelector } from "react-redux";
import { economy } from "../../shared/store/apiActions";
import { t } from "../../shared/services/langService";
import Helper from "../../shared/utils/helper";
import { RootState } from "../../shared/store/rootReducer";

const DebtBreakdown = () => {
  const dispatch = useDispatch();
  const { debtBreakdown } = useSelector((state: RootState) => state.economy);
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );

  useEffect(() => {
    dispatch(economy.getDebtBreakdown());
  }, [producerNumber]);

  const headers: IHeadCell[] = [
    {
      id: "debtType",
      label: t("economy_debtBreakdown_debtType"),
      isNumeric: false,
    },
    { id: "credit", label: t("economy_debtBreakdown_credit"), isNumeric: true },
    {
      id: "hkScanAnimalFunding",
      label: t("economy_debtBreakdown_hkScanAnimalFunding"),
      isNumeric: true,
    },
    {
      id: "otherPaymentMethod",
      label: t("economy_debtBreakdown_otherPaymentMethod"),
      isNumeric: true,
    },
    { id: "total", label: t("economy_debtBreakdown_total"), isNumeric: true },
    {
      id: "animalQty",
      label: t("economy_debtBreakdown_animalQty"),
      isNumeric: true,
    },
  ];

  const getRowJsx = (row: IDebtBreakdown, index: number) => {
    const isOdd = index % 2 !== 0;

    const rowStyle = {
      whiteSpace: "nowrap",
      backgroundColor: isOdd ? "#efefef" : "#fff",
      fontWeight: row.isTotalRow ? "bold" : "normal",
      fontFamily: row.isTotalRow ? "Avenir Next W1G Bold" : "Avenir Next W1G",
    };
    const columStyle = { fontWeight: "inherit", fontFamily: "inherit" };

    return (
      <TableRow key={index} style={rowStyle}>
        <TableCell style={columStyle} align="left">
          {t("economy_debtBreakdown_" + row.debtType)}
        </TableCell>

        <TableCell style={columStyle} align="right">
          {row.credit > 0 && Helper.addThousandSeparator(row.credit.toFixed(2))}
        </TableCell>

        <TableCell style={columStyle} align="right">
          {row.hkScanAnimalFunding > 0 &&
            Helper.addThousandSeparator(row.hkScanAnimalFunding.toFixed(2))}
        </TableCell>

        <TableCell style={columStyle} align="right">
          {row.otherPaymentMethod > 0 &&
            Helper.addThousandSeparator(row.otherPaymentMethod.toFixed(2))}
        </TableCell>

        <TableCell style={columStyle} align="right">
          {row.total > 0 && Helper.addThousandSeparator(row.total.toFixed(2))}
        </TableCell>

        <TableCell style={columStyle} align="right">
          {row.animalQty > 0 && row.animalQty.toFixed(0)}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <PageHeader level={"1"}>
          {t("economy_debtBreakdown_title")} {moment().format("DD.MM.YYYY")}
        </PageHeader>
      </Grid>
      <Grid item xs={12}>
        <GeneralDatalist
          headerCells={headers}
          isLoading={false}
          rows={debtBreakdown}
          getRowJsx={getRowJsx}>
          {t("economy_debtBreakdown_title")} {moment().format("DD.MM.YYYY")}
        </GeneralDatalist>
      </Grid>
    </Grid>
  );
};

export default DebtBreakdown;
