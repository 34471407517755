import http from "./httpService";

const API_URL = `validation/`;

export const getValidationRules = async () => {
  const resp = await http.get(`${API_URL}validationrules`);
  return resp;
};

export const validateBirthIds = async (birthIds: string[]) => {
  const resp = await http.post(`${API_URL}birthIds`, birthIds);
  return resp;
};

export default {
  getValidationRules,
  validateBirthIds,
};
