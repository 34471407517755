import { useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import GeneralDataList from "../../../shared/components/GeneralDatalist/GeneralDatalist";
import { TableCell, TableRow } from "../../../shared/components/Table/Table";
import withTableSorting from "../../../shared/hocs/withTableSorting";
import { IPayment } from "../../../shared/models/Payments";
import { t } from "../../../shared/services/langService";
import { economy } from "../../../shared/store/apiActions";
import { getIsLoading } from "../../../shared/store/ui/common";
import Helper from "../../../shared/utils/helper";
import ProductionArea from "../../../shared/enums/productionLines";
import { RootState } from "../../../shared/store/rootReducer";

interface FrontpagePaymentsProps {
  productionLine?: ProductionArea;
}

const GeneralDataListWithSorting = withTableSorting(GeneralDataList);

const FrontpagePayments: React.FC<FrontpagePaymentsProps> = ({
  productionLine,
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => getIsLoading(state));
  const { payments } = useSelector((state: RootState) => state.economy);
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );

  useEffect(() => {
    dispatch(economy.getFrontPagePayments(productionLine));
  }, [producerNumber]);

  const headerColumns = [
    {
      id: "paymentNumber",
      label: t("economy_paymentNumber"),
      isNumeric: false,
      width: "33%",
    },

    {
      id: "paymentDate",
      label: t("economy_paymentDate"),
      isNumeric: true,
      width: "33%",
    },
    {
      id: "netPayment",
      label: t("economy_frontpagePayments_netPayment"),
      isNumeric: true,
      width: "34%",
    },
  ];

  const download = (paymentNumber) => {
    dispatch(economy.generatePaymentPdf(paymentNumber));
  };

  const getRowJsx = (payment: IPayment) => (
    <TableRow key={payment.paymentNumber}>
      {productionLine !== ProductionArea.Poultry ? (
        <TableCell>
          <Link to="#" onClick={() => download(payment.paymentNumber)}>
            {payment.paymentNumber}
          </Link>
        </TableCell>
      ) : (
        <TableCell>{payment.paymentNumber}</TableCell>
      )}

      <TableCell align="right">
        {Helper.dateToLocale(payment.paymentDate)}
      </TableCell>
      <TableCell align="right">
        {Helper.addThousandSeparator(payment.netPayment.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  return (
    <GeneralDataListWithSorting
      headerCells={headerColumns}
      rows={payments}
      columnGroup={headerColumns}
      getRowJsx={getRowJsx}
      order="desc"
      orderBy="paymentNumber"
      isLoading={isLoading}
    />
  );
};

export default FrontpagePayments;
