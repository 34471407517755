import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { ProductionArea } from "../../enums/productionLines";
import { UserRoles } from "../../enums/CoreEnums";
import navHelpers from "../../helpers/navHelpers";
import { AuthorizationContext } from "../../../management/shared/models/Users";
import { RootState } from "../../store/rootReducer";

interface ProtectedRouteProps {
  component?: React.ComponentType | (React.FC & any);
  allowedProductionLines: ProductionArea | ProductionArea[];
  allowedUserRoles?: UserRoles[];
  authContext: AuthorizationContext;
  orgHasToBeMember?: boolean;
  render?: any;
  [x: string]: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  allowedProductionLines,
  allowedUserRoles,
  authContext,
  orgHasToBeMember = false,
  render,
  ...rest
}) => {
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { organizations, roleMap } = useSelector(
    (state: RootState) => state.user
  );
  const [productionLines, setProductionLines] = useState<string[]>([]);
  const [isMember, setIsMember] = useState<boolean>(false);
  const { roles, producers } = useSelector(
    (state: RootState) => state.auth.currentUser
  );

  useEffect(() => {
    const producerTmp = organizations?.find(
      (org) => org.producerNumber == producerNumber
    );
    setProductionLines(Object.keys(producerTmp?.productionLines || {}));
    if (producerTmp) {
      setIsMember(producerTmp.isMember);
    }
  }, [organizations, producerNumber]);

  const renderContentJsx = () => {
    if (render) {
      return render();
    }
    return <Component />;
  };

  const isAuthorized = () => {
    const isValidProductionLine = productionLines.some((productionLine) =>
      allowedProductionLines instanceof Array
        ? allowedProductionLines
            .map((item) => item.toString())
            .includes(productionLine)
        : productionLine === allowedProductionLines.toString()
    );

    const isValidMember = orgHasToBeMember ? isMember : true;

    const isValidRole = allowedUserRoles
      ? navHelpers.isValidUserRole(
          allowedUserRoles,
          roles,
          producers,
          Number(producerNumber),
          authContext,
          roleMap
        )
      : true;

    return isValidProductionLine && isValidMember && isValidRole;
  };

  if (!Component && !render) {
    throw new Error("Either component or render pattern is required");
  }

  if (productionLines?.length === 0) return null;

  return (
    <Route
      {...rest}
      render={() => (isAuthorized() ? renderContentJsx() : <Redirect to="/" />)}
    />
  );
};

export default ProtectedRoute;
