import React from "react";
import InputField from "../InputField/InputField";
import useStyles from "./DatePickerStyles";
import moment from "moment";
import { t } from "../../services/langService";
import Helper from "../../utils/helper";

interface DatePickerProps {
  onChange: any;
  selectedDate?: string | Date;
  readonly?: boolean;
  type?: "date" | "date-keyboard";
  [x: string]: any;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  selectedDate = new Date(),
  readonly,
  type = "date-keyboard",
  invalidDateMessage = t("invalid_date"),
  singleLine = false,
  ...rest
}) => {
  const classes = useStyles();

  if (selectedDate === "") {
    selectedDate = new Date();
  }

  return (
    <InputField
      type={type}
      format="DD.MM.YYYY"
      readonly={readonly}
      onDateChange={onChange}
      invalidDateMessage={invalidDateMessage}
      selectedDate={moment(
        selectedDate,
        Helper.getDateFormat(selectedDate)
      ).format("YYYY-MM-DD")}
      singleLine={singleLine}
      {...rest}
    />
  );
};

export default DatePicker;
