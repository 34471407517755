import { IEmbedToken } from "../models/PowerBIEmbedding";
import http from "./httpService";
const API_ENDPOINT = "powerbiembedding";

export const getToken = async (
  langCode: string,
  productionLine: string,
  workspaceId: string,
  reportId: string
) => {
  return await http.get<IEmbedToken>(
    `${API_ENDPOINT}/${langCode}/${productionLine}/${workspaceId}/${reportId}`
  );
};

export default getToken;
