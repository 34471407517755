import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "8px",
    margin: "12px"
  },
  printInfo: {
    display:"none"
  }
}));

export default useStyles;