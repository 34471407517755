import React, { Fragment } from "react";
import { LinearProgress, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

import useStyles from "./CourseTileStyles";

interface ICourseTileProps {
  name?: string | undefined;
  image?: any;
  progress?: number | undefined;
  maxPages?: number | undefined;
  trainingUrl?: string;
  isEmbedded?: boolean;
}

const CourseTile: React.FC<ICourseTileProps> = ({
  name,
  image,
  progress,
  maxPages,
  trainingUrl,
  isEmbedded = false,
}) => {
  const classes = useStyles({ maxPages });

  const getContextJsx = () => {
    return (
      <Fragment>
        <Paper
          className={classes.content}
          style={{
            backgroundImage: `url(${image})`,
          }}
          elevation={0}
        />
        <div className={classes.contentText}>
          {name}
          {progress && maxPages && progress >= 0 && maxPages > 0 && (
            <LinearProgress
              variant="determinate"
              value={(progress / maxPages) * 100}
              classes={{
                root: classes.root,
                barColorPrimary: classes.barColorPrimary,
                colorPrimary: classes.colorPrimary,
              }}
              style={{ margin: "5px 0 5px 0" }}
            />
          )}
        </div>
      </Fragment>
    );
  };

  return (
    <div className={classes.container}>
      {isEmbedded ? (
        <Link
          id="course-tile-embedded-link"
          to={{
            pathname: `/video/${name}`,
            state: { videoUri: `${encodeURIComponent(trainingUrl || "")}` },
          }}
          style={{ color: "#fff" }}>
          {getContextJsx()}
        </Link>
      ) : (
        <a
          id="course-tile-external-link"
          href={trainingUrl}
          target="_blank"
          style={{ color: "#fff" }} rel="noreferrer">
          {getContextJsx()}
        </a>
      )}
    </div>
  );
};

export default CourseTile;
