import React from "react";
import { Button, Container, Grid } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Alert } from "@material-ui/lab";
import { useSelector, useDispatch } from "react-redux";
import MomentUtils from "@date-io/moment";

import Header from "./shared/components/Header/Header";
import Main from "./shared/components/Main/Main";
import Footer from "./shared/components/Footer/Footer";
import useStyles from "./AppStyles";
import { t } from "./shared/services/langService";
import RouteLeavingGuard from "./shared/components/RouteLeavingGuard/RouteLeavingGuard";
import {
  routeLeavingGuardDeactivated,
  routeLeavingGuardUnforced,
} from "./shared/store/ui/common";
import { cookieUsageUpdated } from "./shared/store/ui/settings";
import AppInit from "./shared/components/AppInit/AppInit";
import CookieDisclaimerModal, {
  ButtonOption,
} from "./shared/components/CookieDisclaimerModal/CookieDisclaimerModal";
import init from "./config/init";

import BrandLogos from "./shared/components/BrandLogos/BrandLogos";
import { OperationUnit } from "./shared/enums/CoreEnums";
import { RootState } from "./shared/store/rootReducer";
import StaticBanner from "./shared/components/StaticBanner/StaticBanner";

const App = () => {
  const classes = useStyles();
  const { producerNumber, operationUnit } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const dispatch = useDispatch();
  const { routeLeavingGuard, routeLeavingGuardForced } = useSelector(
    (state: RootState) => state.ui.common
  );
  const organization = organizations.find(
    (o) => o.producerNumber == producerNumber
  ); // only == works here, not ===
  const {
    REACT_APP_TAGMANAGER_ID,
    REACT_APP_SHOW_COOKIE_DISCLAIMER,
  } = process.env;

  return (
    <AppInit>
      <CookieDisclaimerModal
        showModal={REACT_APP_SHOW_COOKIE_DISCLAIMER === "true"}
        onSelection={(button) => {
          if (
            REACT_APP_TAGMANAGER_ID &&
            button === ButtonOption.AllowAllCookies
          ) {
            dispatch(cookieUsageUpdated(true));
            init.configureTagManager(REACT_APP_TAGMANAGER_ID);
          } else {
            dispatch(cookieUsageUpdated(false));
          }
        }}
      />
      <span id="printinfo" className={classes.printInfo}>
        {organization ? organization.name : ""} /{" "}
        {organization ? organization.businessId : ""} / {currentUser.firstname}{" "}
        {currentUser.lastname}
      </span>
      <Container maxWidth={"xl"} className={classes.container}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Header />
          <StaticBanner />
          <div className={classes.main}>
            <Main />
            <Grid container className={classes.secondMain}>
              {operationUnit === OperationUnit.Sinetti && (
                <Grid item xs={12}>
                  <BrandLogos />
                </Grid>
              )}
            </Grid>
          </div>
          <Footer />
        </MuiPickersUtilsProvider>
      </Container>
      <RouteLeavingGuard
        when={routeLeavingGuard}
        force={routeLeavingGuardForced}
        title={t("ui_routeLeavingTitle")}
        text={
          t("ui_routeLeavingText")
            .split("\\n")
            ?.map((t) => <span style={{ display: "block" }}>{t}</span>) ||
          t("ui_routeLeavingText")
        }
        cancelButtonText={t("ui_cancel")}
        okButtonText={t("ui_ok")}
        onClose={(button) =>
          button === "ok"
            ? dispatch(routeLeavingGuardDeactivated())
            : dispatch(routeLeavingGuardUnforced())
        }
        onRouteChanged={() => {
          if (routeLeavingGuard === true || routeLeavingGuardForced !== 2)
            dispatch(routeLeavingGuardDeactivated());
        }}
      />
    </AppInit>
  );
};

export default App;
