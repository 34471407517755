interface IUserAckedInfoBanners {
  bannerIds: string[];
}

const _infoBannerStorageKey = "sinetti-info-banner-acks";

export const storeInfoBannerId = (value: string) => {
  const initialValue: IUserAckedInfoBanners = {
    bannerIds: [],
  };

  if (localStorage.getItem(_infoBannerStorageKey) === null) {
    localStorage.setItem(_infoBannerStorageKey, JSON.stringify(initialValue));
  }

  const obj = localStorage.getItem(_infoBannerStorageKey);
  if (obj !== null) {
    const storedObject: IUserAckedInfoBanners = JSON.parse(obj);

    if (storedObject?.bannerIds && storedObject.bannerIds.includes(value)) {
      return;
    }
    const updatedObject = storedObject;
    updatedObject.bannerIds = updatedObject.bannerIds.concat(value);
    localStorage.setItem(_infoBannerStorageKey, JSON.stringify(updatedObject));
  }
};

export const infoBannerIdExists = (value: string): boolean => {
  const obj = localStorage.getItem(_infoBannerStorageKey);
  if (obj !== null) {
    const storedObject: IUserAckedInfoBanners = JSON.parse(obj);
    if (storedObject?.bannerIds && storedObject.bannerIds.includes(value)) {
      return true;
    }
  }
  return false;
};
