import React, { Fragment, useState, useRef, createRef } from "react";
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Hidden,
} from "@material-ui/core";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

export const Table = MuiTable;

export const TableHead = MuiTableHead;

export const TableBody = MuiTableBody;

interface ICustomMuiTableRow {
  highlighted: boolean;
  classes: any;
  [x: string]: any;
}

const CustomMuiTableRow: React.FC<ICustomMuiTableRow> = ({
  highlighted = false,
  classes,
  ...rest
}) => {
  return (
    <MuiTableRow
      classes={{ root: highlighted ? classes.highligtedRoot : classes.root }}
      {...rest}
    />
  );
};

export const TableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:last-child td": {
        borderBottom: "unset",
      },
    },
    head: {},
    body: {},
  })
)(CustomMuiTableRow);

export const TableRow2 = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:last-child td": {
        borderBottom: "unset",
      },
    },
    head: {},
    body: {},
  })
)(CustomMuiTableRow);

export const ExpTableRow = ({
  primary,
  secondary,
  expandable = true,
  cellComponent: CellComponent = ExpTableCell,
  rowComponent: RowComponent = TableRow,
  defaultOpen = false,
  isFixed = false,
  onExpansionChanged = (isExpanded: boolean) => {},
}) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  const handleOpen = () => {
    setOpen((prevState) => {
      const newState = !prevState;
      onExpansionChanged(newState);
      return newState;
    });
  };

  const renderCell = (align: "left" | "right") => {
    const style = {
      paddingLeft: align === "right" ? "10px" : "3px",
      paddingRight: align === "left" ? "10px" : "3px",
      paddingTop: "6px",
      fontSize: 36,
    };

    return (
      <CellComponent
        style={{
          padding: "0",
          textAlign: align,
          width: isFixed ? "15%" : "5px",
        }}>
        {!open && <ArrowDropDown style={style} />}
        {open && <ArrowDropUp style={style} />}
      </CellComponent>
    );
  };

  return (
    <Fragment>
      <RowComponent
        onClick={() => expandable && handleOpen()}
        {...(expandable ? { style: { cursor: "pointer" } } : {})}>
        <Hidden smUp>{expandable && renderCell("left")}</Hidden>
        {primary}
        <Hidden xsDown>{expandable && renderCell("right")}</Hidden>
      </RowComponent>
      {open && (
        <RowComponent>
          <MuiTableCell style={{ padding: "5px" }} colSpan={8}>
            {secondary}
          </MuiTableCell>
        </RowComponent>
      )}
    </Fragment>
  );
};

export const ExpTableCell = withStyles((theme: Theme) =>
  createStyles({
    root: {},
    head: {
      padding: "2px 6px",
      backgroundColor: "#fff",
      color: "#4e5054",
      letterSpacing: "0.28px",
      fontSize: "0.8rem",
      fontWeight: "bolder",
      borderBottom: "unset",
    },
    body: {
      padding: "10px 6px",
      backgroundColor: "#efefef",
      fontSize: 14,
      fontWeight: "normal",
      borderRight: "none",
      borderLeft: "none",
      borderTop: "solid 1px #ddd",
      borderBottom: "solid 1px #ddd",
    },
  })
)(MuiTableCell);

export const ExpTableCell2 = withStyles((theme: Theme) =>
  createStyles({
    root: {},
    head: {
      padding: "20px 15px",
      backgroundColor: "#fff",
      color: "#4e5054",
      letterSpacing: "0.28px",
      fontSize: "0.8rem",
      fontWeight: "bolder",
      borderBottom: "unset",
    },
    body: {
      padding: "10px 15px",
      fontSize: 14,
      fontWeight: "normal",
      borderRight: "none",
      borderLeft: "none",
      borderTop: "unset" /* "solid 1px #ddd", */,
      borderBottom: "dashed 1px #ddd",
    },
  })
)(MuiTableCell);

interface ExpTableHeaderRowProps {
  onRequestSort?: Function;
  order?: string; // "asc" | "desc",
  orderBy?: string;
  children: any[];
  columnInfo?: any[];
  sorting?: boolean;
  expandable?: boolean;
}

export const ExpTableHeaderRow: React.FC<ExpTableHeaderRowProps> = ({
  order = "",
  orderBy = "",
  onRequestSort = () => {},
  children,
  columnInfo = [],
  sorting = false,
  expandable = true,
}) => {
  const ref = useRef<any>(
    Array.from({ length: children?.length || 0 }).map(() => createRef())
  );
  const childrenWithProps = React.Children.map(children, (el: any, i) =>
    React.cloneElement(el, { ref: ref.current[i] })
  );

  const createSortHandler = (property) => (e) => {
    onRequestSort(property);
  };

  const renderCell = () => {
    return childrenWithProps?.map((c, i) => (
      <Fragment key={i}>
        {sorting && (
          <ExpTableCell
            key={columnInfo[i]?.id || i}
            {...(sorting
              ? {
                  sortDirection: (orderBy === columnInfo[i]?.id
                    ? order
                    : "asc") as any,
                }
              : {})}
            /* align={numeric ? "right" : "left"} */
          >
            {c}
          </ExpTableCell>
        )}
        {!sorting && <Fragment>{c}</Fragment>}
      </Fragment>
    ));
  };

  if (!ref.current) return null;

  return (
    <MuiTableRow>
      {expandable && (
        <Hidden smUp>
          <ExpTableCell></ExpTableCell>
        </Hidden>
      )}
      {children && renderCell()}
      {expandable && (
        <Hidden xsDown>
          <ExpTableCell></ExpTableCell>
        </Hidden>
      )}
    </MuiTableRow>
  );
};
