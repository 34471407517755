import moment from "moment";
import * as mimeTypes from "mime-types";

class Helper {
  dateToIsoString(date: string | number | Date | undefined) {
    if (date === undefined || date === null) {
      return "";
    }

    return moment(date).format("YYYY-MM-DD");
  }
  dateToLocale(date: string | number | Date | undefined) {
    if (date === undefined || date === null) {
      return "";
    }

    return moment(date).format("DD.MM.YYYY");
  }
  getDateFormat(date: string | number | Date | undefined) {
    if (date === undefined) {
      return "YYYY-MM-DD";
    }

    if (moment(date, "DD.MM.YYYY", true).isValid()) {
      return "DD.MM.YYYY";
    } else if (moment(date, "YYYY-MM-DD", true).isValid()) {
      return "YYYY-MM-DD";
    } else if (moment(date, "D.M.YYYY", true).isValid()) {
      return "D.M.YYYY";
    }

    return "YYYY-MM-DD"; // default
  }
  addThousandSeparator(number: number | string | undefined) {
    if (number === undefined || number === null) {
      return "";
    }

    let parts = number.toString().split(",");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(",");
  }
  /**
   * to instantly download pdf without popup
   * @param data
   * @param filename
   */
  downloadPdf(data: any, filename: string) {
    this.downloadFile(data, filename, "application/pdf");
  }
  /**
   * to instantly download without popup
   * @param data
   * @param filename
   */
  downloadFile(data: any, filename: string, mimeType?: any) {
    const fileURL = this.createObjectURL(data, filename, mimeType);
    let documentLink = document.createElement("a");
    documentLink.download = filename;
    documentLink.href = fileURL;
    documentLink.click();
  }
  /***
   * for opening to new tab
   */
  openFileLocation(data: any, filename: string, mimeType?: any) {
    const fileURL = this.createObjectURL(data, filename, mimeType);
    //Open the URL on new Window
    window.open(fileURL);
  }
  private createObjectURL(data: any, filename: string, mimeType?: any) {
    mimeType = mimeType || mimeTypes.lookup(filename);
    const blob = new Blob([data], {
      type: mimeType,
    });
    const fileURL = URL.createObjectURL(blob);
    return fileURL;
  }

  arrayUnique(data: any[]) {
    return Array.from(
      new Set<string>(data.map((row) => JSON.stringify(row)))
    ).map((row) => JSON.parse(row));
  }
}

export default new Helper();
