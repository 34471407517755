import { createSlice } from "@reduxjs/toolkit";

import { ITrainings as ITrainingsDTO } from "../models/Trainings";

interface ITrainings {
  trainings?: ITrainingsDTO;
}

const initialState: ITrainings = {};

const slice = createSlice({
  name: "trainings",
  initialState: initialState,
  reducers: {
    trainingsReceived: (state, action) => {
      state.trainings = action.payload.data as ITrainingsDTO;
    },
    trainingsCleared: (state) => {
      state.trainings = initialState.trainings;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("StateReseted", (state, action) => {
      return { ...initialState };
    });
  },
});

export const { trainingsReceived, trainingsCleared } = slice.actions;

export default slice.reducer;
