import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textNormal: {
    letterSpacing: "0.33px",
    fontSize: "0.9rem",
    color: "#777"
  },
  textBold: {
    fontFamily: "Avenir Next W1G Bold",
    letterSpacing: "0.28px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "#535559"
  },
  link: {
    fontFamily: "Avenir Next W1G",
    letterSpacing: "0.28px",
    fontSize: "0.85rem",
    color: "#0077C6",
    textDecoration: "underline",
    cursor: "pointer"
  },
  requiredLabel: {
    fontFamily: "Avenir Next W1G Bold",
    letterSpacing: "0.28px",
    fontSize: "0.8rem",
    fontWeight: "bold"
  },
  labelContainer: {
    display: "block"
  }
}));

export default useStyles;