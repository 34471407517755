import { createTheme, ThemeOptions, Theme } from "@material-ui/core";
import AvenirNextW1GBold from "../../assets/font/AvenirNextW1G-Bold.otf";
import AvenirNextW1GMedium from "../../assets/font/AvenirNextW1G-Medium.otf";
import AvenirNextW1GRegular from "../../assets/font/AvenirNextW1G-Regular.otf";
import BeefImage from "../../assets/images/HKScan_RasinTila_6-2019_3041.jpg";
import PorkImage from "../../assets/images/20160705_pork_0267.jpg";
import PoultryImage from "../../assets/images/Poultry_picture.jpg";
import CooperativeImage from "../../assets/images/LSO_background.jpg";

const avenirNextW1GBold = {
  fontFamily: "Avenir Next W1G Bold",
  fontWeight: "bolder",
  fontStyle: "normal",
  src: `url(${AvenirNextW1GBold})`,
};

const avenirNextW1GMedium = {
  fontFamily: "Avenir Next W1G Medium",
  fontWeight: "500",
  fontStyle: "normal",
  src: `url(${AvenirNextW1GMedium})`,
};

const avenirNextW1GRegular = {
  fontFamily: "Avenir Next W1G",
  fontWeight: "normal",
  fontStyle: "normal",
  src: `url(${AvenirNextW1GRegular})`,
};

export interface ITheme extends Theme {
  custom: ICustom;
}

interface IThemeOptions extends ThemeOptions {
  custom: ICustom;
}

interface ICustom {
  images: IImage;
}

interface IImage {
  Nauta: string;
  Sika: string;
  Broileri: string;
  Osuuskunta: string;
  Muu: string;
}

const theme = createTheme({
  props: {
    MuiPaper: {
      elevation: 1,
      square: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: "#152B62",
    },
    secondary: {
      main: "#0077C6",
    },
  },
  typography: {
    fontFamily: "Avenir Next W1G",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [
          avenirNextW1GRegular,
          avenirNextW1GMedium,
          avenirNextW1GBold,
        ],
        ".MuiGrid-container a, .MuiGrid-container a:hover, .MuiGrid-container a:visited": {
          color: "#0077C6",
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        color: "#000",
        backgroundColor: "#fff",
      },
    },
  },
  custom: {
    images: {
      Nauta: BeefImage,
      Sika: PorkImage,
      Broileri: PoultryImage,
      Osuuskunta: CooperativeImage,
      Muu: "",
    },
  },
} as IThemeOptions);

export default theme;
