import React from "react";
import { TablePagination as Pagination } from "@material-ui/core";
import useStyles from "./TablePaginationStyles";
import TablePaginationActions from "../TablePaginationActions/TablePaginationActions";
import { t } from "../../services/langService";

interface TablePaginationProps {
  rows?: any[] | null;
  labelRowsPerPage?: string;
  rowsPerPage?: number;
  page?: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  paginationAllText?: string;
}

const TablePagination: React.FC<TablePaginationProps> = ({
  rows,
  labelRowsPerPage = "",
  rowsPerPage = 10,
  page = 0,
  onChangePage,
  onChangeRowsPerPage,
  paginationAllText = t("ui_all"),
}) => {
  const classes = useStyles();

  const rowCount = rows && rows.length > 0 ? rows.length : 0;

  return (
    <Pagination
      classes={{ toolbar: classes.tablePaginationToolbar }}
      component={"div"}
      rowsPerPageOptions={[
        5,
        10,
        25,
        {
          value: rowCount === 0 ? 1 : rowCount, // There is a bug where this
          label: paginationAllText || "All",
        },
      ]}
      colSpan={8}
      count={rowCount}
      rowsPerPage={rowsPerPage === 0 ? 1 : rowsPerPage}
      page={page}
      SelectProps={{ native: true }}
      onPageChange={(e, page) => onChangePage(e, page)}
      onRowsPerPageChange={(e) => onChangeRowsPerPage(e, rowCount)}
      ActionsComponent={TablePaginationActions as any}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
      labelRowsPerPage={labelRowsPerPage}
    />
  );
};

export default TablePagination;
