import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
    height: "100%",
    color: "#fff",
    fontSize: "0.9em",
    paddingBottom: "20px",
  },
  logoContainer: {
    paddingTop: "30px",
    paddingBottom: "30px",
    display: "block",
    whiteSpace: "nowrap",
    minWidth: "120px",
  },
  logo: {
    height: "20px",
    pointerEvents: "none",
    "& > *": {
      fill: "#fff !important",
    },
  },
  kivinettiLogo: {
    height: "80px",
    pointerEvents: "none",
    "& > *": {
      fill: "#fff !important",
    },
  },
  icons: {
    marginTop: "20px",
    "& > *": {
      marginLeft: "7px",
      marginRight: "7px",
      fill: "#fff !important",
    },
  },
  hkscanEnvironmentLeft: {
    paddingTop: "30px",
    transform: "scale(0.6)",
    position: "relative",
    right: "-33%",
    "& > *": {
      fill: "#C9C8C6",
    },
  },
  hkscanEnvironmentRight: {
    paddingTop: "30px",
    transform: "scale(0.6)",
    position: "relative",
    left: "-33%",
    "& > *": {
      fill: "#C9C8C6",
    },
  },
  kivinettiEnvironmentLeft: {
    paddingTop: "30px",
    position: "relative",
    right: "-33%",
    height: "250px",
    "& > *": {
      fill: "#C9C8C6",
    },
  },
  kivinettiEnvironmentRight: {
    paddingTop: "30px",
    position: "relative",
    left: "-33%",
    height: "250px",
    "& > *": {
      fill: "#C9C8C6",
    },
  },
  cooperativeFooterImage: {
    float: "right",
    position: "relative",
    left: "70px",
    height: "250px",
    "& > *": {
      fill: "#C9C8C6",
    },
  },
  cooperativeContact: {
    position: "relative",
    top: "20px",
    alignSelf: "center",
    textAlign: "left",
  },
  cooperativeContactAlone: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "250px",
  },
}));

export default useStyles;
