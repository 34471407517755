import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    marginBottom: "0px",
    marginTop: "0px",
  },
  errorMsg: {
    marginLeft: "5px",
  },
  alert: {
    color: "#650000",
  },
});

export default useStyles;
