import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import PageHeader from "../PageHeader/PageHeader";
import CalfMedicalDataGrid from "./CalfMedicalDataGrid";
import CalfMedicalQuestionData from "../../../beef/shared/models/CalfMedicalQuestionData";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";
import * as Yup from "yup";
import { t } from "../../services/langService";

interface HealthQuestionsProps {
  medicalDataOfAnimals: CalfMedicalQuestionData;
  onMedicalDataOfAnimalsChanged: (
    newMedicalDataOfCalvesData: CalfMedicalQuestionData
  ) => void;
  ref: any;
  onValidate?: Function;
  readonly?: boolean;
  handleError;
}

const CalfMedicalQuestions: React.FC<HealthQuestionsProps> = React.forwardRef(
  (
    {
      medicalDataOfAnimals,
      onMedicalDataOfAnimalsChanged,
      onValidate = () => {},
      readonly = false,
      handleError,
    },
    ref
  ) => {
    const radioItems = [
      { label: t("ui_yes"), name: "yes", value: 1 },
      { label: t("ui_no"), name: "no", value: 0 },
    ];

    const onHandleError = (message: string) => {
      handleError(message);
    };

    const animalMedicalDataValidationSchema = Yup.object().shape({
      birthId: Yup.string().required().max(20),
      medicationDate: Yup.string().required(),
      reason: Yup.string().required().max(100),
      productName: Yup.string().required().max(100),
      additionalInformation: Yup.string().max(200),
    });

    return (
      <Grid container spacing={1} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader level="2">
            {t("animalMedicalData_animalMedicalData")}
          </PageHeader>
        </Grid>

        <div>
          <RadioButtonGroup
            label={t("animalMedicalData_gmoNotUsed")}
            groupItems={radioItems}
            required={true}
            useControlled={true}
            defaultValue={Number(medicalDataOfAnimals.gmoNotUsed)}
            onChange={(value: number) => {
              const newData = {
                ...medicalDataOfAnimals,
                gmoNotUsed: !!value,
              };
              onMedicalDataOfAnimalsChanged(newData);
            }}
          />
          <RadioButtonGroup
            label={t("animalMedicalData_grazedWithMother")}
            groupItems={radioItems}
            defaultValue={Number(medicalDataOfAnimals.grazedWithMother)}
            required={true}
            useControlled={true}
            onChange={(value: number) => {
              const newData = {
                ...medicalDataOfAnimals,
                grazedWithMother: !!value,
              };
              onMedicalDataOfAnimalsChanged(newData);
            }}
          />
          <RadioButtonGroup
            label={t("animalMedicalData_savedInNaseva")}
            groupItems={radioItems}
            required={true}
            useControlled={true}
            defaultValue={Number(medicalDataOfAnimals.savedInNaseva)}
            onChange={(value: number) => {
              const newData = {
                ...medicalDataOfAnimals,
                savedInNaseva: !!value,
                medicated: undefined,
              };
              onMedicalDataOfAnimalsChanged(newData);
            }}
          />
          <RadioButtonGroup
            label={t("animalMedicalData_medicated")}
            groupItems={radioItems}
            defaultValue={Number(medicalDataOfAnimals.medicated)}
            required={
              medicalDataOfAnimals.savedInNaseva === false ? true : false
            }
            useControlled={true}
            disabled={
              medicalDataOfAnimals.savedInNaseva === false ? false : true
            }
            onChange={(value: number) => {
              const newData = {
                ...medicalDataOfAnimals,
                medicated: !!value,
              };
              onMedicalDataOfAnimalsChanged(newData);
            }}
          />
        </div>

        <CalfMedicalDataGrid
          handleCalfMedicalDataChange={(newMedicalData) => {
            const newData = {
              ...medicalDataOfAnimals,
              animalMedicalDataRows: newMedicalData,
            };
            onMedicalDataOfAnimalsChanged(newData);
          }}
          handleError={onHandleError}
          medicalData={medicalDataOfAnimals.animalMedicalDataRows}
          canEdit={
            !!medicalDataOfAnimals.medicated &&
            medicalDataOfAnimals.savedInNaseva === false &&
            medicalDataOfAnimals.savedInNaseva !== undefined
          }
          validationSchema={animalMedicalDataValidationSchema}
        />
      </Grid>
    );
  }
);

export default CalfMedicalQuestions;
