import { makeStyles } from "@material-ui/core";
import Image from "../../assets/images/LSO_background.jpg";

const useStyles = makeStyles((theme) => ({
  backGroundStyles: {
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "contain",
  },
}));

export default useStyles;
