import { Grid } from "@material-ui/core";
import React from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import GeneralDataList from "../../../../shared/components/GeneralDatalist/GeneralDatalist";
import { TableCell, TableRow } from "../../../../shared/components/Table/Table";
import { t } from "../../../../shared/services/langService";
import { getIsLoading } from "../../../../shared/store/ui/common";

import { cooperative } from "../../../../shared/store/apiActions";
import { IInterestPayment } from "../../../../shared/models/Cooperative";
import helper from "../../../../shared/utils/helper";

interface IProps {
  isFrontPage: boolean;
  rows: IInterestPayment[];
  [x: string]: any;
}

const InterestPaymentGrid: React.FC<IProps> = ({
  isFrontPage,
  rows,
  ...rest
}) => {
  const isLoading = useSelector((state) => getIsLoading(state));

  const dispatch = useDispatch();
  const download = (invoiceNumber) => {
    dispatch(cooperative.downloadInterestPaymentPdf(invoiceNumber));
  };

  const getFrontpageRowJsx = (row: IInterestPayment, ind: number) => (
    <TableRow key={ind}>
      <TableCell>
        <Link to="#" onClick={() => download(row.paymentNumber)}>
          {row.paymentNumber}
        </Link>
      </TableCell>
      <TableCell align="right">
        {helper.dateToLocale(row.paymentDate.toString())}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.netPayment.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  const getRowJsx = (row: IInterestPayment, ind: number) => (
    <TableRow key={ind}>
      <TableCell>
        <Link to="#" onClick={() => download(row.paymentNumber)}>
          {row.paymentNumber}
        </Link>
      </TableCell>
      <TableCell align="right">
        {helper.dateToLocale(row.paymentDate.toString())}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.interestAmount.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.taxAmount.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.paidShareCapital.toFixed(2))}
      </TableCell>
      <TableCell align="right">
        {helper.addThousandSeparator(row.netPayment.toFixed(2))}
      </TableCell>
    </TableRow>
  );

  const headerCells = {
    frontPage: [
      { id: "paymentNumber", label: t("cooperative_paymentNumber") },
      {
        id: "paymentDate",
        label: t("cooperative_paymentDate"),
        isNumeric: true,
      },
      {
        id: "netPayment",
        label: t("economy_frontpagePayments_netPayment"),
        isNumeric: true,
      },
    ],
    capitalInfo: [
      { id: "paymentNumber", label: t("cooperative_paymentNumber") },
      {
        id: "paymentDate",
        label: t("cooperative_paymentDate"),
        isNumeric: true,
      },
      {
        id: "interestAmount",
        label: t("cooperative_interestAmount"),
        isNumeric: true,
      },
      {
        id: "taxAmount",
        label: t("cooperative_taxAmount"),
        isNumeric: true,
      },
      {
        id: "paidShareCapital",
        label: t("cooperative_paidShareCapital"),
        isNumeric: true,
      },
      {
        id: "netPayment",
        label: t("cooperative_netPayment"),
        isNumeric: true,
      },
    ],
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <GeneralDataList
            headerCells={
              isFrontPage ? headerCells.frontPage : headerCells.capitalInfo
            }
            rows={rows}
            getRowJsx={isFrontPage ? getFrontpageRowJsx : getRowJsx}
            isLoading={isLoading}
            {...rest}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default InterestPaymentGrid;
