import { makeStyles } from "@material-ui/core";
import theme from "../../../../shared/themes";

const useStyles = makeStyles({
  dialog: {
    minWidth: "600px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "350px",
    },
  },
});

export default useStyles;
