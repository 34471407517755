import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: "#fff",
    padding: "5px",
  },
  image: {
    width: "100%",
    height: "300px",
    backgroundImage: `url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "-100px -50px",
    marginBottom: "20px",
  },
  table: {
    width: "100%"
  },
  tablePaginationToolbar: {
    "& > *:nth-child(2), & > *:nth-child(3)": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
}));

export default useStyles;
