import { IContent } from "../models/Publications";
import http from "./httpService";

const API_ENDPOINT = "publicationcontent";

export interface IGetListingParams {
  langCode: string;
  publicationContext: string;
  publicationType: string;
  limit?: number;
  disableSort?: boolean;
}

export const getListing = async ({
  langCode,
  publicationContext,
  publicationType,
  limit,
  disableSort,
}: IGetListingParams) => {
  const params = {
    limit: limit,
    disableSort: disableSort,
  };

  return await http.get<IContent[]>(
    `${API_ENDPOINT}/${langCode}/${publicationContext}/list/${publicationType}`,
    { params }
  );
};

export const getMenuContainerReferenceId = async (
  productionLine: string,
  containerPageName: string
) => {
  return await http.get<any[]>(
    `${API_ENDPOINT}/menuitems/${productionLine}/${containerPageName}`
  );
};

export const getMenuItemTree = async (
  langCode: string,
  productionLine: string,
  referenceId: number
) => {
  return await http.get<any[]>(
    `${API_ENDPOINT}/${langCode}/${productionLine}/${referenceId}/tree`
  );
};

export const getPublication = async (
  langCode: string,
  productionLine: string,
  pageNameOrReferenceId: string
) => {
  return await http.get<any>(
    `${API_ENDPOINT}/${langCode}/${productionLine}/${pageNameOrReferenceId}`
  );
};

export const getFile = async (filePath: string) => {
  return await http.get<any>(`${API_ENDPOINT}/file/${filePath}`, {
    responseType: "arraybuffer",
  });
};

export const getBanners = async (langCode: string, productionLine: string) => {
  return await http.get<any>(
    `${API_ENDPOINT}/${langCode}/${productionLine}/banners`
  );
};

export default {
  getListing,
  getMenuContainerReferenceId,
  getMenuItemTree,
  getPublication,
  getFile,
  getBanners,
};
