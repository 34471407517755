import React, { Fragment, useEffect, useState } from "react";
import { TableContainer } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import PageText from "../../../../shared/components/PageText/PageText";
import * as api from "../../../../shared/store/apiActions";
import {
  ExpTableCell2,
  TableRow2 as ExpTableRow2,
  ExpTableRow,
} from "../../../../shared/components/ExpandableTable/ExpandableTable";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableRow2,
  TableHeaderRow,
  TableCell,
  TableCell2,
} from "../../../../shared/components/Table/Table";
import { t } from "../../../../shared/services/langService";
import sikaTeurasElainLajit from "../../enums/sikaTeurasElainLajit";
import { getComparator, stableSort } from "../../../../shared/utils/sorting";
import { getIsLoading } from "../../../../shared/store/ui/common";
import useStyles from "./TransportRemindersStyles";
import { RootState } from "../../../../shared/store/rootReducer";

interface ITransportReminderProps {
  title?: string;
}

const TransportReminders: React.FC<ITransportReminderProps> = ({ title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reminders = useSelector(
    (state: RootState) => state.pork.porkTransportInfo
  );
  const producerNumber = useSelector(
    (state: RootState) => state.ui.settings.producerNumber
  );
  const [showAll, setShowAll] = useState<boolean>(false);
  const [order, setOrder] = React.useState<any>({
    order: "asc",
    orderBy: "notificationId",
    secOrder: "asc",
    secOrderBy: "syntymatunnus",
  });
  const isLoading = useSelector((state) => getIsLoading(state));

  const elainlajit = Object.values(sikaTeurasElainLajit)
    .filter((el: any) => !isNaN(el))
    .map((el) => ({ [el]: t(`animalType_${sikaTeurasElainLajit[el]}`) }))
    .reduce((acc, x) => {
      for (let key in x) acc[key] = x[key];
      return acc;
    }, {});

  useEffect(() => {
    dispatch(api.pork.getPorkTransportInfo());
  }, [producerNumber]);

  const handleRequestSort = (order, setOrder, id) => {
    const isAsc = order.orderBy === id && order.order === "asc";
    setOrder((prevState) => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: id,
    }));
  };

  return (
    <Fragment>
      <TableContainer>
        <Table>
          {title && (
            <TableHead>
              <TableRow2>
                <TableCell2 colSpan={2}>{title}</TableCell2>
                <TableCell2 colSpan={2}></TableCell2>
              </TableRow2>
            </TableHead>
          )}
          <TableBody>
            {stableSort(
              reminders.slice(0, showAll ? reminders.length : 3),
              getComparator(
                order.order,
                order.orderBy,
                undefined,
                order.secOrder,
                order.secOrderBy
              )
            ).map((reminder: any) => (
              <ExpTableRow
                expandable={true}
                cellComponent={ExpTableCell2}
                rowComponent={ExpTableRow2}
                primary={
                  <Fragment>
                    <ExpTableCell2>
                      <span
                        style={{
                          display: "block",
                          fontFamily: "Avenir Next W1G Bold",
                          fontWeight: "bolder",
                        }}>
                        {moment(reminder.noutoPvm).format(`ddd D.M.YYYY`)}{" "}
                        {reminder.noutoKlo ? "Klo " : ""} {reminder.noutoKlo}
                      </span>
                      <span
                        style={{
                          display: "block",
                          fontFamily: "Avenir Next W1G",
                        }}>
                        {reminder.elainlajiKpl.map((elainlaji: any) => (
                          <span style={{ display: "block" }}>
                            {elainlajit[elainlaji.elainlaji]} {elainlaji.kpl}{" "}
                            {t("ui_pcs")}
                          </span>
                        ))}
                        <span style={{ display: "block" }}>
                          {reminder.kuljettajaNimi} {reminder.kuljettajaPuhNro}
                        </span>
                      </span>
                    </ExpTableCell2>
                    <ExpTableCell2></ExpTableCell2>
                  </Fragment>
                }
                secondary={
                  <Fragment>
                    <Table>
                      <TableHead>
                        <TableHeaderRow
                          order={order.order}
                          orderBy={order.orderBy}
                          onRequestSort={(id) =>
                            handleRequestSort(order, setOrder, id)
                          }
                          columnInfo={[{ id: "notificationId" }, {}, {}]}
                          sorting={false}>
                          <TableCell>{t("animalNotification_id")}</TableCell>
                          <TableCell>
                            {t("animalNotification_animalType")}
                          </TableCell>
                          <TableCell>
                            {t("animalNotification_department")}
                          </TableCell>
                          <TableCell>{t("ui_count2")}</TableCell>
                        </TableHeaderRow>
                      </TableHead>
                      <TableBody>
                        {stableSort(
                          (Object.keys(reminder.ilmoitusData).reduce(
                            (acc: any, id: string) => ({
                              ...reminder.ilmoitusData[id],
                              elaimet: [
                                ...(acc?.elaimet || []),
                                ...reminder.ilmoitusData[id].elaimet.map(
                                  (elain: any) => ({
                                    ...elain,
                                    notificationId: id,
                                  })
                                ),
                              ],
                            }),
                            {}
                          ) as any).elaimet,
                          getComparator(
                            order.order,
                            order.orderBy,
                            undefined,
                            order.secOrder,
                            order.secOrderBy
                          )
                        ).map((elain: any) => (
                          <TableRow>
                            <TableCell>
                              <Link
                                to={`/pork/slaughternotifications/${elain.notificationId}`}>
                                {elain.notificationId}
                              </Link>
                            </TableCell>
                            <TableCell>{elainlajit[elain.elainlaji]}</TableCell>
                            <TableCell>{elain.osasto}</TableCell>
                            <TableCell>{elain.kpl}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {Object.keys(reminder.ilmoitusData).map(
                      (notificationId: string) =>
                        reminder.ilmoitusData[notificationId].lisatiedot && (
                          <p>
                            {t("animalNotification_notificationIdLabel", {
                              0: notificationId,
                            })}{" "}
                            {reminder.ilmoitusData[notificationId].lisatiedot}
                          </p>
                        )
                    )}
                  </Fragment>
                }
              />
            ))}
            {!isLoading && !reminders?.length && (
              <ExpTableRow2>
                <ExpTableCell2 colSpan={4}>
                  {t("animalNotification_noRowsFound")}
                </ExpTableCell2>
              </ExpTableRow2>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Fragment>
        {reminders?.length > 3 && (
          <PageText
            type="link"
            onClick={() => setShowAll(!showAll)}
            style={{ marginLeft: "15px", marginTop: "15px" }}>
            {t(
              showAll
                ? "transportReminders_expanderLinkMinimize"
                : "transportReminders_expanderLink"
            )}
          </PageText>
        )}
      </Fragment>
    </Fragment>
  );
};

export default TransportReminders;
