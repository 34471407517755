import * as React from "react";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "./FullFeaturedDataGridStyles";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Close";
import FileCopy from "@material-ui/icons/FileCopy";
import { t } from "../../services/langService";

import {
  GridRowsProp,
  useGridApiRef,
  DataGrid,
  GridApiRef,
  GridColumns,
  GridRowId,
  GridApi,
  GridRowParams,
  MuiEvent,
  GridToolbarContainer,
  useGridSlotComponentProps,
  DataGridProps,
  GridEditMode,
  GridEditRowsModel,
} from "@mui/x-data-grid";
import { Tooltip } from "@material-ui/core";

interface EditToolbarProps {
  apiRef: GridApiRef;
}

export function EditToolbar(props: EditToolbarProps) {
  const { state, apiRef } = useGridSlotComponentProps();

  const handleClick = () => {
    /**
     * All rows need to have an sortable ID
     */
    const rowIds = apiRef.current.getAllRowIds() as number[];
    const id = rowIds.length > 0 ? Math.max(...rowIds) + 1 : 1;

    apiRef.current.updateRows([{ id, isNew: true }]);
    apiRef.current.setRowMode(id, "edit");
    // Wait for the grid to render with the new row
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });
      apiRef.current.setCellFocus(id, "value"); // TODO : should point to first element
    }, 150);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        {t("farmData_editContactsDlg_AddRecord")}
      </Button>
    </GridToolbarContainer>
  );
}

export interface RowMenuProps {
  api: GridApi;
  id: GridRowId;
}

interface IRenderActionButtons {
  handleSaveClick: any;
  handleCancelClick: any;
  handleEditClick: any;
  handleCopyClick: any;
  handleDeleteClick: any;
  isInEditMode: boolean;
  hasCopyButton: boolean;
  hasClearMode: boolean;
}

export const RenderActionButtons: React.FC<IRenderActionButtons> = ({
  handleSaveClick,
  handleCancelClick,
  handleEditClick,
  handleCopyClick,
  handleDeleteClick,
  isInEditMode,
  hasCopyButton = false,
  hasClearMode = false,
}) => {
  const classes = useStyles();
  if (isInEditMode) {
    return (
      <div className={classes.root}>
        <Tooltip title={t("ui_save")}>
          <IconButton
            color="primary"
            size="small"
            aria-label="save"
            onClick={handleSaveClick}>
            <SaveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("ui_cancel")}>
          <IconButton
            color="inherit"
            size="small"
            aria-label="cancel"
            className={classes.textPrimary}
            onClick={handleCancelClick}>
            <CancelIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Tooltip title={t("ui_edit")}>
        <IconButton
          color="inherit"
          className={classes.textPrimary}
          size="small"
          aria-label="edit"
          onClick={handleEditClick}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      {hasCopyButton && (
        <Tooltip title={t("ui_copy")}>
          <IconButton
            color="inherit"
            size="small"
            aria-label="copy"
            onClick={handleCopyClick}>
            <FileCopy fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={hasClearMode ? t("ui_clear") : t("ui_remove")}>
        <IconButton
          color="inherit"
          size="small"
          aria-label={hasClearMode ? "clear" : "delete"}
          onClick={handleDeleteClick}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

// how to feed props to this?
export function RowMenuCell(props: RowMenuProps) {
  const { api, id } = props;

  const isInEditMode = api.getRowMode(id) === "edit";

  const handleEditClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, "edit");
  };

  const handleCopyClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, "view");

    const row = api.getRow(id);
    api.updateRows([{ ...row, isNew: true }]);
  };

  const handleSaveClick = (event) => {
    event.stopPropagation();
    api.commitRowChange(id);

    api.setRowMode(id, "view");

    const row = api.getRow(id);
    api.updateRows([{ ...row, isNew: false }]);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    api.updateRows([{ id, _action: "delete" }]);
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    api.setRowMode(id, "view");

    const row = api.getRow(id);
    if (row!.isNew) {
      api.updateRows([{ id, _action: "delete" }]);
    }
  };

  return (
    <RenderActionButtons
      handleSaveClick={handleSaveClick}
      handleCancelClick={handleCancelClick}
      handleEditClick={handleEditClick}
      handleCopyClick={handleCopyClick}
      handleDeleteClick={handleDeleteClick}
      isInEditMode={isInEditMode}
      hasCopyButton={false}
      hasClearMode={false}
    />
  );
}

interface IDataGridProps {
  editMode: GridEditMode;
  onRowEditStart?: Function;
  onRowEditStop?: Function;
  onEditRowCommit?: Function;
  height?: number;
  validationSchema?: any; // Yup validation schema
  [x: string]: any;
}

const FullFeaturedDataGrid = ({
  columns,
  rows,
  editMode,
  onRowEditStart = () => {},
  onRowEditStop = () => {},
  onEditRowCommit = () => {},
  rowsPerPageOptions = [5, 10, 25],
  height = 500,
  validationSchema,
  ...rest
}: IDataGridProps & DataGridProps) => {
  const apiRef = useGridApiRef();
  const classes = useStyles();

  const [editRowsModel, setEditRowsModel] = React.useState({});

  // abort early false to get all validation errors in schema
  const yupValidate = async (field) => {
    return await validationSchema.validate(field, {
      abortEarly: false,
    });
  };

  const handleEditRowsModelChange = React.useCallback(
    (newModel: GridEditRowsModel) => {
      const updatedModel = { ...newModel };

      if (Object.keys(updatedModel).length > 0) {
        const id = Object.keys(updatedModel)[0];
        const dataRow = updatedModel[id];

        let newRowObject = { id: Number(id) };
        Object.keys(dataRow).forEach((obj, ind) => {
          let key = Object.keys(dataRow)[ind];
          newRowObject[key] = dataRow[obj]?.value;
        });

        yupValidate(newRowObject)
          .then((isValid) => {
            setEditRowsModel(updatedModel);
          })
          .catch((error) => {
            // attach errors to fields
            for (const key in error.inner) {
              if (Object.prototype.hasOwnProperty.call(error.inner, key)) {
                const fieldError = error.inner[key];

                updatedModel[id][fieldError.path] = {
                  ...updatedModel[id][fieldError.path],
                  error: true,
                };
              }
            }
            setEditRowsModel(updatedModel);
          });
      } else {
        // set empty rows aswell to allow save & delete actions
        setEditRowsModel(updatedModel);
      }
    },
    []
  );

  /**
   * Triggered on row save
   */
  const handleRowEditCommit = (
    id: GridRowId,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    onEditRowCommit(editRowsModel);
  };

  return (
    <div style={{ height: height, width: "100%" }}>
      <DataGrid
        className={classes.grid}
        columns={columns}
        rows={rows}
        editMode={editMode}
        editRowsModel={editRowsModel}
        onEditRowsModelChange={handleEditRowsModelChange}
        onRowEditCommit={handleRowEditCommit}
        rowsPerPageOptions={rowsPerPageOptions}
        hideFooterSelectedRowCount={true}
        {...rest}
      />
    </div>
  );
};

export default FullFeaturedDataGrid;
