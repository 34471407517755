import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

import {
  operationUnitUpdated,
  producerNumberUpdated,
} from "../../../store/ui/settings";

import { contentRefreshed } from "../../../store/ui/common";
import { stateReseted } from "../../../store/createStore";
import useStyles from "./FarmSelectionModalStyles";
import PageHeader from "../../PageHeader/PageHeader";
import PageText from "../../PageText/PageText";
import Button from "../../Button/Button";
import { t } from "../../../services/langService";
import DropdownMenu from "../../DropdownMenu/DropdownMenu";
import { useHistory } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import * as api from "../../../store/apiActions";
import { RootState } from "../../../store/rootReducer";

interface FarmSelectionModalProps {
  open: boolean;
  onClose: any;
}

const FarmSelectionModal: React.FC<FarmSelectionModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  const { producerNumber, operationUnit } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const organizationsForAutocompleteOptions = useSelector(
    (state: RootState) => state.user.organizationsForAutocompleteOptions
  );
  const [selectedProducerNumber, setSelectedProducerNumber] = useState(
    producerNumber
  );

  const [selectedOperationUnit, setSelectedOperationUnit] = useState(
    operationUnit
  );

  const [autoCompleteFilter, setAutoCompleteFilter] = useState("");
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<any[]>([]);
  const [noResultsText, setNoResultsText] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (value) => {
    if (value) {
      setSelectedProducerNumber(value);
      const selectedOrganization = organizations.find(
        (o) => o.producerNumber === Number(value)
      );
      if (selectedOrganization) {
        setSelectedOperationUnit(selectedOrganization.operationUnit);
      }
    }
  };

  const handleAutocompleteChange = (value) => {
    if (value) {
      setSelectedProducerNumber(value);
      const selectedOrganization = organizationsForAutocompleteOptions.find(
        (o) => o.producerNumber === Number(value)
      );
      if (selectedOrganization) {
        setSelectedOperationUnit(selectedOrganization.operationUnit);
      }
    }
  };

  useEffect(() => {
    const opts = autoCompleteFilter
      ? organizationsForAutocompleteOptions.map((p) => {
          return {
            name: p.producerNumber,
            text: p.name,
            value: p.producerNumber,
            businessId: p.businessId,
          };
        })
      : [];
    setAutoCompleteOptions(opts);
    setNoResultsText(
      autoCompleteFilter
        ? t("farmSelectionModal_noResults")
        : t("farmSelectionModal_typeToSearchProducers")
    );
  }, [organizationsForAutocompleteOptions]);

  useEffect(() => {
    if (autoCompleteFilter) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          api.user.getUserAutocompleteOrganizations(
            currentUser.authenticationSource,
            autoCompleteFilter
          )
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setAutoCompleteOptions([]);
      setNoResultsText(t("farmSelectionModal_typeToSearchProducers"));
    }
  }, [autoCompleteFilter]);

  const handleFilterChange = (value, reason) => {
    if (reason === "input") {
      setAutoCompleteOptions([]);
      setNoResultsText(
        value
          ? t("farmSelectionModal_searchingProducers")
          : t("farmSelectionModal_typeToSearchProducers")
      );
      setAutoCompleteFilter(value);
    }
  };

  const handleClose = async (ok: boolean) => {
    onClose(ok);
    if (ok) {
      if (currentUser.authenticationSource === "HKScanAzureAD") {
        await dispatch(
          api.user.getUserFilteredOrganizations(
            "HKScanAzureAD",
            selectedProducerNumber?.toString()
          )
        );
      }
      updateOrganizationSettings();
    } else {
      setSelectedProducerNumber(producerNumber);
    }
    setAutoCompleteFilter("");
    setAutoCompleteOptions([]);
  };

  const updateOrganizationSettings = () => {
    if (currentUser.authenticationSource) {
      dispatch(
        api.user.updateUserLastVisitedOrganization(selectedProducerNumber)
      );
    }
    dispatch(stateReseted());
    dispatch(contentRefreshed());
    history.push("/");
  };

  const dropdownOptions = organizations.map((p) => {
    return {
      name: p.producerNumber.toString(),
      text: p.name,
      value: p.producerNumber,
    };
  });

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.dialog, scrollPaper: classes.scrollPaper }}
      onClose={() => handleClose(false)}
      aria-labelledby="farm-selection-modal-title"
      aria-describedby="farm-selection-modal-description">
      <DialogTitle id="farm-selection-modal-title">
        <PageHeader level="2" component="div">
          {t("farmSelectionModal_header")}
        </PageHeader>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="farm-selection-modal-description">
          <PageText
            type="normal"
            component="span"
            gutterBottom={true}
            display={"block"}>
            {t("farmSelectionModal_infoText")}
          </PageText>
          {currentUser.authenticationSource !== "HKScanAzureAD" && (
            <DropdownMenu
              onChange={handleChange}
              options={dropdownOptions}
              defaultValue={selectedProducerNumber}
              fullWidth={true}
            />
          )}
          {currentUser.authenticationSource === "HKScanAzureAD" && (
            <Autocomplete
              noOptionsText={
                noResultsText
                  ? noResultsText
                  : t("farmSelectionModal_typeToSearchProducers")
              }
              onInputChange={(event, value, reason) =>
                handleFilterChange(value, reason)
              }
              onChange={(event, value) => handleAutocompleteChange(value?.name)}
              options={autoCompleteOptions}
              getOptionLabel={(option) =>
                option?.name
                  ? `${option.text} ${option.name} (${option.businessId})`
                  : ""
              }
              defaultValue={selectedProducerNumber}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} type={"modal"} autoFocus>
          {t("ui_cancel")}
        </Button>
        <Button onClick={() => handleClose(true)} type={"modal"}>
          {t("farmSelectionModal_okButtonText")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FarmSelectionModal;
