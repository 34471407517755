import http from "../../../../shared/services/httpService";
import PorkSlaughterNotificationDTO from "../../models/PorkSlaughterNotificationDTO";
import PorkApiResponseDTO from "../../models/PorkApiResponseDTO";
import KetjukysymysData from "../../models/KetjukysymysData";
import { IDepartmentRange } from "../../models/PorkDispatchListing";

const API_URL = `pork`;

export const getHealthQuestions = async () => {
  const resp = await http.get(`${API_URL}/slaughter/healthquestions`);
  return resp;
};

export const createPorkSlaughterNotification = async (
  notification: PorkSlaughterNotificationDTO
) => {
  const resp = await http.post<PorkApiResponseDTO>(
    `${API_URL}/slaughter`,
    notification
  );
  return resp;
};

export const getPorkNotificationsList = async (
  notificationStatus: "open" | "completed",
  startDate?: string,
  endDate?: string
) => {
  let resp;

  if (startDate && endDate) {
    resp = await http.get<any>(
      `${API_URL}/list/${notificationStatus}?startDate=${startDate}&endDate=${endDate}`
    );
  } else {
    resp = await http.get<any>(`${API_URL}/list/${notificationStatus}`);
  }
  return resp;
};

export const getPorkSlaughterNotification = async (notificationId: number) => {
  const resp = await http.get<any>(`${API_URL}/slaughter/${notificationId}`);
  return resp;
};

export const updatePorkSlaughterInfo = async (
  notification: PorkSlaughterNotificationDTO,
  notificationId: number
) => {
  const resp = await http.put<PorkApiResponseDTO>(
    `${API_URL}/slaughter/${notificationId}`,
    notification
  );
  return resp;
};

export const getPorkTransportInfo = async () => {
  const resp = await http.get<any>(`${API_URL}/transport`);
  return resp;
};

export const getPorkHealthInfo = async (notificationId: number) => {
  return await http.get<KetjukysymysData>(
    `${API_URL}/health/${notificationId}`
  );
};

export const getPorkDepartmentRanges = async () => {
  return await http.get<IDepartmentRange[]>(
    `${API_URL}/slaughter/departmentRanges`
  );
};

export default {
  getHealthQuestions,
  createPorkSlaughterNotification,
  getPorkNotificationsList,
  getPorkSlaughterNotification,
  updatePorkSlaughterInfo,
  getPorkTransportInfo,
  getPorkHealthInfo,
  getPorkDepartmentRanges,
};
