import React, { useState } from "react";
import {
  FolderOutlined,
  InsertDriveFileOutlined,
  ChevronRightOutlined,
  HelpOutline,
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";

import { TableCell, TableRow } from "../Table/Table";
import WithTableSorting from "../../hocs/withTableSorting";
import WithTableFiltering from "../../hocs/withTableFiltering";
import GeneralDataList, { IHeadCell } from "../GeneralDatalist/GeneralDatalist";
import useStyles from "./FileListingStyles";
import Helper from "../../utils/helper";
import { IFileStructContainer, IFileDetails } from "../../models/FileListing";
import { isRejectedWithValue } from "@reduxjs/toolkit";

const GeneralDataListWithSortingFiltering = WithTableSorting(
  WithTableFiltering(GeneralDataList)
);

interface IFileOrFolder {
  name: string;
  displayName: string;
  sortingName: string;
  isFolder: boolean;
  modified?: string;
  [x: string]: any;
}

interface IFileListingProps {
  directoryStructure: IFileStructContainer;
  onFileClicked?: Function;
  defaultFolderPath?: string;
  hideParentsFolders?: boolean;
  inputFieldPlaceholder?: string;
  headerLabels?: string[];
  metadataLabels?: { name: string; size: string; kb: string };
}

const FileListingPage: React.FC<IFileListingProps> = ({
  directoryStructure,
  onFileClicked = () => {},
  defaultFolderPath = "",
  hideParentsFolders = false,
  inputFieldPlaceholder = "",
  headerLabels = ["", "", "", ""],
  metadataLabels = { name: "name", size: "size", kb: "kb" },
}) => {
  const classes = useStyles();
  const [currentFolder, setCurrentFolder] = useState<string>(defaultFolderPath);
  const [flattenFolders, setFlattenFolders] = useState<boolean>(false);

  const handleItemClick = (fileOrFolder: string, isFolder: boolean) =>
    isFolder ? setCurrentFolder(fileOrFolder) : onFileClicked(fileOrFolder);

  const flatten = (
    folders: any[]
  ): Array<IFileStructContainer & IFileDetails> =>
    folders.reduce(
      (acc, cur) =>
        (cur.folders.length ? [cur, ...cur.files, ...acc] : acc).concat(
          cur.folders.length ? flatten(cur.folders) : [cur, ...cur.files]
        ),
      []
    );

  const parseFileName = (name: string, level?: number): string => {
    const parts = name.split("/");
    const newName = parts
      .slice(level ? 0 : parts.length - 1, level ? level : parts.length)
      .join("/");
    return newName;
  };

  const parseBreadcrumbFolderPath = (
    fullPath: string,
    requiredPart: string
  ): string => {
    var segments = fullPath.split("/");
    return segments.slice(0, segments.indexOf(requiredPart) + 1).join("/");
  };

  const mapObject = (
    item: IFileStructContainer & IFileDetails
  ): IFileOrFolder => {
    const isFolder = !!item.folder;
    const parsedFileName = parseFileName(isFolder ? item.folder : item.file);
    return {
      ...item,
      name: isFolder ? item.folder : item.file,
      displayName: parsedFileName,
      sortingName: parsedFileName.toLowerCase(),
      isFolder: isFolder,
      modified: Helper.dateToIsoString(item.modified),
    };
  };

  const handleFilterTyped = (term: string) => setFlattenFolders(Boolean(term));

  const getBreadcrumbTrailJsx = (folder: string, rootItemLinkName = "..") => (
    <ul className={classes.breadcrumbTrailList}>
      <li>
        <ChevronRightOutlined viewBox="2 2 28 28" />
      </li>
      {[rootItemLinkName, ...folder.split("/").filter(Boolean)]
        .slice(
          hideParentsFolders && defaultFolderPath
            ? defaultFolderPath.split("/").length
            : 0
        )
        .map((part, index, array) => {
          const isLast = array.length - 1 === index;
          return (
            <li className={classes.breadcrumbTrailItem} key={index}>
              <a
                {...{
                  style: isLast ? { color: "#333" } : { cursor: "pointer" },
                }}
                onClick={() =>
                  !isLast &&
                  handleItemClick(
                    index === 0
                      ? hideParentsFolders
                        ? defaultFolderPath
                        : ""
                      : parseBreadcrumbFolderPath(folder, part),
                    true
                  )
                }>
                {part}
              </a>
            </li>
          );
        })}
    </ul>
  );

  const getTableRowJsx = (fileOrFolder: IFileOrFolder, ind: number) => {
    return (
      <TableRow key={ind} style={{ whiteSpace: "nowrap" }}>
        <TableCell style={{ width: "20px" }}>
          {fileOrFolder.isFolder ? (
            <FolderOutlined viewBox="2 -3 28 28" />
          ) : (
            <InsertDriveFileOutlined viewBox="2 -4 28 28" />
          )}
        </TableCell>
        <TableCell>
          <span
            className={classes.tableCellName}
            onClick={() =>
              handleItemClick(fileOrFolder.name, fileOrFolder.isFolder)
            }>
            {fileOrFolder.displayName}
          </span>
        </TableCell>
        <TableCell>
          {fileOrFolder.modified
            ? Helper.dateToLocale(fileOrFolder.modified)
            : ""}
        </TableCell>
        <TableCell>
          {!fileOrFolder.isFolder && (
            <Tooltip
              title={`${metadataLabels.size}: ${(
                (fileOrFolder.size || 0) / 1024
              ).toFixed(2)} ${metadataLabels.kb}, ${metadataLabels.name}: ${
                fileOrFolder.name
              }`}
              enterTouchDelay={100}
              leaveTouchDelay={4000}>
              <span style={{ whiteSpace: "nowrap" }}>
                <HelpOutline
                  style={{
                    fontSize: 16,
                    verticalAlign: "sub",
                    color: "#777",
                  }}
                />
              </span>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const getTableRows = () =>
    directoryStructure?.folders
      ? flatten(directoryStructure.folders)
          .map((item) => mapObject(item))
          .filter((item) => {
            if (flattenFolders) return true;
            const regex = new RegExp(
              `^(?!(?:${currentFolder})$)${currentFolder}/?[^\/]*$`,
              "i"
            );
            const isMatch = regex.test(item.name);
            return isMatch;
          })
      : [];

  const getEmptyHeaderCellJsx = (cell: IHeadCell) => <span></span>;

  return (
    <div className={classes.root}>
      {getBreadcrumbTrailJsx(currentFolder)}
      <GeneralDataListWithSortingFiltering
        headerCells={[
          {
            id: "isFolder",
            label: headerLabels[0],
            getCellJsx: getEmptyHeaderCellJsx,
          },
          {
            id: "sortingName",
            label: headerLabels[1],
          },
          { id: "modified", label: headerLabels[2] },
          { id: "", label: headerLabels[3], getCellJsx: getEmptyHeaderCellJsx },
        ]}
        rows={getTableRows()}
        getRowJsx={getTableRowJsx}
        filterByProperty={"name"}
        filterTermPrefixRegex={`${currentFolder}/?.*`}
        onFilterTyped={(term) => handleFilterTyped(term)}
        inputFieldPlaceholder={inputFieldPlaceholder}
        isLoading={false}
        order="desc"
        orderBy="modified"
      />
    </div>
  );
};

export default FileListingPage;
