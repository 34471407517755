import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as api from "../../shared/store/apiActions";
import { RootState } from "../../shared/store/rootReducer";
import { publicationsCleared } from "../../shared/store/publications";
import { IContent, PublicationType } from "../../shared/models/Publications";
import HTMLReactParser from "html-react-parser";
import { Grid } from "@material-ui/core";
import useStyles from "./InstructionsContentStyles";

const InstructionsContent = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { productionLine } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { publications, publication } = useSelector(
    (state: RootState) => state.publications
  );
  const [instructionsContent, setInstructionsContent] = useState<IContent>();
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    if (langCode) {
      dispatch(
        api.publication.getListing({
          langCode: langCode,
          publicationContext: productionLine.toString(),
          publicationType: PublicationType.Instructions.toString(),
        })
      );
    }
    return () => {
      dispatch(publicationsCleared());
    };
  }, [langCode, productionLine]);

  useEffect(() => {
    if (publications && publications.length > 0) {
      let id = publications.find((pub) => pub.title === props.title)
        ?.referenceId;
      if (id) {
        dispatch(
          api.publication.getPublication(
            langCode,
            productionLine.toString(),
            id.toString()
          )
        );
      }
    }
  }, [langCode, productionLine, publications]);

  useEffect(() => {
    if (publication && publication.title === props.title) {
      setInstructionsContent(publication);
      if (publication && publication.body && publication.body.length > 0) {
        setHasContent(true);
      }
    }
  }, [publication]);

  return (
    <Fragment>
      {hasContent && (
        <Grid container xs={12} className={classes.instructions} spacing={2}>
          <Grid container item xs={12}>
            {HTMLReactParser(
              instructionsContent?.body ? instructionsContent.body : ""
            )}
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default InstructionsContent;
