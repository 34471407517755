import { makeStyles } from "@material-ui/core";
import { ITheme } from "../../shared/themes/default";

const useStyles = makeStyles((theme: ITheme) => ({
  backGroundStyles: {
    backgroundImage: `url(${theme.custom.images.Nauta})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
  },
}));

export default useStyles;
