import React, { useState, useEffect, Fragment, useRef } from "react";
import { useParams } from "react-router-dom";
import { Grid, Divider } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import Button from "../../shared/components/Button/Button";
import useStyles from "./SlaughterNotificationPageStyles";
import * as api from "../../shared/store/apiActions";
import { t } from "../../shared/services/langService";
import { getOrganizationByProducerNumber } from "../../shared/store/user";
import FarmSelection from "../../shared/components/FarmSelection/FarmSelection";
import HealthQuestions from "../../shared/components/HealthQuestions/HealthQuestions";
import BeefSlaughterNotificationDTO, {
  NautaKetjuvastausDTO,
} from "../shared/models/BeefSlaughterNotificationDTO";
import AddAnimals from "../shared/components/AddAnimals/AddAnimals";
import {
  routeLeavingGuardActivated,
  routeLeavingGuardForced,
  routeLeavingGuardDeactivated,
} from "../../shared/store/ui/common";
import {
  beefSlaughterNotificationCleared,
  healthQuestionsCleared,
} from "../../shared/store/beef";
import InfoModal from "../../shared/components/InfoModal/InfoModal";
import { errorHandled } from "../../shared/store/api";
import scrollToLocation from "../../shared/utils/scrollToLocation";
import InputField from "../../shared/components/InputField/InputField";
import moment from "moment";
import { RootState } from "../../shared/store/rootReducer";

const SlaughterNotificationPage = () => {
  const classes = useStyles();
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const {
    healthQuestions: initHealthQuestions,
    beefSlaughterNotification,
  } = useSelector((state: RootState) => state.beef);
  const { livestockVenues: farms } = useSelector(
    getOrganizationByProducerNumber(producerNumber, "Nauta")
  );
  const [notificationStatus, setNotificationStatus] = useState("");
  const [
    forceCompletedStateAnimalSave,
    setForceCompletedStateAnimalSave,
  ] = useState(false);
  const { error } = useSelector((state: RootState) => state.api);
  const [infoModal, setInfoModal] = useState<any>({
    open: false,
    title: "",
    text: "",
    okButtonText: "",
    onClose: () => {},
  });
  const [isDirty, setIsDirty] = useState(0);
  const [reset, setReset] = useState(new Date().getTime());
  const [selectedFarm, setSelectedFarm] = useState<any>({
    current: "",
    prev: "",
  });
  const [addedAnimals, setAddedAnimals] = useState<any[]>([]);
  const [healthQuestions, setHealthQuestions] = useState<any[]>([]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const dispatch = useDispatch();
  const childRef = useRef<any>();
  const { notificationId } = useParams();

  useEffect(() => {
    if (notificationId) {
      dispatch(api.beef.getBeefSlaughterNotification(notificationId));
      return () => {
        dispatch(beefSlaughterNotificationCleared());
      };
    } else {
      dispatch(api.beef.getHealthQuestions());
      return () => {
        dispatch(healthQuestionsCleared());
      };
    }
  }, []);

  useEffect(() => {
    if (notificationId) {
      const nautaTeurasIlmoitusRivit =
        beefSlaughterNotification?.animalData?.nautaTeurasIlmoitusRivit;
      const livestockVenue = beefSlaughterNotification?.animalData?.pitopaikka;
      const ilmoituksenTila =
        beefSlaughterNotification?.animalData?.ilmoituksenTila;
      const ketjukysymykset =
        beefSlaughterNotification?.healthData?.ketjukysymykset;
      if (
        nautaTeurasIlmoitusRivit &&
        nautaTeurasIlmoitusRivit?.length > 0 &&
        ketjukysymykset
      ) {
        const animals = nautaTeurasIlmoitusRivit.map((r) => ({
          animalType: r.elainlaji.toString(),
          id: r.syntymatunnus,
          urgent: r.isKiireellinen,
          selectedWeek: r.toivottuVko.toString(),
          breed: r.rotu,
          birthday: r.syntymaPvm,
          lisatiedot: additionalInfo,
          rowId: r.rowId,
          rowDispatchState: r.rowDispatchState,
          kuormaValmis: (r as any).kuormaValmis,
          kuormausAika: (r as any).kuormausAika,
        }));
        setNotificationStatus(ilmoituksenTila ?? "");
        setSelectedFarm((prevState) => ({
          ...prevState,
          current: livestockVenue,
        }));
        setAddedAnimals(animals.sort(getAnimalOrdering));
        setAdditionalInfo(
          beefSlaughterNotification?.animalData?.lisatiedot ?? ""
        );
        setHealthQuestions(ketjukysymykset);
      }
    } else {
      setHealthQuestions(initHealthQuestions);
    }
  }, [beefSlaughterNotification, initHealthQuestions]);

  useEffect(() => {
    if (isDirty > 2) dispatch(routeLeavingGuardActivated());
  }, [isDirty]);

  useEffect(() => {
    if (error?.url?.indexOf("beef/slaughter") > -1 && error.status === 409) {
      dispatch(errorHandled());
      for (let status of ["av", "lo"]) {
        const animals = Object.keys(error.data[status] || {}).map((id) => ({
          id: id,
          notificationId:
            status === "lo"
              ? error.data[status][id].details[0].notificationId
              : error.data[status][id].notificationId[0],
        }));
        if (animals.length > 0) {
          showAnimalAlreadyExistModal(status, animals);
          break;
        }
      }
    }
  }, [error]);

  const showAnimalAlreadyExistModal = (status, animals) => {
    const animalIdsText =
      status === "lo"
        ? animals.map((a) => a.id).join(", ")
        : animals
            .map(
              (a) =>
                `${t("animalNotification_animalIdAlreadyAdded", {
                  0: a.id,
                  1: a.notificationId,
                })}.\n`
            )
            .reduce((pre, cur) => pre + cur, "");

    setInfoModal({
      open: true,
      title:
        status === "lo"
          ? t("animalNotification_animalsAlreadyAddedLOTitle")
          : t("animalNotification_animalsAlreadyAddedTitle"),
      text:
        status === "lo"
          ? t("animalNotification_animalsAlreadyAddedLOText", {
              0: animalIdsText,
            })
          : `${animalIdsText} ${t(
              "animalNotification_animalsAlreadyAddedText"
            )}`,
      cancelButtonText:
        status === "lo" ? t("animalNotification_rejectBirthId") : undefined,
      okButtonText:
        status === "lo" ? t("animalNotification_acceptBirthId") : t("ui_ok"),
      onClose: (button) => {
        setAddedAnimals((prevState) =>
          [
            ...prevState.map((a) => ({
              ...a,
              tila:
                animals.map((animal) => animal.id).indexOf(a.id) > -1
                  ? status
                  : a.tila,
            })),
          ].sort(getAnimalOrdering)
        );
        setInfoModal((prevState) => ({ ...prevState, open: false }));
        if (status === "av") scrollToLocation("#added-animals");
        else if (status === "lo" && button === "ok")
          setForceCompletedStateAnimalSave(true);
      },
    });
  };

  const handleFarmChange = (value, prevValue) => {
    setSelectedFarm({ current: value, prev: prevValue });
    if (isDirty) {
      setInfoModal({
        open: true,
        title: t("farmSelection_farmChangeTitle"),
        text: t("farmSelection_farmChangeText"),
        cancelButtonText: t("ui_cancel"),
        okButtonText: t("ui_rejectChanges"),
        onClose: (button) => handleInfoModalClose(button),
      });
    }
  };

  const resetForm = () => {
    setIsDirty(0);
    setAddedAnimals([]);
    setReset(new Date().getTime());
  };

  const handleInfoModalClose = (button) => {
    setInfoModal({ open: false });
    if (button === "ok") {
      resetForm();
      setSelectedFarm((prevState) => ({
        ...prevState,
        current: prevState.current,
      }));
    } else {
      setSelectedFarm((prevState) => ({
        ...prevState,
        current: prevState.prev,
      }));
    }
  };

  const handleCancel = () => {
    dispatch(routeLeavingGuardForced());
  };

  const handleAnimalRemoved = (id) => {
    setAddedAnimals(
      addedAnimals.filter((a) => a.id !== id).sort(getAnimalOrdering)
    );
    setIsDirty(3);
  };

  const handleAnimalEdited = (id, propertyValues) => {
    const modifiedAnimal = { ...addedAnimals.find((a) => a.id === id) };
    for (let p of propertyValues) modifiedAnimal[p[0]] = p[1];
    setAddedAnimals(
      [...addedAnimals.filter((a) => a.id !== id), modifiedAnimal].sort(
        getAnimalOrdering
      )
    );
    setIsDirty(3);
  };

  const handleSubmit = () => {
    const beefHealthQuestions: NautaKetjuvastausDTO = childRef.current?.validate();
    if (beefHealthQuestions) {
      const notification: BeefSlaughterNotificationDTO = {
        animalData: {
          elaimet: addedAnimals.map((a) => ({
            elainlaji: parseInt(a.animalType),
            syntymatunnus: a.id,
            kiire: a.urgent,
            toivottuVko: parseInt(a.selectedWeek),
            rowId: a.rowId,
            kuormaValmis: a.kuormaValmis,
            kuormausAika: a.kuormausAika,
          })),
          pitopaikka: selectedFarm.current,
          lisatiedot: additionalInfo,
          forceCompletedStateAnimalSave: forceCompletedStateAnimalSave,
        },
        healthData: beefHealthQuestions,
      };
      if (notificationId)
        dispatch(
          api.beef.updateBeefSlaughterInfo(notification, notificationId)
        );
      else dispatch(api.beef.createBeefSlaughterNotification(notification));
      dispatch(routeLeavingGuardDeactivated());
    }
  };

  const getAnimalOrdering = (a, b) => {
    if (notificationId) {
      if (a.rowDispatchState === "os" && b.rowDispatchState !== "os") return -1;

      if (a.rowDispatchState !== "os" && b.rowDispatchState === "os") return 1;

      if (a.rowDispatchState === "av" && b.rowDispatchState !== "av") return -1;

      if (a.rowDispatchState !== "av" && b.rowDispatchState === "av") return 1;

      if (a.rowDispatchState === "os" && b.rowDispatchState === "os") {
        if (a.kuormausAika.length === 0 && b.kuormausAika.length > 0) {
          return 1;
        } else if (a.kuormausAika.length > 0 && b.kuormausAika.length === 0) {
          return -1;
        } else if (a.kuormausAika.length === 0 && b.kuormausAika.length === 0) {
          return 0;
        }

        if (
          moment(a.kuormausAika[0].pvm).isBefore(moment(b.kuormausAika[0].pvm))
        ) {
          return -1;
        }
        if (
          moment(a.kuormausAika[0].pvm).isAfter(moment(b.kuormausAika[0].pvm))
        ) {
          return 1;
        }
      }
    }
    return a.id > b.id ? 1 : -1;
  };

  return (
    <Fragment>
      <InfoModal
        open={infoModal.open}
        title={infoModal.title}
        text={infoModal.text}
        cancelButtonText={infoModal.cancelButtonText}
        okButtonText={infoModal.okButtonText}
        onClose={infoModal.onClose}
      />

      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader level="1">
            {notificationId
              ? t("animalNotification_modifySlaughterNotification", {
                  0: notificationId,
                })
              : t("animalNotification_newSlaughterNotification")}
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <PageHeader level="2">
            {t("animalNotification_farmInformation")}
          </PageHeader>
        </Grid>

        <Grid item xs={12}>
          <FarmSelection
            farms={farms}
            selectedFarm={selectedFarm.current}
            onChange={(value, prevValue) => handleFarmChange(value, prevValue)}
            readonly={Boolean(notificationId) || addedAnimals.length > 0}
          />
        </Grid>

        {selectedFarm.current && (
          <Fragment>
            <Grid item xs={12}>
              <PageHeader level="2" id="added-animals">
                {t("animalNotification_animalInformation")}
              </PageHeader>
            </Grid>

            <Grid item xs={12}>
              <AddAnimals
                notificationType="slaughter"
                key={reset}
                addedAnimals={addedAnimals}
                modify={Boolean(notificationId)}
                readonly={notificationStatus === "os"}
                onAnimalsAdded={(animals) => {
                  setAddedAnimals(
                    [...addedAnimals, ...animals].sort(getAnimalOrdering)
                  );
                  setIsDirty(3);
                }}
                onAnimalTypeChange={(e) => setIsDirty(2)}
                onWeekChange={(e) => setIsDirty(2)}
                onAnimalRemoved={(id) => handleAnimalRemoved(id)}
                onAnimalEdited={(id, propertyValues) =>
                  handleAnimalEdited(id, propertyValues)
                }
              />
            </Grid>
            {addedAnimals?.length > 0 && (
              <Fragment>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <InputField
                    id="slaughter-notification-additionalinformation-input"
                    margin={""}
                    label={t("animalNotification_additionalInformation")}
                    multiline={true}
                    rows={3}
                    readonly={notificationStatus === "os"}
                    defaultValue={additionalInfo}
                    onChange={(e) => {
                      setAdditionalInfo(e.target.value);
                      setIsDirty(3);
                    }}
                    fullWidth={true}
                  />
                </Grid>

                <Grid item xs={12}>
                  <PageHeader level="2">
                    {t("animalNotification_chainInformation")}
                  </PageHeader>
                </Grid>

                <Grid item xs={12}>
                  <HealthQuestions
                    key={reset}
                    ref={childRef}
                    healthQuestions={healthQuestions}
                    animals={addedAnimals}
                    onValidate={(isValid) => setIsDirty(isValid ? 4 : 3)}
                    readonly={notificationStatus === "os"}
                  />
                </Grid>
              </Fragment>
            )}
          </Fragment>
        )}

        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item xs={12}>
            <Button
              id="slaughter-notification-cancel-button"
              type="cancel"
              style={{
                marginRight: "20px",
                marginTop: "5px",
                marginBottom: "5px",
              }}
              onClick={handleCancel}>
              {t("ui_cancel")}
            </Button>

            <Button
              id="slaughter-notification-save-button"
              type="ok"
              disabled={
                addedAnimals.length === 0 ||
                addedAnimals.some((a) => a.tila === "av") ||
                (notificationId && isDirty < 3)
              }
              onClick={handleSubmit}>
              {t("animalNotification_saveNotification")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SlaughterNotificationPage;
