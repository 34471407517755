import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import { AuthorizationContext } from "../../management/shared/models/Users";
import Content from "../../shared/components/Content/Content";
import Notification from "../../shared/components/Notification/Notification";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import { ADUserRole, UserRole } from "../../shared/enums/CoreEnums";
import ProductionArea from "../../shared/enums/productionLines";
import withTimeframe from "../../shared/hocs/withTimeframe";
import {
  errorHandlers,
  successHandlers,
} from "../../shared/utils/notificationHandler";
import GuidesPage from "../GuidesPage/GuidesPage";
import MainPage from "../MainPage/MainPage";
import NotificationListPage from "../NotificationListPage/NotificationListPage";
import SlaughterInfoPage from "../SlaughterInfoPage/SlaughterInfoPage";
import SlaughterNotificationPage from "../SlaughterNotificationPage/SlaughterNotificationPage";
import useStyles from "./PorkContentStyles";

const SlaughterInfoPageWithTimeFrame = withTimeframe(SlaughterInfoPage);
const NotificationListWithtimeFrame = withTimeframe(NotificationListPage);

const PorkContent = () => {
  const classes = useStyles();
  const [showRoutes, setShowRoutes] = useState(true);

  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      {showRoutes && (
        // TODO: need to do protected routes for sub paths
        <Switch>
          <ProtectedRoute
            path="/pork/guides"
            component={GuidesPage}
            allowedProductionLines={ProductionArea.Pork}
            authContext={AuthorizationContext.MainUser}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanSika,
              ADUserRole.SinettiKivinettiSika,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/pork/slaughternotifications/:notificationId"
            render={() => <SlaughterNotificationPage key={1} />}
            allowedProductionLines={ProductionArea.Pork}
            authContext={AuthorizationContext.MainUser}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanSika,
              ADUserRole.SinettiKivinettiSika,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/pork/slaughternotifications"
            render={() => <SlaughterNotificationPage key={2} />}
            allowedProductionLines={ProductionArea.Pork}
            authContext={AuthorizationContext.MainUser}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanSika,
              ADUserRole.SinettiKivinettiSika,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/pork/slaughterinfo/:contractId?/:animalTypeId?"
            component={SlaughterInfoPageWithTimeFrame}
            allowedProductionLines={ProductionArea.Pork}
            authContext={AuthorizationContext.MainUser}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanSika,
              ADUserRole.SinettiKivinettiSika,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/pork/notificationlist"
            component={NotificationListWithtimeFrame}
            allowedProductionLines={ProductionArea.Pork}
            authContext={AuthorizationContext.MainUser}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanSika,
              ADUserRole.SinettiKivinettiSika,
              UserRole.EmployeeUser,
            ]}
          />

          <Route path="/pork" component={MainPage} exact />
        </Switch>
      )}
      <Notification
        onChange={(showNotification) => setShowRoutes(!showNotification)}
        errorHandlers={[
          errorHandlers.economyErrorHandler,
          errorHandlers.porkErrorHandler,
        ]}
        successHandlers={[successHandlers.porkSuccessHandler]}
      />
    </Content>
  );
};

export default PorkContent;
