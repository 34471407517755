import React, {Fragment, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {CircularProgress} from '@material-ui/core';
import {getIsLoading} from "../../store/ui/common";
import useStyles from "./LoadingIndicatorStyles";

interface LoadingIndicatorProps {
     showAlwaysLoadingIndicator?: boolean
  }

const LoadingIndicator = ({showAlwaysLoadingIndicator = false}) => {
    const classes = useStyles();
    const isLoading = useSelector(state => getIsLoading(state));
    const [showLoading, setShowLoading] = useState(isLoading);

    useEffect(() => {
        setShowLoading(isLoading);
    }, [isLoading]);

    return (
        <Fragment>
        {
            (showLoading || showAlwaysLoadingIndicator) &&   
            <div className={classes.root}>
                <CircularProgress />
            </div> 
        }
        </Fragment>
    );
};

export default LoadingIndicator;