import { makeStyles } from "@material-ui/core";
import theme from "../../shared/themes";

const useStyles = makeStyles({
  textField: {
    "& > *": {
      color: theme.palette.primary.main,
    },
  },

  downloadLink: {
    "&:visited": {
      color: "blue",
    },
  },

  headerImage: {
    position: "relative",
  },

  calendar: {
    display: "inline",
    marginBottom: "-2px",
    "& > *": {
      fill: theme.palette.primary.main,
    },
  },

  eventSchedules: {
    marginTop: "10px",
    marginBottom: "20px",
    "& > *": {
      color: theme.palette.primary.main,
    },
  },

  htmlContentPositioning: {
    width: "90%",
    maxWidth: "90%",
    paddingRight: "5%",
  },

  htmlContent: {
    display: "block",
    fontSize: "15px",
    "& h1": {
      fontFamily: "Avenir Next W1G Bold",
      fontSize: "2.3rem",
      fontWeight: "bolder",
      color: theme.palette.primary.main,
    },
    "& h2": {
      fontFamily: "Avenir Next W1G Bold",
      fontSize: "2.3rem",
      fontWeight: "bolder",
      color: theme.palette.primary.main,
    },
    "& h3": {
      fontFamily: "Avenir Next W1G Bold",
      fontSize: "1.4rem",
      fontWeight: "bolder",
      color: theme.palette.primary.main,
    },
    "& img": {
      maxWidth: "100%",
      height: "auto",
      margin: "0.5%",
    },
    "& table": {
      display: "block",
      overflowX: "auto",
      marginTop: "15px",
      border: "0",
      borderCollapse: "collapse",
      width: "100%",
      maxWidth: "100%",
      height: "auto !important",
    },
    "& tbody": {
      overflow: "auto",
    },
    "& th, & td": {
      border: "0",
      paddingTop: "5px",
      paddingBottom: "5px",
      paddingLeft: "20px",
      paddingRight: "20px",
      textAlign: "left",
    },
    "& tr:nth-child(odd)": {
      backgroundColor: "#f8f8f8",
    },
    "& tr:nth-child(1)": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
});

export default useStyles;
