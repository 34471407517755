import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";

import ProductionArea from "../../../shared/enums/productionLines";
import { RootState } from "../../../shared/store/rootReducer";
import { productionLineUpdated } from "../../../shared/store/ui/settings";
import { IOrganization } from "../../../shared/store/user";

const ProductionAreaRouter = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { organizations } = useSelector((state: RootState) => state.user);

  const currentOrganization: IOrganization | undefined = organizations.find(
    (o) => Number(o.producerNumber) === Number(producerNumber)
  );

  useEffect(() => {
    if (params.context === "cooperative") {
      let productionAreaToSelect;
      for (let key in currentOrganization?.productionLines) {
        if (key === ProductionArea.Cooperative) {
          productionAreaToSelect = ProductionArea.Cooperative;
          break;
        }
      }
      if (productionAreaToSelect) {
        dispatch(
          productionLineUpdated({ productionLine: productionAreaToSelect })
        );
      }
    } else {
      let productionAreaToSelect;
      for (let key in currentOrganization?.productionLines) {
        if (key !== ProductionArea.Cooperative) {
          productionAreaToSelect = key;
          break;
        }
      }
      if (productionAreaToSelect) {
        dispatch(
          productionLineUpdated({ productionLine: productionAreaToSelect })
        );
      }
    }
  }, []);

  return <Redirect to={"/"} />;
};

export default ProductionAreaRouter;
