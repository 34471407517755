import KetjukysymysData from "./KetjukysymysData";

export class KuormausAikaData {
  pvm: string = "";
  klo: string = "";
}

class PorkNotificationDetails {
  rowDispatchState: string = "";
  rowId: number = 0;
  elainlaji: number = 0;
  toivottuVko: number = 0;
  osasto: string = "";
  merkintatunnus: string = "";
  ilmoitettuKpl: number = 0;
  kuormissaKpl: number = 0;
  jaljellaKpl: number = 0;
  kuormausAika: KuormausAikaData[] = [];
  autoilijanTiedot: string[] = [];
}

export class PorkNotificationData {
  notificationNumber: number = 0;
  type: string = "";
  state: string = "";
  date: string = "";
  furtherInfo: string = "";
  numAnimalsTotal: number = 0;
  numAnimalsLoaded: number = 0;
  numAnimalsPlannedForLoading: number = 0;
  porkDetails: PorkNotificationDetails[] = [];
  healthData: KetjukysymysData = new KetjukysymysData();
  hasHealthData: boolean = false;
}

export default class PorkDispatchListing {
  notifications: Map<string, PorkNotificationData[]> = new Map<
    string,
    PorkNotificationData[]
  >();
}

export interface IDepartmentRange {
  animalTypeId: number;
  name: string;
  startValue: number;
  endValue: number;
}
