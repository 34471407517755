import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import { AuthorizationContext } from "../../management/shared/models/Users";
import Content from "../../shared/components/Content/Content";
import Notification from "../../shared/components/Notification/Notification";
import ProtectedRoute from "../../shared/components/ProtectedRoute/ProtectedRoute";
import { ADUserRole, UserRole } from "../../shared/enums/CoreEnums";
import ProductionArea from "../../shared/enums/productionLines";
import withTimeframe from "../../shared/hocs/withTimeframe";
import {
  errorHandlers,
  successHandlers,
} from "../../shared/utils/notificationHandler";
import ForwardingNotificationPage from "../ForwardingNotificationPage/ForwardingNotificationPage";
import GuidesPage from "../GuidesPage/GuidesPage";
import MainPage from "../MainPage/MainPage";
import NotificationListPage from "../NotificationListPage/NotificationListPage";
import SlaughterInfoPage from "../SlaughterInfoPage/SlaughterInfoPage";
import SlaughterNotificationPage from "../SlaughterNotificationPage/SlaughterNotificationPage";
import useStyles from "./BeefContentStyles";

const SlaughterInfoPageWithTimeframe = withTimeframe(SlaughterInfoPage);
const NotificationListWithtimeFrame = withTimeframe(NotificationListPage);

const BeefContent = () => {
  const classes = useStyles();
  const [showRoutes, setShowRoutes] = useState(true);

  return (
    <Content backGroundStyles={classes.backGroundStyles}>
      {showRoutes && (
        <Switch>
          <ProtectedRoute
            path="/beef/forwardingnotifications/:notificationId"
            render={() => <ForwardingNotificationPage key={1} />}
            authContext={AuthorizationContext.MainUser}
            allowedProductionLines={ProductionArea.Beef}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanNauta,
              ADUserRole.SinettiKivinettiNauta,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/beef/forwardingnotifications"
            render={() => <ForwardingNotificationPage key={2} />}
            authContext={AuthorizationContext.MainUser}
            allowedProductionLines={ProductionArea.Beef}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanNauta,
              ADUserRole.SinettiKivinettiNauta,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/beef/guides"
            component={GuidesPage}
            authContext={AuthorizationContext.MainUser}
            allowedProductionLines={ProductionArea.Beef}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanNauta,
              ADUserRole.SinettiKivinettiNauta,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/beef/slaughternotifications/:notificationId"
            render={() => <SlaughterNotificationPage key={1} />}
            authContext={AuthorizationContext.MainUser}
            allowedProductionLines={ProductionArea.Beef}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanNauta,
              ADUserRole.SinettiKivinettiNauta,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/beef/slaughternotifications"
            render={() => <SlaughterNotificationPage key={2} />}
            authContext={AuthorizationContext.MainUser}
            allowedProductionLines={ProductionArea.Beef}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanNauta,
              ADUserRole.SinettiKivinettiNauta,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/beef/slaughterinfo/:contractId?/:animalTypeId?"
            component={SlaughterInfoPageWithTimeframe}
            authContext={AuthorizationContext.MainUser}
            allowedProductionLines={ProductionArea.Beef}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanNauta,
              ADUserRole.SinettiKivinettiNauta,
              UserRole.EmployeeUser,
            ]}
          />
          <ProtectedRoute
            path="/beef/notificationlist"
            component={NotificationListWithtimeFrame}
            authContext={AuthorizationContext.MainUser}
            allowedProductionLines={ProductionArea.Beef}
            allowedUserRoles={[
              ADUserRole.SinettiHKScanNauta,
              ADUserRole.SinettiKivinettiNauta,
              UserRole.EmployeeUser,
            ]}
          />
          <Route path="/beef" component={MainPage} />
        </Switch>
      )}
      <Notification
        onChange={(showNotification) => setShowRoutes(!showNotification)}
        errorHandlers={[
          errorHandlers.economyErrorHandler,
          errorHandlers.beefErrorHandler,
        ]}
        successHandlers={[successHandlers.beefSuccessHandler]}
      />
    </Content>
  );
};

export default BeefContent;
