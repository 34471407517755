import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TableCell, TableRow } from "../../shared/components/Table/Table";
import GeneralDataList from "../../shared/components/GeneralDatalist/GeneralDatalist";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import withTablePagination from "../../shared/hocs/withTablePagination";
import withTableSorting from "../../shared/hocs/withTableSorting";
import { t } from "../../shared/services/langService";
import * as api from "../../shared/store/apiActions";
import { slaughterNotificationsCleared } from "../../shared/store/poultry";
import { getIsLoading } from "../../shared/store/ui/common";
import Helper from "../../shared/utils/helper";
import { SlaughterNotificationDTO } from "../shared/models/SlaughterNotificationDTO";
import useStyles from "./LoadingTimetablePageStyles";
import { RootState } from "../../shared/store/rootReducer";

const GeneralDataListWithSortingPagination = withTableSorting(
  withTablePagination(GeneralDataList)
);

const LoadingTimetablePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { slaughterNotifications } = useSelector(
    (state: RootState) => state.poultry
  );

  const isLoading = useSelector((state) => getIsLoading(state));

  const getSortedHalls = (halls: number[] | undefined) => {
    if (!halls) {
      return [];
    }

    const adjacentRemovedHalls = halls.filter(
      (hall, index) => halls[index - 1] !== hall
    );
    const duplicateRemovedHalls = Array.from(
      new Set<number>(adjacentRemovedHalls)
    );
    // return an empty array if there are multiple groups for same hall number
    return adjacentRemovedHalls.length === duplicateRemovedHalls.length
      ? duplicateRemovedHalls
      : [];
  };

  let sortedHalls = getSortedHalls(
    slaughterNotifications?.map((notification) => Number(notification.hall))
  );

  const headerColumns = [
    { id: "hall", label: t("arrivingBatches_hall") },
    {
      id: "slaughterDate",
      label: t("arrivingBatches_slaughterDate"),
      isNumeric: true,
    },
    { id: "fastingStartTime", label: t("loadingTimetables_fastingStartTime") },
    { id: "quantity", label: t("loadingTimetables_birdQuantity") },
    { id: "loadStartTime", label: t("loadingTimetables_loadingStartTime") },
    { id: "loadEndTime", label: t("loadingTimetables_loadingFinished") },
    {
      id: "slaughterStartTime",
      label: t("loadingTimetables_slaughterStartTime"),
    },
  ];

  const getRowJsx = (notification: SlaughterNotificationDTO) => {
    const isGrey: boolean =
      sortedHalls.findIndex((hall) => hall === Number(notification.hall)) % 2 ==
      0;
    return (
      <TableRow
        key={notification.slaughterId}
        style={{
          whiteSpace: "nowrap",
          backgroundColor: isGrey ? "#efefef" : "#fff",
        }}>
        <TableCell>{notification.hall}</TableCell>
        <TableCell align="right">
          {Helper.dateToLocale(notification.slaughterDate)}
        </TableCell>
        <TableCell>
          {moment(notification.fastingStartTime).format("HH:mm")}
        </TableCell>
        <TableCell>{notification.quantity}</TableCell>
        <TableCell>
          {moment(notification.loadStartTime).format("HH:mm")}
        </TableCell>
        <TableCell>
          {moment(notification.loadEndTime).format("HH:mm")}
        </TableCell>
        <TableCell>
          {moment(notification.slaughterStartTime).format("HH:mm")}
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    dispatch(api.poultry.getSlaughterNotifications());
    return () => {
      dispatch(slaughterNotificationsCleared());
    };
  }, []);

  return (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12}>
        <PageHeader level="1">
          {t("nav_productionTimetable_loadingTimetable")}
        </PageHeader>
      </Grid>
      <Grid item xs={12}>
        <GeneralDataListWithSortingPagination
          headerCells={headerColumns}
          rows={slaughterNotifications ?? []}
          getRowJsx={getRowJsx}
          isLoading={isLoading}
          onSorting={(rows) =>
            (sortedHalls = getSortedHalls(rows?.map((row) => Number(row.hall))))
          }
        />
      </Grid>
    </Grid>
  );
};

export default LoadingTimetablePage;
