import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  content: {
    height: "100%",
    width: "100%",
  },
});

export default useStyles;
