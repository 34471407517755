import { Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import Carousel, { CarouselProps } from "react-material-ui-carousel";
import ReactPlayer from "react-player/lazy";

import { MediaType } from "../../models/Publications";
import Button from "../Button/Button";
import useStyles from "./CarouselComponentStyles";
import CustomLink from "../CustomLink/CustomLink";

export interface ICarouselComponentProps extends CarouselProps {}

const CauroselComponent: React.FC<ICarouselComponentProps> = ({
  children,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Carousel
        className={classes.carousel}
        indicatorContainerProps={{
          className: "customIndicatorPos",
          style: { position: "relative", bottom: "10px" },
        }}
        {...rest}>
        {children}
      </Carousel>
    </Fragment>
  );
};

export interface ICauroselMediaItem {
  mediaUrl: string;
  mediaType: MediaType;
  requiresAuth: boolean;
  title?: string;
  videoType?: string;
  linkText?: string;
  linkUrl?: string;
  displayDuration?: number | null;
}

export interface ICarouselMediaItemProps {
  item: ICauroselMediaItem;
  onPlayingEnded?: Function;
}

const linkButtonStyle = {
  position: "absolute",
  right: "10px",
  bottom: "10px",
  zIndex: "10",
};

export const CarouselMediaItem = (props: ICarouselMediaItemProps) => {
  const classes = useStyles(props);

  return (
    <Fragment>
      <div className={classes.advertisementContainer}>
        {props.item.mediaType === MediaType.Image && (
          <img className={classes.image} src={props.item.mediaUrl} alt="test" />
        )}
        {(props.item.mediaType === MediaType.Vimeo ||
          props.item.mediaType === MediaType.Youtube ||
          props.item.mediaType === MediaType.Unknown ||
          props.item.mediaType === MediaType.Video) && (
          <div className={classes.playerWrapper}>
            <ReactPlayer
              className={classes.reactPlayer}
              url={props.item.mediaUrl}
              config={{
                youtube: {
                  playerVars: {
                    autoplay: true,
                    background: true,
                    loop: false,
                    mute: true,
                    disablekb: 1,
                    origin: window.location.origin,
                  },
                },
                vimeo: {
                  playerOptions: {
                    autoplay: true,
                    background: true,
                    loop: false,
                  },
                },
                file: {
                  attributes: {
                    autoPlay: true,
                    muted: true,
                    controls: false,
                  },
                },
              }}
              onEnded={() => {
                if (props?.onPlayingEnded) {
                  props?.onPlayingEnded();
                }
              }}
              width="100%"
              height="250px"
            />
          </div>
        )}
        <div className={classes.controlLayer}>
          {props.item.title && (
            <Typography
              variant={"h2"}
              color={"primary"}
              className={classes.title}>
              {props.item.title}
            </Typography>
          )}
          {props.item.linkText && props.item.linkUrl && (
            <CustomLink to={props.item.linkUrl}>
              <Button style={linkButtonStyle} type="ok">
                {props.item.linkText}
              </Button>
            </CustomLink>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CauroselComponent;
