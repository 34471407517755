import {
  ReportRowBaseType,
  SlaughterReportBaseType,
} from "../../../shared/models/SlaughterReportListingDTO";

export class ReportRowPork extends ReportRowBaseType {
  department: string = "";
  fleshingPercentage?: number = 0;
}

class AvgValuesByDepartment {
  department?: number = 0;
  avgWeight: number = 0;
  avgFleshing?: number = 0;
  count: number = 0;
}

class DistributionBaseType {
  label: string = "";
  amount: number = 0;
  percentage: number = 0;
}

class PorkStatisticsData {
  fleshingDistribution: DistributionBaseType[] = [];
  weightDistribution: DistributionBaseType[] = [];
  avgValuesByDepartment: AvgValuesByDepartment[] = [];
  avgFleshingPercent?: number = 0;
  avgWeight?: number = 0;
}

export class SlaughterReportPork extends SlaughterReportBaseType {
  statistics: PorkStatisticsData = new PorkStatisticsData();
  animalRows: Map<string, ReportRowPork[]> = new Map<string, ReportRowPork[]>();
}
