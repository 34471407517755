import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import Button from "../../shared/components/Button/Button";
import ExpandableListView from "../../shared/components/ExpandableListView/ExpandableListView";
import GeneralDataList from "../../shared/components/GeneralDatalist/GeneralDatalist";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { TableCell, TableRow } from "../../shared/components/Table/Table";
import TimeframePicker from "../../shared/components/TimeframePicker/TimeframePicker";
import withContentBox from "../../shared/hocs/withContentBox";
import WithTablePagination from "../../shared/hocs/withTablePagination";
import WithTableSorting from "../../shared/hocs/withTableSorting";
import { t } from "../../shared/services/langService";
import * as api from "../../shared/store/apiActions";
import { beefSlaughterInfoReportCleared } from "../../shared/store/beef";
import { RootState } from "../../shared/store/rootReducer";
import { getIsLoading } from "../../shared/store/ui/common";
import Helper from "../../shared/utils/helper";
import SlaughterInfoHelper from "../../shared/utils/slaughterInfoHelper";
import {
  beefCarcassClassComparator,
  beefFatClassComparator,
} from "../../shared/utils/sorting";
import {
  ReportRowBeef,
  SlaughterReportBeef,
} from "../shared/models/SlaughterReportBeef";

// ALWAYS WRAP PAGINATION INSIDE SORTING order matters
const GeneralDataListWithSortingPaginationContentBox = WithTableSorting(
  WithTablePagination(withContentBox(GeneralDataList))
);

const ExpListViewWithSortingPagination = WithTableSorting(
  WithTablePagination(ExpandableListView)
);
const ExpListViewWithSorting = WithTableSorting(ExpandableListView);

const defaultRows = [];

const SlaughterInfoPage = ({
  currentStartDate,
  currentEndDate,
  fetchCounter,
  startTimeOnChange,
  endTimeOnChange,
  onFetchClick,
  errorMsg,
}) => {
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { beefSlaughterInfoReport: report } = useSelector(
    (state: RootState) => state.beef
  );
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const isLoading = useSelector((state) => getIsLoading(state));
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { contractId } = useParams();
  const { animalTypeId } = useParams();
  const [discards, setDiscards] = useState<number>(-1);
  const reportExists = (report) => report != undefined && report.length > 0;
  const [currentRow, setCurrentRow] = useState<SlaughterReportBeef | undefined>(
    undefined
  );

  useEffect(() => {
    return () => {
      dispatch(beefSlaughterInfoReportCleared());
    };
  }, []);

  useEffect(() => {
    dispatch(
      api.beef.getBeefSlaughterInfoReport(currentStartDate, currentEndDate)
    );
  }, [fetchCounter]);

  useEffect(() => {
    let selectedSlaughterInfoRow;
    if (report.length > 0) {
      if (animalTypeId === undefined) {
        selectedSlaughterInfoRow = report.find(
          (r) => r.contractNumber == contractId
        );
      } else {
        selectedSlaughterInfoRow = report.find(
          (r) => r.contractNumber == contractId && r.animalType == animalTypeId
        );
      }
      setCurrentRow(selectedSlaughterInfoRow);
    } else {
      setCurrentRow(undefined);
    }
  }, [contractId, animalTypeId, report]);

  const getCurrentRow = (): SlaughterReportBeef => {
    if (currentRow) {
      return currentRow;
    }
    return new SlaughterReportBeef();
  };

  const getDiscardRows = () => {
    if (discards > -1) {
      const animalRows: ReportRowBeef[] = [].concat.apply(
        [],
        Object.values(getCurrentRow()?.animalRows)
      );

      const data = animalRows
        .find((animal) => animal.carcassNumber == discards)
        ?.rejectionCauses.rejectionCauses.map((rejectionCause) => [
          rejectionCause.details[langCode].reason,
          rejectionCause.details[langCode].carcassPart,
          rejectionCause.amountKg,
        ]);
      return data || defaultRows;
    }
    return defaultRows;
  };

  const getBuctcherListJsx = (row, ind) => {
    return (
      <TableRow key={ind}>
        <TableCell>
          <Link
            to={`/beef/slaughterinfo/${row.contractNumber}/${row.animalType}`}>
            {row.contractNumber}
          </Link>
        </TableCell>
        <TableCell align="right">
          {Helper.dateToLocale(row.transportDate)}
        </TableCell>
        <TableCell>{row.animalType}</TableCell>
        <TableCell>{row.rowCount}</TableCell>
        <TableCell align="right">
          {row.statistics.avgWeight?.toFixed(1)}
        </TableCell>
        <TableCell align="center">
          {SlaughterInfoHelper.getPaymentOngoingCheckMark(
            row.paymentDateExists
          )}
        </TableCell>
      </TableRow>
    );
  };

  const currentAnimalRows =
    (Object.values(getCurrentRow()?.animalRows || defaultRows).reduce(
      (pre: any, cur: any) => pre.concat(cur),
      []
    ) as any[]).map((r: ReportRowBeef) => [
      r.carcassNumber,
      r.earNumber,
      Helper.dateToLocale(r.slaughterDate),
      r.breed,
      r.carcassConformationClass,
      r.fatClassification,
      Helper.addThousandSeparator(r.slaughterWeight?.toFixed(1)),
      r.numRejectedItems,
      Helper.addThousandSeparator(r.paymentToProducer?.toFixed(2)),
      SlaughterInfoHelper.getPaymentOngoingCheckMark(r.paymentDateExists),
    ]) || defaultRows;

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader level="1">
            {t("slaughterInfo_pageHeader")} {contractId}
          </PageHeader>
        </Grid>

        {contractId && animalTypeId && (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Button
              type="link"
              to={pathname.replace(`/${contractId}/${animalTypeId}`, "")}
              onClick={() => setDiscards(-1)}>
              {t("ui_back")}
            </Button>
          </Grid>
        )}
        {!contractId && (
          <Grid item xs={12}>
            <TimeframePicker
              selectedStartDate={currentStartDate}
              selectedEndDate={currentEndDate}
              startDateOnChange={startTimeOnChange}
              endDateOnChange={endTimeOnChange}
              onClick={onFetchClick}
              errorMsg={errorMsg}
            />
            <GeneralDataListWithSortingPaginationContentBox
              isExpandable={false}
              title={t("slaughterInfo_purchaseAgreementRows")}
              headerCells={[
                {
                  id: "contractNumber",
                  label: t("slaughterInfo_contract"),
                },
                {
                  id: "transportDate",
                  label: t("slaughterInfo_pickupDate"),
                  isNumeric: true,
                },
                {
                  id: "animalType",
                  label: t("slaughterInfo_animalType"),
                },
                {
                  id: "rowCount",
                  label: t("slaughterInfo_headcount"),
                },
                {
                  id: "statistics.avgWeight",
                  label: t("slaughterInfo_avgWeight"),
                  isNumeric: true,
                },
                {
                  id: "paymentOngoing",
                  label: t("slaughterInfo_paymentOngoing"),
                  centerContent: true,
                },
              ]}
              rows={reportExists(report) ? report : defaultRows}
              isLoading={isLoading}
              getRowJsx={getBuctcherListJsx}
              isShowAllDefault={true}
            />
          </Grid>
        )}
      </Grid>

      {contractId && (
        <Grid
          container
          spacing={0}
          alignItems="flex-start"
          justifyContent="space-between">
          <Grid
            container
            spacing={1}
            xs={12}
            sm={12}
            md={3}
            justifyContent="space-around"
            style={{ marginBottom: "8px" }}>
            <Grid item xs={12}>
              <ExpandableListView
                title={t("slaughterInfo_daySlaughterInfo")}
                rows={[
                  [`${t("slaughterInfo_producer")}:`, producerNumber],
                  [
                    `${t("slaughterInfo_contract")}:`,
                    getCurrentRow().contractNumber,
                  ],
                  [
                    `${t("slaughterInfo_slaughterhouse")}:`,
                    getCurrentRow().slaughterhouse,
                  ],
                  [
                    `${t("slaughterInfo_pickupDate")}:`,
                    Helper.dateToLocale(getCurrentRow().transportDate),
                  ],
                  [
                    `${t("slaughterInfo_animalType")}:`,
                    getCurrentRow().animalType,
                  ],
                  [
                    `${t("slaughterInfo_headcount")}:`,
                    getCurrentRow().rowCount,
                  ],
                  [
                    `${t("slaughterInfo_avgWeight")}:`,
                    getCurrentRow().statistics?.avgWeight,
                  ],
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <ExpListViewWithSorting
                title={t("slaughterInfo_discardReasonsDistribution")}
                headers={[
                  { content: t("slaughterInfo_discards") },
                  { content: t("slaughterInfo_headcountAbbr") },
                  { content: t("slaughterInfo_percent"), isNumeric: true },
                ]}
                rows={
                  getCurrentRow().rejectionCausesDistribution?.items?.map(
                    (s) => [
                      s.details[langCode].reason,
                      s.count,
                      s.percentage?.toFixed(2),
                    ]
                  ) || defaultRows
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            xs={12}
            sm={12}
            md={9}
            justifyContent="space-around">
            <Grid item xs={12}>
              <ExpListViewWithSorting
                title={t("slaughterInfo_discards")}
                headers={[
                  { content: t("slaughterInfo_discardReason") },
                  { content: t("slaughterInfo_animalPart") },
                  { content: t("slaughterInfo_discardKg"), isNumeric: true },
                ]}
                rows={getDiscardRows()}
                order="asc"
                orderBy="0"
                emptyRowsText={
                  discards === -1
                    ? t("slaughterInfo_daySlaughterInfoNoRows")
                    : ""
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ExpListViewWithSortingPagination
                title={t("slaughterInfo_animalSpecificInfo")}
                useColorSeparation={currentAnimalRows.length > 1}
                headers={[
                  { content: t("slaughterInfo_carcassID") },
                  { content: t("slaughterInfo_earBatchNumber") },
                  { content: t("slaughterInfo_butchingDate"), isNumeric: true },
                  { content: t("slaughterInfo_animalBreed") },
                  {
                    content: t("slaughterInfo_class"),
                    sortingMethod: beefCarcassClassComparator,
                  },
                  {
                    content: t("slaughterInfo_fat"),
                    sortingMethod: beefFatClassComparator,
                  },
                  {
                    content: t("slaughterInfo_butchingWeight"),
                    isNumeric: true,
                  },
                  { content: t("slaughterInfo_discards") },
                  {
                    content: t("slaughterInfo_carcassRemittanceValue"),
                    isNumeric: true,
                  },
                  {
                    content: t("slaughterInfo_paymentOngoing"),
                    centerContent: true,
                  },
                ]}
                rows={currentAnimalRows}
                onRowClicked={(row, i) => setDiscards(row[0])} // r.carcassNumber.
                rowsPerPage={10}
                labelRowsPerPage={t("ui_rows")}
                onPageChange={() => setDiscards(-1)}
                onSortingChange={() => setDiscards(-1)}
                isShowAllDefault={true}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default SlaughterInfoPage;
