import { Grid } from "@material-ui/core";
import { FieldHookConfig, Form, Formik, useField } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Button from "../../shared/components/Button/Button";
import Checkbox, {
  CheckboxProps,
} from "../../shared/components/Checkbox/Checkbox";
import InputField, {
  InputFieldProps,
} from "../../shared/components/InputField/InputField";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { ProductionArea } from "../../shared/enums/productionLines";
import { t } from "../../shared/services/langService";
import * as api from "../../shared/store/apiActions";
import { RootState } from "../../shared/store/rootReducer";
import BankAccountForm from "../shared/components/BankAccountForm/BankAccountForm";
import EditableTable from "../shared/components/EditableTable";
import EditDialog from "../shared/components/EditDialog/EditDialog";
import EInvoiceForm from "../shared/components/EInvoiceForm/EInvoiceForm";
import {
  isRequiredInYupSchema,
  REQUIRED,
} from "../shared/utils/validationSchemas";
import useStyles from "./CurrentFarmPageStyles";
import {
  IFarmCapacity,
  IFarmContactItem,
  IFarmContactItemsGroup,
} from "../../shared/models/FarmData";
import ReadOnlyTextField from "../../shared/components/ReadOnlyTextField/ReadOnlyTextField";
import { isValidUserRole } from "../../shared/helpers/navHelpers";
import { mainUserRoles } from "../../shared/enums/CoreEnums";
import { AuthorizationContext } from "../shared/models/Users";
import moment from "moment";

//#region validation
export const basicInfoValidationSchema = Yup.object().shape({
  companyName: Yup.string().required(() => t(REQUIRED)),
});

/**
 * translations through callback functions
 * otherwise this can not translate because it exits
 * out of the component context
 */
export const formValidationSchema = Yup.object().shape({
  basicInfo: basicInfoValidationSchema,
});

export const emailSchema = Yup.object().shape({
  info: Yup.string(),
  value: Yup.string()
    .email()
    .required(() => t(REQUIRED)),
});
//#endregion validation
//#region default & dummy data

const defaultFarmCapacity: IFarmCapacity = {
  amountsOfEmakko: 0,
  amountsOfLehma: 0,
  amountsOfEmolehma: 0,
  placesForNauta: 0,
  placesForLihasika: 0,
};

const defaultPhoneNumbers: IFarmContactItemsGroup[] = [];
const defaultEmailAddresses: IFarmContactItemsGroup[] = [];

const defaultValues = {
  basicInfo: {
    companyName: "",
    producerNumber: "",
    businessId: "",
    address: "",
    productionLines: "",
    livestockVenues: [
      {
        farmNumber: "",
        farmName: "",
        unitNumber: "",
        markingNumber: "",
        departments: [],
      },
    ],
  },
  farmCapacity: defaultFarmCapacity,
  phoneNumbers: defaultPhoneNumbers,
  emailAddresses: defaultEmailAddresses,
  otherInformation: {
    eInvoice: "",
    bankAccountNumber: "",
  },
};

//#endregion default & dummy data

//#region modal global

interface IModal {
  open: boolean;
  type: "email" | "phone" | "bankAccount" | "eInvoice" | undefined;
  onSuccess?: Function;
  title?: string;
  description?: string;
  childJsx?: any;
}

interface IModalMetadata {
  groupId: string; //AU, TV, ID, SP, etc.
  groupName: string; //Clear text name of the group.
  type: "email" | "phone" | "bankAccount" | "eInvoice" | undefined;
}

//#endregion modal global

interface IDisplayState {
  showFarmCapacity: boolean;
  showMarkingNumber: boolean;
  showDepartments: boolean;
  canEdit: boolean;
  checkRequired: boolean;
}

const defaultDisplayConfig: IDisplayState = {
  showFarmCapacity: true,
  showMarkingNumber: true,
  showDepartments: true,
  canEdit: false,
  checkRequired: false,
};

const CurrentFarmPage = () => {
  //#region column definitions
  const emailFormColumns = [
    {
      field: "value",
      headerName: t("farmContacts_dialog_emailAddress"),
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
    },
    {
      field: "info",
      headerName: t("farmContacts_dialog_additionalInfo"),
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
    },
  ];

  const phoneFormColumns = [
    {
      field: "value",
      headerName: t("farmContacts_dialog_phoneNumber"),
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
    },
    {
      field: "info",
      headerName: t("farmContacts_dialog_additionalInfo"),
      flex: 1,
      editable: true,
      disableColumnMenu: true,
      disableReorder: true,
      filterable: false,
    },
  ];

  //#endregion column definitions

  const classes = useStyles();

  //#region state & redux
  const dispatch = useDispatch();
  const organizations = useSelector(
    (state: RootState) => state.user.organizations
  );
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);
  const { roles, producers } = useSelector(
    (state: RootState) => state.auth.currentUser
  );
  const { roleMap } = useSelector((state: RootState) => state.user);
  const { organizationDataUpdatedByUser } = useSelector(
    (state: RootState) => state.user
  );
  const { validationRules } = useSelector(
    (state: RootState) => state.ui.validation
  );

  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );

  const currentOrganization = organizations.find(
    (o) => o.producerNumber === Number(producerNumber)
  );

  const [initialValues, setInitialValues] = useState(defaultValues);
  const [modal, setModal] = useState<IModal>({ open: false, type: undefined });
  const [modalData, setModalData] = useState<any>([]);
  const [display, setDisplay] = useState<IDisplayState>(defaultDisplayConfig);

  const [phoneSchema, setPhoneSchema] = useState(
    Yup.object().shape({
      info: Yup.string(),
      value: Yup.string().required(() => t(REQUIRED)),
    })
  );

  const emptyModalMetadata: IModalMetadata = {
    groupId: "",
    groupName: "",
    type: undefined,
  };
  const [modalMetadata, setModalMetadata] = useState<IModalMetadata>(
    emptyModalMetadata
  );

  //#endregion

  //#region lifecycle hooks

  // dispatch datafetching
  useEffect(() => {
    const filter =
      currentUser.authenticationSource === "HKScanAzureAD"
        ? producerNumber
        : "";
    dispatch(
      filter
        ? api.user.getUserFilteredOrganizations(
            currentUser.authenticationSource,
            filter?.toString()
          )
        : api.user.getUserOrganizations()
    );
  }, [organizationDataUpdatedByUser]);

  // populate form data
  useEffect(() => {
    populateCurrentFarmFormData();
  }, [currentOrganization, producerNumber]);

  useEffect(() => {
    if (validationRules) {
      const phoneNumberRegEx = new RegExp(
        validationRules["ContactInformation"]["PhoneNumber"]
      );

      if (phoneNumberRegEx) {
        setPhoneSchema(
          Yup.object().shape({
            info: Yup.string(),
            value: Yup.string()
              .required(() => t(REQUIRED))
              .matches(phoneNumberRegEx, "Value not valid"),
          })
        );
      }
    }
  }, [validationRules]);

  useEffect(() => {
    dispatch(api.ui.getValidationRules());
  }, [producerNumber]);

  //set display state
  useEffect(() => {
    const fc = currentOrganization?.farmCapacity;
    const showFarmCapacity: boolean =
      fc !== undefined &&
      fc !== null &&
      ((fc?.amountsOfEmakko && fc.amountsOfEmakko > 0) ||
        (fc?.amountsOfEmolehma && fc.amountsOfEmolehma > 0) ||
        (fc?.amountsOfLehma && fc.amountsOfLehma > 0) ||
        (fc?.placesForLihasika && fc.placesForLihasika > 0) ||
        (fc?.placesForNauta && fc.placesForNauta > 0))
        ? true
        : false;
    const isPorkProducer =
      currentOrganization?.productionLines?.["Sika"] !== undefined;

    const showDepartments =
      isPorkProducer &&
      currentOrganization?.livestockVenues.some((e) => {
        return e.departments !== undefined &&
          e.departments !== null &&
          e.departments.length > 0
          ? true
          : false;
      })
        ? true
        : false;

    const canEdit: boolean =
      producerNumber === undefined
        ? false
        : isValidUserRole(
            [...mainUserRoles],
            roles,
            producers,
            producerNumber ?? 0,
            AuthorizationContext.MainUser,
            roleMap
          );

    const checkRequired: boolean = currentOrganization?.checkRequired ?? false;

    setDisplay((prevState) => {
      let display = Object.assign({}, prevState);
      display.showFarmCapacity = showFarmCapacity;
      display.showMarkingNumber = isPorkProducer;
      display.showDepartments = showDepartments;
      display.canEdit = canEdit;
      display.checkRequired = checkRequired;
      return display;
    });
  }, [currentOrganization, producerNumber]);

  //#endregion lifecycle hooks

  //#region modal stuff

  const initModalByType = (
    type: "email" | "phone" | "bankAccount" | "eInvoice" | undefined,
    groupKey = "",
    groupId = ""
  ) => {
    let rowData;
    let currentModal: IModal;

    setModalMetadata({ groupId: groupId, groupName: groupKey, type: type });

    switch (type) {
      case "email":
        rowData =
          currentOrganization?.emailAddresses.find(
            (emailGroup) => emailGroup.groupName === groupKey
          )?.values ?? [];

        setModalData(rowData);

        currentModal = {
          type: "email",
          open: true,
          title: groupKey,
          childJsx: (
            <EditableTable
              columns={emailFormColumns}
              setRowData={setModalData}
              rows={rowData}
              enableActionButtons
              validationSchema={emailSchema}
            />
          ),
        };
        return currentModal;

      case "phone":
        rowData =
          currentOrganization?.phoneNumbers.find(
            (phoneGroup) => phoneGroup.groupName === groupKey
          )?.values ?? [];
        setModalData(rowData);

        currentModal = {
          type: "phone",
          open: true,
          title: groupKey,
          childJsx: (
            <EditableTable
              columns={phoneFormColumns}
              setRowData={setModalData}
              rows={rowData}
              enableActionButtons
              validationSchema={phoneSchema}
            />
          ),
        };
        return currentModal;
      case "bankAccount":
        currentModal = {
          type: "bankAccount",
          open: true,
          title: t("farmData_bankaccountedit"),
          childJsx: (
            <BankAccountForm handleSubmit={handleBankAccountModalSubmit} />
          ),
        };
        return currentModal;
      case "eInvoice":
        currentModal = {
          type: "eInvoice",
          open: true,
          title: t("farmData_einvoiceedit"),
          childJsx: <EInvoiceForm handleSubmit={handleEInvoiceModalSubmit} />,
        };
        return currentModal;
      default:
        currentModal = { open: false, type: undefined };
        return currentModal;
    }
  };

  const handleEInvoiceModalSubmit = (values) => {
    const { brokerIdentifier, eInvoiceAddress, eInvoiceOperator } = values;
    dispatch(
      api.organizationManagement.updateEInvoiceInformation(
        brokerIdentifier,
        eInvoiceOperator,
        eInvoiceAddress
      )
    );
    cleanUpModal();
  };

  const handleBankAccountModalSubmit = (values) => {
    const { bankAccountNumber, bankAccountDate } = values;
    dispatch(
      api.organizationManagement.updateBankAccoutInformation(
        bankAccountNumber,
        bankAccountDate
      )
    );

    cleanUpModal();
  };

  const getContactsHandledInModal = (
    data: any
  ): [any, string, IFarmContactItem[]] => {
    let contacts: IFarmContactItemsGroup[] = [];
    if (currentOrganization) {
      if (currentOrganization.emailAddresses) {
        contacts = contacts.concat(currentOrganization.emailAddresses);
      }
      if (currentOrganization.phoneNumbers) {
        contacts = contacts.concat(currentOrganization.phoneNumbers);
      }
    }

    const allContacts = contacts
      .map((c) => {
        return c.values;
      })
      .flat();

    const isChanged = (contactItem: IFarmContactItem): boolean => {
      let existItem = allContacts.find((c) => c.id === contactItem.id);

      return existItem === undefined ||
        (existItem.info === contactItem.info &&
          existItem.value === contactItem.value)
        ? false
        : true;
    };

    const findContact = (id: number): IFarmContactItem | undefined => {
      return allContacts.find((c) => c.id === id);
    };

    const getEditorEmailAddress = (contactItem: IFarmContactItem): string => {
      if (isChanged(contactItem)) {
        return currentUser.emailAddress;
      }
      let item = findContact(contactItem.id);
      if (item !== undefined) {
        return item.changedBy;
      }
      return currentUser.emailAddress;
    };

    const storedIds: Number[] = contacts
      ?.filter((c) => c.groupName === modalMetadata.groupName)
      .map((group) => {
        return group.values.map((val) => {
          return val.id;
        });
      })
      .flat();

    const modalIds: Number[] = data.map((row) => {
      return Number(row.id);
    });

    const addedIds: Number[] = modalIds?.filter((x) => !storedIds?.includes(x));
    const updatedIds: Number[] = modalIds?.filter(
      (x) => !addedIds?.includes(x)
    );

    const addedContacts: IFarmContactItem[] = data
      .filter((x) => addedIds.includes(x.id))
      .map((rec) => {
        return {
          id: 0,
          value: rec.value,
          info: rec.info,
          changedBy: currentUser.emailAddress,
        };
      });

    const updatedContacts: IFarmContactItem[] = data
      .filter((x) => updatedIds.includes(x.id))
      .map((rec) => {
        return {
          id: rec.id,
          value: rec.value,
          info: rec.info,
          changedBy: getEditorEmailAddress(rec),
        };
      });

    return [
      modalMetadata.type,
      modalMetadata.groupId,
      addedContacts.concat(updatedContacts),
    ];
  };

  const handleModalClose = (action: "ok" | "cancel" = "cancel") => {
    const isSubmit = action === "ok";
    const data = modalData;

    if (isSubmit) {
      switch (modalMetadata.type) {
        case "email":
        case "phone":
          const [
            modalType,
            groupId,
            addedOrUpdatedContacts,
          ] = getContactsHandledInModal(data);
          dispatch(
            api.organizationManagement.updateContactInformation(
              groupId,
              addedOrUpdatedContacts
            )
          );
          break;

        default:
          break;
      }
    }
    cleanUpModal();
  };

  const cleanUpModal = () => {
    setModal({ open: false, type: undefined });
    setModalData([]);
    setModalMetadata(emptyModalMetadata);
  };

  const handleSubmit = (values, { props, ...formikBag }) => {
    cleanUpModal();
  };

  const handleOpenModalClick = (
    type: "email" | "phone" | "bankAccount" | "eInvoice",
    groupKey = "",
    groupId = ""
  ) => {
    const initializedModal = initModalByType(type, groupKey, groupId);

    setModal({
      ...modal,
      ...initializedModal,
    });
  };
  //#endregion modal stuff

  //#region currentfarm form

  const populateCurrentFarmFormData = () => {
    let productionLinesNames: string[] = [];

    if (currentOrganization && producerNumber) {
      for (let key in currentOrganization.productionLines) {
        if (key !== ProductionArea.Cooperative) {
          productionLinesNames.push(
            ...currentOrganization.productionLines[key].map((p) => {
              return t(`productionLine_${p}`);
            })
          );
        }
      }

      /** Rest of those should not exist on form
       * since they're
       * handled independetly
       */
      const bindedValues = {
        basicInfo: {
          companyName: currentOrganization?.name ?? "",
          producerNumber: producerNumber?.toString() ?? "",
          businessId: currentOrganization?.businessId ?? "",
          address: `${currentOrganization?.producerAddress?.streetAddress}${
            currentOrganization?.producerAddress?.streetAddress ? ", " : ""
          } ${currentOrganization?.producerAddress?.postNumber} ${
            currentOrganization?.producerAddress?.postOffice
          }`,
          productionLines: productionLinesNames?.sort().join(", "),
          livestockVenues: currentOrganization?.livestockVenues
            ? [...currentOrganization?.livestockVenues]
            : [], // TODO: this binding causes some warnings because change from uncontrolled to controlled
        },
        farmCapacity: currentOrganization?.farmCapacity,
        phoneNumbers: currentOrganization?.phoneNumbers,
        emailAddresses: currentOrganization?.emailAddresses,
        otherInformation: {
          eInvoice: "",
          bankAccountNumber: "",
          bankAccountDate: "",
        },
      };
      setInitialValues({ ...initialValues, ...bindedValues });
    }
  };

  const onCurrentFarmFormSubmit = (values, formikBag) => {
    dispatch(api.organizationManagement.setFarmDataChecked());
    formikBag.setSubmitting(false);
    formikBag.resetForm({ values: initialValues });
  };

  const MyInputField = (props: InputFieldProps & FieldHookConfig<string>) => {
    const [field, meta] = useField(props);

    return (
      <InputField
        {...field}
        {...props}
        error={meta.touched && Boolean(meta.error)}
        errorText={meta.touched && meta.error}
        required={isRequiredInYupSchema(formValidationSchema, props.name)}
        singleLine
        disabled={props.disabled ?? true}
      />
    );
  };

  const MyCheckbox = (props: CheckboxProps & FieldHookConfig<boolean>) => {
    const [field, meta] = useField(props);

    return (
      <Checkbox
        {...field}
        {...props}
        onChangeAsBoolean={false}
        error={meta.touched && Boolean(meta.error) ? true : undefined}
        required={isRequiredInYupSchema(formValidationSchema, props.name)}
        disabled={props.disabled ?? true}
      />
    );
  };

  const keyBase = () => {
    return (
      initialValues.basicInfo.producerNumber ?? moment().format("YYYY-MM-DD")
    );
  };

  //#endregion
  return (
    <Grid container spacing={2} alignItems="flex-start">
      <EditDialog modal={modal} handleClose={handleModalClose}>
        {modal.childJsx}
      </EditDialog>
      <Grid item xs={12}>
        <PageHeader level="1">{t("options_currentFarm")}</PageHeader>
      </Grid>

      <Grid container item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={formValidationSchema}
          onSubmit={onCurrentFarmFormSubmit}
          enableReinitialize>
          {({ values, isSubmitting }) => (
            <Form noValidate>
              <Grid container item xs={12} spacing={4} alignItems="flex-start">
                <Grid container item xs={12} md={6}>
                  <PageHeader level="2">
                    {t("currentFarm_basicInfo")}
                  </PageHeader>
                  <Grid item xs={12} style={{ paddingBottom: "40px" }}>
                    <ReadOnlyTextField
                      key={`${keyBase()}-basicInfo_companyName`}
                      label={t("basicInfo_companyName")}
                      text={initialValues.basicInfo.companyName}
                    />
                    <ReadOnlyTextField
                      key={`${keyBase()}-basicInfo_producerNumber`}
                      label={t("basicInfo_producerNumber")}
                      text={initialValues.basicInfo.producerNumber}
                    />
                    <ReadOnlyTextField
                      key={`${keyBase()}-basicInfo_businessId`}
                      label={t("basicInfo_businessId")}
                      text={initialValues.basicInfo.businessId}
                    />
                    <ReadOnlyTextField
                      key={`${keyBase()}-basicInfo_address`}
                      label={t("basicInfo_address")}
                      text={initialValues.basicInfo.address}
                    />
                    <ReadOnlyTextField
                      key={`${keyBase()}-basicInfo_productionLines`}
                      label={t("basicInfo_productionLines")}
                      text={initialValues.basicInfo.productionLines}
                    />
                  </Grid>

                  {currentOrganization?.livestockVenues && (
                    <Grid item xs={12}>
                      <PageHeader level="2">
                        {t("currentFarm_livestockVenues")}
                      </PageHeader>
                    </Grid>
                  )}
                  <Grid container item spacing={4}>
                    {initialValues.basicInfo.livestockVenues.map(
                      (lv, index) => (
                        <Grid item xs={12} md={6} key={index}>
                          <ReadOnlyTextField
                            key={`${keyBase()}-basicInfo_farmNumber-${index}`}
                            label={t("basicInfo_farmNumber")}
                            text={
                              initialValues.basicInfo.livestockVenues[index]
                                .farmNumber
                            }
                          />
                          {initialValues.basicInfo.livestockVenues[index]
                            .farmName && (
                            <ReadOnlyTextField
                              key={`${keyBase()}-basicInfo_farmName-${index}`}
                              label={t("basicInfo_farmName")}
                              text={
                                initialValues.basicInfo.livestockVenues[index]
                                  .farmName
                              }
                            />
                          )}
                          <ReadOnlyTextField
                            key={`${keyBase()}-basicInfo_unitNumber-${index}`}
                            label={t("basicInfo_unitNumber")}
                            text={
                              initialValues.basicInfo.livestockVenues[index]
                                .unitNumber
                            }
                          />
                          {display.showMarkingNumber &&
                            initialValues.basicInfo.livestockVenues[index]
                              .markingNumber && (
                              <ReadOnlyTextField
                                key={`${keyBase()}-basicInfo_markingNumber-${index}`}
                                label={t("basicInfo_markingNumber")}
                                text={
                                  initialValues.basicInfo.livestockVenues[index]
                                    .markingNumber
                                }
                              />
                            )}
                          {display.showDepartments && (
                            <ReadOnlyTextField
                              key={`${keyBase()}-basicInfo_departments-${index}`}
                              label={t("basicInfo_departments")}
                              text={initialValues.basicInfo.livestockVenues[
                                index
                              ].departments.join(",")}
                            />
                          )}
                        </Grid>
                      )
                    )}
                  </Grid>

                  {display.showFarmCapacity && (
                    <Fragment>
                      <PageHeader level="2">
                        {t("currentFarm_farmCapacity")}
                      </PageHeader>
                      <Grid item xs={12} md={12}>
                        {initialValues?.farmCapacity?.placesForNauta &&
                        initialValues.farmCapacity.placesForNauta > 0 ? (
                          <ReadOnlyTextField
                            key={`${keyBase()}-farmCapacity_beefSlots`}
                            label={t("farmCapacity_beefSlots")}
                            text={initialValues.farmCapacity.placesForNauta.toString()}
                          />
                        ) : (
                          []
                        )}

                        {initialValues?.farmCapacity?.amountsOfEmolehma &&
                        initialValues.farmCapacity.amountsOfEmolehma > 0 ? (
                          <ReadOnlyTextField
                            key={`${keyBase()}-farmCapacity_motherCowAmount`}
                            label={t("farmCapacity_motherCowAmount")}
                            text={initialValues.farmCapacity.amountsOfEmolehma.toString()}
                          />
                        ) : (
                          []
                        )}

                        {initialValues?.farmCapacity?.amountsOfLehma &&
                        initialValues.farmCapacity.amountsOfLehma > 0 ? (
                          <ReadOnlyTextField
                            key={`${keyBase()}-farmCapacity_cowAmount`}
                            label={t("farmCapacity_cowAmount")}
                            text={initialValues.farmCapacity.amountsOfLehma.toString()}
                          />
                        ) : (
                          []
                        )}

                        {initialValues?.farmCapacity?.placesForLihasika &&
                        initialValues.farmCapacity.placesForLihasika > 0 ? (
                          <ReadOnlyTextField
                            key={`${keyBase()}-farmCapacity_meatPorkSlots`}
                            label={t("farmCapacity_meatPorkSlots")}
                            text={initialValues.farmCapacity.placesForLihasika.toString()}
                          />
                        ) : (
                          []
                        )}

                        {initialValues?.farmCapacity?.amountsOfEmakko &&
                        initialValues.farmCapacity.amountsOfEmakko > 0 ? (
                          <ReadOnlyTextField
                            key={`${keyBase()}-farmCapacity_femalePorkSlots`}
                            label={t("farmCapacity_femalePorkSlots")}
                            text={initialValues.farmCapacity.amountsOfEmakko.toString()}
                          />
                        ) : (
                          []
                        )}
                      </Grid>
                    </Fragment>
                  )}
                </Grid>

                <Grid container item xs={12} md={6}>
                  <PageHeader level="2">
                    {t("currentFarm_phoneNumbers")}
                  </PageHeader>
                  <Grid item xs={12} style={{ paddingBottom: "40px" }}>
                    <Fragment>
                      {currentOrganization?.phoneNumbers &&
                        currentOrganization.phoneNumbers.map(
                          (numberGroup, groupInd) => (
                            <Grid item container xs={12} key={groupInd}>
                              <Grid item xs={11}>
                                <ReadOnlyTextField
                                  key={`${keyBase()}-currentFarm_phoneNumbers-${
                                    numberGroup.groupId
                                  }`}
                                  label={numberGroup.groupName}
                                  text={numberGroup.values.map(
                                    (entity, index) => (
                                      <span
                                        key={`${keyBase()}-currentFarm_phoneNumbers-${
                                          numberGroup.groupId
                                        }-${entity.id}`}>
                                        {entity.value} {entity.info}
                                        <br />
                                      </span>
                                    )
                                  )}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                {display.canEdit && (
                                  <Button
                                    type="modal"
                                    onClick={() =>
                                      handleOpenModalClick(
                                        "phone",
                                        numberGroup.groupName,
                                        numberGroup.groupId
                                      )
                                    }>
                                    {t("farmDataForm_btnLabel_Edit")}
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                    </Fragment>
                  </Grid>

                  <PageHeader level="2">
                    {t("currentFarm_emailAdresses")}
                  </PageHeader>
                  <Grid item xs={12} style={{ paddingBottom: "40px" }}>
                    <Fragment>
                      {currentOrganization?.emailAddresses &&
                        currentOrganization.emailAddresses.map(
                          (emailGroup, groupInd) => (
                            <Grid item container xs={12} key={groupInd}>
                              <Grid item xs={11}>
                                <ReadOnlyTextField
                                  key={`${keyBase()}-currentFarm_emailAdresses-${
                                    emailGroup.groupId
                                  }`}
                                  label={emailGroup.groupName}
                                  text={emailGroup.values.map(
                                    (entity, index) => (
                                      <span
                                        key={`${keyBase()}-currentFarm_emailAdresses-${
                                          emailGroup.groupId
                                        }-${entity.id}`}>
                                        {entity.value} {entity.info}
                                        <br />
                                      </span>
                                    )
                                  )}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                {display.canEdit && (
                                  <Button
                                    type="modal"
                                    onClick={() =>
                                      handleOpenModalClick(
                                        "email",
                                        emailGroup.groupName,
                                        emailGroup.groupId
                                      )
                                    }>
                                    {t("farmDataForm_btnLabel_Edit")}
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                    </Fragment>
                  </Grid>
                  <PageHeader level="2">
                    {t("currentFarm_otherInformation")}
                  </PageHeader>
                  <Grid container item xs={12}>
                    <Fragment>
                      {currentOrganization?.generalAgreement &&
                        currentOrganization?.generalAgreement.length > 0 && (
                          <Grid item xs={12}>
                            <ReadOnlyTextField
                              key={`${keyBase()}-currentFarm_agreements`}
                              label={t("currentFarm_agreements")}
                              text={
                                currentOrganization?.generalAgreement
                                  ? currentOrganization?.generalAgreement &&
                                    currentOrganization?.generalAgreement.map(
                                      (item) => {
                                        return (
                                          <span
                                            key={`${keyBase()}-currentFarm_agreements-${
                                              item.id
                                            }`}>
                                            {item.name}
                                            <br />
                                          </span>
                                        );
                                      }
                                    )
                                  : ""
                              }
                            />
                          </Grid>
                        )}
                    </Fragment>

                    <Grid item xs={11}>
                      <ReadOnlyTextField
                        key={`${keyBase()}-otherInformation_eInvoice`}
                        label={t("otherInformation_eInvoice")}
                        text={
                          currentOrganization?.eInvoiceInUse &&
                          currentOrganization.eInvoiceInUse
                            ? t("farmData_eInvoiceInUse_yes")
                            : t("farmData_eInvoiceInUse_no")
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {display.canEdit && (
                        <Button
                          type="modal"
                          onClick={() => handleOpenModalClick("eInvoice")}>
                          {t("farmDataForm_btnLabel_Edit")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid item xs={11}>
                      <ReadOnlyTextField
                        key={`${keyBase()}-otherInformation_bankAccountNumber`}
                        label={t("otherInformation_bankAccountNumber")}
                        text={
                          currentOrganization?.bankAccountNumber
                            ? currentOrganization.bankAccountNumber
                            : t("farmData_eInvoiceInUse_no")
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      {display.canEdit && (
                        <Button
                          type="modal"
                          onClick={() => handleOpenModalClick("bankAccount")}>
                          {t("farmDataForm_btnLabel_Edit")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  {display.checkRequired && (
                    <Fragment>
                      <Grid item xs={12}>
                        <br />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        {display.canEdit && (
                          <Button
                            type="submit"
                            disabled={isSubmitting}
                            fullWidth>
                            {t("farmData_save")}
                          </Button>
                        )}
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default CurrentFarmPage;
