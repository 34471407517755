enum nautaTeurasElainlajit
{
  Sonni = 23,
  Lehma = 25,
  Hieho = 24,
  Mulli = 22,
  Pikkuvasikka = 20,
  Ruohovasikka = 21
}

export default nautaTeurasElainlajit;