import TagManager from "react-gtm-module";
import React, { useState } from "react";
import { RootState } from "../shared/store/rootReducer";
import { useSelector } from "react-redux";
import ProductionArea from "../shared/enums/productionLines";

export enum GTMEvents {
  buttonClick = "buttonClick",
  publicationContentFetch = "publicationContentFetch",
  trainingContentFetch = "trainingContentFetch",
  animalNotification = "animalNotification",
  economy = "economy",
}

export interface IGTMContentFetchProps {
  id: number;
  title?: string;
}

export interface IGTMButtonClickProps {
  id: string;
  title?: string;
}

interface IGTMDataLayerProps {
  event: string;
  url: string;
  productionLine: ProductionArea;
  producerNumber: number;
  langCode: string;
  operationUnit: string;
  authenticationSource: string;
  details?: IGTMContentFetchProps | IGTMButtonClickProps;
}

interface IGTMEventArgs {
  dataLayer: IGTMDataLayerProps;
}

const useGTM = () => {
  const { REACT_APP_TAGMANAGER_ID } = process.env;
  const {
    productionLine,
    producerNumber,
    operationUnit,
    allowAllCookies,
  } = useSelector((state: RootState) => state.ui.settings);
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const { authenticationSource } = useSelector(
    (state: RootState) => state.auth.currentUser
  );

  const tagManagerArgs = { gtmId: REACT_APP_TAGMANAGER_ID };
  if (allowAllCookies) {
    TagManager.initialize(tagManagerArgs);
    console.log("useGTM: TagManager initialized!");
  }

  const gtmDispatch = (
    event: GTMEvents,
    details: IGTMContentFetchProps | IGTMButtonClickProps
  ) => {
    if (allowAllCookies && producerNumber !== undefined) {
      const tagManagerArgs: IGTMEventArgs = {
        dataLayer: {
          event: event,
          url: window.location.href,
          productionLine: productionLine,
          producerNumber: producerNumber,
          langCode: langCode,
          operationUnit: operationUnit === 1 ? "HKScan" : "Kivinetti",
          authenticationSource: authenticationSource,
          details: details,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  return { gtmDispatch };
};

export default useGTM;
