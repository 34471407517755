import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles({
  gridContainer: {
    marginTop: "20px",
    marginBottom: "20px",
    padding: "100px"
  },
  form: {
    //margin: "200px"
    // width: '80%', // Fix IE 11 issue.
    // marginTop: theme.spacing(1),
  },
/*   content: {
    height: "100%",
    width: "100%",
  } */
});

export default useStyles;