import { Grid } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import Button from "../../shared/components/Button/Button";
import DropdownMenu from "../../shared/components/DropdownMenu/DropdownMenu";
import ExpandableListView from "../../shared/components/ExpandableListView/ExpandableListView";
import GeneralDataList from "../../shared/components/GeneralDatalist/GeneralDatalist";
import PageHeader from "../../shared/components/PageHeader/PageHeader";
import { TableCell, TableRow } from "../../shared/components/Table/Table";
import TimeframePicker from "../../shared/components/TimeframePicker/TimeframePicker";
import WithContentBox from "../../shared/hocs/withContentBox";
import WithTablePagination from "../../shared/hocs/withTablePagination";
import WithTableSorting from "../../shared/hocs/withTableSorting";
import { t } from "../../shared/services/langService";
import * as api from "../../shared/store/apiActions";
import { porkSlaughterInfoReportCleared } from "../../shared/store/pork";
import { RootState } from "../../shared/store/rootReducer";
import { getIsLoading } from "../../shared/store/ui/common";
import Helper from "../../shared/utils/helper";
import SlaughterInfoHelper from "../../shared/utils/slaughterInfoHelper";
import {
  ReportRowPork,
  SlaughterReportPork,
} from "../shared/models/SlaughterReportPork";

const GeneralDataListWithSortingPaginationContentBox = WithTableSorting(
  WithTablePagination(WithContentBox(GeneralDataList))
);

const ExpListViewWithSortingPagination = WithTableSorting(
  WithTablePagination(ExpandableListView)
);
const ExpListViewWithSorting = WithTableSorting(ExpandableListView);

const defaultRows = [];

const SlaughterInfoPage = ({
  currentStartDate,
  currentEndDate,
  fetchCounter,
  startTimeOnChange,
  endTimeOnChange,
  onFetchClick,
  errorMsg,
}) => {
  const { producerNumber } = useSelector(
    (state: RootState) => state.ui.settings
  );
  const { porkSlaughterInfoReport: report } = useSelector(
    (state: RootState) => state.pork
  );
  const { langCode } = useSelector((state: RootState) => state.ui.i18n);
  const isLoading = useSelector((state: RootState) => getIsLoading(state));
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { contractId } = useParams();
  const { animalTypeId } = useParams();
  const [discards, setDiscards] = useState<number>(-1);
  const reportExists = (report) => report != undefined && report.length > 0;

  const [selectedEarNumber, setSeletedEarNumber] = useState<number>(-1);
  const [earNumberSelectListOptions, setEarNumberSelectlistOptions] = useState<
    any[]
  >([]);
  const [currentRow, setCurrentRow] = useState<SlaughterReportPork | undefined>(
    undefined
  );
  const [filteredAnimalRows, setFilteredAnimalRows] = useState<any[]>([]);

  useEffect(() => {
    return () => {
      dispatch(porkSlaughterInfoReportCleared());
      setSeletedEarNumber(-1);
    };
  }, []);

  useEffect(() => {
    dispatch(
      api.pork.getPorkSlaughterInfoReport(currentStartDate, currentEndDate)
    );
  }, [fetchCounter]);

  useEffect(() => {
    setSeletedEarNumber(-1);
    let selectedSlaughterInfoRow;
    if (report.length > 0) {
      if (animalTypeId === undefined) {
        selectedSlaughterInfoRow = report.find(
          (r) => r.contractNumber == contractId
        );
      } else {
        selectedSlaughterInfoRow = report.find(
          (r) => r.contractNumber == contractId && r.animalType == animalTypeId
        );
      }
      setCurrentRow(selectedSlaughterInfoRow);
    } else {
      setCurrentRow(undefined);
    }
  }, [contractId, animalTypeId, report]);

  const handleDropdownMenuChange = (value) => {
    setSeletedEarNumber(value);
  };

  useEffect(() => {
    let animalRows = Object.values(getCurrentRow()?.animalRows || []).reduce(
      (pre: any, cur: any) => pre.concat(cur),
      []
    ) as any[];

    const earnumbers = animalRows.map((row: ReportRowPork) => ({
      name: "whatever",
      text: row.earNumber.toString(),
      value: row.earNumber,
    }));

    if (selectedEarNumber && selectedEarNumber > 0) {
      animalRows = animalRows.filter(
        (row: ReportRowPork) =>
          Number(row.earNumber) === Number(selectedEarNumber)
      );
    }

    const rows =
      animalRows.map((r: ReportRowPork) => [
        r.carcassNumber,
        r.earNumber,
        Helper.dateToLocale(r.slaughterDate),
        r.carcassConformationClass,
        r.fleshingPercentage,
        Helper.addThousandSeparator(r.slaughterWeight?.toFixed(1)),
        r.numRejectedItems,
        Helper.addThousandSeparator(r.paymentToProducer?.toFixed(2)),
        getCurrentRow().slaughterhouse,
        SlaughterInfoHelper.getPaymentOngoingCheckMark(r.paymentDateExists),
      ]) || defaultRows;

    const earNumberSelectListOptions = Helper.arrayUnique(earnumbers);

    earNumberSelectListOptions.unshift({
      name: "default",
      text: `${t("poultry_hall_filter_all")}`,
      value: -1,
    });

    setEarNumberSelectlistOptions(earNumberSelectListOptions);
    setFilteredAnimalRows(rows);
  }, [selectedEarNumber, currentRow]);

  const getCurrentRow = (): SlaughterReportPork => {
    if (currentRow) {
      return currentRow;
    }
    return new SlaughterReportPork();
  };

  const getDiscardRows = () => {
    if (discards > -1) {
      const animalRows: ReportRowPork[] = [].concat.apply(
        [],
        Object.values(getCurrentRow()?.animalRows)
      );

      const data = animalRows
        .find((animal) => animal.carcassNumber == discards)
        ?.rejectionCauses.rejectionCauses.map((rejectionCause) => [
          `${rejectionCause.details[langCode].reason}${
            rejectionCause.isSerious ? "!" : ""
          }`,
          rejectionCause.details[langCode].carcassPart,
          rejectionCause.amountKg,
        ]);
      return data || defaultRows;
    }
    return defaultRows;
  };

  const getBuctcherListJsx = (row, ind) => {
    return (
      <TableRow key={ind}>
        <TableCell>
          <Link
            to={`/pork/slaughterinfo/${row.contractNumber}/${row.animalType}`}>
            {row.contractNumber}
          </Link>
        </TableCell>
        <TableCell align="right">
          {Helper.dateToLocale(row.transportDate)}
        </TableCell>
        <TableCell>{row.animalType}</TableCell>
        <TableCell>{row.rowCount}</TableCell>
        <TableCell align="right">
          {row.statistics.avgFleshingPercent?.toFixed(1)}
        </TableCell>
        <TableCell align="right">
          {row.statistics.avgWeight?.toFixed(1)}
        </TableCell>
        <TableCell align="center">
          {SlaughterInfoHelper.getPaymentOngoingCheckMark(
            row.paymentDateExists
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12}>
          <PageHeader level="1">
            {t("slaughterInfo_pageHeader")} {contractId}
          </PageHeader>
        </Grid>

        {contractId && (
          <Grid item xs={12} style={{ marginBottom: "20px" }}>
            <Button
              type="link"
              to={pathname.replace(`/${contractId}/${animalTypeId}`, "")}
              onClick={() => setDiscards(-1)}>
              {t("ui_back")}
            </Button>
          </Grid>
        )}
        {!contractId && (
          <Grid item xs={12}>
            <TimeframePicker
              selectedStartDate={currentStartDate}
              selectedEndDate={currentEndDate}
              startDateOnChange={startTimeOnChange}
              endDateOnChange={endTimeOnChange}
              onClick={onFetchClick}
              errorMsg={errorMsg}
            />
            <GeneralDataListWithSortingPaginationContentBox
              title={t("slaughterInfo_purchaseAgreementRows")}
              isExpandable={false}
              headerCells={[
                {
                  id: "contractNumber",
                  label: t("slaughterInfo_contract"),
                },
                {
                  id: "transportDate",
                  label: t("slaughterInfo_pickupDate"),
                  isNumeric: true,
                },
                {
                  id: "animalType",
                  label: t("slaughterInfo_animalType"),
                },
                {
                  id: "rowCount",
                  label: t("slaughterInfo_headcount"),
                },
                {
                  id: "statistics.avgFleshingPercent",
                  label: t("slaughterInfo_fleshingAvgAbbr"),
                  isNumeric: true,
                },
                {
                  id: "statistics.avgWeight",
                  label: t("slaughterInfo_avgWeight"),
                  isNumeric: true,
                },
                {
                  id: "paymentDateExists",
                  label: t("slaughterInfo_paymentOngoing"),
                  centerContent: true,
                },
              ]}
              rows={reportExists(report) ? report : defaultRows}
              isLoading={isLoading}
              getRowJsx={getBuctcherListJsx}
              isShowAllDefault={true}
            />
          </Grid>
        )}
      </Grid>

      {contractId && (
        <Grid
          container
          spacing={0}
          alignItems="flex-start"
          justifyContent="space-between">
          <Grid
            container
            spacing={1}
            xs={12}
            sm={12}
            md={3}
            justifyContent="space-around"
            style={{ marginBottom: "8px" }}>
            <Grid item xs={12}>
              <ExpandableListView
                title={t("slaughterInfo_daySlaughterInfo")}
                rows={[
                  [`${t("slaughterInfo_producer")}:`, producerNumber],
                  [
                    `${t("slaughterInfo_contract")}:`,
                    getCurrentRow().contractNumber,
                  ],
                  [
                    `${t("slaughterInfo_pickupDate")}:`,
                    Helper.dateToLocale(getCurrentRow()?.transportDate),
                  ],
                  [
                    `${t("slaughterInfo_animalType")}:`,
                    getCurrentRow()?.animalType,
                  ],
                  [
                    `${t("slaughterInfo_headcount")}:`,
                    getCurrentRow()?.rowCount,
                  ],
                  [
                    `${t("slaughterInfo_fleshingAvg")}:`,
                    getCurrentRow().statistics?.avgFleshingPercent?.toFixed(1),
                  ],
                  [
                    `${t("slaughterInfo_weightAvg")}:`,
                    getCurrentRow().statistics?.avgWeight?.toFixed(1),
                  ],
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <ExpandableListView
                title={t("slaughterInfo_fleshingPercent")}
                headers={[
                  { content: "  " },
                  { content: t("kpl"), isNumeric: true },
                  {
                    content: t("slaughterInfo_percentageOutOfBatch"),
                    isNumeric: true,
                  },
                ]}
                rows={
                  getCurrentRow().statistics.fleshingDistribution?.map((s) => [
                    t(
                      SlaughterInfoHelper.constructLabelText(
                        "slaughterInfo_",
                        s.label
                      )
                    ),
                    s.amount,
                    s.percentage === 0 ? s.percentage : s.percentage.toFixed(1),
                  ]) || defaultRows
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ExpandableListView
                title={t("slaughterInfo_slaughterWeight")}
                headers={[
                  { content: "  " },
                  { content: t("kpl"), isNumeric: true },
                  {
                    content: t("slaughterInfo_percentageOutOfBatch"),
                    isNumeric: true,
                  },
                ]}
                rows={
                  getCurrentRow().statistics.weightDistribution?.map((s) => [
                    t(
                      SlaughterInfoHelper.constructLabelText(
                        "slaughterInfo_",
                        s.label
                      )
                    ),
                    s.amount,
                    s.percentage === 0 ? s.percentage : s.percentage.toFixed(1),
                  ]) || defaultRows
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ExpListViewWithSorting
                title={t("slaughterInfo_avgValuesByDepartment")}
                headers={[
                  { content: t("slaughterInfo_department") },
                  { content: t("slaughterInfo_headcountDepartment") },
                  {
                    content: t("slaughterInfo_avgWeightDepartment"),
                    isNumeric: true,
                  },
                  {
                    content: t("slaughterInfo_avgFleshingDepartment"),
                    isNumeric: true,
                  },
                ]}
                rows={
                  getCurrentRow().statistics.avgValuesByDepartment?.map((s) => [
                    s.department,
                    s.count,
                    s.avgWeight?.toFixed(1),
                    s.avgFleshing?.toFixed(1),
                  ]) || defaultRows
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ExpListViewWithSorting
                title={t("slaughterInfo_discardReasonsDistribution")}
                headers={[
                  {
                    content: t("slaughterInfo_discards"),
                    highlightText: "!",
                  },
                  { content: t("slaughterInfo_headcountAbbr") },
                  { content: t("slaughterInfo_percent"), isNumeric: true },
                ]}
                rows={
                  getCurrentRow().rejectionCausesDistribution?.items?.map(
                    (s) => [
                      `${s.details[langCode].reason}${s.isSerious ? "!" : ""}`,
                      s.count,
                      s.percentage?.toFixed(2),
                    ]
                  ) || defaultRows
                }
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            xs={12}
            sm={12}
            md={9}
            justifyContent="space-around">
            <Grid item xs={12}>
              <ExpListViewWithSorting
                title={t("slaughterInfo_discards")}
                headers={[
                  {
                    content: t("slaughterInfo_discardReason"),
                    highlightText: "!",
                  },
                  { content: t("slaughterInfo_animalPart") },
                  { content: t("slaughterInfo_discardKg"), isNumeric: true },
                ]}
                order="asc"
                orderBy="0"
                rows={getDiscardRows()}
                emptyRowsText={
                  discards === -1
                    ? t("slaughterInfo_daySlaughterInfoNoRows")
                    : ""
                }
              />
            </Grid>

            <Grid
              item
              style={{
                alignSelf: "center",
                paddingLeft: "5px",
                marginTop: "0px",
              }}
              xs={12}>
              <DropdownMenu
                id="slaughter-info-earnumber-select"
                onChange={(value) => handleDropdownMenuChange(value)}
                selectedValue={selectedEarNumber}
                options={earNumberSelectListOptions}
                label={t("slaughterInfo_selectEarNumber")}
                singleLine={true}
              />
            </Grid>

            <Grid item xs={12}>
              <ExpListViewWithSortingPagination
                title={t("slaughterInfo_animalSpecificInfo")}
                headers={[
                  { content: t("slaughterInfo_carcassID") },
                  { content: t("slaughterInfo_earBatchNumber") },
                  { content: t("slaughterInfo_butchingDate"), isNumeric: true },
                  { content: t("slaughterInfo_class") },
                  { content: t("slaughterInfo_fatClass") },
                  {
                    content: t("slaughterInfo_butchingWeight"),
                    isNumeric: true,
                  },
                  { content: t("slaughterInfo_discards2") },
                  {
                    content: t("slaughterInfo_carcassRemittanceValue"),
                    isNumeric: true,
                  },
                  { content: t("slaughterInfo_slaughterhouse") },
                  {
                    content: t("slaughterInfo_paymentOngoing"),
                    centerContent: true,
                  },
                ]}
                rows={filteredAnimalRows}
                onRowClicked={(row, i) => setDiscards(row[0])} // r.carcassNumber.
                rowsPerPage={10}
                labelRowsPerPage={t("ui_rows")}
                onPageChange={() => setDiscards(-1)}
                onSortingChange={() => setDiscards(-1)}
                isShowAllDefault={true}
                useColorSeparation={filteredAnimalRows.length > 1}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
};

export default SlaughterInfoPage;
