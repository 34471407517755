import { makeStyles } from "@material-ui/core";
import { ITheme } from "../../shared/themes/default";

const useStyles = makeStyles((theme: ITheme) => ({
  backGroundStyles: ({ productionLine }: any) => ({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundImage: `url(${theme.custom.images[productionLine]})`,
  }),
  gridContainerStyles: {
    paddingTop: "0",
  },
}));

export default useStyles;
