import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  main: {
    minHeight: "calc(100vh - 337px)",
    backgroundColor: "#E1E0DC",
  },
  secondMain: {
    backgroundColor: "#E1E0DC",
    marginBottom: "20px",
    padding: "20px",
  },
  printInfo: {
    display: "none",
  },
}));

export default useStyles;
