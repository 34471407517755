import { Button as MuiButton, Link as DirectLink } from "@material-ui/core";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as RemoveIcon } from "../../../assets/images/iconmonstr-x-mark-1.svg";
import useStyles from "./ButtonStyles";

interface ButtonProps {
  type:
    | "ok"
    | "cancel"
    | "add"
    | "table"
    | "modal"
    | "link"
    | "direct-link"
    | "submit"
    | "modal-submit"
    | "powerbi-page-select"
    | "powerbi-page-select-clicked";
  fn?: "remove" | "action";
  to?: string;
  [x: string]: any;
}

const Button: React.FC<ButtonProps> = ({ type, children, fn, to, ...rest }) => {
  const classes = useStyles();
  return (
    <Fragment>
      {type === "ok" && (
        <MuiButton
          variant={"contained"}
          color={"primary"}
          {...rest}
          className={`${classes.button} ${classes.okButton}`}>
          {children}
        </MuiButton>
      )}
      {type === "cancel" && (
        <MuiButton variant={"contained"} {...rest} className={classes.button}>
          {children}
        </MuiButton>
      )}
      {type === "add" && (
        <MuiButton
          variant={"contained"}
          color={"primary"}
          {...rest}
          className={`${classes.button} ${classes.addButton}`}>
          {children}
        </MuiButton>
      )}
      {type === "table" && fn === "remove" && (
        <MuiButton
          variant={"text"}
          {...rest}
          className={`${classes.button} ${classes.tableButton}`}
          classes={{ startIcon: classes.iconClass }}
          startIcon={<RemoveIcon height={7} />}>
          {children}
        </MuiButton>
      )}
      {type === "table" && fn === "action" && (
        <MuiButton
          variant={"text"}
          {...rest}
          className={`${classes.button} ${classes.tableButton}`}
          style={{ backgroundColor: "#fff", padding: "4px 6px" }}>
          {children}
        </MuiButton>
      )}
      {type === "modal" && (
        <MuiButton
          variant={"text"}
          {...rest}
          className={`${classes.button} ${classes.modalButton}`}>
          {children}
        </MuiButton>
      )}
      {type === "link" && (
        <Link to={to} className={classes.linkButton}>
          <Button type="ok" {...rest}>
            {children}
          </Button>
        </Link>
      )}
      {type === "direct-link" && (
        <DirectLink href={to} className={classes.linkButton} target="_blank">
          <Button type="ok" {...rest}>
            {children}
          </Button>
        </DirectLink>
      )}
      {type === "submit" && (
        <MuiButton
          type="submit"
          variant={"contained"}
          color={"primary"}
          {...rest}
          className={`${classes.button} ${classes.okButton}`}>
          {children}
        </MuiButton>
      )}
      {type === "modal-submit" && (
        <MuiButton
          type="submit"
          variant={"text"}
          {...rest}
          className={`${classes.button} ${classes.modalButton}`}>
          {children}
        </MuiButton>
      )}
      {type === "powerbi-page-select" && (
        <MuiButton
          type="submit"
          variant={"text"}
          {...rest}
          className={`${classes.button} ${classes.powerBIPageSelect}`}>
          {children}
        </MuiButton>
      )}
      {type === "powerbi-page-select-clicked" && (
        <MuiButton
          type="submit"
          variant={"text"}
          {...rest}
          className={`${classes.button} ${classes.powerBIPageSelectClicked}`}>
          {children}
        </MuiButton>
      )}
    </Fragment>
  );
};

export default Button;
